// This is the JavaScript file corresponding to index.html.
// This file loads into an HTML element with the id "root".

// Include necessary imports here. Sort them according to name.
import "./index.css"; // Import the CSS file associated with the component
import App from "./App"; // Import the main App component
import React from "react"; // Import the React library
import ReactDOM from "react-dom"; // Import the ReactDOM library
import { HunelProvider, HunelCreditCard } from "reactjs-credit-card";

const hunel = new HunelCreditCard();

// const RootComponent = (
//   <React.StrictMode>
//     <HunelProvider config={hunel}>
//       <App />
//     </HunelProvider>
//   </React.StrictMode>
// );
// Render the App component to the HTML element with the id "root"
ReactDOM.render(

  <React.StrictMode>
    <HunelProvider config={hunel}>
      <App />
    </HunelProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

// ReactDOM.render(RootComponent, document.getElementById('root'));
// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(RootComponent, rootElement);
// } else {
//   ReactDOM.render(RootComponent, rootElement);
// }
