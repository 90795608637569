import React from "react";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardActions,
  Container,
  Backdrop,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserContext } from "../../components/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, storage, dbQA } from "../../components/Firebase";
import { doc, getDoc } from "@firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { FooterBlog } from "../../components/Footer";
import { Helmet } from "react-helmet";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import dayjs from "dayjs";
import { months } from "../../Constant/Variables";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Poppins",
  },
  card: {
    maxWidth: "100%",
    margin: "auto",
    marginBottom: "2%",
    transition: "box-shadow 0.3s ease",
    "&:hover": {
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)", // Apply elevation on hover
    },
    cursor: "pointer",
    backgroundColor: "#f6f6f6",
  },
  media: {
    height: 140,
  },

  genericButton: {
    marginRight: "1rem",
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#fff", // Dark gray text color
    backgroundColor: "#187cae", // Blue background color
    border: "none",
    borderRadius: "4px",
    padding: "0.5rem 1rem", // Increase padding for larger clickable area
    cursor: "pointer",
    transition: "background-color 0.3s ease, color 0.3s ease",
    "&:hover": {
      backgroundColor: "#444", // Darker blue on hover
      color: "#fff", // White text on hover
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      padding: "0.4rem 0.8rem", // Adjust padding for smaller screens
    },
  },

  blogTitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "left",
  },

  aref: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "left",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "1.2rem",
    transform: "none",
    lineHeight: "1.5", // or whatever value you want
    color: "Black", // Set the default link color

    "&:hover": {
      textDecoration: "none",
      color: "#187cae", // Change color to green on hover
      fontSize: "1.2rem",
      fontWeight: "bold",
      lineHeight: "1.5", // or whatever value you want
    },
  },

  blogDescription: {
    fontFamily: "Poppins",
    fontWeight: "lightweight",
    textAlign: "left",
    marginTop: "1%",
    color: "#777f87",
    fontSize: "15px",
  },
  rootService: {
    padding: theme.spacing(3),
    maxWidth: "100%",
    fontFamily: "Poppins",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  iconService: {
    fontSize: "3rem",
    fontFamily: "Poppins",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Light gray background overlay
    backdropFilter: "blur(2px)",
    transform: "scale(1.05)", // Add a scaling effect
  },
}));

const BlogHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [user, setUser] = React.useContext(UserContext);
  const [userDetails, setUserDetails] = React.useState({});
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [accountMenu, setAccountMenu] = React.useState(null);
  const [serviceMenu, setServiceMenu] = React.useState(null);
  const [LandscapeMenu, setLandscapeMenu] = React.useState(null);
  const [allHome, setAllHome] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [paginatedObjects, setPaginatedObjects] = React.useState([]);
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  //Generic functions
  const handleOpenPopover = (event, state) => {
    state(event.currentTarget);
  };

  const handleClosePopover = (event, state) => {
    state(null);
  };

  const handlePageTop = (event) => {
    setServiceMenu(null);
  };

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          const UserAcct = await getDoc(doc(db, "UserAcct", user.uid));
          setUserDetails({
            name: UserAcct.data().FirstName + " " + UserAcct.data().LastName,
          });
        } else {
          setUser(null);
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // New firebase method to get all blogs:
  React.useEffect(async () => {
    const currentDate = dayjs();

    // Get the current month (e.g., "January", "February", etc.)
    // const currentMonth = currentDate.month() + 1;
    // setMonth(currentMonth.toString());

    // Get the current year (e.g., 2023)
    // const currentYear = currentDate.format("YYYY");
    // setYear(currentYear.toString());

    const TITLEREF = await getDoc(doc(dbQA, "Blogs", "Titles"));
    const all = TITLEREF.data().All;
    // Sort the array based on the 'date' property
    all.sort((a, b) => {
      // Parse the date strings into Date objects for comparison
      const dateA = new Date(a.Date);
      const dateB = new Date(b.Date);

      // Compare the dates
      return dateB - dateA;
    });
    setAllHome(all);
    setLoading(false);
  }, []);

  // React.useEffect(() => {
  //   if (allHome.length > 0) {
  //     const startIndex = page * rowsPerPage;
  //     setPaginatedObjects(allHome.slice(startIndex, startIndex + rowsPerPage));
  //   }
  // }, [allHome, page, rowsPerPage]);

  // console.log(paginatedObjects, "paginationObjects");

  return (
    <>
      {/* Header for Seo */}
      <Helmet>
        <title>Lawnber Blogs</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Access all our lawn maintainence blogs under one roof. We have blogs about landscaping, custom design, lawn maintenance."
        />
        <link rel="canonical" href="https://lawnber.com/blog" />
        <meta
          property="og:image"
          content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
        />
        <meta property="og:title" content="Lawnber Blogs" />
        <meta
          property="og:description"
          content="Access all our lawn maintainence blogs under one roof. We have blogs about landscaping, custom design, lawn maintenance.."
        />
        <meta property="og:url" content="https://lawnber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <AppBar
        color="white"
        elevation={3}
        position="fixed"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            {/* <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setServiceMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Services
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={serviceMenu}
                open={Boolean(serviceMenu)}
                onClose={(event) => handleClosePopover(event, setServiceMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Lawn Service
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Snow Service
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Spring Cleanup
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setLandscapeMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Landscaping
              </Button>
              <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button"
                anchorEl={LandscapeMenu}
                open={Boolean(LandscapeMenu)}
                onClose={(event) => handleClosePopover(event, setLandscapeMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Custom Designs
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Features v/s Cost
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  High vs Low Maintenance
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  {" "}
                  Hardscape
                </MenuItem>
              </Menu>
            </Box> */}

            <MenuItem
              value="Lawn Service"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: matchesSm ? "0.9rem" : matchesMd ? "0.9rem" : "1rem",
              }}
              onClick={() => history.push("/Blog")}
            >
              Blogs
            </MenuItem>

            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/contact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
              >
                Contact Us
              </Button>
            )}

            <Box>
              <div
                className="navLink"
                onClick={(event) => handleOpenPopover(event, setAccountMenu)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                {userDetails["name"] ? userDetails["name"].split(" ")[0] : ""}
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={accountMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "1%" }}
                open={Boolean(accountMenu)}
                onClose={(event) => handleClosePopover(event, setAccountMenu)}
              >
                <>
                  {matchesSm && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/Contact")}
                    >
                      Contact US
                    </MenuItem>
                  )}

                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/Dashboard")}
                    >
                      Dashboard
                    </MenuItem>
                  )}
                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/profile")}
                    >
                      Profile
                    </MenuItem>
                  )}
                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => {
                        signOut(auth)
                          .then(() => {
                            // Sign-out successful.
                            setUser(null);
                            localStorage.removeItem("user");
                            history.push("/");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  )}
                  {!user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </MenuItem>
                  )}
                </>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress
          color="inherit" // Adjust the color as per your theme
          size={50}
          thickness={5}
        />
        <Typography
          variant="h6"
          color="inherit"
          style={{ fontWeight: "bolder", marginLeft: "1%", fontSize: "3vh" }}
        >
          Hang tight, we're almost there!
        </Typography>
      </Backdrop>

      {/* <Container maxWidth="lg"> */}
      <Grid
        container
        spacing={3}
        style={{ paddingLeft: "5%", paddingRight: "5%", marginTop: '75px' }}
      >
        <Grid item xs={12} md={8} style={{ marginTop: "2%" }}>
          {allHome !== null ? (
            allHome.map((post, i) => (
              <Card
                key={i}
                className={classes.card}
              // onClick={() => {
              //   // Navigate to the blog post when the card is clicked
              //   history.push(`/Blog/${year}-${month}/${post.Index}/${post.Title.split(" ").join("-")}`);
              // }}
              >
                <CardContent>
                  <h2 gutterBottom className={classes.blogTitle}>
                    <a
                      href={`/Blog/${post.Date.substring(0, 7)}/${post.Index
                        }/${post.Title.split(" ").join("-")}`}
                      className={classes.aref}
                    >
                      {allHome[i].Title}
                    </a>
                  </h2>

                  <p className={classes.blogDescription}>
                    {allHome[i].Description}
                  </p>
                </CardContent>
                <CardActions>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <a
                      href={`/Blog/${post.Date.substring(0, 7)}/${post.Index
                        }/${post.Title.split(" ").join("-")}`}
                      className={classes.genericButton}
                      style={{ textDecoration: "none" }} // Remove underline with this style
                    >
                      Read More
                    </a>

                    <Typography
                      component="p"
                      className={classes.blogDescription}
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      {dayjs(allHome[i].Date).format("MMM D, YYYY")}
                    </Typography>
                  </div>
                </CardActions>
              </Card>
            ))
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}

          {/* {paginatedObjects !== null ? (
              paginatedObjects.map((post) => (
                <Card key={post.id} className={classes.card}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.blogTitle}
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.blogDescription}
                    >
                      {post.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      className={classes.genericButton}
                      onClick={() =>
                        history.push({
                          pathname: `blog/Home/${post.title
                            .split(" ")
                            .join("-")}`,
                          state: {
                            Title: post.title,
                            Description: post.description,
                          },
                        })
                      }
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <div>
                <CircularProgress />
              </div>
            )} */}
          {/* <span dangerouslySetInnerHTML={template} /> */}

          {/* <TablePagination
              component="div"
              count={allHome.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            /> */}
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{
            marginTop: "2%",
            position: !matchesSm ? "fixed" : "sticky",
            maxWidth: !matchesMd ? "27vw" : "",
            left: "65vw",
          }}
        >
          <div
            style={{
              padding: theme.spacing(3),
              maxWidth: "100%",
              fontFamily: "Poppins",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(2),
              },
            }}
          >
            <h2
              style={{
                textAlign: "center",
                padding: "2%",
                fontFamily: "Poppins",
                color: "white",
                fontWeight: "bold",
                fontSize: '3vh'
              }}
            >
              Lawn Maintenance Services
            </h2>
            <p
              style={{
                fontFamily: "Poppins",
                textAlign: "justify",
                letterSpacing: "-0.05em",
                color: "white",
                fontSize: '2.2vh'
              }}
            >
              Experience top-notch lawn care services at affordable prices with
              Lawnber. Our local lawn care experts are here to make your lawn
              beautiful and healthy. Schedule your service today!
            </p>
            <button
              className="buttonPrimary2"
              id="get-instant-quote"
              style={{
                fontSize: "1rem",
                backgroundColor: "#ff9900",
                marginTop: "10%",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
              onClick={() => history.push("/")}
            >
              <span id="button-text">Get an Instant Quote</span>
            </button>
          </div>
          {/* Blog archieve design UI */}
          {/* <div
                style={{
                  padding: theme.spacing(3),
                  maxWidth: "100%",
                  fontFamily: "Poppins",
                  backgroundColor: "#F6F6F6",
                  color: "black",
                  marginTop: "3vh",
                  [theme.breakpoints.down("sm")]: {
                    padding: theme.spacing(2),
                  },
                }}
              >
              
                  <h2
                    style={{
                      textAlign: "center",
                      padding: "2%",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    Blog Archieve
                  </h2>
                <div style={{ display: "grid", placeItems: "center" }}>
                  {months.map((data) => {
                    return (
                      <a href="#" key={data.id} style={{color: "black", textDecoration: "none"}}>
                        {data.monthName}
                      </a>
                    );
                  })}
                </div>
              </div> */}
        </Grid>
      </Grid>
      {/* </Container> */}

      {/* <FooterBlog
        title1="Lawnber"
        content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
        title2="Recent Posts"
        content2={allHome.slice(0, 5)}
        flag={"Home"}
        URL={"blog"}
      /> */}
    </>
  );
};

export default BlogHome;
