/***************************************************************************************************
App.js           Created: 10/22/2022         Rev 1.0.5

This is the file for App Component. App Component is the main component in React which acts 
as a container for all other components. Routes to the other pages are also included

***************************************************************************************************/

/// ----------------- Imports ------------------------------ ///
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { UserContext } from "./components/UserContext";
import { auth, authQA } from "./components/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { ServiceAgreementSnow } from "./components/ServiceAgreementSnow";
import { ServiceAgreementLawn } from "./components/ServiceAgreementLawn";
import { ServiceAgreementSpring } from "./components/ServiceAgreementSpring";
import { ServiceAgreementMulch } from "./components/ServiceAgreementMulch";
import { ServiceAgreementHedge } from "./components/ServiceAgreementHedge";
import { ServiceAgreementFall } from "./components/ServiceAgreementFall";
import { Helmet } from "react-helmet";

import "./App.css";

/// ------------ PROD PAGE IMPORTS --------------------///
import ProdHome from "./PROD/Home/NewHome";
import Login from "./PROD/Login/Login";
import StripeContainer from "./PROD/Signup/StripeContainer";
import Dashboard from "./PROD/Dashboard/Dashboard";
import Profile from "./PROD/Profile/Profile";
import Contact_PROD from "./PROD/Contact/contact";
import BlogHome_PROD from "./PROD/Blogs/BlogHome";
import BlogPosts_PROD from "./PROD/Blogs/BlogPosts";

/// ------------ QA PAGE IMPORTS --------------------///
import Login_QA from "./QA/Login/Login";
import StripeContainer_QA from "./QA/Signup/StripeContainer";
import Dashboard_QA from "./QA/Dashboard/Dashboard";
import Profile_QA from "./QA/Profile/Profile";
import AdminLogin from "./QA/Admin/AdminLogin";
import NewHome from "./QA/Home/NewHome";
import Contact from "./QA/Contact/contact";
import BlogHome from "./QA/Blogs/BlogHome";
import BlogPosts from "./QA/Blogs/BlogPosts";
import UserImpersonation from "./QA/Dashboard/UserImpersonation";

import {
  MuiThemeProvider,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ErrorPage from "./components/ErrorPage";
import AboutUs from "./QA/AboutUs/AboutUs";

function App() {
  const [user, setUser] = useState(null);
  const value = useMemo(() => [user, setUser], [user, setUser]);

  const [userQA, setUserQA] = useState(null);
  const valueQA = useMemo(() => [userQA, setUserQA], [userQA, setUserQA]);

  const [adminUser, setAdminUser] = useState(null);
  const AdminValue = useMemo(
    () => [adminUser, setAdminUser],
    [adminUser, setAdminUser]
  );

  useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          return;
        } else {
          setUser(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []);

  useEffect(() => {
    function checkUserQA() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userQA) => {
        if (userQA) {
          setUserQA(userQA);
          return;
        } else {
          setUserQA(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUserQA();
  }, []);

  useEffect(() => {
    function checkAdminUser() {
      localStorage.setItem("adminUser", JSON.stringify(adminUser));
      if (adminUser) {
        setAdminUser(adminUser);
        return;
      } else {
        setUser(null);
        console.log("No admin user");
        return;
      }
    }
    checkAdminUser();
  }, []);

  const theme = createTheme({
    palette: {
      textColor: "#000000",
      fontFamily: "Lexend",
      primary: {
        main: "#187CAE",
      },
      secondary: {
        main: "#d3d3d3",
      },
      Tertiary: {},
    },
  });

  return (
    <>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Router>
            <UserContext.Provider value={value} AdminValue={AdminValue}>
              <Switch>
                {/* PRODUCTION ROUTES  */}
                <Route
                  exact
                  path="/"
                  component={ProdHome}
                  user={user}
                />
                <Route exact path="/login" component={Login} user={user} />

                <Route
                  exact
                  path="/signup"
                  component={StripeContainer}
                  user={user}
                />

                <Route
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  user={user}
                />
                <Route exact path="/profile" component={Profile} user={user} />
                <Route
                  exact
                  path="/ServiceAgreementSnow"
                  component={ServiceAgreementSnow}
                  user={user}
                />
                <Route
                  exact
                  path="/ServiceAgreementLawn"
                  component={ServiceAgreementLawn}
                  user={user}
                />
                <Route
                  exact
                  path="/ServiceAgreementSpring"
                  component={ServiceAgreementSpring}
                  user={user}
                />
                <Route
                  exact
                  path="/ServiceAgreementMulch"
                  component={ServiceAgreementMulch}
                  user={user}
                />
                <Route
                  exact
                  path="/ServiceAgreementHedge"
                  component={ServiceAgreementHedge}
                  user={user}
                />
                <Route
                  exact
                  path="/ServiceAgreementFall"
                  component={ServiceAgreementFall}
                  user={user}
                />
                <Route
                  exact
                  path="/PrivacyPolicy"
                  component={PrivacyPolicy}
                  user={user}
                />
                <Route
                  exact
                  path="/Contact"
                  component={Contact_PROD}
                  user={user}
                />
                <Route
                  exact
                  path="/Blog"
                  component={BlogHome_PROD}
                  user={user}
                />
                <Route
                  path="/Blog/:date/:index/:title"
                  component={BlogPosts_PROD}
                />
                {/* ------------------------------------------------------------------- */}

                {/* QA ROUTES */}
                <Route
                  exact
                  path="/QA"
                  component={AdminLogin}
                  adminUser={adminUser}
                />
                <Route
                  exact
                  path="/QAHome"
                  component={NewHome}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAHome/Mulch"
                  component={() => <NewHome redirect="true" />}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAsignup"
                  component={StripeContainer_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAdashboard"
                  component={Dashboard_QA}
                  userQA={userQA}
                />
                <Route exact path="/QAlogin" component={Login_QA} user={user} />
                <Route
                  exact
                  path="/QAprofile"
                  component={Profile_QA}
                  user={user}
                />

                <Route
                  exact
                  path="/QABlog"
                  component={BlogHome}
                  userQA={userQA}
                />
                <Route path="/QAblog/:category/:slug" component={BlogPosts} />

                <Route
                  exact
                  path="/QAuserImpersonation"
                  component={UserImpersonation}
                  user={userQA}
                />

                <Route
                  exact
                  path="/QAContact"
                  component={Contact}
                  user={adminUser}
                />

                <Route
                  exact
                  path="/QAAboutUs"
                  component={AboutUs}
                  user={adminUser}
                />

                <Route path="*" component={ErrorPage}></Route>
              </Switch>
            </UserContext.Provider>
          </Router>
        </ThemeProvider>
      </div>
    </>
  );
}
export default App;
