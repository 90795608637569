import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ContactSupport } from "@material-ui/icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { useHistory } from "react-router-dom";

import Logo from "./assets/images/lawnberlogo2.png";

function ErrorPage() {
  const history = useHistory();
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <>
      {/* Navbar */}
      <AppBar
        color="white"
        elevation={2}
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: matchesSm ? "0px" : "2%",
          paddingRight: "1%",
          maxHeight: matchesSm ? "60px" : "75px",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "1rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>
          <Box flexGrow={1} />
          {!matchesSm && (
            <Button
              variant="contained"
              color="secondary"
              aria-label="Contact Us"
              onClick={() => history.push("/contact")}
              style={{
                marginRight: "1rem",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "bold",
                minHeight: "40px",
                fontSize: matchesSm
                  ? "0.9rem"
                  : matchesMd
                  ? "1rem"
                  : isBetweenMdAndLg
                  ? "1.1vh"
                  : "1.6vh",
              }}
            >
              Contact Us
            </Button>
          )}
          <Box display="flex" alignItems="center">
            {matchesSm && (
              <Box sx={{ flexGrow: 0 }}>
                <div
                  className="navLink"
                  onClick={() => {
                    history.push("/contact");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: matchesSm ? "0.9rem" : "1.1rem",
                  }}
                >
                  <ContactSupport
                    fontSize="large"
                    style={{ color: "#187CAE", fontSize: 45 }}
                  />
                </div>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <div
                className="navLink"
                onClick={() => {
                  history.push("/Login");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div style={{ display: "grid", height: "80%", placeItems: "center" }}>
        <div style={{ padding: "1%" }}>
          <Typography variant="h2" style={{ color: "#393C41" }}>
            404 <br /> Page not found
          </Typography>
          <Typography variant="h6" style={{ marginTop: "3vh" }}>
            Sorry, we can't find this page. The Web address you entered is not a
            functioning page on our site.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginTop: "10vh" }}
            onClick={() => history.push("/")}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
