import { makeStyles } from "@material-ui/core";
import lawnhelp from "../../components/assets/images/helpLawn.png";
import lawnhelpsnow from "../../components/assets/images/how-we-can-help.png";
export const useStyles = makeStyles((theme, isWinter) => ({
  root: {
    width: "100%",
    height: "100%",
    // fontFamily: "Poppins",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {},
  },
  appBar: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingLeft: "2%",
    paddingRight: "1%",
    maxHeight: "75px",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      maxHeight: "60px",
    },
  },
  logo: {
    maxWidth: "150px",
    minWidth: "150px",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
      minWidth: "100px",
      maxHeight: "100px",
      minHeight: "100px",
    },
  },
  h1: {
    fontSize: "48px",
    textAlign: "left",
    color: "#1B1C57",
    fontFamily: "Poppins",
    fontWeight: "bold",
    lineHeight: "60px",
    // marginLeft: isWinter ? "-12%" : '0%',
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "28px",
      textAlign: "center",
      marginLeft: "0%",
    },
  },

  Heading: {
    color: "#1B1C57",
    fontFamily: "Poppins",
    fontSize: "48px",
    textAlign: "center",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      paddingLeft: "10px",
      paddingRight: "10px",
      marginTop: "15%",
    },
  },

  aHeading: {
    fontFamily: "Poppins",
    color: "#1B1C57",
    fontSize: "48px",
    borderBottom: "8px solid #187CAE",
    textDecoration: "none",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  p: {
    color: "#626687",
    opacity: "0.8",
    fontSize: "18px",
    textAlign: "left",
    lineHeight: "28px",
    // paddingLeft: "-2%",
    fontFamily: "Poppins",
    maxWidth: "540px",
    [theme.breakpoints.down("sm")]: {
      padding: "2%",
      lineHeight: "24px",
      fontSize: "16px",
      paddingLeft: "5%",
      paddingRight: "5%",
      textAlign: "justify",
      marginLeft: "-2%",
    },
  },

  aLinkParagraph:{
    textDecoration: 'underline',
    color: "#626687",
    fontSize: "18px",
    fontFamily: "Poppins",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },

  whoWeArep: {
    color: "#626687",
    opacity: "0.8",
    fontSize: "18px",
    fontFamily: "Poppins",
    textAlign: "justify", // Set text alignment to justify
    marginTop: "2vh",
    maxWidth: "80vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      maxWidth: "100vw",
      width: "100%",
    },
  },

  subheading: {
    textAlign: "left",
    textTransform: "uppercase",
    fontWeight: "700",
    letterSpacing: "0.055em",
    fontSize: "16px",
    color: "#331B3B",
    fontFamily: "Poppins",

    marginTop: "10px",
  },

  card: {
    fontFamily: "Poppins",
    padding: "1.4%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "320px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },

  SameHeightCards: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "88%",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "88%",
    },
  },

  helpContainer: {
    fontFamily: "Poppins",

    backgroundImage: `url(${isWinter ? lawnhelpsnow : lawnhelp})`,
    marginTop: "5%",
    backgroundSize: "1480px 550px",
    backgroundRepeat: "no-repeat",
    //  backgroundPosition: 'center',
    height: "100%",
  },

  searchBox: {
    fontFamily: "Poppins",
    padding: "3px 3px",
    paddingLeft: "15px",
    marginTop: "20px",
    // borderRadius: "32px",
    borderRadius: "5px",
    width: "85%",
    maxHeigth: "50px",
    // border: "2px solid #E0E3EB", // #E0E3EB
    display: "flex",
    boxShadow: "1px 2px 9px #a5a4a4",
    alignItems: "center",
    maxWidth: "1000px",
    // width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      maxHeight: "40px",
      marginTop: "30px",
    },
  },

  InstantQuoteButton: {
    fontFamily: "Poppins",
    borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    width: "20%",
    padding: "0px",
    minHeight: "40px",
    maxHeight: "42px",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "nowrap",
      padding: "10px",
      maxHeight: "30px",
      lineHeight: "15px",
    },
  },
  grid1: {
    fontFamily: "Poppins",
    display: "grid",
    placeItems: "center",
    height: "45vh",
    marginTop: "12%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
  },

  stepsGrid: {
    maxWidth: "100%",
  },

  grid2: {
    fontFamily: "Poppins",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15%",
    },
  },
  grid3: {
    fontFamily: "Poppins",
    display: "grid",
    placeItems: "flex-start",
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      placeItems: "center",
    },
  },
  cardStack: {
    fontFamily: "Poppins",
    marginTop: "5%",
    // marginLeft: "2%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      placeItems: "center",
    },
  },

  textDiv: {
    width: "80%",
    height: "55%",
    margin: "150px auto",
    background: "#FFFFFF",
    boxShadow: "0px 9px 32px rgba(89, 92, 219, 0.05)",
    borderRadius: "16px",
    padding: "2%",
    block: "inline",
    verticalAlign: "middle",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },

  reviewHeading: {
    fontFamily: "Poppins",
    color: "#1B1C57",
    fontSize: "17px",
    fontWeight: "bolder",
    textAlign: "left",
    marginLeft: "5px",
    paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },

  footer: {
    fontFamily: "Poppins",
    padding: "3%",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  menuButton: {
    marginRight: "1rem",
  },
  drawer: {
    width: 200,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 200,
    height: "20%",
    margin: "auto",
  },
  menuButtonContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: theme.spacing(2),
    width: "100%",
    marginBottom: "2%",
  },
  menuButton: {
    margin: "auto",
  },
  footerTitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "left",
  },
  footerContent: {
    fontFamily: "Poppins",
    textAlign: "left",
    cursor: "pointer",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flexStart",
    "&:hover": {
      textDecoration: "none",
      color: "#187cae",
      fontSize: "inherit",
    },
  },
}));
