import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserContext } from "../../components/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import { ToastContainer } from "react-toastify";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, dbQA } from "../../components/Firebase";
import { FooterBlog } from "../../components/Footer";
import { Helmet } from "react-helmet";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import dayjs from 'dayjs';
import { months, targetWords } from "../../Constant/Variables";

const BlogPosts = () => {
  const history = useHistory();
  const theme = useTheme();
  const [user, setUser] = React.useContext(UserContext);
  const [userDetails, setUserDetails] = React.useState({});
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [accountMenu, setAccountMenu] = React.useState(null);
  const [serviceMenu, setServiceMenu] = React.useState(null);
  const [LandscapeMenu, setLandscapeMenu] = React.useState(null);
  const [content, setContent] = React.useState([]);
  const { category, slug } = useParams();
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const [blogDetails, setBlogDetails] = React.useState({
    Title: '',
    Index: 0,
    Year: '',
    Month: '',
    Content: [],
    ImageURL: ''
  })

  const linkedWords = {};
  const currentURL = useLocation().pathname;


  // Loop through all the img tags and do something

  //Generic functions
  const handleOpenPopover = (event, state) => {
    state(event.currentTarget);
  };

  const handleClosePopover = (event, state) => {
    state(null);
  };

  const handlePageTop = (event) => {
    // console.log(event.target.id)
    // setSignUpPageSelected(event.currentTarget.getAttribute('value'));
    setServiceMenu(null);
  };


  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          const UserAcct = await getDoc(doc(db, "UserAcct", user.uid));
          setUserDetails({
            name: UserAcct.data().FirstName + " " + UserAcct.data().LastName,
          });
        } else {
          setUser(null);
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // New firebase method to get all blogs:
  React.useEffect(async () => {
    // Split the URL by '/'
    const urlParts = location.pathname.split('/');

    const year = urlParts[2].split('-')[0]
    const monthNumber = urlParts[2].split('-')[1]
    const dummyDate = dayjs(`${year}-${monthNumber}-01`);
    const monthName = dummyDate.format('MMMM'); // Full month name

    // Extract the index (assuming it's the second part of the URL)
    const index = urlParts[3]; // Index is at position 3
    // Extract the title (assuming it's the last part of the URL)
    const title = urlParts[urlParts.length - 1].split('-').join(' '); // Title is the last part

    const POSTREF = await getDoc(doc(dbQA, "Blogs", `${year}`, `${monthName}`, `BlogPost${index}`));
    // Convert the object into an array of key-value pairs
    const contentArray = Object.entries(POSTREF.data()['Content']);

    // Sort the array based on the numeric part of the keys
    contentArray.sort((a, b) => {
      const keyA = parseInt(a[0].replace("Paragraph", ""));
      const keyB = parseInt(b[0].replace("Paragraph", ""));
      return keyA - keyB;
    });
    // console.log(contentArray)
    // setContent(contentArray)
    setBlogDetails((data) => ({
      ...data,
      Title: title,
      Index: index,
      Year: year,
      Month: monthName,
      Content: contentArray,
      ImageURL: (POSTREF.data().hasOwnProperty('ImageURL') && POSTREF.data().ImageURL.length > 0) ? POSTREF.data().ImageURL : null
    }))

    // console.log(POSTREF.data().ImageURL)

    setLoading(false);

  }, []);


  return (
    <>
      {blogDetails['Title'].length > 0 &&
        <Helmet>
          <title>Lawnber Blogs : {blogDetails['Title']}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={blogDetails['Description']} />
          <link rel="canonical" href="https://lawnber.com/blog" />
          <meta
            property="og:image"
            content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
          />
          <meta property="og:title" content={blogDetails['Title']} />
          <meta property="og:description" content={blogDetails['Description']} />
          <meta property="og:url" content="https://lawnber.com/" />
          <meta property="og:type" content="website" />
        </Helmet>
      }

      <ToastContainer />
      <AppBar
        color="white"
        elevation={3}
        position="fixed"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            {/* <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setServiceMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Services
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={serviceMenu}
                open={Boolean(serviceMenu)}
                onClose={(event) => handleClosePopover(event, setServiceMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Lawn Service
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Snow Service
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Spring Cleanup
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setLandscapeMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Landscaping
              </Button>
              <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button"
                anchorEl={LandscapeMenu}
                open={Boolean(LandscapeMenu)}
                onClose={(event) => handleClosePopover(event, setLandscapeMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Custom Designs
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Features v/s Cost
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  High vs Low Maintenance
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  {" "}
                  Hardscape
                </MenuItem>
              </Menu>
            </Box> */}
            <MenuItem
              value="Lawn Service"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: matchesSm
                  ? "0.9rem"
                  : matchesMd
                    ? "0.9rem"
                    : "1rem",
              }}
              onClick={() => history.push("/Blog")}
            >
              Blogs
            </MenuItem>
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/contact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box>
              <div
                className="navLink"
                onClick={(event) => handleOpenPopover(event, setAccountMenu)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                {userDetails["name"] ? userDetails["name"].split(" ")[0] : ""}
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={accountMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "1%" }}
                open={Boolean(accountMenu)}
                onClose={(event) => handleClosePopover(event, setAccountMenu)}
              >
                <>
                  {matchesSm && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/Ccontact")}
                    >
                      Contact us
                    </MenuItem>
                  )}

                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/Dashboard")}
                    >
                      Dashboard
                    </MenuItem>
                  )}
                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/profile")}
                    >
                      Profile
                    </MenuItem>
                  )}
                  {user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => {
                        signOut(auth)
                          .then(() => {
                            // Sign-out successful.
                            console.log("signed out");
                            setUser(null);
                            localStorage.removeItem("user");
                            history.push("/");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  )}
                  {!user && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </MenuItem>
                  )}
                </>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Backdrop
        open={loading}
        style={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Light gray background overlay
          backdropFilter: 'blur(2px)',
          transform: 'scale(1.05)', // Add a scaling effect

        }}
      >
        <CircularProgress
          color="primary" // Use a color that contrasts with the background
          size={50}
          thickness={5}
        />
        <Typography
          variant="h6"
          color="textPrimary" // Use a dark color for text
          style={{ fontWeight: 'bolder', marginLeft: '1%', fontSize: '3vh' }}
        >
          Hang tight, we're almost there!
        </Typography>
      </Backdrop>

      {content !== null && (
        <Container maxWidth="lg">
          <Grid container style={{ marginTop: "80px" }} spacing={3}>
            <Grid item xs={12} md={8}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <h1 style={{ fontFamily: 'Poppins', color: '#333', marginBottom: '2%' }}>
                  {blogDetails['Title']}
                </h1>

                {/* Add an image tag with an alt attribute */}
                {blogDetails['ImageURL'] && (
                  <img
                    src={blogDetails['ImageURL']}
                    alt={blogDetails['Title']}
                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '2%' }}
                  />
                )}

                <div>

                  {blogDetails['Content'].map(([key, paragraph]) => {
                    const startIndex = paragraph.indexOf('/*');
                    const endIndex = paragraph.indexOf('*/');
                    const hasHeading = startIndex !== -1 && endIndex !== -1 && startIndex < endIndex;

                    const heading = hasHeading
                      ? paragraph.substring(startIndex + 2, endIndex).trim()
                      : null;

                    const paragraphContent = hasHeading
                      ? paragraph.substring(0, startIndex) + paragraph.substring(endIndex + 2)
                      : paragraph;

                    // Function to wrap specific words with links, ensuring they are linked only once across all paragraphs.
                    const addLinksToWords = (text) => {
                      const linkedText = [];
                      let remainingText = text;

                      while (remainingText) {
                        let wordMatched = false;

                        // Find the first matching word in the remaining text.
                        for (const targetWord of targetWords) {
                          const lowercasedWord = targetWord.word.toLowerCase();
                          const wordIndex = remainingText.toLowerCase().indexOf(lowercasedWord);

                          const linkURLNotSameAsCurrent = targetWord.link !== currentURL;

                          if (wordIndex !== -1 && !linkedWords[lowercasedWord] && linkURLNotSameAsCurrent) {
                            // Add the text before the matched word as plain text.
                            linkedText.push(remainingText.substring(0, wordIndex));
                            remainingText = remainingText.substring(wordIndex);

                            // Add the matched word with a link.
                            linkedText.push(
                              <a href={targetWord.link} key={linkedText.length} style={{ touchAction: 'manipulation' }}
                              >
                                {remainingText.substring(0, lowercasedWord.length)}
                              </a>
                            );

                            remainingText = remainingText.substring(lowercasedWord.length);
                            linkedWords[lowercasedWord] = true; // Mark the word as linked across all paragraphs.
                            wordMatched = true;
                            break;
                          }
                        }

                        // If no word is matched or all instances are already linked, add the remaining text as plain text.
                        if (!wordMatched) {
                          linkedText.push(remainingText);
                          break;
                        }
                      }

                      return linkedText;
                    };

                    return (
                      <div key={key}>
                        {hasHeading && (
                          <h2
                            style={{
                              fontFamily: 'Poppins',
                              color: '#555',
                              textAlign: 'justify',
                              marginBottom: '0.5%',
                            }}
                          >
                            {heading}
                          </h2>
                        )}
                        <p
                          style={{
                            fontFamily: 'Poppins',
                            color: '#555',
                            textAlign: 'justify',
                            marginBottom: '2%',
                          }}
                        >
                          {addLinksToWords(paragraphContent)}
                        </p>
                      </div>
                    );
                  })}

                </div>

              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              xl={3}
              style={{ position: !matchesSm ? "fixed" : "", left: "65vw" }}
            >
              <div
                style={{
                  padding: theme.spacing(3),
                  maxWidth: "100%",
                  fontFamily: "Poppins",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  [theme.breakpoints.down("sm")]: {
                    padding: theme.spacing(2),
                  },
                }}
              >
                <h2
                  style={{
                    textAlign: "center",
                    padding: "2%",
                    fontFamily: "Poppins",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Lawn Maintenance Services
                </h2>
                <p
                  style={{
                    fontFamily: "Poppins",
                    textAlign: "justify",
                    letterSpacing: "-0.05em",
                    color: "white",
                  }}
                >
                  Experience top-notch lawn care services at affordable prices with Lawnber. Our local lawn care experts are here to make your lawn beautiful and healthy. Schedule your service today!
                </p>
                <button
                  className="buttonPrimary2"
                  id="get-instant-quote"
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#ff9900",
                    marginTop: "10%",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => history.push("/")}
                >
                  <span id="button-text">Get an Instant Quote</span>
                </button>
              </div>
              <div
                style={{
                  padding: theme.spacing(3),
                  maxWidth: "100%",
                  fontFamily: "Poppins",
                  backgroundColor: "#F6F6F6",
                  color: "black",
                  marginTop: "3vh",
                  [theme.breakpoints.down("sm")]: {
                    padding: theme.spacing(2),
                  },
                }}
              >
                <header>
                  <h2
                    style={{
                      textAlign: "center",
                      padding: "2%",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    Blog Archieve
                  </h2>
                </header>
                <div style={{ display: "grid", placeItems: "center" }}>
                  {months.map((data) => {
                    return (
                      <a href="#" key={data.id} style={{ color: "black", textDecoration: "none" }}>
                        {data.monthName}
                      </a>
                    );
                  })}
                </div>
              </div>
            </Grid>

          </Grid>
        </Container >
      )
      }
      {/* {content !== null && (
        <FooterBlog
          title1="Lawnber"
          content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
          title2="Recent Posts"
          content2={content["titles"]}
          flag={category}
          URL={"blog"}
        />
      )} */}
    </>
  );
};

export default BlogPosts;