import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserContext } from "../../components/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import { ToastContainer } from "react-toastify";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { authQA, dbQA } from "../../components/Firebase";
import { FooterBlog } from "../../components/Footer";
import { Helmet } from "react-helmet";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import dayjs from 'dayjs';

const BlogPosts = () => {
  const history = useHistory();
  const theme = useTheme();
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [userDetails, setUserDetails] = React.useState({});
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [accountMenu, setAccountMenu] = React.useState(null);
  const [serviceMenu, setServiceMenu] = React.useState(null);
  const [LandscapeMenu, setLandscapeMenu] = React.useState(null);
  const [content, setContent] = React.useState(null);
  const { category, slug } = useParams();
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const [blogDetails, setBlogDetails] = React.useState({
    Title: '',
    Index: 0,
    Year: '',
    Month: ''
  })

  // Loop through all the img tags and do something

  //Generic functions
  const handleOpenPopover = (event, state) => {
    state(event.currentTarget);
  };

  const handleClosePopover = (event, state) => {
    state(null);
  };

  const handlePageTop = (event) => {
    // console.log(event.target.id)
    // setSignUpPageSelected(event.currentTarget.getAttribute('value'));
    setServiceMenu(null);
  };

  // React.useEffect(() => {
  //   axios
  //     .post(
  //       "https://blogs-server-lawnber.herokuapp.com/HomeSinglePost",
  //       // "http://localhost:4009/HomeSinglePost",
  //       {
  //         title: slug.split("-").join(" "),
  //       }
  //     )
  //     .then((response) => {
  //       setContent(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userQA) => {
        if (userQA) {
          setUserQA(userQA);
          const UserAcct = await getDoc(doc(dbQA, "UserAcct", userQA.uid));
          setUserDetails({
            name: UserAcct.data().FirstName + " " + UserAcct.data().LastName,
          });
        } else {
          setUserQA(null);
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // New firebase method to get all blogs:
  React.useEffect(async () => {
    // Split the URL by '/'
    const urlParts = location.pathname.split('/');

    const year = urlParts[2].split('-')[0]
    const monthNumber = urlParts[2].split('-')[1]
    const dummyDate = dayjs(`2023-${monthNumber}-01`);
    const monthName = dummyDate.format('MMMM'); // Full month name

    // Extract the index (assuming it's the second part of the URL)
    const index = urlParts[3]; // Index is at position 3
    // Extract the title (assuming it's the last part of the URL)
    const title = urlParts[urlParts.length - 1].split('-').join(' '); // Title is the last part

    setBlogDetails((data) => ({
      ...data,
      Title: title,
      Index: index,
      Year: year,
      Month: monthName,
    }))

    const POSTREF = await getDoc(doc(dbQA, "Blogs", `${year}`, `${monthName}`, `BlogPost${index}`));
    setContent(POSTREF.data().Content)
    setLoading(false);

  }, []);


  return (
    <>
      {blogDetails['Title'].length > 0 &&
        <Helmet>
          <title>Lawnber Blogs : {blogDetails['Title']}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={blogDetails['Description']} />
          <link rel="canonical" href="https://lawnber.com/blog" />
          <meta
            property="og:image"
            content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
          />
          <meta property="og:title" content={blogDetails['Title']} />
          <meta property="og:description" content={blogDetails['Description']} />
          <meta property="og:url" content="https://lawnber.com/" />
          <meta property="og:type" content="website" />
        </Helmet>
      }

      <ToastContainer />
      <AppBar
        color="white"
        elevation={3}
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/QAHome")}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setServiceMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Services
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={serviceMenu}
                open={Boolean(serviceMenu)}
                onClose={(event) => handleClosePopover(event, setServiceMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Lawn Service
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Snow Service
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Spring Cleanup
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setLandscapeMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Landscaping
              </Button>
              <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button"
                anchorEl={LandscapeMenu}
                open={Boolean(LandscapeMenu)}
                onClose={(event) => handleClosePopover(event, setLandscapeMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Custom Designs
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Features v/s Cost
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  High vs Low Maintenance
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  {" "}
                  Hardscape
                </MenuItem>
              </Menu>
            </Box>
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/QAcontact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box>
              <div
                className="navLink"
                onClick={(event) => handleOpenPopover(event, setAccountMenu)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                {userDetails["name"] ? userDetails["name"].split(" ")[0] : ""}
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={accountMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "1%" }}
                open={Boolean(accountMenu)}
                onClose={(event) => handleClosePopover(event, setAccountMenu)}
              >
                <>
                  {matchesSm && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QACcontact")}
                    >
                      Contact us
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QADashboard")}
                    >
                      QADashboard
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QAprofile")}
                    >
                      QAProfile
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => {
                        signOut(authQA)
                          .then(() => {
                            // Sign-out successful.
                            console.log("signed out");
                            setUserQA(null);
                            localStorage.removeItem("userQA");
                            history.push("/QAHome");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  )}
                  {!userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QAlogin")}
                    >
                      QALogin
                    </MenuItem>
                  )}
                </>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Backdrop
        open={loading}
        style={{
          zIndex: theme.zIndex.drawer + 1,
          color: "black",
        }}
      >
        <CircularProgress
          color="inherit"
          indeterminate
          size={50}
          thickness={5}
        />
      </Backdrop>
      {content !== null && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} style={{ marginTop: "3%" }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <h1 style={{ fontFamily: 'Poppins', color: '#333', marginBottom: '2%' }}>
                  {blogDetails['Title']}
                </h1>

                {Object.values(content).map((paragraph, index) => (
                  <p key={index} style={{
                    fontFamily: 'Poppins', color: '#555', textAlign: 'justify', marginBottom: '2%'
                  }}>
                    {paragraph}
                  </p>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "3%" }}>
              <div
                style={{
                  padding: theme.spacing(3),
                  maxWidth: "100%",
                  fontFamily: "Poppins",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  [theme.breakpoints.down("sm")]: {
                    padding: theme.spacing(2),
                  },
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    textAlign: "center",
                    padding: "2%",
                    fontFamily: "poppins",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Lawn Maintenance
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "Poppins",
                    textAlign: "justify",
                    letterSpacing: "-0.05em",
                    color: "white",
                  }}
                >
                  Lawnber provides convenient and professional lawn care
                  services at affordable prices. Schedule your service today
                  with a local lawn care expert.
                </Typography>
                <button
                  className="buttonPrimary2"
                  id="submit"
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#ff9900",
                    marginTop: "10%",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => history.push("/")}
                >
                  <span id="button-text">Get Instant Quote</span>
                </button>
              </div>
            </Grid>
          </Grid>
        </Container >
      )}
      {/* {content !== null && (
        <FooterBlog
          title1="Lawnber"
          content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
          title2="Recent Posts"
          content2={content["titles"]}
          flag={category}
          URL={"QAblog"}
        />
      )} */}
    </>
  );
};

export default BlogPosts;