/***************************************************************************************************
Profile.js           Created: 10/10/2022         Rev 1.0.3

This file will fetch data from different points to show user history

  getUserDetails -
    Callback to get user related information for the user from firestore db

  handleSave - 
    On click event to save details to firestore db

  handleCancel - 
    On click event to cancel or undo changed data in input fields 

***************************************************************************************************/

//imports
import React from "react";
import { doc, getDoc, setDoc, deleteField } from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import "./card.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../components/UserContext";
import { useStyles } from "../Dashboard/Styles.js";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { auth as auth, db as db } from "../../components/Firebase";
import {
  Grid,
  Button,
  Container,
  Box,
  Typography,
  Paper,
  Divider,
  styled,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Backdrop,
  Link,
  // Card,
  CardContent,
  Radio,
  RadioGroup,
  CardMedia,
  TextField,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import "./Profile.css";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import Logo2 from "../../components/assets/images/lawnber_new.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MaterialTable from "material-table";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Popup from "../../components/Popup";
import { Close } from "@material-ui/icons";

export default function Profile_() {
  //states
  const [user, setUser] = React.useContext(UserContext);
  const [savePressed, setSavePressed] = useState(false);
  const [password, setPassword] = useState("xxxxxx");
  const [oldPassword, setOldPassword] = useState("yyyyyy");
  const [checkPassword, setCheckPassword] = useState("zzzzzz");
  const [checkChange, setCheckChange] = useState({});
  const [savedCards, setSavedCards] = React.useState([]);
  const [addCard, setAddCard] = React.useState([]);
  const [defaultCard, setDefaultCard] = React.useState([]);
  const [numberValid, setNumberValid] = useState(true);
  const [nickname, setNickName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [userDetails, setUserDetails] = React.useState({
    FirstName: "",
    LastName: "",
    FullName: "",
    Address: "",
    StreetAddress: "",
    City: "",
    ZipCode: "",
    Email: "",
    State: "",
    PhoneNumber: "",
    UID: "",
  });
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState("");
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState("");

  const [number, SetNumber] = useState("");
  const [cardName, SetCardName] = useState("");
  const [expiry, SetExpiry] = useState("");
  const [expiryMonth, SetExpiryMonth] = useState("");
  const [expiryYear, SetExpiryYear] = useState("");
  const [cvc, SetCvc] = useState("");
  const [focus, SetFocus] = useState("");
  const [processingCard, SetProcessingCard] = useState(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
      <DeleteIcon style={{ color: "black" }} {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  //variables
  const classes = useStyles();
  const history = useHistory();

  const stripeRef = (uid) => {
    const striperef = doc(db, "UserAcct", uid, "Payments", "Stripe");
    return striperef;
  };

  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [pmData, setPmData] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // remove error function if focused on CardNumber
  const handleFocus = (e) => {
    setNumberValid(true);
    SetFocus(e.target.name);
  };
  // const getFormData = useCardForm();

  // Render dom for the first time or when state changes
  useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          setUserDetails((userDetails) => ({
            ...userDetails,
            UID: user.uid,
          }));
        } else {
          setUser(null);
          console.log("No user");
          // history.push("/");
          return;
        }
      });
    }
    checkUser();
  }, []);

  const handlePageTop = (event) => {
    setAnchorEl(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloses = () => {
    setAnchorEl(null);
  };

  // Fetches all the cards associated with the user
  React.useEffect(() => {
    const getCardsFromStripe = async () => {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const { data } = await axios.post(
            process.env.REACT_APP_CARDS,
            // "http://localhost:4008/cards",
            {
              email: user.email,
            }
          );
          if (data.status === "Error") {
            console.log(data.Error);
            // console.log(data.savedCards)
            setSavedCards(data.savedCards);
            return;
          }
          setSavedCards(data.savedCards);
        } else {
          console.log("No user");
        }
      });
    };
    getCardsFromStripe();
  }, [addCard]);

  // fetch user details to pre-fill data in input fields
  React.useEffect(() => {
    setTimeout(() => {
      getUserDetails();
    }, 3000);
  }, [savePressed]);

  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  // Function: Add a new card using customer details
  const handleAddCard = async (e) => {
    e.preventDefault();
    SetProcessingCard(true);
    //check if data is present, if not return and show popup
    if (!cardName || !cvc || !number || !expiryMonth || !expiryMonth) {
      showAutoClosePopup("Missing Card Details", "Enter All Details", 3000);
      SetProcessingCard(false);
      return;
    } else {
      // post data to backend and recieve Id in the front end in return
      const { data } = await axios.post(
        process.env.REACT_APP_ADD_CARD,
        // "http://localhost:4008/addCard",
        {
          email: userDetails.Email,
          name: userDetails.FullName,
          number: number,
          exp_month: expiryMonth,
          exp_year: expiryYear,
          cvc: cvc,
        }
      );

      if (data.status === "Success") {
        setAddCard(data.paymentMethodId);
        showAutoClosePopup("Info", "New Card has been added!", 3000);
        SetProcessingCard(false);
        handleClose();
        await setDoc(
          stripeRef(user.uid),
          {
            Cards: {
              [data.digits]: {
                Id: data.paymentMethodId,
                BillingAddress: {
                  CardName: cardName,
                  NickName: nickname,
                  State: userDetails.State,
                  City: userDetails.City,
                  StreetAddress: userDetails.StreetAddress,
                  Zipcode: userDetails.ZipCode,
                },
              },
            },
          },
          { merge: true }
        ).then(async () => {
          if (data.isNew) {
            await setDoc(
              stripeRef(user.uid),
              {
                CustomerId: data.customerId,
                DefaultCard: {
                  Id: data.paymentMethodId,
                  Brand: data.brand,
                  Index: 0,
                  Digits: data.digits,
                },
              },
              { merge: true }
            );
          }
          await axios.post('https://be-operation-services.herokuapp.com/QA/AddActivityLog',
            {
              UID: user.uid,
              Subject: 'Notes',
              Message: `${userDetails['FullName']} added a new card ending with ${data.digits} (${data.brand})`,
              Status: 'Unread'
            }, {
            headers: {
              "Content-Type": "application/json",
              "X-Mode": "PROD", // Set the custom header "X-Mode" with the mode value
            },
          }
          )
          showAutoClosePopup("Info", "New Card has been added!", 3000);
          handleClose();
        })
          .catch((err) => console.log(err));
      } else {
        showAutoClosePopup("Error", `${data.error}`, 3000);
        console.log(data.error);
        SetProcessingCard(false);
        return;
      }
    }
  };

  // fetch user information from firestore db
  const getUserDetails = () => {
    localStorage.setItem("user", JSON.stringify(user));
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userInfo = await getDoc(doc(db, "UserAcct", user.uid));
        const docSnap = await getDoc(stripeRef(user.uid));
        if (userInfo.exists()) {
          const Address = userInfo.data()["Address"].split(",");
          setUserDetails((data) => ({
            ...data,
            FirstName: userInfo.data()["FirstName"],
            LastName: userInfo.data()["LastName"],
            FullName:
              userInfo.data()["FirstName"] + " " + userInfo.data()["LastName"],
            Email: userInfo.data()["EmailID"],
            PhoneNumber: userInfo.data()["Phone"],
            UID: userInfo.data()["UID"],
            StreetAddress: Address[0],
            City: Address[1],
            ZipCode: userInfo.data()["AddressZipCode"],
            State: Address[2].slice(0, -6).trim(),
            Address: userInfo.data()["Address"],
          }));
          var checkData = {
            firstName: userInfo.data()["FirstName"],
            lastName: userInfo.data()["LastName"],
            phone: userInfo.data()["Phone"],
          };
          if (docSnap.exists()) {
            setDefaultCard([
              docSnap.data().DefaultCard["Brand"],
              docSnap.data().DefaultCard["Index"],
              docSnap.data().DefaultCard["Id"],
              docSnap.data().DefaultCard["Digits"],
            ]);
          }
          setCheckChange(checkData);
        } else {
          console.log("No such document");
        }
      } else {
        console.log("No user");
      }
    });
  };

  const handleDeleteClose = () => {
    setDeleteConfirm(false);
  };

  // Delete the payment method based on user selection
  const handleDelete = async (index) => {
    // setDeleteCard(select);
    const sendDeletePaymentId = async () => {
      const { data } = await axios.post(
        // process.env.REACT_APP_DELETE,
        "https://be-operation-services.herokuapp.com/deleteCard",
        // "http://localhost:4008/deleteCard",
        {
          paymentMethodId: savedCards[index]["paymentMethod"],
        }
      );
      if (data.status === "Success") {
        await setDoc(
          stripeRef(user.uid),
          {
            Cards: {
              [savedCards[index]["digits"]]: deleteField(),
            },
          },
          { merge: true }
        ).then(async () => {
          await axios.post('https://be-operation-services.herokuapp.com/QA/AddActivityLog',
            {
              UID: user.uid,
              Subject: 'Notes',
              Message: `${userDetails['FullName']} deleted the card ending with ${savedCards[index]["digits"]} as default`,
              Status: 'Unread'
            }, {
            headers: {
              "Content-Type": "application/json",
              "X-Mode": "PROD", // Set the custom header "X-Mode" with the mode value
            },
          }
          )
          showAutoClosePopup("Delete", `Card ending with ${savedCards[index]["digits"]} was deleted`, 3000);
        }).catch((err) => alert(err));
      } else {
        console.log(data.error);
        return;
      }
    };
    sendDeletePaymentId();
  };

  // Set default payment method based on user selection
  const handleSetDefault = (rowData) => {
    // Send the payemnt id to backend to set default card
    const sendPaymentMethodId = async () => {
      // post selected payment method id to backend
      const { data } = await axios.post(
        process.env.REACT_APP_SET_DEFUALT,
        // "http://localhost:4008/setDefaultCard",
        {
          email: user.email,
          paymentMethodId: savedCards[rowData.tableData.id]["paymentMethod"],
        }
      );
      console.log(data);
      if (data.status === "Success") {
        setDefaultCard([
          rowData.cards,
          rowData.tableData.id,
          rowData.paymentMethod,
          rowData.digits,
        ]);
        await setDoc(
          stripeRef(user.uid),
          {
            DefaultCard: {
              Id: savedCards[rowData.tableData.id]["paymentMethod"],
              Brand: savedCards[rowData.tableData.id]["cards"],
              Index: rowData.tableData.id,
              Digits: savedCards[rowData.tableData.id]["digits"],
            },
          },
          { merge: true }

        ).then(async () => {
          await axios.post('https://be-operation-services.herokuapp.com/QA/AddActivityLog',
            {
              UID: user.uid,
              Subject: 'Notes',
              Message: `${userDetails['FullName']} set the card ending with ${savedCards[rowData.tableData.id]["digits"]} as default`,
              Status: 'Unread'
            }, {
            headers: {
              "Content-Type": "application/json",
              "X-Mode": "PROD", // Set the custom header "X-Mode" with the mode value
            },
          }
          )
          showAutoClosePopup("Update", `Card ending with ${savedCards[rowData.tableData.id]["digits"]} was set as default card`, 3000);
        }).catch((err) => alert(err));
      } else {
        console.log(data.error);
        return;
      }
    };

    //process the request to set set default card
    sendPaymentMethodId();
  };

  // save the information entered on click
  const handleSave = async (e) => {
    e.preventDefault();
    if (
      userDetails.FirstName === checkChange.firstName &&
      userDetails.LastName === checkChange.lastName &&
      userDetails.PhoneNumber === checkChange.phone
    ) {
      // showAutoClosePopup('Message', 'No Update', 3000)
    } else {
      const userInfoRef = await doc(db, "UserAcct", user.uid);
      if (userDetails.FirstName !== checkChange.firstName) {
        showAutoClosePopup("Update", "First Name Updated", 3000);

        setDoc(
          userInfoRef,
          {
            FirstName: userDetails.FirstName,
          },
          { merge: true }
        );
        // toast.info("First Name Updated");
        // setSavePressed(Math.random().toString(36).slice(2));
      }
      if (userDetails.LastName !== checkChange.lastName) {
        setDoc(
          userInfoRef,
          {
            LastName: userDetails.LastName,
          },
          { merge: true }
        );
        // toast.info("Last Name updated");
        showAutoClosePopup("Update", "Last Name Updated", 3000);
        setSavePressed(Math.random().toString(36).slice(2));
      }
      if (userDetails.PhoneNumber !== checkChange.phone) {
        setDoc(
          userInfoRef,
          {
            Phone: userDetails.PhoneNumber,
          },
          { merge: true }
        );
        // toast.info("Phone Number updated");
        showAutoClosePopup("Update", "Phone Number Updated", 3000);

        setSavePressed(Math.random().toString(36).slice(2));
      }
      if (oldPassword !== "yyyyyy") {
        if (password === checkPassword) {
          if (checkPassword.length > 0) {
            const credential = EmailAuthProvider.credential(
              user.email,
              oldPassword
            );
            reauthenticateWithCredential(user, credential)
              .then(() => {
                updatePassword(user, checkPassword)
                  .then(() => {
                    // toast.info("Password Updated Successfully");
                    showAutoClosePopup(
                      "Update",
                      "Password Updated Successfully",
                      3000
                    );
                    signOut(auth)
                      .then(() => {
                        // Sign-out successful.
                        console.log("signed out");
                        setUser(null);
                        localStorage.removeItem("user");
                        history.push("/");
                      })
                      .catch((error) => {
                        // An error happened.
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log("Error occurred");
                    console.log(error);
                  });
                // user re-authenticated.
              })
              .catch((error) => {
                console.log(error);
                showAutoClosePopup("Error", "Wrong Old Password", 3000);
              });
          } else {
            return;
          }
        } else {
          showAutoClosePopup("Error", "Passwords Dont Match", 3000);
          return;
        }
      } else {
        // setOldPassword(" ");
        // setPassword(" ");
        // setCheckPassword(" ");
        // showAutoClosePopup('Error', 'New Password and Old Password Should be different', 3000)
        return;
      }
    }
  };

  // retain data even if something changed in input fields
  const handleCancel = () => {
    getUserDetails();
    setSavePressed(Math.random().toString(36).slice(2));
    history.push("/Dashboard");
  };

  return (
    <>
      <ToastContainer />
      <AppBar
        color="white"
        elevation={3}
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar style={{ display: "flex", alignItems: "center" }}>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>

          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            <Button
              id="demo-positioned-button"
              onClick={handleDropDown}
              style={{
                marginRight: "1rem",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: matchesSm ? "0.9rem" : matchesMd ? "1rem" : "1.1rem",
              }}
              endIcon={<ArrowDropDownIcon />}
            >
              Services
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloses}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                value="Lawn Service"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
                onClick={handlePageTop}
              >
                Lawn Service
              </MenuItem>
              <MenuItem
                value="Snow Service"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
                onClick={handlePageTop}
              >
                Snow Service
              </MenuItem>
              <MenuItem
                value="Spring Cleanup"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
                onClick={handlePageTop}
              >
                Spring Cleanup
              </MenuItem>
            </Menu>
          </Box>
          {!matchesSm && (
            <Button
              variant="contained"
              color="secondary"
              aria-label="Contact Us"
              onClick={() => history.push("/contact")}
              style={{
                marginRight: "1rem",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: matchesSm ? "0.9rem" : matchesMd ? "0.9rem" : "1rem",
              }}
            >
              Contact Us
            </Button>
          )}
          <Box display="flex" alignItems="center">
            {user ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open More">
                  <div
                    className="navLink"
                    onClick={handleOpenUserMenu}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <AccountCircleIcon
                      sx={{ color: "#187CAE", fontSize: 45 }}
                    />
                    {checkChange.firstName ? checkChange.firstName : ""}
                  </div>
                </Tooltip>
                <Menu
                  // sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {matchesSm && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/Contact")}
                    >
                      Conatct US
                    </MenuItem>
                  )}
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={() => history.push("/Dashboard")}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUser(null);
                          localStorage.removeItem("user");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </Menu>
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
      <Popup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        title={popupTitles}
        content={popupContent}
        actions={popupActions}
        alert
      />
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            fontSize: "30px",
            margin: "0px",
            paddingTop: "30px",
            fontWeight: "bolder",
          }}
        >
          Profile{" "}
        </h4>
        <p className={classes.p}>Update your personal details</p>

        <form
          autoComplete="off"
          style={{
            display: "grid",
            placeItems: "center",
            marginTop: "4%",
          }}
        >
          <Grid container xs={10} lg={10} md={10} sm={10} spacing={3}>
            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                First Name
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="fname"
                value={userDetails.FirstName}
                onChange={(e) =>
                  setUserDetails((data) => ({
                    ...data,
                    FirstName: e.target.value,
                  }))
                }
                className="greyInput"
              ></input>
            </Grid>
            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="lastname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Last Name
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="lname"
                value={userDetails.LastName}
                onChange={(e) =>
                  setUserDetails((data) => ({
                    ...data,
                    LastName: e.target.value,
                  }))
                }
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Email
              </label>
              <input
                readonly
                type="text"
                placeholder=""
                id="fname"
                value={userDetails.Email}
                // onChange={(e) => setEmail(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Phone
              </label>
              <input
                required
                type="text"
                id="fname"
                value={userDetails.PhoneNumber}
                onChange={(e) =>
                  setUserDetails((data) => ({
                    ...data,
                    PhoneNumber: e.target.value,
                  }))
                }
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Address
              </label>
              <input
                readonly
                id="address"
                value={userDetails.Address}
                // onChange={(e) => setAddress(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Old password
              </label>
              <input
                autoComplete="off"
                type="password"
                placeholder="Enter old password"
                id="fname"
                onChange={(e) => setOldPassword(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter the new password"
                id="fname"
                onChange={(e) => setPassword(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>
            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Re-enter Password
              </label>
              <input
                type="password"
                placeholder="Re-enter the password"
                id="fname"
                onChange={(e) => setCheckPassword(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: "Brand",
                  field: "cards",
                  cellStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  },
                },
                {
                  title: "Digits",
                  field: "digits",
                  cellStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  },
                },
                {
                  title: "Expiry",
                  field: "expiry",
                  cellStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  },
                },
                {
                  title: "",
                  cellStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    maxWidth: 300,
                  },
                  render: (rowData) => {
                    const SetDefaultbutton = (
                      <div
                        style={{
                          fontSize: "14px",
                          background: "#D1D2D2",
                          borderRadius: "69px",
                          fontWeight: "400",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          padding: "3px",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#000000",
                          fontStyle: "normal",
                          lineHeight: "22px",
                        }}
                        onClick={() => {
                          handleSetDefault(rowData);
                        }}
                      >
                        Set Default
                      </div>
                    );
                    const Defaultbutton = (
                      <div
                        style={{
                          fontSize: "14px",
                          background: "#1F724A",
                          borderRadius: "69px",
                          padding: "2px 15px",
                          fontWeight: "400",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F5FFFA",
                          fontStyle: "normal",
                          lineHeight: "22px",
                        }}
                      >
                        Default
                      </div>
                    );

                    if (
                      (defaultCard &&
                        defaultCard[2] === rowData.paymentMethod) ||
                      savedCards.length === 1
                    ) {
                      return Defaultbutton;
                    } else {
                      return SetDefaultbutton;
                    }
                  },
                },

                {
                  title: "",

                  render: (rowData) => {
                    const deletePM = (id) => {
                      const deleteIndex = id;
                      const updatedRow = [...savedCards];
                      updatedRow.splice(deleteIndex, 1);
                      handleDelete(id);
                      setSavedCards(updatedRow);
                      setDeleteConfirm(false);
                    };

                    const Deletebutton = (
                      <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            style={{ color: "black" }}
                            onClick={() => {
                              setPmData(rowData.tableData.id);
                              setDeleteConfirm(true);
                            }}
                          ></DeleteIcon>
                          <Dialog
                            open={deleteConfirm}
                            onClose={handleDeleteClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogContent>
                              <h4
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                Do you want to Delete the payment method?
                              </h4>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleDeleteClose}
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                }}
                              >
                                No
                              </Button>
                              <Button
                                onClick={() => deletePM(pmData)}
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </IconButton>
                      </Tooltip>
                    );
                    if (
                      defaultCard &&
                      defaultCard[2] != rowData.paymentMethod
                    ) {
                      return Deletebutton;
                    }
                  },
                },
              ]}
              data={savedCards}
              title={
                <label
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "black",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Payment Methods
                </label>
              }
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <>
                      {savedCards.length > 0 ? (
                        <label
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            color: "black",
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          No card has been added. Click Add New Card below, to
                          add a new Card
                        </label>
                      ) : (
                        ""
                      )}
                    </>
                  ),
                },
              }}
              components={{
                Toolbar: (props) => {
                  // Make a copy of props so we can hide the default title and show our own
                  // This let's us use our own Title while keeping the search
                  const propsCopy = { ...props };
                  // Hide default title
                  propsCopy.showTitle = false;
                  return (
                    <div style={{ display: "flex" }}>
                      <label
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "black",
                          paddingLeft: "13px",
                          marginTop: "10px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        Payment Methods
                      </label>
                    </div>
                  );
                },

                Container: (props) => (
                  <Paper
                    {...props}
                    elevation={0}
                    style={{
                      margin: "0px",
                      padding: "0px",
                      width: matchesXS ? "250px" : "",
                    }}
                  />
                ),
              }}
              options={{
                paging: false,
                sorting: false,
                headerStyle: {
                  fontWeight: "500",
                  color: "#252424",
                  paddingTop: "5px",
                },
                rowStyle: {
                  backgroundColor: "white",
                  color: "black",
                  padding: "0px",
                  margin: "0px",
                  textAlign: "left",
                },
                actionsColumnIndex: -1,
                addRowPosition: "first",
                showTitle: true,
                search: false,
              }}
            />

            <div style={{ display: "grid", placeItems: "center" }}>
              <div
                onClick={handleClickOpen}
                style={{
                  fontSize: "1.5vh",
                  border: "4px solid #187CAE",
                  borderRadius: "28px",
                  fontWeight: "600",
                  padding: "5px 15px",
                  marginLeft: "0%",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  color: "#187CAE",
                  marginLeft: "10px",
                  marginBottom: "3vh",
                }}
              >
                + Add new card
              </div>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <>
                  <DialogTitle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#212121",
                      fontWeight: "500",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={Logo2}
                        alt="logo"
                        style={{
                          width: matchesSm ? "7%" : "5%",
                          height: matchesSm ? "7%" : "5%",
                          marginRight: "3%",
                        }}
                      />
                      Add New Card
                    </span>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <form
                      style={{
                        display: "grid",
                        placeItems: "center",
                        width: "100%",
                      }}
                    >
                      {/* React Credit Card UI */}
                      <Cards
                        number={number}
                        name={cardName}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focus}
                      />
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sm={12} lg={12} xl={12}>
                          <TextField
                            color="primary"
                            type="num"
                            name="number"
                            fullWidth
                            inputProps={{ maxLength: 16 }}
                            value={number}
                            placeholder="Card Number*"
                            className="input-text"
                            style={{
                              margin: "5px",
                              marginTop: "3vh",
                              marginLeft: matchesSm ? "1%" : "5%",
                              width: matchesSm ? "102%" : "89%",
                            }}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              SetNumber(e.target.value);
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sm={6} lg={7} xl={7}>
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            name="number"
                            color="primary"
                            placeholder="Name on Card*"
                            className="input-text"
                            variant="outlined"
                            value={cardName}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              SetCardName(e.target.value);
                            }}
                            inputProps={{ maxLength: 80 }}
                          />
                        </Grid>
                        <Grid item sm={6} lg={4} xl={4}>
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="text"
                            placeholder="Nick Name"
                            color="primary"
                            className="input-text"
                            value={nickname}
                            onChange={(e) => setNickName(e.target.value)}
                            variant="outlined"
                            inputProps={{ maxLength: 16 }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sm={12} lg={7} xl={7}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: matchesSm ? "column" : "row",
                              width: matchesSm ? "100%" : "96%",
                            }}
                          >
                            <TextField
                              style={{ width: "100%", margin: "5px" }}
                              type="num"
                              name="expiry"
                              placeholder="Expiry Month*"
                              className="input-text semi"
                              color="primary"
                              variant="outlined"
                              value={expiryMonth}
                              onFocus={handleFocus}
                              onChange={(e) => {
                                SetExpiryMonth(e.target.value);
                                SetExpiry(e.target.value);
                              }}
                              inputProps={{ maxLength: 2 }}
                            />
                            <TextField
                              style={{ width: "100%", margin: "5px" }}
                              type="num"
                              name="expiry"
                              placeholder="Expiry Year*"
                              className="input-text semi"
                              color="primary"
                              variant="outlined"
                              value={expiryYear}
                              onFocus={handleFocus}
                              onChange={(e) => {
                                SetExpiryYear(e.target.value);
                                SetExpiry(expiryMonth.concat(e.target.value));
                              }}
                              inputProps={{ maxLength: 2 }}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6} lg={4} xl={4}>
                          <TextField
                            style={{ width: "100%", margin: "5px" }}
                            type="num"
                            name="cvc"
                            placeholder="CVV/CVC*"
                            className="input-text semi"
                            color="primary"
                            variant="outlined"
                            value={cvc}
                            inputProps={{ maxLength: 3 }}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              SetCvc(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={processingCard}
                        onClick={handleAddCard}
                        style={{
                          color: "white",
                          marginTop: "3vh",
                          width: "50%",
                        }}
                      >
                        {processingCard ? <CircularProgress /> : "Add Card"}
                      </Button>
                    </form>
                  </DialogContent>
                </>
              </Dialog>
            </div>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3vh",
              marginBottom: "3vh",
            }}
          >
            <button
              className={classes.buttonPrimarySmall}
              id="submit"
              onClick={(e) => handleSave(e)}
              style={{
                fontSize: "16px",
                fontFamily: "Poppins",
              }}
            >
              <span>Save</span>
            </button>

            <button
              className={classes.buttonPrimary2}
              id="submit"
              onClick={handleCancel}
              style={{
                fontSize: "16px",
                marginLeft: "5%",
                fontFamily: "Poppins",
              }}
            >
              <span>Cancel</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
