import React, { useState } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import axios from 'axios';
import { authQA, auth } from '../../components/Firebase';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useMediaQuery } from '@material-ui/core';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		fontFamily: 'Poppins',
		fontSize: '1.6rem',
		fontWeight: 'bold'
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		minHeight: '100vh', // Changed height to minHeight
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh', // Added marginTop to center the form vertically
		},

	},
	input: {
		maxWidth: '600px',
		width: '100%',
		padding: '10px',
		marginBottom: '20px',
		fontSize: '16px',
		fontWeight: '500',
		fontFamily: 'Poppins',
		borderRadius: '5px',
		border: '2px solid #ccc',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '300px'
		}
	},
	button: {
		width: '300px',
		padding: '10px',
		fontSize: '16px',
		borderRadius: '5px',
		backgroundColor: '#187CAE',
		fontFamily: 'Poppins',
		color: '#fff',
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
		border: 'none',
		'&:hover': {
			backgroundColor: '#0d8bf1',
		},
		[theme.breakpoints.down('sm')]: {
			width: '200px'
		}
	},
}));

const UserImpersonation = () => {
	const history = useHistory();
	const classes = useStyles();
	const [adminEmail, setAdminEmail] = useState('');
	const [adminPassword, setAdminPassword] = useState('');
	const [UID, setUID] = useState('');
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));


	// Make API request to backend for impersonation
	const handleImpersonate = async (e) => {
		e.preventDefault();

		try {
			if (!adminEmail || !adminPassword || !UID) {
				alert('Missing fields')
				return;
			}

			// Sign in the user with email and password
			const userCredential = await signInWithEmailAndPassword(authQA, adminEmail, adminPassword);
			// User signed in successfully
			const user = userCredential.user;

			try {
				if (user) {
					console.log('User signed in:', user.uid);

					// Send the API request
					const response = await axios.post(
						// 'http://localhost:4008/QA/AdminImpersonation',
						'https://be-operation-services.herokuapp.com/AdminImpersonation',
						{
							adminEmail: user.email,
							UID: UID,
						});
					const data = response.data;

					if (data.status === 'success') {
						// Custom token successfully obtained
						await signInWithCustomToken(auth, data.token)
						console.log('User impersonation successful');
						history.push('/dashboard');
					} else {
						alert(data.message);
						return;
					}
				}
			} catch (e) {
				alert(e.message);
			}

		} catch (e) {
			console.error('Error occurred during user impersonation:', e);
			if (e.code === `auth/invalid-email`)
				alert('wrong email');
			else if (e.code === `auth/wrong-password`)
				alert('wrong password');
			else {
				alert('user does not exist');
			}
		}
	};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar>
					<IconButton edge="start" className={classes.backButton} color="inherit" onClick={() => {
						window.location.href = 'https://admin.alltramatic.com/dashboard';
					}}>
						<ArrowBackIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Admin Operations
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid container spacing={2} justifyContent="center" className={classes.formContainer}>
				<Grid item xs={12} sm={6} md={4}>
					<form onSubmit={handleImpersonate}>
						<div>
							<input
								type="email"
								placeholder="Admin Email"
								value={adminEmail}
								onChange={(e) => setAdminEmail(e.target.value)}
								className={classes.input}
							/>
						</div>
						<div>
							<input
								type="password"
								placeholder="Password"
								value={adminPassword}
								onChange={(e) => setAdminPassword(e.target.value)}
								className={classes.input}
							/>
						</div>
						<div>
							<input
								type="text"
								placeholder="Customer UID"
								value={UID}
								onChange={(e) => setUID(e.target.value)}
								className={classes.input}
							/>
						</div>
						<div>
							<button type="submit" className={classes.button}>
								Impersonate User
							</button>
						</div>
					</form>
				</Grid>
			</Grid>

		</div>
	);
};

export default UserImpersonation;
