import React from 'react'

const PrivacyPolicy = () => {
	const content = `<html>

	<head>
		<meta content="text/html; charset=UTF-8" http-equiv="content-type">
		<style type="text/css">
			.lst-kix_b6ho8zrdgvad-8>li:before {
				content: "\u25cf  "
			}
	
			.lst-kix_b6ho8zrdgvad-6>li:before {
				content: "\u25cb  "
			}
	
			.lst-kix_b6ho8zrdgvad-5>li:before {
				content: "\u25cf  "
			}
	
			.lst-kix_b6ho8zrdgvad-7>li:before {
				content: "\u25c6  "
			}
	
			.lst-kix_b6ho8zrdgvad-0>li:before {
				content: "\u2794  "
			}
	
			.lst-kix_b6ho8zrdgvad-2>li:before {
				content: "\u25cf  "
			}
	
			.lst-kix_b6ho8zrdgvad-1>li:before {
				content: "\u25c6  "
			}
	
			.lst-kix_b6ho8zrdgvad-4>li:before {
				content: "\u25c6  "
			}
	
			.lst-kix_d0nebbplh3m5-0>li:before {
				content: "\u2794  "
			}
	
			.lst-kix_b6ho8zrdgvad-3>li:before {
				content: "\u25cb  "
			}
	
			ul.lst-kix_d0nebbplh3m5-0 {
				list-style-type: none
			}
	
			.lst-kix_d0nebbplh3m5-1>li:before {
				content: "\u25c6  "
			}
	
			.lst-kix_d0nebbplh3m5-2>li:before {
				content: "\u25cf  "
			}
	
			ul.lst-kix_d0nebbplh3m5-2 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-1 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-4 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-3 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-6 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-5 {
				list-style-type: none
			}
	
			ul.lst-kix_d0nebbplh3m5-8 {
				list-style-type: none
			}
	
			.lst-kix_d0nebbplh3m5-5>li:before {
				content: "\u25cf  "
			}
	
			ul.lst-kix_d0nebbplh3m5-7 {
				list-style-type: none
			}
	
			.lst-kix_d0nebbplh3m5-3>li:before {
				content: "\u25cb  "
			}
	
			.lst-kix_d0nebbplh3m5-4>li:before {
				content: "\u25c6  "
			}
	
			li.li-bullet-0:before {
				margin-left: -18pt;
				white-space: nowrap;
				display: inline-block;
				min-width: 18pt
			}
	
			ul.lst-kix_b6ho8zrdgvad-1 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-0 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-3 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-2 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-5 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-4 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-7 {
				list-style-type: none
			}
	
			ul.lst-kix_b6ho8zrdgvad-6 {
				list-style-type: none
			}
	
			.lst-kix_d0nebbplh3m5-6>li:before {
				content: "\u25cb  "
			}
	
			ul.lst-kix_b6ho8zrdgvad-8 {
				list-style-type: none
			}
	
			.lst-kix_d0nebbplh3m5-7>li:before {
				content: "\u25c6  "
			}
	
			.lst-kix_d0nebbplh3m5-8>li:before {
				content: "\u25cf  "
			}
	
			ol {
				margin: 0;
				padding: 0
			}
	
			table td,
			table th {
				padding: 0
			}
	
			.c14 {
				background-color: #ffffff;
				margin-left: 36pt;
				padding-top: 8pt;
				padding-left: 0pt;
				padding-bottom: 8pt;
				line-height: 1.15;
				orphans: 2;
				widows: 2;
				text-align: left;
				margin-right: 8pt
			}
	
			.c19 {
				background-color: #ffffff;
				margin-left: 30pt;
				padding-top: 8pt;
				padding-bottom: 8pt;
				line-height: 1.15;
				orphans: 2;
				widows: 2;
				text-align: left;
				margin-right: 8pt
			}
	
			.c0 {
				background-color: #ffffff;
				padding-top: 0pt;
				padding-bottom: 7pt;
				line-height: 1.15;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			.c8 {
				color: #4a86e8;
				font-weight: 700;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 27pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c5 {
				color: #4a4a4a;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 12pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c3 {
				color: #4a86e8;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 14pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c17 {
				color: #000000;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 11pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c2 {
				color: #373e3b;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 10.5pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c10 {
				color: #4a86e8;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 18pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c18 {
				color: #4a86e8;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 12pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c6 {
				color: #4a86e8;
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-size: 13.5pt;
				font-family: "Arial";
				font-style: normal
			}
	
			.c20 {
				background-color: #ffffff;
				padding-top: 0pt;
				padding-bottom: 0pt;
				line-height: 1.1;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			.c9 {
				background-color: #ffffff;
				padding-top: 12pt;
				padding-bottom: 0pt;
				line-height: 1.15;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			.c4 {
				padding-top: 0pt;
				padding-bottom: 0pt;
				line-height: 1.15;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			.c21 {
				font-weight: 400;
				text-decoration: none;
				vertical-align: baseline;
				font-family: "Arial";
				font-style: normal
			}
	
			.c1 {
				font-size: 7pt;
				font-family: "Times New Roman";
				color: #4a4a4a;
				font-weight: 400
			}
	
			.c12 {
				background-color: #ffffff;
				max-width: 468pt;
				padding: 72pt 72pt 72pt 72pt
			}
	
			.c16 {
				color: #3273dc;
				font-size: 12pt
			}
	
			.c15 {
				color: #4a4a4a;
				font-size: 10.5pt
			}
	
			.c22 {
				color: #4a86e8;
				font-size: 16pt
			}
	
			.c13 {
				color: inherit;
				text-decoration: inherit
			}
	
			.c11 {
				padding: 0;
				margin: 0
			}
	
			.c23 {
				color: #4a86e8;
				font-size: 10.5pt
			}
	
			.c7 {
				height: 11pt
			}
	
			.title {
				padding-top: 0pt;
				color: #000000;
				font-size: 26pt;
				padding-bottom: 3pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			.subtitle {
				padding-top: 0pt;
				color: #666666;
				font-size: 15pt;
				padding-bottom: 16pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			li {
				color: #000000;
				font-size: 11pt;
				font-family: "Arial"
			}
	
			p {
				margin: 0;
				color: #000000;
				font-size: 11pt;
				font-family: "Arial"
			}
	
			h1 {
				padding-top: 20pt;
				color: #000000;
				font-size: 20pt;
				padding-bottom: 6pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			h2 {
				padding-top: 18pt;
				color: #000000;
				font-size: 16pt;
				padding-bottom: 6pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			h3 {
				padding-top: 16pt;
				color: #434343;
				font-size: 14pt;
				padding-bottom: 4pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			h4 {
				padding-top: 14pt;
				color: #666666;
				font-size: 12pt;
				padding-bottom: 4pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			h5 {
				padding-top: 12pt;
				color: #666666;
				font-size: 11pt;
				padding-bottom: 4pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				orphans: 2;
				widows: 2;
				text-align: left
			}
	
			h6 {
				padding-top: 12pt;
				color: #666666;
				font-size: 11pt;
				padding-bottom: 4pt;
				font-family: "Arial";
				line-height: 1.15;
				page-break-after: avoid;
				font-style: italic;
				orphans: 2;
				widows: 2;
				text-align: left
			}
		</style>
	</head>
	
	<body class="c12 doc-content">
		<h1 class="c20" id="h.ufdfv1pd8u94"><span class="c8">Privacy Policy</span></h1>
		<p class="c4 c7"><span class="c17"></span></p>
		<p class="c0"><span class="c2">Lawnber (&ldquo;Company,&rdquo; &ldquo;we&rdquo; or &ldquo;us&rdquo;) respects the
				privacy rights of our online user and mobile application users. This Privacy Policy explains how Lawnber
				collects, uses, shares and protects information about you. This Privacy Policy also describes how you can
				access/change your information and make choices about how your information is used.</span></p>
		<p class="c0"><span class="c2">This Privacy Policy covers Lawnber&rsquo;s online, including websites and mobile
				sites and applications, and services made available to you. This Policy also applies to your use of
				interactive features or downloads that: (i) we own or control; (ii) are available through the Services; or
				(iii) interact with the Services and post or incorporate this Policy.</span></p>
		<p class="c0"><span class="c2">BY USING OUR SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU AGREE TO THE
				TERMS OF THIS PRIVACY POLICY. We may update this Privacy Policy at appropriate times to reflect our latest
				information practices. Please review this Privacy Policy carefully. Do not use our Services if you do not
				agree to this Policy. Our Terms of Service may also apply to your use of our online and mobile applications.
				This Privacy Policy is incorporated by reference into the Terms of Service. Any changes will be effective
				immediately upon the posting of the revised Privacy Policy. We will provide notice of changes by email. In
				addition please review this Policy periodically to ensure up to date information.</span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c0"><span class="c2">Lawnber built the Lawnber app as a Free app. This SERVICE is provided by Lawnber at
				no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the
				collection, use, and disclosure of Personal Information if anyone decided to use our Service.</span></p>
		<p class="c0"><span class="c2">If you choose to use our Service, then you agree to the collection and use of
				information in relation to this policy. The Personal Information that we collect is used for providing and
				improving the Service. We will not use or share your information with anyone except as described in this
				Privacy Policy.</span></p>
		<p class="c0"><span class="c2">The terms used in this Privacy Policy have the same meanings as in our Terms and
				Conditions, which are accessible at Lawnber unless otherwise defined in this Privacy Policy.</span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c4"><span class="c10">We Collect Three Types Of Information And Data </span></p>
		<ul class="c11 lst-kix_b6ho8zrdgvad-0 start">
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp;</span><span class="c1"><a class="c13"
						href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1679286468734513&amp;usg=AOvVaw2msEW7a3npMsg34iPDk06y">&nbsp;</a></span><span
					class="c16"><a class="c13"
						href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1679286468734949&amp;usg=AOvVaw0RGjMHv9KvpVDJHUXDOJSo">Google
						Play Services</a></span></li>
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp;</span><span class="c1"><a class="c13"
						href="https://www.google.com/url?q=https://firebase.google.com/policies/analytics&amp;sa=D&amp;source=editors&amp;ust=1679286468735526&amp;usg=AOvVaw1S-0io8ZI8jZgTMroshPhT">&nbsp;</a></span><span
					class="c16"><a class="c13"
						href="https://www.google.com/url?q=https://firebase.google.com/policies/analytics&amp;sa=D&amp;source=editors&amp;ust=1679286468735820&amp;usg=AOvVaw1oXOJN8d6zPxI6RueiabI7">Google
						Analytics for Firebase</a></span></li>
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp;</span><span class="c1"><a class="c13"
						href="https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;source=editors&amp;ust=1679286468736288&amp;usg=AOvVaw1Iphk5XSPkD5bsL9wP98JL">&nbsp;</a></span><span
					class="c16"><a class="c13"
						href="https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;source=editors&amp;ust=1679286468736592&amp;usg=AOvVaw0nlvWPORHXTQ1LZKFhXRVc">Firebase
						Crashlytics</a></span></li>
		</ul>
		<p class="c7 c19"><span class="c10"></span></p>
		<p class="c4 c7"><span class="c10"></span></p>
		<p class="c4"><span class="c3">User Provided Personal Information.</span></p>
		<p class="c0"><span class="c2">Personal Information. We may ask for standard personal information used to identify
				you personally and to send you service updates, such as your name, company, e-mail address, and mobile
				number (&ldquo;Personal Information&rdquo;). We may collect this information through forms in the Contact Us
				forms, account registration forms, or when you otherwise interact with us. When you sign up to use the
				Services, you will be required to create an account profile.</span></p>
		<p class="c0"><span class="c2">Certain Services suggest you to include a photo to help our service crews to better
				identify you. If you choose to upload a photo when registering for our Services, the photo may be viewable
				by us and by the lawn care providers. You may remove your photo at any time from your online acct
				profile.</span></p>
		<p class="c4"><span class="c3">User Generated Content and Public Information.</span></p>
		<p class="c0"><span class="c2">User Content. The Services may ask you to share public information to help provide
				the best services to you. We offer publicly accessible blogs or community forums or other ways to permit you
				to submit service requests, project ideas, user preferences, property photographs, video, audio recordings,
				pictures, questions, comments, reviews, feedbacks, suggestions or other content, including Personal
				Information (collectively, &ldquo;User Content&rdquo;). We or others may reproduce, publish, distribute or
				otherwise use User Content online or offline in any media or format. Others may have access to this User
				Content and may have the ability to share it with third parties across the Internet. You should be aware
				that any User Content you provide in these areas may be read, collected, and used by others who access them.
				This Privacy Policy does not apply to any information that you disclose publicly, share with others or
				otherwise upload, whether through the Services or otherwise. Thus, please think carefully before you share
				information, including Personal Information, in connection with your User Content. The Company does not
				control who will have access to the information that you choose to make public, and cannot ensure that
				parties who have access to such publicly available information will respect your privacy or keep it secure.
				We are not responsible for the accuracy, use or misuse of any content or information that you disclose or
				receive through the Services.</span></p>
		<p class="c0"><span class="c2">We may also publish your name, voice, likeness and other Personal Information that is
				part of your User Content, and we may use the content, or any portion of the content, for advertising,
				marketing, publicity and promotional activities.</span></p>
		<p class="c0"><span class="c2">To request removal of your User Content from our blog or community forum or similar
				features, contact us through customer support. In some cases, we may not be able to remove your User
				content, in which case we will let you know if we are unable to do so and why.</span></p>
		<p class="c4"><span class="c3">Location.</span></p>
		<p class="c0"><span class="c2">With respect to Location Data, we use user&rsquo;s GPS position coordinates, IP
				Addresses and geolocation data through online and Mobile Devices. We use location information for various
				purposes including for you to be able to set the location from which you wish you requested Services, to
				track progress of the Services, follow weather forecast and provide that information to you and your lawn
				care provider, to send you notifications, promotions and offers, and to allow you (if you choose through any
				features we may provide) to share this information with other people. Except as otherwise permitted in this
				Privacy Policy, we will not share this information with third parties for any purpose and will only use this
				information for the sole purpose of providing you with the Services. You may at any time no longer allow our
				Services to use your location by turning this feature off at the Mobile Device level.</span></p>
		<p class="c0"><span class="c2">We also provide some of your Personal Information (such as your first name, email
				address, phone number and physical address) to the lawn care provider so that the lawn care provider may
				contact and find you. We may associate your unique Mobile Device identifier or Service usage information
				with any Personal Information you provide, but we will treat the combined information as Personal
				Information.</span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c9"><span class="c21 c22">Children&rsquo;s Privacy</span></p>
		<p class="c0"><span class="c5">These Services do not address anyone under the age of 13. We do not knowingly collect
				personally identifiable information from children under 13 years of age. In the case we discover that a
				child under 13 has provided us with personal information, we immediately delete this from our servers. If
				you are a parent or guardian and you are aware that your child has provided us with personal information,
				please contact us so that we will be able to do the necessary actions.</span></p>
		<p class="c0 c7"><span class="c5"></span></p>
		<p class="c9"><span class="c3">Log Data</span></p>
		<p class="c0"><span class="c5">We want to inform you that whenever you use our Service, in a case of an error in the
				app we collect data and information (through third-party products) on your phone called Log Data. This Log
				Data may include information such as your device Internet Protocol (&ldquo;IP&rdquo;) address, device name,
				operating system version, the configuration of the app when utilizing our Service, the time and date of your
				use of the Service, and other statistics.</span></p>
		<p class="c0 c7"><span class="c5"></span></p>
		<p class="c9"><span class="c3">Cookies</span></p>
		<p class="c9"><span class="c5">Cookies are files with a small amount of data that are commonly used as anonymous
				unique identifiers. These are sent to your browser from the websites that you visit and are stored on your
				device&#39;s internal memory.</span></p>
		<p class="c9"><span class="c5">This Service does not use these &ldquo;cookies&rdquo; explicitly. However, the app
				may use third-party code and libraries that use &ldquo;cookies&rdquo; to collect information and improve
				their services. You have the option to either accept or refuse these cookies and know when a cookie is being
				sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this
				Service.</span></p>
		<p class="c0 c7"><span class="c5"></span></p>
		<p class="c0 c7"><span class="c5"></span></p>
		<p class="c9"><span class="c3">Service Providers</span></p>
		<p class="c9"><span class="c5">We may employ third-party companies and individuals due to the following
				reasons:</span></p>
		<ul class="c11 lst-kix_d0nebbplh3m5-0 start">
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span><span class="c5">To facilitate
					our Service;</span></li>
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span><span class="c5">To provide the
					Service on our behalf;</span></li>
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span><span class="c5">To perform
					Service-related services; or</span></li>
			<li class="c14 li-bullet-0"><span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span><span class="c5">To assist us in
					analyzing how our Service is used.</span></li>
		</ul>
		<p class="c9"><span class="c5">We want to inform users of this Service that these third parties have access to their
				Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
				obligated not to disclose or use the information for any other purpose.</span></p>
		<p class="c0 c7"><span class="c5"></span></p>
		<p class="c4 c7"><span class="c10"></span></p>
		<p class="c4"><span class="c10">Information From Your Access and Use of the Services.</span></p>
		<p class="c0"><span class="c2">In addition to the information that you directly submit to us, we and our third-party
				service providers may use a variety of technologies that automatically collect information whenever you
				visit or interact with the Services (&ldquo;Usage Information&rdquo;). This Usage Information may include
				the browser that you are using, the URL that referred you to our Services, all of the areas within our
				Services that you visit, and the time of day, among other information. We may use Usage Information for a
				variety of purposes, including to enhance or otherwise improve the Services. In addition, we collect your IP
				address or other unique identifier (&ldquo;Device Identifier&rdquo;) for your computer, mobile or other
				device used to access the Services (&ldquo;Device&rdquo;). Usage Information may be non-identifying or may
				be associated with you. Whenever we associate Usage Information or a Device Identifier with your Personal
				Information, we will treat it as Personal Information.</span></p>
		<p class="c0"><span class="c2">In addition, tracking information is collected as you use our Services, including,
				but not limited to geolocation. If you are receiving lawn care via our Services, the lawn care
				provider&rsquo;s mobile device will send your GPS coordinates, during the lawn care service, to our servers.
				Most GPS enabled mobile devices can define one&rsquo;s location to within 50 feet. We collect this
				information for various purposes, including to determine the duration of the lawn care service, to provide
				customer support, to send promotions and offers, to enhance our Services, and for our internal business
				purposes.</span></p>
		<p class="c0"><span class="c2">We may also collect Usage Information using the following methods or technologies to
				name a few:</span></p>
		<p class="c0"><span class="c2">Cookies. A cookie is a data file placed on a Device when it is used to access the
				Services. A Flash cookie is a data file placed on a Device via the Adobe Flash plug-in that may be built-in
				to or downloaded by you to your Device. Cookies and Flash Cookies may be used for many purposes, including,
				without limitation, remembering you and your preferences and tracking your visits to our web pages. Cookies
				work by assigning a number to the user that has no meaning outside of the assigning website.</span></p>
		<p class="c0"><span class="c2">Your browser allows you to deny or accept the use of cookies. Cookies can be disabled
				or controlled by setting a preference within your web browser or on your Device. However, if you disable
				cookies or Flash cookies on your Device, some features of the Services may not function properly or may not
				be able to customize the delivery of information. The Company cannot control the use of cookies (or the
				resulting information) by third parties, and use of third-party cookies is not covered by this Privacy
				Policy.</span></p>
		<p class="c0"><span class="c2">Web Beacons. Small graphic images or computer code called web beacons may be included
				in our web and mobile pages and messages. The web beacons are tiny graphics with a unique identifier,
				similar in function to cookies, and are used to track the online movements of Web users. In contrast to
				cookies, which are stored in a user&rsquo;s computer hard drive, web beacons are embedded invisibly on Web
				pages. Web beacons or similar technologies help us better manage content on our Services by informing us
				what content is effective, count users of the Services, monitor how users navigate the Services, count how
				many e-mails that we send were actually opened or to count how many particular articles or links were
				actually viewed. We do not tie the information gathered by web beacons to our customers&rsquo; personal
				information.</span></p>
		<p class="c0"><span class="c2">Embedded Scripts. An embedded script is computer code that is designed to collect
				information about your interactions with the Services, such as the links you click on. The code is
				temporarily downloaded onto your Device from our web server or a third party service provider, is active
				only while you are connected to the Services, and is deactivated or deleted thereafter.</span></p>
		<p class="c4"><span class="c10">Information With Third Parties.</span></p>
		<p class="c0"><span class="c2">If you choose to refer a friend to our Services we will ask you for the
				recipient&rsquo;s name, phone number, physical address and email address. We will send the recipient a
				one-time email inviting them to visit Lawnber.com and provide promotions or an invitation to use the
				Services. We may store this information for the purpose of sending this one-time email and tracking use of
				our Services. We do not use this information for any other marketing purpose unless we obtain consent from
				that person or we explicitly say otherwise. Please be aware that when you enter the recipient&rsquo;s email
				address through our Services, your name, e-mail address and physical address may be included in the
				communication sent to the recipient.</span></p>
		<p class="c4"><span class="c10">Information From Lawn Service Providers and Other Applicants.</span></p>
		<p class="c0"><span class="c2">If you wish to apply for a job on our website, we will collect Personal Information
				such as your name, email address, phone number, insurance information, driver&rsquo;s license, social
				security number and may collect additional information such as resume, gender, and your ethnicity. We use
				the information collected within this area of the website to determine your qualifications for the position
				in which you have applied and to contact you to set up an interview.</span></p>
		<p class="c4"><span class="c6">Use of the Collected Information.</span></p>
		<p class="c0"><span class="c2">When you register for an account with us, we will send you welcome email and
				promotional emails inviting you to use the Services. We will also communicate with you in response to your
				inquiries, to provide the Services you request, and to manage your account. We will communicate with you by
				email, telephone, push notification, and SMS or text message.</span></p>
		<p class="c0"><span class="c2">We use your information to understand which features of the Services are used most,
				to allow you to view your lawn care history, store your credit card information on a secure page, view any
				promotions we may currently be running, rate the Services, and to otherwise improve the Services. The credit
				card information you provide in your personal profile at sign-up is not stored by us, but is stored and used
				by our third party credit card processors in order for them to process payment that you owe third parties
				for transportation services received by you.</span></p>
		<p class="c0"><span class="c2">We use your IP address to help diagnose problems with our computer server, and to
				administer our website(s). Your IP address is used to help identify you, but contains no personal
				information about you.</span></p>
		<p class="c0"><span class="c2">In addition, we may use your Personal Information or Usage Information that we
				collect about you: (1) to provide you with information or services or process transactions that you have
				requested or agreed to receive including to send you electronic newsletters, or to provide you with special
				offers or promotional materials on behalf of us or third parties; (2) to process your registration with the
				Services, including verifying your information is active and valid; (3) to improve the Services or our
				services, to customize your experience with the Services, or to serve you specific content that is most
				relevant to you; (4) to enable you to participate in a variety of the Services&rsquo; features such as
				online or mobile entry sweepstakes, contests or other promotions; (5) to contact you with regard to your use
				of the Services and, in our discretion, changes to the Services and the Services&rsquo; policies; (6) for
				internal business purposes; (7) for inclusion in our data analytics; and (8) for purposes disclosed at the
				time you provide your information or as otherwise set forth in this Privacy Policy.</span></p>
		<p class="c0"><span class="c2">Please note that information submitted to the Services via a &ldquo;contact us&rdquo;
				or other similar function may not receive a response.</span></p>
		<p class="c4"><span class="c6">Disclosure of Information to Third Parties.</span></p>
		<p class="c0"><span class="c2">We may share non-personally identifiable information, such as aggregated user
				statistics and log data, with third parties for industry analysis, demographic profiling, to deliver
				targeted advertising about other products or services, or for other business purposes. We do not sell,
				share, rent or trade the information we have collected about you, including Personal Information, other than
				as disclosed within this Privacy Policy or at the time you provide your information. We do not share your
				Personal Information with third parties for those third parties&rsquo; direct marketing purposes unless you
				consent to such sharing at the time you provide your Personal Information.</span></p>
		<p class="c4"><span class="c10">When You Agree to Receive Information from Third Parties.</span></p>
		<p class="c0"><span class="c2">You may be presented with an opportunity to receive information and marketing offers
				directly from third parties. If you do agree to have your Personal Information shared, your Personal
				Information will be disclosed to such third parties and all information you disclose will be subject to the
				privacy policy and practices of such third parties. We are not responsible for the privacy policies and
				practices of such third parties and, therefore, you should review the privacy policies and practices of such
				third parties prior to agreeing to receive such information from them. If you later decide that you no
				longer want to receive communication from a third party, you will need to contact that third party
				directly.</span></p>
		<p class="c4"><span class="c10">Third Parties Providing Services on Our Behalf.</span></p>
		<p class="c0"><span class="c2">We use third party companies and individuals to facilitate our Services, provide or
				perform certain aspects of the Services on our behalf. We may provide vendors with access to user
				information, including Personal Information, this information sharing is limited to only the information
				needed by the vendor to carry out the services they are performing for you or for us. Each of these vendors
				are obligated not to disclose or use Personal Information for any other purpose.</span></p>
		<p class="c0"><span class="c2">While we may use third party analytics service providers to evaluate and provide us
				with information about the use of the Services and viewing of our content, we do not share Personal
				Information with these analytics service providers, but they may set and access their own cookies, web
				beacons and embedded scripts on your Device and they may otherwise collect or have access to information
				about you, including non-personally identifiable information.</span></p>
		<p class="c0"><span class="c2">We use a third party hosting provider who hosts our support section of our website.
				Information collected within this section of our website is governed by our Privacy Policy.</span></p>
		<p class="c4"><span class="c10">Legal Reasons.</span></p>
		<p class="c0"><span class="c2">We cooperate with government and law enforcement officials and private parties to
				enforce and comply with the law. Thus, we may access, use, preserve, transfer and disclose your information
				(including Personal Information), including disclosure to third parties such as government or law
				enforcement officials or private parties as we reasonably determine is necessary and appropriate: (i) to
				satisfy any applicable law, regulation, subpoenas, governmental requests or legal process; (ii) to protect
				and defend the Terms of Service for online and mobile Services or other policies applicable to any online
				and mobile Services, including investigation of potential violations thereof; (iii) to protect the safety,
				rights, property or security of Lawnber, our Services or any third party; (iv) to protect the safety of the
				public for any reason; (v) to detect, prevent or otherwise address fraud, security or technical issues; or
				(vi) to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical,
				or legally actionable activity. Further, we may use IP address or other Device Identifiers, to identify
				users, and may do so in cooperation with third parties such as copyright owners, internet service providers,
				wireless service providers or law enforcement agencies, including disclosing such information to third
				parties, all in our discretion. Such disclosures may be carried out without notice to you.</span></p>
		<p class="c4"><span class="c10">Changing Your Information and Canceling Your Account.</span></p>
		<p class="c0"><span class="c2">You are responsible for maintaining the accuracy of the information you submit to us,
				such as your contact information provided as part of account registration. If your Personal Information
				changes, or if you no longer desire our Services, you may correct, delete inaccuracies, or amend information
				by making the change on our member information page or by contacting us through customer support. We will
				make good faith efforts to make requested changes in our then active databases as soon as reasonably
				practicable.</span></p>
		<p class="c0"><span class="c2">You may also cancel or modify your account or the communications that you have
				elected to receive from the Services by following the instructions contained within an e-mail or by logging
				into your user account and changing your communication preferences.</span></p>
		<p class="c0"><span class="c2">We will retain your Personal Information and Usage Information (including
				geolocation) for as long as your account with the Services is active and as needed to provide you services.
				Even after your account is terminated, we will retain your Personal Information and Usage Information
				(including geolocation, lawn care history, credit card information and transaction history) as needed to
				comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to
				cancellation of an account (such as addressing chargebacks from your credit card companies), investigate or
				prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason.
				After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as
				necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for
				analytic purposes.</span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c4"><span class="c10">Security.</span></p>
		<p class="c9"><span class="c15">We value your trust in providing us your Personal Information, thus we are striving
				to use commercially acceptable means of protecting it. But remember that no method of transmission over the
				internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute
				security.</span></p>
		<p class="c0"><span class="c2">The Personal Information and Usage Information we collect is securely stored within
				our databases, and we use standard, industry-wide, commercially reasonable security practices such as
				encryption, firewalls and SSL (Secure Socket Layers) for protecting your information - such as any portions
				of your credit card number which we retain (we do not ourselves retain your entire credit card information)
				and geolocation information. However, as effective as encryption technology is, no security system is
				impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that information you
				supply won&rsquo;t be intercepted while being transmitted to us over the Internet or wireless communication,
				and any information you transmit to Lawnber you do at your own risk. We recommend that you not disclose your
				password to anyone.</span></p>
		<p class="c4"><span class="c3">Information Processed And Stored In The United States.</span></p>
		<p class="c0"><span class="c2">Information we collect, including, Personal Information, will be processed and stored
				in the United States. Your Personal Information may be subject to access requests from governments, courts,
				or law enforcement in the United States according to laws of the United States. By using the Services or
				providing us with any information, you consent to the processing and storage of your information in the
				United States.</span></p>
		<p class="c0 c7"><span class="c2"></span></p>
		<p class="c9"><span class="c18">Changes to This Privacy Policy</span></p>
		<p class="c9"><span class="c5">We may update our Privacy Policy from time to time. Thus, you are advised to review
				this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy
				on this page.</span></p>
		<p class="c9"><span class="c5">This policy is effective as of 2023-03-18</span></p>
		<p class="c0 c7"><span class="c17"></span></p>
		<p class="c0"><span class="c3">Contact us</span></p>
		<p class="c0"><span>If you have any general questions about the Site or the information we collect about you and how
				we use it, please contact us using the contact information shown on our Site or email
				services@lawnber.com</span></p>
	</body>
	
	</html>`
	return (
		<div
			style={{
				maxWidth: '100%',
				margin: '0 auto',
				padding: '20px',
				boxSizing: 'border-box',
				wordWrap: 'break-word',
				fontFamily: 'Poppins',
				fontSize: '1.1rem',
				lineHeight: '1.5'
			}}
			dangerouslySetInnerHTML={{ '__html': content }}
		></div>
	)
}

export default PrivacyPolicy