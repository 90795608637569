import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: "100%"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: 'black',
		width: '100%',
		margin: 'auto',
	},
	agreementText: {
		textAlign: "left",
		marginTop: '2em',
		marginLeft: '5%',
		marginRight: '5%',
		fontSize: '18px'
	},
	heading: {
		fontWeight: 'bold',
	},
	fullScreenGrid: {
		width: "100%",
		height: "100%"
	}
}));

const ServiceAgreementFall = () => {
	const classes = useStyles();

	return (
		<>
			<ToastContainer />
			<div className={classes.root}>
				<Grid container spacing={2} className={classes.fullScreenGrid}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<div>
								<h2>Fall Cleanup Service Agreement</h2>
								<ul className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}>
										Standard Fall Cleanup:
									</Typography>
									<li>
										<ul>
											<li>Blow out the lawn of dead leaves</li>
											<li>Rake out the lawn</li>
											<li>Pickup small fallen branches</li>
										</ul>
									</li>
									<Typography variant='p' className={classes.heading}>
										Premium Fall Cleanup:
									</Typography>
									<li>
										<ul>
											<li>Aeration</li>											
											<li>And everything in the Standard service</li>
										</ul>
									</li>
									<Typography variant='p' className={classes.heading}>
										Add on services:
									</Typography>
									<li>
										<ul>
											<li>Over Seeding & Fertilizer</li>
											<li>Bush Trim & Plant Removal</li>
										</ul>
									</li>
								</ul>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> Customer Responsibilities:</Typography>
									<ul>
										<li>Ensure all outdoor areas are clear of obstacles, or else Lawnber crews will exclude
											areas around the obstacles.
										</li>
										<li>Ensure access to the back yard for the crew and equipment to get through. Gates should be
											unlocked and pathways should be unblocked.
										</li>
										<li>Place high-visible stake flags to indicate any area that should not be serviced by the equipment
											to avoid damages, such as large cracks, hidden utility, gas pipes, electrical pipes, water pipes,
											and any hazards that can damage mowing equipment and your property.
										</li>
										<li>Mark all hidden sprinklers and landscape lights with high-visible stake flags for the crew to
											work around them.
										</li>
										<li>Ensure no one and no pets are outside during the cleanup service to avoid accidents.</li>
										<li>Ensure irrigation sprinklers are turned off before and during the mowing service to avoid sprinkler
											damages and slippery lawn accidents.
										</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> Lawnber And Service Crew Are Not
										Responsible For:</Typography>
									<ul>
										<li>Not responsible to remove any obstacles and objects on or around the mowing areas and
											will skip those areas.</li>
										<li>Not responsible for any accidents, damages and injuries from the lawn service. Lawnber
											crew are professional and will do their best to prevent accidents.</li>
										<li>Not responsible for cleaning up pet and animal waste.</li>
										<li>Not responsible for delays due to unforeseen situations, traffic and road closures.</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}>Indemnifications</Typography>
								</p>
								<p className={classes.agreementText} style={{ marginTop: '1em' }}>
									<Typography variant='p' className={classes.heading}>The Customer Agrees Not To Hold Lawnber
										And Its Crew Liable For The Following:</Typography>
									<ul>
										<li>Not liable for any property damages due to unintentional accidents from all services
											including mowing, trimming, blowing, digging and shoveling. Property
											damages can be considered house exterior structures such as walls, siding, roofing,
											windows, doors, utility pipes. House interior furniture. Outdoor furniture, all types of
											hard ground surface and edging, fence structure, lawns, gardens, plants and sheds.</li>
										<li>Not liable for any person or pet injuries due to slippery falls. Lawnber does not
											provide ice melt salt service on snow and ice grounds. Surface can freeze and refreeze
											and therefore the customer should always ensure the sidewalks, pathways and stairs are
											safe to walk on.
										</li>
										<li>Not liable for any person or pet injuries from equipment accidents while Lawnber crew
											are working in the outdoor areas. All people and pets should stay inside the house
											during the service period.</li>
										<li>Not liable for any vehicle damages due to unintentional accidents from all services
											including mowing, trimming, blowing, digging, snow clearing and shoveling.</li>
									</ul>
								</p>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export { ServiceAgreementFall }
