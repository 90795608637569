import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5L18tS49f_SMp1Vd4qZZYrBvUp5P0l2w",
  authDomain: "user-database-2021.firebaseapp.com",
  databaseURL: "https://user-database-2021-default-rtdb.firebaseio.com",
  projectId: "user-database-2021",
  storageBucket: "user-database-2021.appspot.com",
  messagingSenderId: "49535356389",
  appId: "1:49535356389:web:dda40ef6f048607b81b30c",
  measurementId: "G-WRPZXB55VX",
};

const firebaseConfigQA = {
  apiKey: "AIzaSyD6UAUXPqavwOaLDw8AaYCFEwi-CccUgCI",
  authDomain: "qadb-d5afd.firebaseapp.com",
  projectId: "qadb-d5afd",
  storageBucket: "qadb-d5afd.appspot.com",
  messagingSenderId: "711276359328",
  appId: "1:711276359328:web:ca798d7d6a170b6eebe3c5",
  measurementId: "G-C76ZV460VT",
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const firebaseAppQA = initializeApp(firebaseConfigQA, "QADB");

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseapp);
const db = getFirestore(firebaseapp);
const authQA = getAuth(firebaseAppQA);
const dbQA = getFirestore(firebaseAppQA);
const storage = getStorage(firebaseAppQA)

export { auth, db, authQA, dbQA, storage };
