/***************************************************************************************************
Signup.js Created: 10/10/2022 Rev 1.0.5

Description:
This file handles the user signup process, processes data, and creates a new user account while saving the necessary information in our database. It also facilitates the selection of multiple services by the user, and on proceeding, it performs several tasks including API calls to external servers.

Functions:

handleProceed:
Description: This function is triggered on a click event when the user clicks the "Proceed" button to submit the signup form.
Actions:
Checks if all required input fields are not empty.
Generates a unique User ID (UID) and Service ID documents for the user.
Makes an API call to the CPS (Customer Payment System) and PS (Payment System) servers to create a new customer and payment method, respectively.
Makes an API call to the JA (Job Assignment) server to find professionals in the specified zipcode and updates the service document accordingly.

Note:

The API endpoints and configurations for the CPS, PS, and JA servers are not provided in this file. They should be set up separately and securely.
The specific implementation details for generating UID and Service ID documents are not included in this file. These details may depend on the backend and database setup.
Ensure to validate user input data before proceeding with any API calls or database operations to maintain data integrity and security.

***************************************************************************************************/
//todo: Please add IDS to all the react useEffects

// External Libraries
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { v4 as unique_id } from "uuid";
import config from "../Config";
import { allServices } from "../../Constant/Variables";

// Material-UI Components
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Popover,
  Avatar,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ContactSupport from "@material-ui/icons/ContactSupport";
import Link from "@material-ui/core/Link";
import Check from "@material-ui/icons/Check";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";

// Material-UI Styles
import { SignupStyles } from "./SignupStyles";
import { useStyles } from "../Dashboard/Styles";

// Material-UI Date-Pickers
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

// Firebase
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../components/Firebase";
import { UserContext } from "../../components/UserContext";

// React Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// React Google Maps
import { GoogleMap, LoadScript } from "@react-google-maps/api";

// Images
import GoogleLogo from "../../components/assets/images/google-logo.jpeg";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import BlackMulch from "../../components/assets/images/BlackMulch.jpg";
import BrownMulch from "../../components/assets/images/BrownMulch.jpg";
import RedMulch from "../../components/assets/images/RedMulch.jpg";
import BagOfMulch from "../../components/assets/images/BagOfMulch.jpg";
import MulchEdging from "../../components/assets/images/MulchEdging.jpg";
import MulchWeeding from "../../components/assets/images/MulchWeeds.jpg";
import Aeration from "../../components/assets/images/Aeration.jpg";
import Overseeding from "../../components/assets/images/OverSeedingFertilizer.jpg";
import Fertilizer from "../../components/assets/images/Fertilizrs.jpg";
import BushTrimming from "../../components/assets/images/BushTrimming.jpg";
import SpotSeeding from "../../components/assets/images/SpotSeeding.webp";
import BagClipping from "../../components/assets/images/BagClippings.jpg";
import PoolEdging from "../../components/assets/images/PoolEdging.jpg";

// .css
import "./Signup.css";
import "../Home/NewHome.css";
import { AlternateEmail, LocationOn, SquareFoot } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Popup from "../../components/Popup";

function Signup_() {
  // Component State
  const location = useLocation();
  const { googleUser, emailFromLanding, Service } = location.state;
  //Add a select state for all service:
  const [select, setSelect] = React.useState("");
  const [selectDrv, setSelectDrv] = React.useState("");
  const [selectSpring, setSelectSpring] = React.useState("");
  const [selectMulch, setSelectMulch] = React.useState("");
  const [selectHedge, setSelectHedge] = React.useState("");
  const [selectFall, setSelectFall] = React.useState("");
  const [defaultSelect, setDefaultSelect] = React.useState("Double Driveway");

  //Add a price state for all service:
  const [price, setPrice] = React.useState([]);
  const [priceSnow, setPriceSnow] = React.useState([]);
  const [priceSpring, setPriceSpring] = React.useState([]);
  const [priceMulch, setPriceMulch] = React.useState([]);
  const [priceHedge, setPriceHedge] = React.useState([]);
  const [priceFall, setPriceFall] = React.useState([]);

  const [date, setDate] = React.useState();
  const [phoneNumber, setphoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [prevAddress, setPrevAddress] = React.useState();
  const googleMapsLibraries = ["visualization", "places"];
  const [lati, setLati] = useState();
  const [lngi, setLngi] = useState();
  const [zipcode, setZipcode] = useState([]);
  const [propertyDetails, setPropertyDetails] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [user, setUser] = React.useContext(UserContext);
  const [users, setUsers] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [cases, setCases] = React.useState([]);
  const [splitAddr, setSplitAddr] = React.useState([]);
  const [checkPassword, setCheckPassword] = React.useState("");
  const [checkEmail, setCheckEmail] = React.useState("");
  const [currentDate, setCurrentDate] = React.useState("");
  const lastDateOfYear = `${new Date().getFullYear()}-12-31`;
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXl = useMediaQuery(theme.breakpoints.down("xl"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between("md", "lg"));

  //Add check state for agreements for all services:
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedSnow, setIsCheckedSnow] = useState(false);
  const [isCheckedSpring, setIsCheckedSpring] = useState(false);
  const [isCheckedMulch, setIsCheckedMulch] = useState(false);
  const [isCheckedHedge, setIsCheckedHedge] = useState(false);
  const [isCheckedFall, setIsCheckedFall] = useState(false);

  //Add GetPrice for backend prices for all services:
  const [getLawnPrice, setgetLawnPrice] = useState(null);
  const [getSnowPrice, setGetSnowPrice] = useState(null);
  const [getSpringPrice, setGetSpringPrice] = useState(null);
  const [getMulchPrice, setGetMulchPrice] = useState(null);
  const [getFallPrice, setGetFallPrice] = useState(null);

  const [loadKey, setLoadKey] = React.useState(0);
  const [signUpPageSelected, setSignUpPageSelected] = useState("");
  const [isClicked, setIsClicked] = useState(null);
  const [googleSign, setGoogleSign] = useState(false);
  const [uid, setUid] = useState("");
  const [oneTimeMowCount, setOneTimeMowCount] = useState();
  const [weeklyMowCount, setWeeklyMowCount] = useState();
  const [biweeklyMowCount, setBiWeeklyMowCount] = useState();
  const [cornerHouse, setCornerHouse] = React.useState("No");
  const [slopeDrv, setSlopeDrv] = React.useState("No");
  const thumbCount = doc(db, "Reviews", "thumbsUp");
  const detailsRef = React.useRef(null);
  const [subscription, setSubscription] = React.useState("");
  const [showServiceSel, setShowServiceSel] = React.useState(false);
  const [showContract, setShowContract] = React.useState(false);
  const [serviceList, setServiceList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const providers = new GoogleAuthProvider();
  const [isCardEmpty, setIsCardEmpty] = React.useState(false);

  const [lawnAddon, setLawnAddon] = React.useState(null);
  const [mulchAddon, setMulchAddon] = React.useState(null);
  const [fallAddon, setFallAddon] = React.useState(null);

  const [selectedAddonsLawn, setSelectedAddonsLawn] = useState({});
  const [selectedAddonsMulch, setSelectedAddonsMulch] = useState({
    "5 Additional Yards": false,
    "Remove Extra Weed": false,
    "Reshape The Edge": false,
  });
  const [selectedAddonsFall, setSelectedAddonsFall] = useState({});

  const [totalLawnAddon, setTotalLawnAddon] = useState(0);
  const [totalMulchAddon, setTotalMulchAddon] = useState(0);
  const [totalFallAddon, setTotalFallAddon] = useState(0);

  const [selectedOption, setSelectedOption] = useState("Weekly");
  const [selectedOptionMulch, setSelectedOptionMulch] = useState("Brown");

  const [allPrices, setAllPrices] = useState(null);
  const [emaildialogbox, setEmaildialogbox] = useState(true);
  const [anchorElAddons, setAnchorElAddons] = React.useState(null);
  const [redMulchAddon, setRedMulchAddon] = React.useState(0);
  const [mulchSalesTax, setMulchSalesTax] = React.useState(0);
  const cardNumberRef = React.useRef(null);
  const [totalYardsSelected, setTotalYardsSelected] = React.useState(0);
  const [getHedgePrice, setGetHedgePrice] = React.useState(null);
  const [hedgeOverShoot, setHedgeOverShoot] = React.useState(false);
  const [hedgeOverShootEmail, setHedgeOverShootEmail] = React.useState({
    Email: emailFromLanding,
    Name: fname + " " + lname,
    Subject: "Requesting Hedge Trimming Quote",
    Content: "I need a quote for Hedge Trimming",
  });
  const [fallAddonsPopup, setfallAddonsPopup] = React.useState(false);

  // Dialog box variables
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState("");
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState("");

  //variables
  const AccessLevel = "User";
  const AcctStatus = "Active";
  const u_id = unique_id();

  const infoCases = {
    case1:
      "Thank you for submitting your address. Your address is in our service area, but we will get back to you within a day for a price. Please continue to sign up below and we will notify you as soon as service is available.",
    case2:
      "Thank you for submitting your address. Your address is a commercial property that is not available for service at this time. Please continue to sign up below and we will notify you as soon as service is available.",
    case3:
      "Error - Your property is within our service area, however it is a large property will have an estimate within 24Hr. Please check back later",
    case4:
      "Thank you for submitting your address. Your address is not in our current service area. Please continue to sign up below and we will notify you as soon as service is available",
  };

  const LawnAddons = [
    {
      id: 0,
      addonName: "Spot Seeding",
      img: SpotSeeding,
      description:
        "Spot seeding is a technique used to address bare areas within an existing grassy area. It involves the precise application of grass seed to specific spots that require rejuvenation. This targeted approach promotes healthy grass growth and helps fill in gaps, resulting in a more uniform and lush lawn.",
    },
    {
      id: 1,
      addonName: "Pool Edging",
      img: PoolEdging,
      description:
        "Pool edging refers to the boundary or border material used to enclose and define the area around a swimming pool. It serves both functional and aesthetic purposes, providing a barrier to prevent pool water from splashing onto surrounding surfaces and adding a visually pleasing element to the poolside environment.",
    },
    {
      id: 2,
      addonName: "Bag Clippings",
      img: BagClipping,
      description:
        "Bag clipping is a process commonly used in packaging and agriculture where a clip or fastener is applied to seal bags or sacks. These clips are usually made of plastic or metal and are designed to securely hold the bag's open end closed, preventing the contents from spilling out.",
    },
  ];

  const Mulch = [
    {
      id: 0,
      mulchColor: "Brown",
      Color: "#433937",
      img: BrownMulch,
    },
    {
      id: 1,
      mulchColor: "Black",
      img: BlackMulch,
      Color: "black",
    },
    {
      id: 2,
      mulchColor: "Red",
      img: RedMulch,
      Color: "Red",
    },
  ];

  const MulchAddonsObject = [
    {
      id: 0,
      addonName: "5 Additional Yards",
      img: BagOfMulch,
      description:
        "Expanding yard areas with mulch application to create visually appealing and low-maintenance outdoor spaces. Proficient in selecting and installing various mulch types tailored to specific landscaping needs. Enhancing landscapes by extending mulch coverage to additional yards for improved aesthetics and weed control.",
    },
    {
      id: 1,
      addonName: "Remove Extra Weed",
      img: MulchWeeding,
      description:
        "Well-versed in careful weed-removal techniques; committed to preserve the life and beauty of outdoor areas.  Skilled in both chemical and manual weed treatment, assuring the wellbeing of plants and lawns.  Capable of converting overgrown areas into landscapes that are kept up.",
    },
    {
      id: 2,
      addonName: "Reshape The Edge",
      img: MulchEdging,
      description:
        "Reshaping landscape edges to define and enhance garden boundaries. Proficient in creating clean and visually appealing transitions between different outdoor spaces. Transforming ordinary edges into aesthetically pleasing and well-defined borders for a polished landscape.",
    },
  ];

  const FallCleanupAddons = [
    {
      id: 0,
      addonName: "Bush Trim & Plant Removal",
      description:
        "Expertly removing unwanted plants and skillfully trimming bushes, fostering both health and beauty.",
      img: BushTrimming,
    },
    // {
    //   id: 1,
    //   addonName: "Aeration",
    //   description:
    //     "Helps the soil and the grass roots get air, water and nutrients by making small holes in the soil.",
    //   img: Aeration,
    // },
    {
      id: 1,
      addonName: "Over Seeding & Fertilizer",
      description:
        "Adding grass seed to make it thicker, healthier and resistant to pests and diseases.",
      img: Overseeding,
      cost: "$10",
    },
  ];

  const FallCleanupAdditionalAddons = [
    {
      id: 0,
      addonName: "Junk Cleaning",
      description:
        "Expertly removing unwanted plants and skillfully trimming bushes, fostering both health and beauty.",
      img: Fertilizer,
    },
    {
      id: 1,
      addonName: "Power Washing",
      description:
        "Helps the soil and the grass roots get air, water and nutrients by making small holes in the soil.",
      img: Aeration,
    },
    {
      id: 2,
      addonName: "Garden Beds",
      description:
        "Adding grass seed to make it thicker, healthier and resistant to pests and diseases.",
      img: Overseeding,
      cost: "$10",
    },
  ];

  const classes = useStyles();
  const SignupClass = SignupStyles();
  const imageRef = React.useRef(null);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  /**
   * Checks the status of Stripe.
   */
  React.useEffect(() => {
    // Check if Stripe is not available
    if (!stripe) {
      return;
    }
  }, []);

  /**
   * Checks the user details and sets them in state variables.
   */
  React.useEffect(() => {
    // Function to check the user details
    const checkUser = async () => {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      var firstTimeCustomer = "true";
      localStorage.setItem("FIRSTTIMEUSER", firstTimeCustomer);

      // Log the email and google user for debugging
      console.log(emailFromLanding, "emailFromLanding");
      console.log(googleUser, "googleUser");

      // Check if Google user details are present
      if (googleUser[2]) {
        console.log("Google user is present");

        // Set the user details from the googleUser array
        setUsers(googleUser[0]);

        if (googleUser[1]) {
          setGoogleSign(googleUser[1]);
        }

        if (googleUser[2]) {
          setFname(googleUser[2].split(" ")[0]);
          setLname(googleUser[2].split(" ")[1]);
        }

        setEmail(googleUser[3]);
        setCheckEmail(googleUser[3]);
        setphoneNumber(googleUser[4]);
        setUid(googleUser[0]);
      } else {
        console.log("Google user is not present");

        // Set the email from the landing page
        setEmail(emailFromLanding);
        console.log(emailFromLanding);
        console.log(Service, "Selected Service");
      }

      // Store user in local storage
      localStorage.setItem("user", JSON.stringify(user));

      // Check the authentication state of the user
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          // history.push("/dashboard");
        } else {
          setUser(null);
          return;
        }
      });
    };

    // Call the `checkUser` function to check the user details
    checkUser();
    // setSignUpPageSelected(Service);
    setSignUpPageSelected("Fall Cleanup")

    //Add service check and set premium:

    if (Service === "Lawn Mowing") {
      setSelect("Premium");
    }
    if (Service === "Spring Cleanup") {
      setSelectSpring("Premium");
    }
    if (Service === "Mulch") {
      setSelectMulch("Premium");
      setTotalYardsSelected(4);
    }
    if (Service === "Hedge Trimming") {
      setSelectHedge("Premium");
    }
    if (Service === "Fall Cleanup") {
      setSelectFall("Premium");
    }
  }, []);

  /**
   * Retrieves the like count for different types of mowing services.
   */
  React.useEffect(() => {
    // Function to fetch the like count
    function thumbsUpCount() {
      // Call the `getDoc` function to retrieve data from `thumbCount`
      getDoc(thumbCount).then((Snapdata) => {
        console.log(Snapdata.data(), "SnapData");

        // Set the like counts for different mowing services in state variables
        setOneTimeMowCount(Snapdata.data().OneTimeMowCount);
        setWeeklyMowCount(Snapdata.data().WeeklyMowCount);
        setBiWeeklyMowCount(Snapdata.data().BiWeeklyMowCount);
      });
    }

    // Call the `thumbsUpCount` function to fetch the like count
    thumbsUpCount();
  }, []);

  /**
   * Retrieves address and location coordinates from local storage.
   */
  React.useEffect(() => {
    // Retrieve the previously stored address from local storage
    var completeAddress = JSON.parse(
      window.localStorage.getItem("Prev_Address")
    );

    // Set the retrieved address as the previous address
    setPrevAddress(completeAddress);

    // Retrieve latitude and set it in state
    const latitude = JSON.parse(window.localStorage.getItem("Latitude"));
    setLati(latitude);

    // Retrieve longitude and set it in state
    const longitude = JSON.parse(window.localStorage.getItem("Longitude"));
    setLngi(longitude);
  }, []);

  /**
   * Fetches property details and price data based on the entered address.
   */
  React.useEffect(() => {
    // Function to fetch property details
    const getPropertyRow = async () => {
      // Retrieving the previously entered address from local storage
      var completeAddress = JSON.parse(
        window.localStorage.getItem("Prev_Address")
      );
      var completeAddressList = completeAddress.split(",");
      var sheetAddress = completeAddressList[0].toUpperCase();
      setSplitAddr(completeAddressList);

      // API call to fetch data based on the address
      const { data } = await axios.post(
        config.FETCH_DATA_URL,
        // "http://localhost:4008/fetchData",
        {
          address: sheetAddress,
        }
      );

      // API call to get price details
      const allPrice = await axios.post(
        config.ALL_PRICES_URL,
        // "http://localhost:4008/getAllPrice",
        {
          Address: data["Address"],
          Zipcode: data["Zipcode"],
          PropertySize: data["TotalArea"],
        }
      );

      //console.log(allPrice.data); // Logging the retrieved price data

      // Checking conditions for showing the page
      if (
        data["AddressStatus"] === "Correct" &&
        data["ZipcodeStatus"] === "Correct" &&
        Number(data["UseCode"]) < 400 &&
        allPrice.data.status === "Success"
      ) {
        setShow(true); // Showing the page
      } else {
        setShow(false); // Hiding the page

        // Setting specific cases for handling errors
        if (data["AddressStatus"] === "Incorrect") {
          setCases("case1");
        }
        if (Number(data["UseCode"]) > 400) {
          setCases("case2");
        }
        if (allPrice.data.status !== "Success") {
          setCases("case3");
        }
        if (data["ZipcodeStatus"] === "Incorrect") {
          setCases("case4");
        }
      }

      // Formatting and setting the retrieved price data

      // Function calls using destructuring parameters with Promise.all() for parallel execution
      await Promise.all([
        checkDataAndUpdatePrice(
          allPrice.data,
          setgetLawnPrice,
          "MowBasic",
          "MowStandard",
          "MowPremium",
          "MowBiWeekly"
        ),
        checkDataAndUpdatePrice(
          allPrice.data,
          setGetSpringPrice,
          "SpringBasic",
          "SpringStandard",
          "SpringPremium"
        ),
        checkDataAndUpdatePrice(
          allPrice.data,
          setGetMulchPrice,
          "MulchBasic",
          "MulchStandard",
          "MulchPremium",
          "MulchRed"
        ),
        checkDataAndUpdatePrice(
          allPrice.data,
          setGetHedgePrice,
          "HedgeBasic",
          "HedgeStandard",
          "HedgePremium"
        ),
        checkDataAndUpdatePrice(
          allPrice.data,
          setGetFallPrice,
          "FallBasic",
          "FallStandard",
          "FallPremium"
        ),
      ]);

      setPropertyDetails(data);
      setAllPrices(allPrice.data);
      console.log(allPrice.data);

      // Generate a random key for loading purposes
      setLoadKey(Math.random().toString(36).slice(2));
    };

    // Delayed execution of fetching property data
    setTimeout(() => {
      getPropertyRow(); // Fetching property details
      setOpen(!open); // Setting 'open' state to true

      // Setting initial date for calendar
      let tempDate = new Date();
      tempDate.setDate(tempDate.getDate() + 7);
      let initialDate = dayjs(tempDate);
      setDate(initialDate);

      // Setting current date for date selection restriction
      var currentDateString = new Date();
      setCurrentDate(
        new Date(
          currentDateString.getTime() -
          currentDateString.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0]
      );
    }, 6000);
  }, []);

  /**
   * Updates the service list and visibility of service selection and contract based on selected options.
   */
  React.useEffect(() => {
    // Define the options array with their corresponding state variables
    const options = [
      { name: "SpringCleanup", state: selectSpring },
      { name: "LawnMowing", state: select },
      { name: "Mulch", state: selectMulch },
      { name: "HedgeTrimming", state: selectHedge },
      { name: "FallCleanup", state: selectFall },
      // Add more options here as needed in the future
    ];

    // Create an empty array to store the updated service list
    const updatedList = [];

    // Loop through the options and add the selected ones to the updated list
    options.forEach((option) => {
      if (option.state) {
        updatedList.push(option.name);
      }
    });

    // Determine the visibility of the service selection
    const showServiceSelection = options.some((option) => option.state);

    // Determine the visibility of the contract
    const showContract = options.filter((option) => option.state).length > 1;

    // Set the updated service list
    setServiceList(updatedList);

    // Update the visibility states
    setShowServiceSel(showServiceSelection);
    setShowContract(showContract);
  }, [selectSpring, select, selectMulch, selectHedge, selectFall]);

  /**
   * Price adjustment for mulch feature.
   */
  React.useEffect(() => {
    // Create a flag to track if the state has been updated
    if (selectMulch.length > 0 && allPrices !== null && getLawnPrice !== null) {
      // Destructuring to access required values
      const { MulchRed, MulchTax } = allPrices;

      // Calculate redMulchAddon based on selectedOptionMulch and selectMulch
      let redMulchAddon = 0;
      var totalYards = 0;

      totalYards =
        selectMulch === "Premium" ? 4 : selectMulch === "Standard" ? 2 : 0;

      if (selectedAddonsMulch["5 Additional Yards"]) {
        totalYards += 5;
      }

      if (selectedOptionMulch === "Red") {
        redMulchAddon = selectMulch === "Premium" ? MulchRed * 4 : MulchRed * 2;
      }

      // Add $125 if '5 Additional Yards' addon is true
      if (
        selectedOptionMulch === "Red" &&
        selectedAddonsMulch["5 Additional Yards"] === true
      ) {
        redMulchAddon += MulchRed * 5;
      }

      let totalSelectedAddons = 0;
      Object.entries(selectedAddonsMulch).forEach(([addon, selected]) => {
        if (addon === "Remove Extra Weed" || addon === "Reshape the Edge") {
          if (selected) {
            totalSelectedAddons++;
          }
        } else if (selected && addon !== "5 Additional Yards") {
          totalSelectedAddons++;
        }
      });

      const additionalAddons =
        Number(mulchAddon["Reshape The Edge"]) *
        totalYards *
        totalSelectedAddons;

      // Calculate mulchSalesTax based on selected options and addons
      const mulchSalesTax =
        (getMulchPrice[selectMulch] +
          totalMulchAddon +
          redMulchAddon +
          additionalAddons) *
        MulchTax;

      // console.log(mulchSalesTax);

      // Calculate the total price for the mulch
      const totalPriceMulch = Number(
        getMulchPrice[selectMulch] +
        totalMulchAddon +
        redMulchAddon +
        mulchSalesTax +
        additionalAddons
      ).toFixed(2);

      // Update the state variables with the calculated values
      setRedMulchAddon(redMulchAddon);
      setMulchSalesTax(mulchSalesTax.toFixed(2));
      setPriceMulch(totalPriceMulch);
      setMulchSalesTax(mulchSalesTax.toFixed(2));
      setTotalYardsSelected(totalYards);
    }

    // Set the flag to true indicating that the state has been updated
  }, [selectMulch, selectedOptionMulch, allPrices, selectedAddonsMulch]);

  /**
   * Updates prices and addons based on the provided parameters and data.
   *
   * @param {Object} allPrice - The object containing price data.
   * @param {Function} setPriceState - The function to set the updated price state.
   * @param {...string} params - The parameter(s) representing the keys in the `allPrice` object to update.
   */
  const checkDataAndUpdatePrice = async (
    allPrice,
    setPriceState,
    ...params
  ) => {
    // Create a temporary object to store the updated prices
    const tempPriceObj = {};

    // Initialize the service variable to store the service type (e.g., 'Mow', 'Mulch')
    let service = "";

    // Loop through the provided parameters
    for (const param of params) {
      // Update the basic, standard, and premium prices
      allPrice[param] =
        allPrice[param] === "NA" ? 0.0 : +Number(allPrice[param]).toFixed(2);

      // Extract the first and second words from the parameter using regex
      const [firstWord, secondWord] = param.match(/([A-Z][a-z]+)|[A-Z]+/g);

      // Check if the second word exists
      if (secondWord !== undefined) {
        service = firstWord;
        // Set the Surcharge value based on the service type
        tempPriceObj["Surcharge"] =
          service === "Mow"
            ? +Number(allPrice.Surcharge).toFixed(2)
            : +Number(0).toFixed(2);

        // Check if the second word is one of 'Basic', 'Standard', or 'Premium'
        if (["Basic", "Standard", "Premium"].includes(secondWord)) {
          tempPriceObj[secondWord] = allPrice[param];
        }
      }
    }

    console.log(tempPriceObj);
    setPriceState(tempPriceObj);

    // Update addons
    allPrice[`${service}Addons`] =
      allPrice[`${service}Addons`] === "NA" ? {} : allPrice[`${service}Addons`];

    console.log(service);

    // Update lawn or mulch addons based on the service type
    if (service === "Mow") {
      setLawnAddon(allPrice[`${service}Addons`]);
      setPrice(Number(allPrice.MowPremium) + Number(allPrice.Surcharge));
    }
    if (service === "Mulch") {
      setMulchAddon(allPrice[`${service}Addons`]);

      var mulchTax =
        (Number(allPrice.MulchPremium) +
          Number(totalMulchAddon) +
          Number(redMulchAddon)) *
        allPrice.MulchTax;

      setMulchSalesTax(mulchTax.toFixed(2));

      setPriceMulch(
        Number(
          allPrice.MulchPremium + totalMulchAddon + redMulchAddon + mulchTax
        ).toFixed(2)
      );
    }
    if (service === "Spring") {
      setPriceSpring(Number(allPrice.SpringPremium));
    }
    if (service === "Hedge") {
      setPriceMulch(Number(allPrice.HedgePremium));

      //Check if the property size is greater than 12000 sqft and show route them to contact us
      if (Number(allPrice.TotalArea) > 12000) {
        setHedgeOverShoot(true);
      }
    }
    if (service === "Fall") {
      setFallAddon(allPrice[`${service}Addons`]);
      setPriceFall(Number(allPrice.FallPremium));
    }
  };

  const sendHedgeOverShootEmail = async (url) => {
    if (
      hedgeOverShootEmail["Name"].trim() &&
      hedgeOverShootEmail["Email"].trim() &&
      hedgeOverShootEmail["Content"].trim()
    ) {
      const { data } = await axios.post(url, {
        email: "services@Lawnber.com",
        subject: hedgeOverShootEmail["Subject"],
        message: hedgeOverShootEmail["Content"],
        name: hedgeOverShootEmail["Name"],
        email_from: hedgeOverShootEmail["Email"],
        phone: "NA",
        uid: "",
      });

      if (data) {
        // toast.success("We will get back to you within 24hrs");
        showAutoClosePopup(
          "Info",
          "We will get back to you within 24hrs",
          3000
        );
      }
    } else {
      // toast.error("Name, Email, Message is mandatory");
      showAutoClosePopup("Error", "Name, Email, Message is mandatory", 3000);
      return;
    }
  };

  /**
   * Handles the opening of the Popup.
   *
   * @param {title - Heading, content - Subheading, duration-Time taken to close the dialogbox}.
   */
  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  /**
   * Handles the opening of the dropdown menu.
   *
   * @param {Event} event - The event object.
   */
  const handleDropDown = (event) => {
    // Set the anchor element to the current target, which triggers the opening of the dropdown menu
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the closing of the dropdown menu.
   */
  const handleClose = () => {
    // Set the anchor element to null, which triggers the closing of the dropdown menu
    setAnchorEl(null);
  };

  /**
   * Handles the click event to open the addons anchor element.
   */
  const handleClick = () => {
    setAnchorElAddons(true);
  };

  /**
   * Handles the closing of the addons anchor element.
   */
  const handleCloseAddons = () => {
    setAnchorElAddons(null);
  };

  /**
   * Checks the format of an email address.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} - Returns true if the email is in a valid format, false otherwise.
   */
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checks the format of a phone number.
   *
   * @param {string} phone - The phone number to validate.
   * @returns {boolean} - Returns true if the phone number is in a valid format, false otherwise.
   */
  const validatePhone = (phone) => {
    // Regular expression pattern for phone number validation
    var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return re.test(phone); // Returns true if phone number matches the pattern, false otherwise
  };

  /**
   * Checks the format of a name.
   *
   * @param {string} name - The name to validate.
   * @returns {boolean} - Returns true if the name is in a valid format, false otherwise.
   */
  const validateName = (name) => {
    // Regular expression pattern for name validation
    var re = /^[a-zA-Z\s]*$/;
    return re.test(name); // Returns true if name matches the pattern, false otherwise
  };

  /**
   * Handles the selection of an addon.
   *
   * @param {string} addonName - The name of the addon.
   * @param {number} addonPrice - The price of the addon.
   */
  const handleSelect = (
    addonName,
    addonPrice,
    SetSelectedAddonState,
    TotalAddonState,
    selectedAddonState
  ) => {
    // Update the selected addons for the lawn
    SetSelectedAddonState((prevSelectedAddons) => ({
      ...prevSelectedAddons,
      [addonName]: !prevSelectedAddons[addonName],
    }));

    if (addonName === "Reshape The Edge" || addonName === "Remove Extra Weed" || addonPrice === 'TBD') {
      addonPrice = 0;
    }

    console.log("Handle Select:", addonPrice);

    var updatedAddonPrice = parseFloat(addonPrice);


    TotalAddonState((prevTotalPrice) =>
      selectedAddonState[addonName]
        ? prevTotalPrice - updatedAddonPrice
        : prevTotalPrice + updatedAddonPrice
    );
  };

  /*
  Function: createUserProfile
   
  Description:
  This function creates a user profile by taking input from the user and storing it in a database. It performs validation on the user inputs and handles errors appropriately. The function prompts the user to enter their personal information such as first name, last name, address, zip code, email, and credit card details. It then validates the inputs, creates a new user account using the provided email and password, and stores the user profile information in a database. If the user profile is created successfully, it returns a success message.
   
  Parameters:
  - None
   
  Returns:
  - A success message string if the user profile is created successfully.
   
  Imported Modules:
  - The function may require importing modules such as `random` or `bcrypt` for generating unique IDs or encrypting user passwords, respectively.
   
  Important Functionality:
  - User Input: The function prompts the user to enter their personal information.
  - Input Validation: The function validates the user inputs to ensure they meet the required criteria (e.g., valid email format, non-empty fields, etc.).
  - Account Creation: The function creates a new user account using the provided email and password.
  - Database Storage: The function stores the user profile information in a database.
  - Error Handling: The function handles errors that may occur during the user profile creation process (e.g., database connection errors, validation errors, etc.).
   
  Note: This function assumes the availability of appropriate functions and resources for user account creation and database storage.
  */
  const handleProceed = async (e) => {
    e.preventDefault();
    var nextServiceDate;
    if (selectedOption === "Weekly") {
      nextServiceDate = date.add(7, "days");
    } else if (selectedOption === "Bi-Weekly") {
      nextServiceDate = date.add(14, "days");
    }

    var newDate = date.format("M-D-YYYY");
    nextServiceDate = nextServiceDate.format("M-D-YYYY");
    const dayOfWeek = date.day();
    const dayFormatted = `D${dayOfWeek}_${date.format("ddd")}`;

    if (!show) {
      if (googleSign === false) {
        if (fname.length === 0) {
          // toast.error("First Name is empty");
          showAutoClosePopup("Error", "First Name is empty", 3000);
          return;
        }

        if (validateName(fname) == false) {
          // toast.error("Incorrect First Name format");
          showAutoClosePopup("Error", "Incorrect First Name format", 3000);
          return;
        }

        if (lname.length === 0) {
          // toast.error("Last Name is empty");
          showAutoClosePopup("Error", "Last Name is empty", 3000);
          return;
        }

        if (validateName(lname) == false) {
          // toast.error("Incorrect Last Name format");
          showAutoClosePopup("Error", "Incorrect Last Name format", 3000);
          return;
        }
        if (phoneNumber.length === 0) {
          // toast.error("Phone Number is empty");
          showAutoClosePopup("Error", "Phone Number is empty", 3000);
          return;
        }

        if (validatePhone(phoneNumber) == false) {
          // toast.error("Incorrect Phone Number format");
          showAutoClosePopup("Error", "Incorrect Phone Number format", 3000);
          return;
        }

        if (email.length === 0) {
          // toast.error("Email is empty");
          showAutoClosePopup("Error", "Email is empty", 3000);
          return;
        }
        if (validateEmail(email) == false) {
          // toast.error("Incorrect Email format");
          showAutoClosePopup("Error", "Incorrect Email format", 3000);
          return;
        }

        if (checkEmail.length === 0) {
          // toast.error("Re-Enter Email is empty");
          showAutoClosePopup("Error", "Re-Enter Email is empty", 3000);
          return;
        }

        if (validateEmail(checkEmail) == false) {
          // toast.error("Incorrect Email format");
          showAutoClosePopup("Error", "Incorrect Email format", 3000);
          return;
        }
        if (password.length === 0) {
          // toast.error("Password is empty");
          showAutoClosePopup("Error", "Password is empty", 3000);
          return;
        }
        if (checkPassword.length === 0) {
          // toast.error("Re-Enter Password is empty");
          showAutoClosePopup("Error", "Re-Enter Password is empty", 3000);
          return;
        }
        if (password !== checkPassword || email !== checkEmail) {
          // toast.error("Email or Password not matching");
          showAutoClosePopup("Error", "Email or Password not matching ", 3000);
          return;
        } else {
          setIsLoading(true);
          createUserWithEmailAndPassword(auth, email, password).then(
            async (userCredential) => {
              const user = userCredential.user;
              const uuid = user.uid;
              // const auth = getAuth();
              var Pincode = prevAddress.match(/\b\d{5}\b/g);
              zipcode.push(Pincode[0]);
              // console.log("Pincode", Pincode[0]);

              const userAcct = await doc(db, "UserAcct", uuid);

              await setDoc(userAcct, {
                AccessLevel: AccessLevel,
                AcctStatus: AcctStatus,
                ActiveService: [],
                FirstName: fname,
                LastName: lname,
                Address: prevAddress,
                AddressZipCode: Pincode[0],
                Phone: phoneNumber,
                EmailID: email,
                CreatedDate: serverTimestamp(),
              }).catch((err) => showAutoClosePopup("Error", `${err}`, 3000));

              setIsLoading(false);

              history.push({
                pathname: "/dashboard",
                state: {
                  newUser: true,
                },
              });
              return;
            }
          );
        }
      } else {
        if (phoneNumber.length === 0) {
          // toast.error("Phone Number is empty");
          showAutoClosePopup("Error", "Phone Number is empty", 3000);
          return;
        }
        if (validatePhone(phoneNumber) == false) {
          // toast.error("Incorrect Phone Number format");
          showAutoClosePopup("Error", "Incorrect Phone Number format", 3000);
          return;
        } else {
          setIsLoading(true);
          // Create new user with email and password

          const uuid = users;
          // const auth = getAuth();
          var Pincode = prevAddress.match(/\b\d{5}\b/g);
          zipcode.push(Pincode[0]);
          // console.log("Pincode", Pincode[0]);

          const userAcct = await doc(db, "UserAcct", uuid);

          await setDoc(userAcct, {
            AccessLevel: AccessLevel,
            AcctStatus: AcctStatus,
            ActiveService: [],
            FirstName: fname,
            LastName: lname,
            Address: prevAddress,
            AddressZipCode: Pincode[0],
            Phone: phoneNumber,
            EmailID: email,
            CreatedDate: serverTimestamp(),
          }).catch((err) => toast.error(err));

          setIsLoading(false);
          history.push({
            pathname: "/dashboard",
            state: {
              newUser: true,
            },
          });
          return;
        }
      }
    } else {
      // console.log(show);
      console.log("proceed pressed");
      var valueDate = date;
      const cardNumber = elements.getElement(CardElement);
      setIsCardEmpty(cardNumber.empty);
      console.log(
        fname,
        lname,
        phoneNumber,
        email,
        password,
        checkPassword,
        checkEmail,
        select,
        valueDate
      );

      if (!stripe || !elements) {
        console.log("Error in stripe");
        return;
      }

      if (serviceList.length < 0) {
        // toast.error("Select Atleast 1 service");
        showAutoClosePopup("Error", "Select Atleast one service", 3000);
        return;
      }

      if (!valueDate.toDate()) {
        // toast.error("Date input is empty");
        showAutoClosePopup("Error", "Date input is empty", 3000);
        return;
      }

      if (googleSign === false) {
        if (fname.length === 0) {
          // toast.error("First Name is empty");
          showAutoClosePopup("Error", "First Name is empty", 3000);
          return;
        }

        if (validateName(fname) == false) {
          // toast.error("Incorrect First Name format");
          showAutoClosePopup("Error", "Incorrect First Name format", 3000);
          return;
        }

        if (lname.length === 0) {
          // toast.error("Last Name is empty");
          showAutoClosePopup("Error", "Last Name is empty", 3000);
          return;
        }

        // if (validateName(lname) == false) {
        //   toast.error("Incorrect Last Name format");
        //   return;
        // }

        if (phoneNumber.length === 0) {
          // toast.error("Phone Number is empty");
          showAutoClosePopup("Error", "Phone Number is empty", 3000);
          return;
        }

        if (validatePhone(phoneNumber) == false) {
          // toast.error("Incorrect Phone Number format");
          showAutoClosePopup("Error", "Incorrect Phone Number format", 3000);
          return;
        }

        if (email.length === 0) {
          // toast.error("Email is empty");
          showAutoClosePopup("Error", "Email is empty", 3000);
          return;
        }

        if (validateEmail(email) == false) {
          // toast.error("Incorrect Email format");
          showAutoClosePopup("Error", "Incorrect Email format", 3000);
          return;
        }

        if (checkEmail.length === 0) {
          // toast.error("Re-Enter Email is empty");
          showAutoClosePopup("Error", "Re-Enter Email is empty", 3000);
          return;
        }

        if (validateEmail(checkEmail) == false) {
          // toast.error("Incorrect Re-Enter Email format");
          showAutoClosePopup("Error", "Incorrect Re-Enter Email format", 3000);
          return;
        }

        if (password.length === 0) {
          // toast.error("Password is empty");
          showAutoClosePopup("Error", "Password is empty", 3000);
          return;
        }
        if (checkPassword.length === 0) {
          // toast.error("Re-Enter Password is empty");
          showAutoClosePopup("Error", "Re-Enter Password is empty", 3000);
          return;
        }

        if (isCardEmpty) {
          // toast.error("Enter card details");
          showAutoClosePopup("Error", "Enter card details", 3000);
          return;
        }

        if (password !== checkPassword || email !== checkEmail) {
          // toast.error("Email or Password not matching");
          showAutoClosePopup("Error", "Email or Password not matching", 3000);
          return;
        }

        if (
          !(
            isChecked ||
            isCheckedSnow ||
            isCheckedSpring ||
            isCheckedMulch ||
            isCheckedHedge ||
            isCheckedFall
          )
        ) {
          // toast.error("Confirm Service agreement");
          showAutoClosePopup("Error", "Confirm Service agreement", 3000);
          return;
        } else {
          try {
            setIsLoading(true);
            var Pincode = prevAddress.match(/\b\d{5}\b/g);
            zipcode.push(Pincode[0]);
            var billingDetails, billingAddress;
            billingDetails = {
              name: fname + " " + lname,
              email: email,
              phone: phoneNumber,
              address: {
                line1: splitAddr[0],
                postal_code: Pincode[0],
                state: splitAddr[2].slice(0, -6).trim(),
                city: splitAddr[1],
              },
            };
            billingAddress = {
              CardName: fname + " " + lname,
              NickName: fname + " " + lname,
              State: splitAddr[2].slice(0, -6).trim(),
              City: splitAddr[1],
              StreetAddress: splitAddr[0],
              Zipcode: Pincode[0],
            };
            const paymentMethodReq = await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardElement),
              billing_details: billingDetails,
            });
            if (paymentMethodReq.error) {
              showAutoClosePopup(
                "Error",
                `${paymentMethodReq.error.message}`,
                3000
              );
              setMessage(paymentMethodReq.error.message);
              console.log(paymentMethodReq.error, "Enter Payment Info Error");
            }
            const { data } = await axios.post(config.REGISTER_USER_URL, {
              name: fname + " " + lname,
              email: email,
              paymentMethod: paymentMethodReq.paymentMethod.id,
            });
            if (data.status == "Success") {
              createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                  // Variables:
                  const user = userCredential.user;
                  const uuid = user.uid;
                  const selectedLawnAddonsWithPrice = {};
                  const selectedMulchAddonsWithPrice = {};
                  const selectedFallAddonsWithPrice = {};

                  Object.keys(selectedAddonsLawn).forEach((key) => {
                    if (selectedAddonsLawn[key]) {
                      selectedLawnAddonsWithPrice[key] = +Number(
                        lawnAddon[key]
                      );
                    }
                  });

                  Object.keys(selectedAddonsMulch).forEach((key) => {
                    if (selectedAddonsMulch[key]) {
                      selectedMulchAddonsWithPrice[key] = +Number(
                        mulchAddon[key]
                      );
                    }
                  });

                  Object.keys(selectedAddonsFall).forEach((key) => {
                    if (selectedAddonsFall[key]) {
                      selectedFallAddonsWithPrice[key] = +Number(
                        fallAddon[key]
                      );
                    }
                  });

                  const selectedServices = {
                    Main: select,
                    Addons: selectedLawnAddonsWithPrice,
                    Pricing: getLawnPrice,
                    ServiceCat: "LawnMowing",
                    SubscriptionType: selectedOption,
                  };

                  // const selectedServicesSnow = {
                  //   Main: {
                  //     CornerHouse: cornerHouse,
                  //     SlopedDriveway: slopeDrv,
                  //     DrivewayType: subscription,
                  //     PropertyZipcode: Pincode[0],
                  //     PropertySize: propertyDetails["TotalArea"],
                  //   },
                  //   Addons: {},
                  //   Pricing: {
                  //     OneTime: getSnowPrice["OneTime"],
                  //     SeasonPayFull: getSnowPrice["SeasonPayFull"],
                  //     SeasonPayEach: getSnowPrice["SeasonPayEach"],
                  //   },
                  //   ServiceCat: "SnowCleaning",
                  //   SubscriptionType: subscription,
                  //   Status: "Idle - Not expecting snow yet",
                  // };

                  const selectedServicesSpring = {
                    Main: selectSpring,
                    Addons: {},
                    Pricing: getSpringPrice,
                    ServiceCat: "SpringCleanup",
                    SubscriptionType: "One-Time",
                  };

                  const selectedServicesMulch = {
                    Main: selectMulch,
                    Addons: selectedMulchAddonsWithPrice,
                    Pricing: getMulchPrice,
                    ServiceCat: "Mulch",
                    SalesTax: +Number(mulchSalesTax),
                    SubscriptionType: selectedOptionMulch,
                    TotalYardsSelected: totalYardsSelected,
                  };

                  const selectedServicesHedge = {
                    Main: selectHedge,
                    Addons: {},
                    Pricing: getHedgePrice,
                    ServiceCat: "HedgeTrimming",
                    SubscriptionType: "One-Time",
                  };

                  const selectedServicesFall = {
                    Main: selectFall,
                    Addons: selectedFallAddonsWithPrice,
                    Pricing: getFallPrice,
                    ServiceCat: "FallCleanup",
                    SubscriptionType: "One-Time",
                  };

                  const ServiceDoc = {
                    LawnMowing: selectedServices,
                    // SnowRemoval: selectedServicesSnow,
                    SpringCleanup: selectedServicesSpring,
                    Mulch: selectedServicesMulch,
                    HedgeTrimming: selectedServicesHedge,
                    FallCleanup: selectedServicesFall,
                  };

                  const serviceRefs = {};
                  var backendParameters = {};

                  var backendURL = {
                    LawnMowing: config.JOB_ASSIGN_URL,
                    SnowRemoval: config.PROCESS_NEW_USER_ACCOUNT_URL,
                    SpringCleanup: config.JOB_ASSIGN_URL,
                    Mulch: config.JOB_ASSIGN_URL,
                    HedgeTrimming: config.JOB_ASSIGN_URL,
                    FallCleanup: config.JOB_ASSIGN_URL,
                  };

                  //-------------------------//

                  // Doc reference: //
                  const userAcct = await doc(db, "UserAcct", user.uid);
                  const Stripe = await doc(
                    db,
                    "UserAcct",
                    user.uid,
                    "Payments",
                    "Stripe"
                  );

                  serviceList.forEach(async (service) => {
                    const u_id = unique_id();
                    const orderID = u_id.slice(0, 8);
                    const serviceRef = await doc(
                      collection(db, "ProServices")
                    );
                    serviceRefs[service] = {
                      ref: serviceRef,
                      id: serviceRef.id,
                      orderId: orderID,
                      URL: backendURL[service],
                    };

                    const parameterKey =
                      service === "SnowRemoval" ? "serviceId" : "servID";

                    backendParameters[service] = {
                      zipCode: Pincode[0],
                      [parameterKey]: serviceRef.id,
                    };
                  });

                  // console.log(serviceRefs, 'FirstCheck')
                  //-------------------------//

                  // console.log(priceMulch, totalMulchAddon);
                  // Useracct creation: //
                  await setDoc(userAcct, {
                    AccessLevel: AccessLevel,
                    AcctStatus: AcctStatus,
                    ActiveService: serviceList,
                    ServiceStatus: {},
                    FirstName: fname,
                    LastName: lname,
                    Address: prevAddress,
                    AddressZipCode: Pincode[0],
                    Phone: phoneNumber,
                    EmailID: email,
                    CreatedDate: serverTimestamp(),
                  }).catch((err) => toast.error(err));
                  //-------------------------//

                  // console.log('userAcct doc created, Second check')

                  // Stripe document creation: //
                  await setDoc(Stripe, {
                    CustomerId: data.customerId,
                    DefaultCard: {
                      Id: data.card["paymentMethod"],
                      Brand: data.card["card"],
                      Index: 0,
                      Digits: data.card["digits"],
                    },
                    Cards: {
                      [data.card["digits"]]: {
                        Id: paymentMethodReq.paymentMethod.id,
                        BillingAddress: billingAddress,
                      },
                    },
                  }).catch((err) => {
                    // toast.error(err);
                    showAutoClosePopup("Error", `${err}`, 3000);
                    console.log(err, "Stripe doc creation Error");
                    return;
                  });
                  //------------------------------//

                  // console.log('Stripe document created, Third check')

                  //Create Service document for services://
                  serviceList.forEach(async (service) => {
                    await setDoc(
                      doc(db, "UserAcct", user.uid, "Services", service),
                      {
                        ServiceID: serviceRefs[service].id,
                        Services: ServiceDoc[service],
                        ProID: "",
                        ServiceDate: newDate,
                        NextService: nextServiceDate,
                        ServiceDay: dayFormatted,
                      }
                    ).catch((err) =>
                      console.log(err, "Service doc creation error")
                    );

                    // console.log('Service doc created, fourth check')

                    //Create Service contract for services://
                    await setDoc(serviceRefs[service].ref, {
                      UserEmail: email,
                      UserName: fname + " " + lname,
                      QuoteDate: serverTimestamp(),
                      JobRequest:
                        service === "LawnMowing"
                          ? select
                          : service === "SpringCleanup"
                            ? selectSpring
                            : service === "Mulch"
                              ? selectMulch
                              : service === "HedgeTrimming"
                                ? selectHedge
                                : service === "FallCleanup"
                                  ? selectFall
                                  : null,
                      FinalStatus: "Pending",
                      OrderNumber: serviceRefs[service].orderId,
                      ProID: "",
                      ServiceID: serviceRefs[service].id,
                      Price:
                        service === "LawnMowing"
                          ? +Number(Number(price) + Number(totalLawnAddon))
                          : service === "SnowRemoval"
                            ? +Number(priceSnow).toFixed(2)
                            : service === "SpringCleanup"
                              ? +Number(priceSpring).toFixed(2)
                              : service === "Mulch"
                                ? +Number(priceMulch)
                                : service === "HedgeTrimming"
                                  ? +Number(priceHedge)
                                  : service === "FallCleanup"
                                    ? +Number(Number(priceFall) + Number(totalFallAddon))
                                    : null,
                      ServiceCharge:
                        service === "LawnMowing"
                          ? +Number(getLawnPrice["Surcharge"]).toFixed(2)
                          : 0,
                      UID: uuid,
                      Services: ServiceDoc[service],
                      ServiceAddr: prevAddress,
                      JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
                      ServiceStatus: "", //[Active, Service cancelled on 'date']
                      ServiceDate: newDate,
                      NextService: nextServiceDate,
                      ServiceDay: dayFormatted,
                      ProPrice: "",
                    }).catch((err) => console.log(err));

                    // console.log('Service contract updated, fifth check')

                    await axios
                      .post(
                        serviceRefs[service].URL,
                        backendParameters[service]
                      )
                      .then((response) => {
                        // console.log(response.data);
                      })
                      .catch((error) => {
                        console.log(error, "ServiceRef and Backend error");
                      });

                    // console.log('Post request completed')
                  });
                  //------------------------------//

                  setIsLoading(false);
                  history.push({
                    pathname: "/dashboard",
                    state: {
                      newUser: true,
                    },
                  });
                  return;
                })
                .catch((err) => {
                  // toast.error(err);
                  showAutoClosePopup("Error", `${err}`, 3000);
                  console.log(err.message, "Error Inside Creating Acccount");
                  setIsLoading(false);
                  return;
                });
              //================================//
            } else {
              console.log(data.error);
              setIsLoading(false);
              showAutoClosePopup("Error", `${data.error}`, 3000);
              return;
            }
          } catch (error) {
            setIsLoading(false);
            console.log(error, "catch");
            return;
          }
        }
      } else {
        try {
          if (phoneNumber === null) {
            // toast.error("Phone Number is empty, click on sign up manually");
            showAutoClosePopup(
              "Error",
              "Phone Number is empty, click on sign up manually",
              3000
            );
            return;
          }

          if (validatePhone(phoneNumber) == false) {
            // toast.error("Incorrect Phone Number format");
            showAutoClosePopup("Error", "Incorrect Phone Number format", 3000);
            return;
          }

          if (isCardEmpty) {
            // toast.error("Enter card details");
            showAutoClosePopup("Error", "Enter card details", 3000);
            return;
          }

          if (
            !(
              isChecked ||
              isCheckedSnow ||
              isCheckedSpring ||
              isCheckedMulch ||
              isCheckedHedge
            )
          ) {
            // toast.error("Confirm Service agreement");
            showAutoClosePopup("Error", "Confirm Service agreement", 3000);
            return;
          } else {
            setIsLoading(true);
            var Pincode = prevAddress.match(/\b\d{5}\b/g);
            zipcode.push(Pincode[0]);
            var billingDetails;
            var billingAddress;

            billingDetails = {
              name: fname + " " + lname,
              email: email,
              phone: phoneNumber,
              address: {
                line1: splitAddr[0],
                postal_code: Pincode[0],
                state: splitAddr[2].slice(0, -6).trim(),
                city: splitAddr[1],
              },
            };
            billingAddress = {
              CardName: fname + " " + lname,
              NickName: fname + " " + lname,
              State: splitAddr[2].slice(0, -6).trim(),
              City: splitAddr[1],
              StreetAddress: splitAddr[0],
              Zipcode: Pincode[0],
            };

            const paymentMethodReq = await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardElement),
              billing_details: billingDetails,
            });

            if (paymentMethodReq.error) {
              // toast.error(paymentMethodReq.error.message);
              showAutoClosePopup(
                "Error",
                `${paymentMethodReq.error.message}`,
                3000
              );
              setMessage(paymentMethodReq.error.message);
              return;
            }

            const { data } = await axios.post(config.REGISTER_USER_URL, {
              name: fname + " " + lname,
              email: email,
              paymentMethod: paymentMethodReq.paymentMethod.id,
            });
            if (data.status == "Success") {
              // Variables: //
              // const user = userCredential.user;
              console.log(users);
              const uuid = users;
              const selectedLawnAddonsWithPrice = {};
              const selectedMulchAddonsWithPrice = {};
              const selectedFallAddonsWithPrice = {};

              Object.keys(selectedAddonsLawn).forEach((key) => {
                if (selectedAddonsLawn[key]) {
                  selectedLawnAddonsWithPrice[key] = +Number(lawnAddon[key]);
                }
              });

              Object.keys(selectedAddonsMulch).forEach((key) => {
                if (selectedAddonsMulch[key]) {
                  selectedMulchAddonsWithPrice[key] = +Number(mulchAddon[key]);
                }
              });
              Object.keys(selectedAddonsFall).forEach((key) => {
                if (selectedAddonsFall[key]) {
                  selectedFallAddonsWithPrice[key] = +Number(fallAddon[key]);
                }
              });

              const selectedServices = {
                Main: select,
                Addons: selectedLawnAddonsWithPrice,
                Pricing: getLawnPrice,
                ServiceCat: "LawnMowing",
                SubscriptionType: selectedOption,
              };

              // const selectedServicesSnow = {
              //   Main: {
              //     CornerHouse: cornerHouse,
              //     SlopedDriveway: slopeDrv,
              //     DrivewayType: subscription,
              //     PropertyZipcode: Pincode[0],
              //     PropertySize: propertyDetails["TotalArea"],
              //   },
              //   Addons: {},
              //   Pricing: {
              //     OneTime: getSnowPrice["OneTime"],
              //     SeasonPayFull: getSnowPrice["SeasonPayFull"],
              //     SeasonPayEach: getSnowPrice["SeasonPayEach"],
              //   },
              //   ServiceCat: "SnowCleaning",
              //   SubscriptionType: subscription,
              //   Status: "Idle - Not expecting snow yet",
              // };

              const selectedServicesSpring = {
                Main: selectSpring,
                Addons: {},
                Pricing: getSpringPrice,
                ServiceCat: "SpringCleanup",
                SubscriptionType: "One-Time",
              };

              const selectedServicesMulch = {
                Main: selectMulch,
                Addons: selectedMulchAddonsWithPrice,
                Pricing: getMulchPrice,
                ServiceCat: "Mulch",
                SubscriptionType: selectedOptionMulch,
                SalesTax: +Number(mulchSalesTax),
                TotalYardsSelected: totalYardsSelected,
              };

              const selectedServicesHedge = {
                Main: selectHedge,
                Addons: {},
                Pricing: getHedgePrice,
                ServiceCat: "HedgeTrimming",
                SubscriptionType: "One-Time",
              };

              const selectedServicesFall = {
                Main: selectFall,
                Addons: selectedFallAddonsWithPrice,
                Pricing: getFallPrice,
                ServiceCat: "FallCleanup",
                SubscriptionType: "One-Time",
              };

              const ServiceDoc = {
                LawnMowing: selectedServices,
                // SnowRemoval: selectedServicesSnow,
                SpringCleanup: selectedServicesSpring,
                Mulch: selectedServicesMulch,
                HedgeTrimming: selectedServicesHedge,
                FallCleanup: selectedServicesFall,
              };

              const serviceRefs = {};
              var backendParameters = {};

              var backendURL = {
                LawnMowing: config.JOB_ASSIGN_URL,
                SnowRemoval: config.PROCESS_NEW_USER_ACCOUNT_URL,
                SpringCleanup: config.JOB_ASSIGN_URL,
                Mulch: config.JOB_ASSIGN_URL,
                HedgeTrimming: config.JOB_ASSIGN_URL,
                FallCleanup: config.JOB_ASSIGN_URL,
              };

              //-------------------------//

              // Doc reference: //
              const userAcct = await doc(db, "UserAcct", uuid);
              const Stripe = await doc(
                db,
                "UserAcct",
                uuid,
                "Payments",
                "Stripe"
              );

              serviceList.forEach(async (service) => {
                const u_id = unique_id();
                const orderID = u_id.slice(0, 8);
                const serviceRef = await doc(collection(db, "ProServices"));
                serviceRefs[service] = {
                  ref: serviceRef,
                  id: serviceRef.id,
                  orderId: orderID,
                  URL: backendURL[service],
                };
                const parameterKey =
                  service === "SnowRemoval" ? "serviceId" : "servID";

                backendParameters[service] = {
                  zipCode: Pincode[0],
                  [parameterKey]: serviceRef.id,
                };
              });

              // console.log(serviceRefs, 'FirstCheck')
              //-------------------------//

              // Useracct creation: //
              await setDoc(userAcct, {
                AccessLevel: AccessLevel,
                AcctStatus: AcctStatus,
                ActiveService: serviceList,
                ServiceStatus: {},
                FirstName: fname,
                LastName: lname,
                Address: prevAddress,
                AddressZipCode: Pincode[0],
                Phone: phoneNumber,
                EmailID: email,
                CreatedDate: serverTimestamp(),
              }).catch((err) => toast.error(err));
              //-------------------------//

              // console.log('userAcct doc created, Second check')

              // Stripe document creation: //
              await setDoc(Stripe, {
                CustomerId: data.customerId,
                DefaultCard: {
                  Id: data.card["paymentMethod"],
                  Brand: data.card["card"],
                  Index: 0,
                  Digits: data.card["digits"],
                },
                Cards: {
                  [data.card["digits"]]: {
                    Id: paymentMethodReq.paymentMethod.id,
                    BillingAddress: billingAddress,
                  },
                },
              }).catch((err) => {
                // toast.error(err);
                showAutoClosePopup("Error", `${err}`, 3000);
                console.log(err, "Stripe doc creation Error");
                return;
              });
              //------------------------------//

              // console.log('Stripe document created, Third check')

              //Create Service document for services://
              serviceList.forEach(async (service) => {
                await setDoc(doc(db, "UserAcct", uuid, "Services", service), {
                  ServiceID: serviceRefs[service].id,
                  Services: ServiceDoc[service],
                  ProID: "",
                  ServiceDate: newDate,
                  NextService: nextServiceDate,
                  ServiceDay: dayFormatted,
                }).catch((err) =>
                  console.log(err, "Service doc creation error")
                );

                // console.log('Service doc created, fourth check')

                //Create Service contract for services://
                await setDoc(serviceRefs[service].ref, {
                  UserEmail: email,
                  UserName: fname + " " + lname,
                  QuoteDate: serverTimestamp(),
                  JobRequest:
                    service === "LawnMowing"
                      ? select
                      : service === "SpringCleanup"
                        ? selectSpring
                        : service === "Mulch"
                          ? selectMulch
                          : service === "HedgeTrimming"
                            ? selectHedge
                            : service === "FallCleanup"
                              ? selectFall
                              : null,
                  FinalStatus: "Pending",
                  OrderNumber: serviceRefs[service].orderId,
                  ProID: "",
                  ServiceID: serviceRefs[service].id,
                  Price:
                    service === "LawnMowing"
                      ? +Number(Number(price) + Number(totalLawnAddon))
                      : service === "SnowRemoval"
                        ? +Number(priceSnow).toFixed(2)
                        : service === "SpringCleanup"
                          ? +Number(priceSpring).toFixed(2)
                          : service === "Mulch"
                            ? +Number(priceMulch)
                            : service === "HedgeTrimming"
                              ? +Number(priceHedge)
                              : service === "FallCleanup"
                                ? +Number(Number(priceFall) + Number(totalFallAddon))
                                : null,
                  ServiceCharge:
                    service === "LawnMowing"
                      ? +Number(getLawnPrice["Surcharge"]).toFixed(2)
                      : 0,
                  UID: uuid,
                  Services: ServiceDoc[service],
                  ServiceAddr: prevAddress,
                  JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
                  ServiceStatus: "", //[Active, Service cancelled on 'date']
                  ServiceDate: newDate,
                  NextService: nextServiceDate,
                  ServiceDay: dayFormatted,
                  ProPrice: "",
                }).catch((err) => console.log(err));

                // console.log('Service contract updated, fifth check')

                await axios
                  .post(serviceRefs[service].URL, backendParameters[service])
                  .then((response) => {
                    // console.log(response.data);
                  })
                  .catch((error) => {
                    console.log(error, "ServiceRef and Backend error");
                  });

                // console.log('Post request completed')
              });
              //------------------------------//

              setIsLoading(false);
              history.push({
                pathname: "/dashboard",
                state: {
                  newUser: true,
                },
              });
              return;
            } else {
              setIsLoading(false);
              console.log(data.error);
              showAutoClosePopup("Error", `${data.error}`, 3000);
              return;
            }
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error, "whole try catch error");
          return;
        }
      }
    }
  };

  /**
   * Handles the edit action triggered by a user event.
   *
   * @param {Event} e - The event object.
   * @param {string} state - The current state.
   * @param {function} SetState - The setter function for updating the state.
   * @param {function} SetPrices - The setter function for updating the prices.
   * @param {object} getServicePrices - The object containing price information.
   */
  const handleEdit = (e, state, SetState, SetPrices, getServicePrices) => {
    e.preventDefault();
    // Check if the current state matches the event target ID
    if (state === e.target.id) {
      SetState("");
      SetPrices(0);
    } else {
      // Set the new state to the event target ID and update the prices
      SetState(e.target.id);
      SetPrices(
        Number(getServicePrices[e.target.id]) +
        Number(getServicePrices["Surcharge"])
      );
    }
  };

  /**
   * Handles the selection among different options lawn.
   *
   * @param {string} option - The selected option.
   */
  const handleSelectAmongLawn = (option) => {
    // Calculate the price adjustment based on the selected option
    const priceAdjustment =
      option === "Bi-Weekly" ? allPrices["MowBiWeekly"] : 0;

    // Update the prices based on the selected option and price adjustment
    const updatedPrices = {
      Standard: +Number(allPrices.MowStandard) + priceAdjustment,
      Premium: +Number(allPrices.MowPremium) + priceAdjustment,
      Basic: +Number(allPrices.MowBasic) + priceAdjustment,
      Surcharge: +Number(allPrices["Surcharge"]).toFixed(2),
    };

    // Update the price state and selected option
    setgetLawnPrice(updatedPrices);
    setPrice(
      Number(updatedPrices[select]) + Number(updatedPrices["Surcharge"])
    );
    setSelectedOption(option);
  };

  /**
   * Renders the page based on the selected option.
   *
   * @param {Event} event - The event object.
   */
  const handlePage = (event) => {
    // Update the selected sign-up page based on the event target ID
    setSignUpPageSelected(event.target.id);
  };

  /**
   * Renders the page based on the top bar option.
   *
   * @param {Event} event - The event object.
   */
  const handlePageTop = (event) => {
    // Update the selected sign-up page based on the value attribute of the current target
    setSignUpPageSelected(event.currentTarget.getAttribute("value"));
    setAnchorEl(null);
  };

  /**
   * Performs Google sign-in.
   *
   * @returns {Promise<void>} - A promise that resolves when the sign-in process is completed.
   */
  const googleSignIn = async () => {
    const result = await signInWithPopup(auth, providers);
    // Obtain the credential and user information from the sign-in result
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;

    // Set various state variables based on the user information
    setUsers(user.uid);
    setGoogleSign(user.emailVerified);
    setFname(user.displayName.split(" ")[0]);
    setLname(user.displayName.split(" ")[1]);
    setEmail(user.email);
    setCheckEmail(user.email);
    setphoneNumber(user.phoneNumber);
    setUid(user.uid);
  };

  return (
    <>
      <div
        ref={cardNumberRef}
        style={{ backgroundColor: "white", width: "100vw", height: "100vh" }}
      >
        {/* ------ Navbar component start ------  */}
        <AppBar
          color="white"
          elevation={3}
          position="fixed"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingLeft: "2%",
            paddingRight: "1%",
            maxHeight: "75px",
          }}
        >
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  maxWidth: matchesSm ? "100px" : "150px",
                  minWidth: matchesSm ? "100px" : "150px",
                  maxHeight: matchesSm ? "100px" : "150px",
                  minHeight: matchesSm ? "100px" : "150px",
                  margin: "auto",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/")}
              />
            </Box>
            <Box flexGrow={1} />
            <Box display="flex" alignItems="center">
              {!matchesMd &&
                allServices.map((value, index) => (
                  <button
                    id={value}
                    key={index}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor:
                        value === signUpPageSelected ? "#187CAE" : "#d3d3d3",
                      color: value === signUpPageSelected ? "white" : "black",
                      fontWeight: "bold",
                      fontSize: isBetweenMdAndLg ? "1vh" : "1.6vh",
                      borderRadius: "5px",
                      border: "none",
                      cursor: "pointer",
                      minHeight: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      textAlign: "center",
                      textTransform: "none",
                      marginLeft: "0.5rem",
                    }}
                    onClick={handlePage}
                  >
                    {value}
                    {(value === "Hedge Trimming" ||
                      value === "Fall Cleanup") && (
                        <Typography
                          style={{
                            background: "red",
                            color: "white",
                            paddingLeft: "3px",
                            paddingRight: "3px",
                            marginLeft: "3px",
                            marginTop: "-5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            pointerEvents: "none",
                          }}
                        >
                          New
                        </Typography>
                      )}
                  </button>
                ))}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: matchesSm ? "8%" : "4%" }}
              >
                {allServices.map((value, index) => (
                  <MenuItem
                    value={value}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={handlePageTop}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box flexGrow={2} />
            <Box display="flex" alignItems="center">
              <>
                {matchesMd && (
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleDropDown}
                    style={{
                      marginRight: "1rem",
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "1rem"
                        : matchesMd
                          ? "1.1rem"
                          : "1.2rem",
                    }}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Services
                  </Button>
                )}
              </>
            </Box>
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/contact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  minHeight: "40px",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : isBetweenMdAndLg
                        ? "1.1vh"
                        : "1.6vh",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box display="flex" alignItems="center">
              {matchesSm && (
                <Box sx={{ flexGrow: 0 }}>
                  <div
                    className="navLink"
                    onClick={() => {
                      history.push("/contact");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <ContactSupport
                      fontSize="large"
                      style={{ color: "#187CAE", fontSize: 45, margin: "auto" }}
                    />
                  </div>
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <div
                  className="navLink"
                  onClick={() => {
                    history.push("/Login");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: matchesSm ? "0.9rem" : "1.1rem",
                  }}
                >
                  <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                </div>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {/* ------ Navbar component end ------  */}

        {/* Popup DialogBox */}
        <Popup
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          title={popupTitles}
          content={popupContent}
          actions={popupActions}
          alert
        />

        {/* ------ Parent grid start ------  */}
        <Grid
          container
          sm={12}
          xs={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
          justifyContent="center"
          className={classes.responsiveContentForAppbar}
        >
          {/* ------ Left grid item start ------  */}
          <Grid item sm={11} xs={13} md={9} lg={9} style={{ width: "100%" }}>
            {/* ------ All Active service -----*/}
            <>
              {/* Lawn mowing section */}
              {show && signUpPageSelected === "Lawn Mowing" ? (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    marginLeft: "2px",
                  }}
                  justifyContent="center"
                  alignItems="baseline"
                >
                  <Grid
                    lg={12}
                    sm={10}
                    xl={12}
                    xs={10}
                    md={11}
                    container
                    style={{
                      backgroundColor: "#cfe4e8",
                      width: "100%",
                      paddingTop: matchesSm ? "10px" : "35px",
                    }}
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      style={{
                        width: "100%",
                        maxWidth: matchesSm
                          ? "100%"
                          : matchesMd
                            ? "80%"
                            : "80%",
                      }}
                      xl={12}
                      lg={12}
                      sm={10}
                      xs={12}
                      md={10}
                    >
                      <span
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                          fontSize: "1.5rem",
                        }}
                      >
                        For the first time service, if the grass is more than
                        12in tall, the price will be charged as a standard
                        cleanup
                      </span>
                      <span
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                          fontSize: "1.2rem",
                          fontWeight: "500",
                        }}
                      >
                        First mowing service is scheduled within a few days, but
                        can be delayed due to weather.
                      </span>
                      <br />
                      <h6
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                        }}
                      >
                        {/* 1. Select a preferred start date and a Plan. */}
                        1. Select a preferred Plan
                      </h6>
                    </Grid>
                  </Grid>

                  <Grid
                    lg={12}
                    sm={10}
                    xl={12}
                    xs={10}
                    md={11}
                    container
                    className={classes.gridForPlans}
                  >
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={5}
                      sm={9}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                    >
                      <Paper elevation={5} style={{ maxWidth: "400px" }}>
                        {/* <div
                            style={{
                              width: "100%",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px 5px 0px 0px",
                              fontSize: "16px",
                              fontFamily: "poppins",
                            }}
                          >
                            Most Popular
                          </div> */}
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            style={{
                              lineHeight: "22px",
                              fontFamily: "poppins",
                              marginBottom: "2%",
                              fontWeight: "bold",
                            }}
                          >
                            Standard Plan
                          </Typography>

                          {getLawnPrice !== null ? (
                            <Typography
                              color="text.secondary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                fontFamily: "poppins",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Box flexGrow={0.8} />$
                              {Number(getLawnPrice["Standard"]).toFixed(2)}
                              <Box flexGrow={0.4} />
                              <Tooltip title="Do you like the price?">
                                <ThumbUpIcon
                                  style={{
                                    color: localStorage.getItem(
                                      "WeeklyMowCount"
                                    )
                                      ? "#187cae"
                                      : "#a5a4a4",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      localStorage.getItem("WeeklyMowCount")
                                    ) {
                                      return;
                                    } else {
                                      setWeeklyMowCount(
                                        Number(weeklyMowCount) + 1
                                      );
                                      updateDoc(thumbCount, {
                                        WeeklyMowCount:
                                          Number(weeklyMowCount) + 1,
                                      });
                                      localStorage.setItem(
                                        "WeeklyMowCount",
                                        JSON.stringify(weeklyMowCount)
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                              {weeklyMowCount ? (
                                <p
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "normal",
                                    paddingLeft: "6px",
                                  }}
                                >
                                  {weeklyMowCount}
                                </p>
                              ) : (
                                "10"
                              )}
                            </Typography>
                          ) : (
                            <CircularProgress />
                          )}
                          <div
                            style={{ display: "grid", placeItems: "center" }}
                          >
                            <div style={{ display: "flex" }}>
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "5%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Service includes Mowing, Edging, and Blowing
                                debris of hard surfaces
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "5%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Discounted prices based on mowing service
                                subscription from April 15 till the end of the
                                Fall mowing season usually on Nov 30th.
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "5%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                This plan ensures availability and mowing
                                service where weather premits..
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "5%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                You agreed to pay this price after each service.
                                there is no risk to you and noting to pay at
                                signup.
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                        <button
                          id="Standard"
                          variant="contained"
                          size="small"
                          style={{
                            margin: "2%",
                            marginBottom: "5%",
                            backgroundColor:
                              select === "Standard" ? "#187CAE" : "#d3d3d3",
                            color: select === "Standard" ? "white" : "black",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            fontSize: "1.8vh",
                            border: "none",
                            minWidth: "100px",
                            width: "50%",
                            cursor: "pointer",
                            height: "3vh",
                            minHeight: "40px",
                          }}
                          onClick={(e) =>
                            handleEdit(
                              e,
                              select,
                              setSelect,
                              setPrice,
                              getLawnPrice
                            )
                          }
                        >
                          {select === "Standard" ? (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Subscribed{" "}
                              <Check
                                fontSize="small"
                                style={{ marginLeft: "17%" }}
                              />
                            </span>
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={5}
                      sm={9}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                    >
                      <Paper elevation={3} style={{ maxWidth: "400px" }}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px 5px 0px 0px",
                            fontSize: "16px",
                            fontFamily: "poppins",
                            fontWeight: "bold",
                          }}
                        >
                          Most Popular
                        </div>
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            style={{
                              lineHeight: "22px",
                              fontFamily: "poppins",
                              marginBottom: "2%",
                              fontWeight: "bold",
                            }}
                          >
                            Premium Plan
                          </Typography>

                          {/* Biweekly */}
                          {getLawnPrice !== null ? (
                            <Typography
                              color="text.secondary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Box flexGrow={0.8} />
                              <span>
                                {" "}
                                ${Number(getLawnPrice["Premium"]).toFixed(
                                  2
                                )}{" "}
                              </span>
                              <Box flexGrow={0.4} />
                              <Tooltip title="Do you like the price?">
                                <ThumbUpIcon
                                  style={{
                                    color: localStorage.getItem(
                                      "BiWeeklyMowCount"
                                    )
                                      ? "#187cae"
                                      : "#a5a4a4",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      localStorage.getItem("BiWeeklyMowCount")
                                    ) {
                                      return;
                                    } else {
                                      setBiWeeklyMowCount(
                                        Number(biweeklyMowCount) + 1
                                      );
                                      updateDoc(thumbCount, {
                                        BiWeeklyMowCount:
                                          Number(biweeklyMowCount) + 1,
                                      });
                                      localStorage.setItem(
                                        "BiWeeklyMowCount",
                                        JSON.stringify(biweeklyMowCount)
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                              {biweeklyMowCount ? (
                                <p
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "normal",
                                    paddingLeft: "6px",
                                  }}
                                >
                                  {biweeklyMowCount}
                                </p>
                              ) : (
                                "10"
                              )}
                            </Typography>
                          ) : (
                            <CircularProgress />
                          )}

                          <div style={{ display: "flex" }}>
                            <Check />
                            <Typography
                              style={{
                                marginBottom: "5%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              Service includes Mowing, Edging, and Blowing
                              debris of hard surfaces
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check />
                            <Typography
                              style={{
                                marginBottom: "5%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              Additional lawn fertilizer included
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check />
                            <Typography
                              style={{
                                marginBottom: "5%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              Discounted prices based on mowing service
                              subscription from April 15 till the end of the
                              Fall mowing season usually on Nov 30th.
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check />
                            <Typography
                              style={{
                                marginBottom: "5%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              This plan ensures availability and mowing service
                              where weather premits..
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check />
                            <Typography
                              style={{
                                marginBottom: "5%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: "16px",
                              }}
                            >
                              You agreed to pay this price after each service.
                              there is no risk to you and noting to pay at
                              signup.
                            </Typography>
                          </div>
                        </CardContent>
                        <button
                          id="Premium"
                          variant="contained"
                          size="small"
                          style={{
                            margin: "2%",
                            marginBottom: "5%",
                            backgroundColor:
                              select === "Premium" ? "#187CAE" : "#d3d3d3",
                            color: select === "Premium" ? "white" : "black",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            border: "none",
                            minWidth: "100px",
                            width: "50%",
                            fontSize: "1.8vh",
                            height: "3vh",
                            cursor: "pointer",
                            minHeight: "40px",
                          }}
                          onClick={(e) =>
                            handleEdit(
                              e,
                              select,
                              setSelect,
                              setPrice,
                              getLawnPrice
                            )
                          }
                        >
                          {select === "Premium" ? (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Subscribed{" "}
                              <Check
                                fontSize="small"
                                style={{ marginLeft: "17%" }}
                              />
                            </span>
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={10}
                      sm={9}
                      xs={12}
                      spacing={2}
                      className={SignupClass.leftItem}
                    >
                      {
                        <Paper
                          elevation={8}
                          className={classes.responsiveThirdCardPlan}
                        >
                          <CardContent>
                            {
                              <>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  className={classes.addonCardMainHeading}
                                  style={{ textAlign: "center" }}
                                >
                                  Mowing frequency
                                </Typography>
                                {["Weekly", "Bi-Weekly"].map((key) => {
                                  return (
                                    <button
                                      id={key}
                                      key={key}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        margin: "2%",
                                        marginBottom: "2vh",
                                        background:
                                          selectedOption === key
                                            ? "#187cae"
                                            : "#d3d3d3",
                                        color:
                                          selectedOption === key
                                            ? "white"
                                            : "black",
                                        fontWeight: "bold",
                                        borderRadius: "5px",
                                        border: "none",
                                        minWidth: "150px",
                                        height: "3vh",
                                        cursor: "pointer",
                                        minHeight: "60px",
                                        fontSize: "2.3vh",
                                        width: "90%",
                                        padding: "1%",
                                        whiteSpace: "nowrap",
                                      }}
                                      onClick={() => handleSelectAmongLawn(key)}
                                    >

                                      {key === "Bi-Weekly"
                                        ? `Bi-Weekly ${allPrices !== null
                                          ? "(+$" +
                                          allPrices["MowBiWeekly"] +
                                          ")"
                                          : " "
                                        }`
                                        : key}
                                      {selectedOption === key && (
                                        <Check
                                          style={{
                                            color: "white",
                                            fontSize: "3vh",
                                            marginLeft: "3%",
                                          }}
                                        />
                                      )}
                                    </button>
                                  );
                                })}
                              </>
                            }
                          </CardContent>
                        </Paper>
                      }
                    </Grid>
                    {/* Addons Lawn : */}
                    <Grid
                      className={classes.responsiveFlex}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={11}
                    >
                      <Grid
                        item
                        lg={11}
                        xl={11}
                        md={11}
                        sm={9}
                        xs={11}
                        className={SignupClass.leftItem}
                        spacing={1}
                      >
                        <Paper elevation={3}>
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="div"
                              className={classes.addonText}
                            >
                              Addons
                            </Typography>
                            {lawnAddon !== null && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: matcheslg ? "column" : "row",
                                  justifyContent: "space-between",
                                  height: "100%",
                                  paddingBottom: "2vh",
                                }}
                              >
                                {Object.keys(lawnAddon).map((key, i) => {
                                  return (
                                    <button
                                      id={lawnAddon[key]}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        alignItems: "center",
                                        border: "none",
                                        width: "100%",
                                        margin: "3px",
                                        marginBottom: matchesSm ? "5%" : "0%",
                                        background: selectedAddonsLawn[key]
                                          ? "#187cae"
                                          : "#d3d3d3",
                                        color: selectedAddonsLawn[key]
                                          ? "white"
                                          : "black",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        height: "100%",
                                        maxHeight: !matchesMd ? "6.3rem" : "",
                                        minHeight: !matchesMd ? "6.3rem" : "",
                                        maxWidth: !matcheslg ? "20vw" : "",
                                      }}
                                      onClick={() =>
                                        handleSelect(
                                          key,
                                          Number(lawnAddon[key]).toFixed(2),
                                          setSelectedAddonsLawn,
                                          setTotalLawnAddon,
                                          selectedAddonsLawn
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          // justifyContent: "space-between",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "1.7vh",
                                          fontWeight: "bold",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <img
                                          src={LawnAddons[i].img}
                                          style={{
                                            borderRadius: "5px",
                                            width: "40%",
                                            maxHeight: "13vh",
                                            minHeight: "100px",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div style={{ width: "100%" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: matchesSm ? "98%" : "90%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                margin: "3%",
                                                width: "100%",
                                                marginLeft: "5%",
                                              }}
                                            >
                                              <Typography
                                                className={classes.addonCardMainHeading}
                                                style={{
                                                  color: selectedAddonsLawn[key]
                                                    ? "white"
                                                    : "black",
                                                }}
                                              >
                                                {key}
                                              </Typography>
                                              <Typography
                                                className={classes.addonCardSubHeading}
                                                style={{
                                                  color: selectedAddonsLawn[key]
                                                    ? "white"
                                                    : "black",
                                                }}
                                              >
                                                ${Number(lawnAddon[key])}
                                                <span
                                                  style={{
                                                    fontWeight: "normal",
                                                    marginLeft: "3px",
                                                  }}
                                                >
                                                  {" "}
                                                  Estimated
                                                </span>
                                                {selectedAddonsLawn[key] && (
                                                  <Check
                                                    style={{
                                                      color: "white",
                                                      fontSize: "2.5vh",
                                                      marginLeft: "5%",
                                                    }}
                                                  />
                                                )}
                                              </Typography>
                                            </div>
                                            <Tooltip
                                              arrow
                                              enterTouchDelay={0}
                                              leaveTouchDelay={5000}
                                              title={
                                                <Typography
                                                  style={{
                                                    fontSize: "1.7vh",
                                                    fontWeight: "bold",
                                                    width: "100%",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {LawnAddons[i].description}
                                                </Typography>
                                              }
                                            >
                                              <InfoIcon
                                                style={{
                                                  fontSize: "3vh",
                                                  color: selectedAddonsLawn[key]
                                                    ? "white"
                                                    : "#187cae",
                                                }}
                                              />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  );
                                })}
                              </div>
                            )}
                          </CardContent>
                        </Paper>
                      </Grid>
                    </Grid>

                    <br />

                    <div style={{ width: "100%" }}>
                      <Typography style={{ marginTop: "20px" }}>
                        <>
                          <span>Click to read</span>
                          <span>
                            <Link
                              href="/ServiceAgreementLawn"
                              target="_blank"
                              className={SignupClass.link}
                              style={{
                                border: "none",
                                color: "#187CAE",
                                fontWeight: "bold",
                                fontSize: "15px",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginLeft: "1%",
                                fontFamily: "poppins",
                              }}
                            >
                              Full contract details
                            </Link>{" "}
                          </span>
                          <span> - features and responsibilities </span>
                        </>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              ) : null}

              {/* Snow service section*/}
              {show && signUpPageSelected === "Snow Cleanup" ? (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    padding: "7%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "2.5vh",
                        }}
                      >
                        Snow cleanup services will be available in November for
                        early signup. <br />
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "poppins",
                        textAlign: "justify",
                        fontSize: "1.85vh",
                      }}
                    >
                      Please come back on October 2023.
                    </Typography>
                  </Grid> */}
                    {/* Add login form and project description input here */}
                  </Grid>
                </Grid>
              ) : null}

              {/* Spring Cleanup section */}
              {show && signUpPageSelected === "Spring Cleanup" ? (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                  }}
                  justifyContent="center"
                >
                  <Grid
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    md={11}
                    container
                    style={{
                      backgroundColor: "#cfe4e8",
                      width: "100%",
                      paddingTop: matchesSm ? "10px" : "20px",
                    }}
                  >
                    <Grid
                      item
                      style={{ width: "100%" }}
                      xl={6}
                      lg={5}
                      sm={10}
                      xs={12}
                      md={10}
                    >
                      <h6
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                        }}
                      >
                        1. Select a preferred start date and service plan.
                        <br />
                      </h6>
                      <h6
                        style={{
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          paddingLeft: "0%",
                          width: matchesSm ? "90%" : "100%",
                          paddingTop: "0px",
                          paddingBottom: matchesSm ? "10px" : "0px",
                          marginTop: "0%",
                          fontSize: "1.7vh",
                          fontWeight: "normal",
                        }}
                      >
                        Note: Spring clean service will not be available after
                        May 31st.
                      </h6>
                    </Grid>
                    <Box flexGrow={1} />
                    <Grid
                      style={{ width: "100%", display: "flex" }}
                      item
                      lg={4}
                      md={5}
                      sm={9}
                      xl={4}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                      justifyContent="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          type="date"
                          id="Date"
                          // label="Select a date"
                          value={date}
                          minDate={dayjs(currentDate)}
                          maxDate={dayjs(lastDateOfYear)}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                style: {
                                  maxHeight: "45px",
                                  fontFamily: "Poppins",
                                  fontWeight: "bold",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  backgroundColor: "white",
                                  border: "2px solid black",
                                },
                              }}
                              sx={{ input: { textAlign: "center" } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={5}
                    sm={9}
                    xl={4}
                    xs={11}
                    className={SignupClass.leftItem}
                    spacing={1}
                  >
                    <Paper elevation={3} style={{ maxWidth: "550px" }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            lineHeight: "22px",
                            marginBottom: "5px",
                            fontFamily: "poppins",
                          }}
                        >
                          Standard Plan
                        </Typography>

                        {getSpringPrice !== null ? (
                          <Typography
                            color="text.secondary"
                            style={{
                              fontWeight: "bold",
                              fontSize: "30px",
                              fontFamily: "poppins",
                              alignItems: "center",
                              display: "flex",
                              marginBottom: "5px",
                            }}
                          >
                            <Box flexGrow={0.8} />$
                            {Number(getSpringPrice["Standard"]).toFixed(2)}
                            <Box flexGrow={0.4} />
                            <Tooltip title="Do you like the price?">
                              <ThumbUpIcon
                                style={{
                                  color: localStorage.getItem("WeeklyMowCount")
                                    ? "#187cae"
                                    : "#a5a4a4",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (localStorage.getItem("WeeklyMowCount")) {
                                    return;
                                  } else {
                                    setWeeklyMowCount(
                                      Number(weeklyMowCount) + 1
                                    );
                                    updateDoc(thumbCount, {
                                      WeeklyMowCount:
                                        Number(weeklyMowCount) + 1,
                                    });
                                    localStorage.setItem(
                                      "WeeklyMowCount",
                                      JSON.stringify(weeklyMowCount)
                                    );
                                  }
                                }}
                              />
                            </Tooltip>
                            {weeklyMowCount ? (
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "normal",
                                  paddingLeft: "6px",
                                }}
                              >
                                {weeklyMowCount}
                              </p>
                            ) : (
                              "10"
                            )}
                          </Typography>
                        ) : (
                          <CircularProgress />
                        )}
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Mowing the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Blow out the lawn of dead leaves
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Trim out small plants upto 3ft tall
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Rake out the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Pickup small fallen branches
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Throw away additional debris
                          </Typography>
                        </div>
                      </CardContent>
                      <button
                        id="Standard"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            selectSpring === "Standard" ? "#187CAE" : "#d3d3d3",
                          color:
                            selectSpring === "Standard" ? "white" : "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          fontSize: "1.8vh",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          cursor: "pointer",
                          height: "3vh",
                          minHeight: "40px",
                        }}
                        onClick={(e) =>
                          handleEdit(
                            e,
                            selectSpring,
                            setSelectSpring,
                            setPriceSpring,
                            getSpringPrice
                          )
                        }
                      >
                        {selectSpring === "Standard" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Selected{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Select"
                        )}
                      </button>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={5}
                    sm={9}
                    xl={4}
                    xs={11}
                    className={SignupClass.leftItem}
                    spacing={1}
                  >
                    <Paper elevation={3} style={{ maxWidth: "500px" }}>
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "5px 5px 0px 0px",
                          fontSize: "16px",
                          fontFamily: "poppins",
                        }}
                      >
                        Most Popular
                      </div>
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            lineHeight: "22px",
                            marginBottom: "5px",
                            fontFamily: "poppins",
                          }}
                        >
                          Premium Plan
                        </Typography>

                        {/* Biweekly */}
                        {getSpringPrice !== null ? (
                          <Typography
                            color="text.secondary"
                            style={{
                              fontWeight: "bold",
                              fontSize: "30px",
                              alignItems: "center",
                              display: "flex",
                              marginBottom: "5px",
                            }}
                          >
                            <Box flexGrow={0.8} />
                            <span>
                              {" "}
                              ${Number(getSpringPrice["Premium"]).toFixed(
                                2
                              )}{" "}
                            </span>
                            <Box flexGrow={0.4} />
                            <Tooltip title="Do you like the price?">
                              <ThumbUpIcon
                                style={{
                                  color: localStorage.getItem(
                                    "BiWeeklyMowCount"
                                  )
                                    ? "#187cae"
                                    : "#a5a4a4",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    localStorage.getItem("BiWeeklyMowCount")
                                  ) {
                                    return;
                                  } else {
                                    setBiWeeklyMowCount(
                                      Number(biweeklyMowCount) + 1
                                    );
                                    updateDoc(thumbCount, {
                                      BiWeeklyMowCount:
                                        Number(biweeklyMowCount) + 1,
                                    });
                                    localStorage.setItem(
                                      "BiWeeklyMowCount",
                                      JSON.stringify(biweeklyMowCount)
                                    );
                                  }
                                }}
                              />
                            </Tooltip>
                            {biweeklyMowCount ? (
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "normal",
                                  paddingLeft: "6px",
                                }}
                              >
                                {biweeklyMowCount}
                              </p>
                            ) : (
                              "10"
                            )}
                          </Typography>
                        ) : (
                          <CircularProgress />
                        )}
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Mowing the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Blow out the lawn of dead leaves
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Trim out small plants upto 3ft tall
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Rake out the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Pickup small fallen branches
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "2%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Dethatch the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Throw away additional debris
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "2%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Areate the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Apply lime to the lawn
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Check fontSize="small" />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: matchesSm ? "14px" : "16px",
                              maxWidth: "99%",
                            }}
                          >
                            Spot seeding not included - can be added upon
                            request{" "}
                          </Typography>
                        </div>
                      </CardContent>
                      <button
                        id="Premium"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            selectSpring === "Premium" ? "#187CAE" : "#d3d3d3",
                          color: selectSpring === "Premium" ? "white" : "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          fontSize: "1.8vh",
                          height: "3vh",
                          cursor: "pointer",
                          minHeight: "40px",
                        }}
                        onClick={(e) =>
                          handleEdit(
                            e,
                            selectSpring,
                            setSelectSpring,
                            setPriceSpring,
                            getSpringPrice
                          )
                        }
                      >
                        {selectSpring === "Premium" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Selected{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Select"
                        )}
                      </button>
                    </Paper>
                  </Grid>

                  <br />

                  <div style={{ width: "100%" }}>
                    <Typography>
                      <>
                        <span>Click to read</span>
                        <span>
                          <Link
                            href="/ServiceAgreementSpring"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "#187CAE",
                              fontWeight: "bold",
                              fontSize: "15px",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "5px",
                              fontFamily: "poppins",
                            }}
                          >
                            Full contract details
                          </Link>{" "}
                        </span>
                        <span> - features and responsibilities </span>
                      </>
                    </Typography>
                  </div>
                </Grid>
              ) : null}

              {/* Mulch section */}
              {show && signUpPageSelected === "Mulch" ? (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    marginLeft: "2px",
                    paddingTop: matchesSm ? "2px" : "1%",
                  }}
                  justifyContent="center"
                  alignItems="baseline"
                >
                  {/* <Paper
                    style={{
                      display: "flex",
                      background: "red",
                      padding: "0% 1% 0% 1%",
                      color: "white",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Summer Special Savings, Limited Time Offer For The Month
                      Of July!
                    </Typography>
                  </Paper> */}

                  <Grid
                    xl={12}
                    lg={12}
                    sm={11}
                    xs={12}
                    md={12}
                    container
                    style={{
                      backgroundColor: "#cfe4e8",
                      width: "100%",
                      paddingTop: matchesSm ? "10px" : "1vh",
                    }}
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      style={{
                        width: "100%",
                        maxWidth: matchesSm
                          ? "100%"
                          : matchesMd
                            ? "80%"
                            : "80%",
                      }}
                      xl={12}
                      lg={12}
                      sm={10}
                      xs={12}
                      md={10}
                    >
                      <span
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "justify",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                        }}
                      >
                        Mulch is a layer of colored bark chips applied to the
                        surface of soil. Reasons for applying mulch include
                        conservation of soil moisture, improving fertility and
                        health of the soil, reducing weed growth and enhancing
                        the visual appeal of the area. It may be applied to bare
                        soil or around existing plants. The recommendation is 2
                        inch thick over existing mulch or 3 inch thick over bare
                        soil. If mulch is too thin, then weeds can push through.
                        If mulch is too thick, it prevents water from reaching
                        the soil. The process is usually straightforward, but
                        you should let Lawnber know to avoid areas with young
                        plants. As a reference 1 yard of mulch can cover
                        approximately 160 sqft at 2 inch thick. Service Date
                        will be scheduled once we have the material ordered.
                      </span>
                      <h6
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "1.5%",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                        }}
                      >
                        1. Select a preferred Plan
                      </h6>
                    </Grid>
                  </Grid>

                  {show && (
                    <Grid
                      lg={12}
                      sm={10}
                      xl={12}
                      xs={10}
                      md={11}
                      container
                      className={classes.gridForPlans}
                    >
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        md={5}
                        sm={9}
                        xs={12}
                        className={SignupClass.leftItem}
                        spacing={1}
                      >
                        <Paper elevation={5} style={{ maxWidth: "400px" }}>
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                lineHeight: "22px",
                                fontFamily: "poppins",
                                marginBottom: "2%",
                                fontWeight: "bold",
                              }}
                            >
                              Standard Plan
                            </Typography>

                            {getMulchPrice !== null ? (
                              <Typography
                                color="text.secondary"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "30px",
                                  fontFamily: "poppins",
                                }}
                              >
                                ${Number(getMulchPrice["Standard"]).toFixed(2)}
                              </Typography>
                            ) : (
                              <CircularProgress />
                            )}
                            <div
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <div style={{ display: "flex" }}>
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "#423e3e",
                                  }}
                                >
                                  Get coverage of 2 Yards
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Choose from a variety of colors: Brown, Black,
                                  Red.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Add color, control weeds, and improve soil
                                  quality.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Enhances soil quality for optimal plant
                                  growth.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Our service includes top-notch mulch,
                                  hassle-free delivery, and professional
                                  installation.
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                          <button
                            id="Standard"
                            variant="contained"
                            size="small"
                            style={{
                              margin: "2%",
                              marginBottom: "5%",
                              backgroundColor:
                                selectMulch === "Standard"
                                  ? "#187CAE"
                                  : "#d3d3d3",
                              color:
                                selectMulch === "Standard" ? "white" : "black",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              fontSize: "1.8vh",
                              border: "none",
                              minWidth: "100px",
                              width: "50%",
                              cursor: "pointer",
                              height: "3vh",
                              minHeight: "40px",
                            }}
                            onClick={(e) =>
                              handleEdit(
                                e,
                                selectMulch,
                                setSelectMulch,
                                setPriceMulch,
                                getMulchPrice
                              )
                            }
                          >
                            {selectMulch === "Standard" ? (
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                Selected{" "}
                                <Check
                                  fontSize="small"
                                  style={{ marginLeft: "25%" }}
                                />
                              </span>
                            ) : (
                              "Select"
                            )}
                          </button>
                        </Paper>
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        md={5}
                        sm={9}
                        xs={12}
                        className={SignupClass.leftItem}
                        spacing={1}
                      >
                        <Paper elevation={3} style={{ maxWidth: "400px" }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px 5px 0px 0px",
                              fontSize: "16px",
                              fontFamily: "poppins",
                              fontWeight: "bold",
                            }}
                          >
                            Most Popular
                          </div>
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                lineHeight: "22px",
                                fontFamily: "poppins",
                                marginBottom: "2%",
                                fontWeight: "bold",
                              }}
                            >
                              Premium Plan
                            </Typography>

                            {/* Premium Mulch */}
                            {getMulchPrice !== null ? (
                              <Typography
                                color="text.secondary"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "30px",
                                }}
                              >
                                <span>
                                  {" "}
                                  ${Number(getMulchPrice["Premium"]).toFixed(
                                    2
                                  )}{" "}
                                </span>
                              </Typography>
                            ) : (
                              <CircularProgress />
                            )}

                            <div
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <div style={{ display: "flex" }}>
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "#423e3e",
                                  }}
                                >
                                  Get coverage of 4 yards.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Choose from a variety of colors: Brown, Black,
                                  Red.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Enhance the appearance, control weeds, and
                                  improve soil health.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Improves soil health by retaining moisture,
                                  regulating temperature, and promoting
                                  nutrient-rich soil.
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "5%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Our service includes high-quality mulch,
                                  convenient delivery, and professional
                                  installation.
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                          <button
                            id="Premium"
                            variant="contained"
                            size="small"
                            style={{
                              margin: "2%",
                              marginBottom: "5%",
                              backgroundColor:
                                selectMulch === "Premium"
                                  ? "#187CAE"
                                  : "#d3d3d3",
                              color:
                                selectMulch === "Premium" ? "white" : "black",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              border: "none",
                              minWidth: "100px",
                              width: "50%",
                              fontSize: "1.8vh",
                              height: "3vh",
                              cursor: "pointer",
                              minHeight: "40px",
                            }}
                            onClick={(e) =>
                              handleEdit(
                                e,
                                selectMulch,
                                setSelectMulch,
                                setPriceMulch,
                                getMulchPrice
                              )
                            }
                          >
                            {selectMulch === "Premium" ? (
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                Selected{" "}
                                <Check
                                  fontSize="small"
                                  style={{ marginLeft: "25%" }}
                                />
                              </span>
                            ) : (
                              "Select"
                            )}
                          </button>
                        </Paper>
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={10}
                        sm={9}
                        xs={12}
                        spacing={2}
                        className={SignupClass.leftItem}
                      >
                        {
                          <Paper
                            elevation={3}
                            className={classes.responsiveThirdCardPlan}
                          >
                            <CardContent>
                              {
                                <>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    style={{
                                      lineHeight: "22px",
                                      fontFamily: "poppins",
                                      fontWeight: "bold",
                                      marginBottom: "3vh",
                                    }}
                                  >
                                    Mulch Color
                                  </Typography>

                                  {Mulch.map((key) => {
                                    return (
                                      <>
                                        <div
                                          id={key.mulchColor}
                                          key={key.id}
                                          // className={classes.addonCardLayout}
                                          style={{
                                            marginBottom: "1.5vh",
                                            borderRadius: "5px",
                                            background:
                                              selectedOptionMulch ===
                                                key.mulchColor
                                                ? "#187cae"
                                                : "#d3d3d3",
                                            color:
                                              selectedOptionMulch ===
                                                key.mulchColor
                                                ? "white"
                                                : "black",
                                          }}
                                          onClick={() => {
                                            setSelectedOptionMulch(
                                              key.mulchColor
                                            );
                                          }}
                                        >
                                          <div
                                            className={
                                              classes.fullWidthFlexCenter
                                            }
                                          >
                                            <img
                                              className={classes.addonImages}
                                              src={key.img}
                                              style={{ width: "58%" }}
                                            />
                                            <div style={{ marginLeft: "5%" }}>
                                              <Typography
                                                className={
                                                  classes.addonCardMainHeading
                                                }
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {key.mulchColor}
                                                {selectedOptionMulch ===
                                                  key.mulchColor && (
                                                    <Check
                                                      style={{
                                                        color: "white",
                                                        fontSize: "2.5vh",
                                                        marginLeft: "5%",
                                                      }}
                                                    />
                                                  )}
                                              </Typography>
                                              {key.mulchColor === "Red" && (
                                                <Typography
                                                  className={
                                                    classes.addonCardSubHeading
                                                  }
                                                >
                                                  {allPrices !== null ? (
                                                    <Typography
                                                      className={classes.addonCardSubHeading}
                                                      style={{
                                                        color:
                                                          selectedOptionMulch ===
                                                            key.mulchColor
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      +${allPrices["MulchRed"]}
                                                      /yd
                                                    </Typography>
                                                  ) : (
                                                    " "
                                                  )}
                                                </Typography>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              }
                            </CardContent>
                          </Paper>
                        }
                      </Grid>
                      {/* Addons Mulch : */}
                      <Grid
                        className={classes.responsiveFlex}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={11}
                      >
                        <Grid
                          item
                          lg={11}
                          xl={11}
                          md={11}
                          sm={9}
                          xs={11}
                          className={SignupClass.leftItem}
                          spacing={1}
                        >
                          {
                            <Paper elevation={3}>
                              <CardContent>
                                <>
                                  {/* Addons: */}
                                  <CardContent>
                                    <>
                                      <Typography
                                        variant="h6"
                                        component="div"
                                        className={classes.addonText}
                                      >
                                        Addons
                                      </Typography>
                                      <div>
                                        {mulchAddon !== null ? (
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              flexDirection: matcheslg
                                                ? "column"
                                                : "row",
                                              justifyContent: "space-between",
                                              height: "100%",
                                            }}
                                          >
                                            {Object.keys(mulchAddon).map(
                                              (key, i) => {
                                                return (
                                                  <>
                                                    <div
                                                      id={mulchAddon[key]}
                                                      variant="contained"
                                                      size="small"
                                                      style={{
                                                        alignItems: "center",
                                                        width: "100%",
                                                        margin: "3px",
                                                        marginBottom: matchesSm
                                                          ? "3%"
                                                          : "0%",
                                                        background:
                                                          selectedAddonsMulch[
                                                            key
                                                          ]
                                                            ? "#187cae"
                                                            : "#d3d3d3",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        height: "100%",
                                                        maxHeight: !matchesMd
                                                          ? "6.3rem"
                                                          : "",
                                                        minHeight: !matchesMd
                                                          ? "6.3rem"
                                                          : "",
                                                        maxWidth: !matcheslg
                                                          ? "19.4vw"
                                                          : "",
                                                      }}
                                                      onClick={() =>
                                                        handleSelect(
                                                          key,
                                                          Number(
                                                            mulchAddon[key]
                                                          ).toFixed(2),
                                                          setSelectedAddonsMulch,
                                                          setTotalMulchAddon,
                                                          selectedAddonsMulch
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          // justifyContent: "space-between",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                          width: "100%",
                                                          height: "100%",
                                                          fontSize: "1.7vh",
                                                          fontWeight: "bold",
                                                          width: "100%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            MulchAddonsObject[i]
                                                              .addonName ===
                                                            key &&
                                                            MulchAddonsObject[i]
                                                              .img
                                                          }
                                                          style={{
                                                            borderRadius: "5px",
                                                            width: "40%",
                                                            maxHeight: "13vh",
                                                            minHeight: "100px",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                          }}
                                                        />
                                                        <div
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                              alignItems:
                                                                "center",
                                                              width: matchesSm
                                                                ? "98%"
                                                                : "90%",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                margin: "3%",
                                                                width: "100%",
                                                                marginLeft:
                                                                  "5%",
                                                              }}
                                                            >
                                                              {key ===
                                                                "5 Additional Yards" ? (
                                                                <>
                                                                  <Typography
                                                                    className={classes.addonCardMainHeading}
                                                                    style={{
                                                                      color:
                                                                        selectedAddonsMulch[
                                                                          key
                                                                        ]
                                                                          ? "white"
                                                                          : "black",
                                                                    }}
                                                                  >
                                                                    {key}
                                                                  </Typography>
                                                                  <Typography
                                                                    className={classes.addonCardSubHeading}
                                                                    style={{
                                                                      color:
                                                                        selectedAddonsMulch[
                                                                          key
                                                                        ]
                                                                          ? "white"
                                                                          : "black",
                                                                    }}
                                                                  >
                                                                    $
                                                                    {Number(
                                                                      mulchAddon[
                                                                      key
                                                                      ]
                                                                    )}
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          "normal",
                                                                        marginLeft:
                                                                          "3px",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      Estimated
                                                                    </span>
                                                                    {selectedAddonsMulch[
                                                                      key
                                                                    ] && (
                                                                        <Check
                                                                          style={{
                                                                            color:
                                                                              "white",
                                                                            fontSize:
                                                                              "2.5vh",
                                                                            marginLeft:
                                                                              "5%",
                                                                          }}
                                                                        />
                                                                      )}
                                                                  </Typography>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <Typography
                                                                    className={classes.addonCardMainHeading}
                                                                    style={{
                                                                      color:
                                                                        selectedAddonsMulch[
                                                                          key
                                                                        ]
                                                                          ? "white"
                                                                          : "black",
                                                                    }}
                                                                  >
                                                                    {key}
                                                                  </Typography>
                                                                  <Typography
                                                                    className={classes.addonCardSubHeading}
                                                                    style={{
                                                                      color:
                                                                        selectedAddonsMulch[
                                                                          key
                                                                        ]
                                                                          ? "white"
                                                                          : "black",
                                                                    }}
                                                                  >
                                                                    $
                                                                    {Number(
                                                                      mulchAddon[
                                                                      key
                                                                      ]
                                                                    )}
                                                                    /Yd
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          "normal",
                                                                        marginLeft:
                                                                          "3px",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      Estimated
                                                                    </span>
                                                                    {selectedAddonsMulch[
                                                                      key
                                                                    ] && (
                                                                        <Check
                                                                          style={{
                                                                            color:
                                                                              "white",
                                                                            fontSize:
                                                                              "2.5vh",
                                                                            marginLeft:
                                                                              "5%",
                                                                          }}
                                                                        />
                                                                      )}
                                                                  </Typography>
                                                                </>
                                                              )}
                                                            </div>
                                                            <Tooltip
                                                              arrow
                                                              enterTouchDelay={0}
                                                              leaveTouchDelay={5000}
                                                              title={
                                                                <Typography
                                                                  style={{
                                                                    fontSize:
                                                                      "1.7vh",
                                                                    fontWeight:
                                                                      "bold",
                                                                    width:
                                                                      "100%",
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  {
                                                                    MulchAddonsObject[
                                                                      i
                                                                    ]
                                                                      .description
                                                                  }
                                                                </Typography>
                                                              }
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  fontSize:
                                                                    "3vh",
                                                                  color:
                                                                    selectedAddonsMulch[
                                                                      key
                                                                    ]
                                                                      ? "white"
                                                                      : "#187cae",
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          <CircularProgress />
                                        )}
                                      </div>
                                    </>
                                  </CardContent>
                                </>
                              </CardContent>
                            </Paper>
                          }
                        </Grid>
                      </Grid>
                      <br />

                      <div style={{ width: "100%" }}>
                        <Typography style={{ marginTop: "20px" }}>
                          <>
                            <span>Click to read</span>
                            <span>
                              <Link
                                href="/ServiceAgreementMulch"
                                target="_blank"
                                className={SignupClass.link}
                                style={{
                                  border: "none",
                                  color: "#187CAE",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  marginLeft: "1%",
                                  fontFamily: "poppins",
                                }}
                              >
                                Full contract details
                              </Link>{" "}
                            </span>
                            <span> - features and responsibilities </span>
                          </>
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </Grid>
              ) : null}

              {/*Hedge Trimming section */}
              {show && signUpPageSelected === "Hedge Trimming" ? (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                  }}
                  alignItems="center"
                >
                  <Grid
                    xl={12}
                    lg={12}
                    sm={11}
                    xs={12}
                    md={12}
                    container
                    style={{
                      backgroundColor: "#cfe4e8",
                      width: "100%",
                      paddingTop: matchesSm ? "10px" : "1vh",
                    }}
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={{
                        width: "100%",
                        maxWidth: matchesSm
                          ? "100%"
                          : matchesMd
                            ? "80%"
                            : "80%",
                      }}
                      xl={12}
                      lg={12}
                      sm={10}
                      xs={12}
                      md={10}
                    >
                      <span
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "0px",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "justify",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          marginTop: "1%",
                          paddingBottom: "3px",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                        }}
                      >
                        Summer offers an excellent opportunity to trim back any
                        overgrown bushes and hedges in your yard. If your
                        property is less than 12,000 square feet, take advantage
                        of Lawnber's special discount this month for hedge
                        trimming services. For properties greater than 12,000
                        square feet, simply press the "Get price" button, and we
                        will promptly email you the price within 24 hours.
                      </span>
                    </Grid>
                  </Grid>

                  {hedgeOverShoot === false ? (
                    <Grid
                      lg={12}
                      sm={10}
                      xl={12}
                      xs={11}
                      md={11}
                      container
                      style={{
                        width: "100%",
                        backgroundColor: "#cfe4e8",
                        paddingBottom: "10px",
                        marginLeft: matchesSm ? "2%" : "0px",
                      }}
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <h6
                        className={SignupClass.smallHeading}
                        style={{
                          marginTop: "1.5%",
                          marginLeft: matchesSm
                            ? "5%"
                            : matchesMd
                              ? "5%"
                              : "9vw",
                          textAlign: "left",
                          width: matchesSm ? "90%" : "100%",
                          marginBottom: "0px",
                          paddingBottom: "3px",
                        }}
                      >
                        1. Select a preferred Plan
                      </h6>
                      <Grid
                        item
                        lg={3}
                        md={5}
                        sm={9}
                        xl={3}
                        xs={12}
                        className={SignupClass.leftItem}
                        spacing={1}
                      >
                        <Paper elevation={3} style={{ maxWidth: "500px" }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px 5px 0px 0px",
                              fontSize: "16px",
                              fontFamily: "poppins",
                            }}
                          >
                            Most Popular
                          </div>
                          <CardContent>
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                lineHeight: "22px",
                                marginBottom: "5px",
                                fontFamily: "poppins",
                              }}
                            >
                              Premium Plan
                            </Typography>

                            {/* Biweekly */}
                            {getHedgePrice !== null ? (
                              <Typography
                                color="text.secondary"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "30px",
                                  alignItems: "center",
                                  display: "flex",
                                  marginBottom: "5px",
                                }}
                              >
                                <Box flexGrow={0.8} />
                                <span>
                                  {" "}
                                  ${Number(getHedgePrice["Premium"]).toFixed(
                                    2
                                  )}{" "}
                                </span>
                                <Box flexGrow={0.4} />
                                <Tooltip title="Do you like the price?">
                                  <ThumbUpIcon
                                    style={{
                                      color: localStorage.getItem(
                                        "BiWeeklyMowCount"
                                      )
                                        ? "#187cae"
                                        : "#a5a4a4",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        localStorage.getItem("BiWeeklyMowCount")
                                      ) {
                                        return;
                                      } else {
                                        setBiWeeklyMowCount(
                                          Number(biweeklyMowCount) + 1
                                        );
                                        updateDoc(thumbCount, {
                                          BiWeeklyMowCount:
                                            Number(biweeklyMowCount) + 1,
                                        });
                                        localStorage.setItem(
                                          "BiWeeklyMowCount",
                                          JSON.stringify(biweeklyMowCount)
                                        );
                                      }
                                    }}
                                  />
                                </Tooltip>
                                {biweeklyMowCount ? (
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "normal",
                                      paddingLeft: "6px",
                                    }}
                                  >
                                    {biweeklyMowCount}
                                  </p>
                                ) : (
                                  "10"
                                )}
                              </Typography>
                            ) : (
                              <CircularProgress />
                            )}
                            <div style={{ display: "flex" }}>
                              <Check fontSize="small" />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: matchesSm ? "14px" : "16px",
                                  maxWidth: "99%",
                                }}
                              >
                                We specialize in trimming hedges up to 8 feet
                                tall for a polished appearance.
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check fontSize="small" />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: matchesSm ? "14px" : "16px",
                                  maxWidth: "99%",
                                }}
                              >
                                Our comprehensive service includes removing all
                                debris and branches, leaving your yard pristine.
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check fontSize="small" />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: matchesSm ? "14px" : "16px",
                                  maxWidth: "99%",
                                }}
                              >
                                Email us your specific instructions and
                                conveniently attach pictures at{" "}
                                <a
                                  style={{
                                    textTransform: "none",
                                    // textDecoration: 'none'
                                  }}
                                // href="mailto:services@lawnber.com"
                                >
                                  services@lawnber.com.
                                </a>
                              </Typography>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Check fontSize="small" />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: matchesSm ? "14px" : "16px",
                                  maxWidth: "99%",
                                }}
                              >
                                Our plan does not include removing bushes
                              </Typography>
                            </div>
                          </CardContent>
                          <button
                            id="Premium"
                            variant="contained"
                            size="small"
                            style={{
                              margin: "2%",
                              marginBottom: "5%",
                              backgroundColor:
                                selectHedge === "Premium"
                                  ? "#187CAE"
                                  : "#d3d3d3",
                              color:
                                selectHedge === "Premium" ? "white" : "black",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              border: "none",
                              minWidth: "100px",
                              width: "50%",
                              fontSize: "1.8vh",
                              height: "3vh",
                              cursor: "pointer",
                              minHeight: "40px",
                            }}
                            onClick={(e) =>
                              handleEdit(
                                e,
                                selectHedge,
                                setSelectHedge,
                                setPriceHedge,
                                getHedgePrice
                              )
                            }
                          >
                            {selectHedge === "Premium" ? (
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                Selected{" "}
                                <Check
                                  fontSize="small"
                                  style={{ marginLeft: "25%" }}
                                />
                              </span>
                            ) : (
                              "Select"
                            )}
                          </button>
                        </Paper>
                      </Grid>

                      <br />

                      <div style={{ width: "100%" }}>
                        <Typography>
                          <>
                            <span>Click to read</span>
                            <span>
                              <Link
                                href="/ServiceAgreementHedge"
                                target="_blank"
                                className={SignupClass.link}
                                style={{
                                  border: "none",
                                  color: "#187CAE",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  fontFamily: "poppins",
                                }}
                              >
                                Full contract details
                              </Link>{" "}
                            </span>
                            <span> - features and responsibilities </span>
                          </>
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Grid
                      xl={12}
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      container
                      style={{
                        backgroundColor: "#cfe4e8",
                        width: "100%",
                        paddingLeft: "7%",
                        paddingRight: "7%",
                        paddingBottom: "7%",
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid container xl={12} alignItems="center">
                        <Grid item xs={12}>
                          <form
                            autoComplete="off"
                            style={{
                              display: "grid",
                              placeItems: "center",
                              padding: "3vh",
                              width: matchesSm ? "100%" : "60%",
                              margin: "3% auto 0",
                              background: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontFamily: "poppins",
                                textAlign: matchesSm ? "justify" : "center",
                                fontSize: "2.8vh",
                                lineHeight: "30px",
                              }}
                            >
                              Your property exceeds 12,000 sqft.{" "}
                              {!matchesSm && <br />}
                              We will provide you a quote within 24 hours.
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "poppins",
                                textAlign: "center",
                                marginTop: "1vh",
                                marginBottom: "2vh",
                              }}
                            >
                              Check the information below and add any comments,
                              Then press "GET QUOTE"
                            </Typography>
                            <Grid
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                              }}
                              lg={12}
                              item
                            >
                              <label
                                htmlFor="firstname"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder="Enter your name"
                                id="fname"
                                className="greyInput"
                                value={hedgeOverShootEmail["Name"]}
                                onChange={(e) => {
                                  setHedgeOverShootEmail((key) => ({
                                    ...key,
                                    Name: e.target.value,
                                  }));
                                }}
                              />
                            </Grid>
                            <Grid
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                              }}
                              lg={12}
                              item
                            >
                              <label
                                htmlFor="lastname"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                Email
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="lname"
                                className="greyInput"
                                value={hedgeOverShootEmail["Email"]}
                                onChange={(e) => {
                                  setHedgeOverShootEmail((key) => ({
                                    ...key,
                                    Email: e.target.value,
                                  }));
                                }}
                              ></input>
                            </Grid>
                            <Grid
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                              }}
                              lg={12}
                              item
                            >
                              <label
                                htmlFor="lastname"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                Request
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="lname"
                                className="greyInput"
                                style={{
                                  minHeight: "60px",
                                  whiteSpace: "nowrap",
                                }}
                                value={hedgeOverShootEmail["Content"]}
                                onChange={(e) => {
                                  setHedgeOverShootEmail((key) => ({
                                    ...key,
                                    Content: e.target.value,
                                  }));
                                }}
                              />
                              <button
                                className="buttonPrimary2"
                                id="submit"
                                style={{
                                  fontSize: "2vh",
                                  backgroundColor: "#187CAE",
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                  borderRadius: "5px",
                                  width: matchesSm ? "100%" : "30%",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                onClick={() => {
                                  sendHedgeOverShootEmail(
                                    "https://be-operation-services.herokuapp.com/Email"
                                    // "http://localhost:4008/Email",
                                  );
                                }}
                              >
                                GET QUOTE
                              </button>
                            </Grid>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : null}

              {/* Fall Cleanup */}
              {show && signUpPageSelected === "Fall Cleanup" && (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    marginLeft: "2px",
                    paddingTop: matchesSm ? "2px" : "1%",
                  }}
                  justifyContent="center"
                  alignItems="baseline"
                >
                  <Grid
                    lg={12}
                    sm={10}
                    xl={12}
                    xs={10}
                    md={11}
                    container
                    style={{
                      width: "100%",
                      backgroundColor: "#cfe4e8",
                      paddingBottom: "3%",
                    }}
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <span
                      className={SignupClass.smallHeading}
                      style={{
                        marginTop: "0px",
                        marginLeft: matchesSm ? "5%" : matchesMd ? "5%" : "2vw",
                        textAlign: "justify",
                        width: matchesSm ? "90%" : "80%",
                        marginBottom: "0px",
                        paddingBottom: "3px",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                      }}
                    >
                      Get your lawn ready for the fall season with our
                      comprehensive Fall Clean-Up Services. We'll clear away
                      fallen leaves, trim overgrown plants, and remove debris to
                      ensure a tidy and vibrant yard. Our expert team will help
                      your lawn breathe, absorb sunlight, and stay resilient
                      against pests and diseases. Prepare your outdoor space for
                      the changing seasons and enjoy a well-maintained landscape
                      throughout the year.
                    </span>
                    <br />
                    <h6
                      className={SignupClass.smallHeading}
                      style={{
                        marginTop: "20px",
                        marginLeft: matchesSm ? "5%" : matchesMd ? "5%" : "9vw",
                        textAlign: "left",
                        width: matchesSm ? "90%" : "100%",
                        marginBottom: "0px",
                        paddingBottom: "3px",
                      }}
                    >
                      {/* 1. Select a preferred start date and a Plan. */}
                      1. Select a preferred Plan
                    </h6>
                    <Grid
                      item
                      lg={4}
                      md={5}
                      sm={9}
                      xl={4}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                    >
                      <Paper elevation={3} style={{ maxWidth: "350px" }}>
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            style={{
                              lineHeight: "22px",
                              fontFamily: "poppins",
                              marginBottom: "2%",
                              fontWeight: "bold",
                            }}
                          >
                            Standard Plan
                          </Typography>

                          {getFallPrice !== null ? (
                            <Typography
                              color="text.secondary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                fontFamily: "poppins",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box flexGrow={0.4} />$
                              {Number(getFallPrice["Standard"]).toFixed(2)}
                              <Box flexGrow={0.4} />
                              {/* <Typography
                                style={{
                                  textDecoration: "line-through",
                                  fontSize: matchesSm ? "14px" : "16px",
                                }}
                              >
                                $
                                {Math.trunc(
                                  Number(getFallPrice["Standard"]) + 50
                                )}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: matchesSm ? "14px" : "16px",
                                  backgroundColor: "red",
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                  marginLeft: "5px",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                $50 savings
                              </Typography> */}
                            </Typography>
                          ) : (
                            <CircularProgress />
                          )}
                          <div
                            style={{ display: "grid", placeItems: "center" }}
                          >
                            {/* <div style={{ display: "flex" }}>
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  marginTop: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#423e3e",
                                }}
                              >
                                1 Time Fall Cleanup Service
                              </Typography>
                            </div> */}
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Blow out the lawn of dead leaves.
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Rake out the lawn.
                              </Typography>
                            </div>
                            {/* <div
                                style={{ display: "flex", marginRight: "auto" }}
                              >
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "3%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Bush Trimming and Plant Removal.
                                </Typography>
                              </div> */}
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Pick up small fallen branches.
                              </Typography>
                            </div>
                            {/* <div
                                style={{ display: "flex", marginRight: "auto" }}
                              >
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "3%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Throw away additional debris.
                                </Typography>
                              </div> */}
                          </div>
                        </CardContent>
                        <button
                          id="Standard"
                          variant="contained"
                          size="small"
                          style={{
                            margin: "2%",
                            marginBottom: "5%",
                            backgroundColor:
                              selectFall === "Standard" ? "#187CAE" : "#d3d3d3",
                            color:
                              selectFall === "Standard" ? "white" : "black",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            fontSize: "1.8vh",
                            border: "none",
                            minWidth: "100px",
                            width: "50%",
                            cursor: "pointer",
                            height: "3vh",
                            minHeight: "40px",
                          }}
                          onClick={(e) =>
                            handleEdit(
                              e,
                              selectFall,
                              setSelectFall,
                              setPriceFall,
                              getFallPrice
                            )
                          }
                        >
                          {selectFall === "Standard" ? (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Selected{" "}
                              <Check
                                fontSize="small"
                                style={{ marginLeft: "25%" }}
                              />
                            </span>
                          ) : (
                            "Select"
                          )}
                        </button>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={5}
                      sm={9}
                      xl={4}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                    >
                      <Paper elevation={8} style={{ maxWidth: "350px" }}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px 5px 0px 0px",
                            fontSize: "16px",
                            fontFamily: "poppins",
                            fontWeight: "bold",
                          }}
                        >
                          Most Popular
                        </div>
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            style={{
                              lineHeight: "22px",
                              fontFamily: "poppins",
                              marginBottom: "2%",
                              fontWeight: "bold",
                            }}
                          >
                            Premium Plan
                          </Typography>

                          {/* Premium Mulch */}
                          {getFallPrice !== null ? (
                            <Typography
                              color="text.secondary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Box flexGrow={0.5} />
                              <span>
                                {" "}
                                ${Number(getFallPrice["Premium"]).toFixed(
                                  2
                                )}{" "}
                              </span>
                              <Box flexGrow={0.4} />

                              {/* <Typography
                                style={{
                                  textDecoration: "line-through",
                                  fontSize: matchesSm ? "14px" : "16px",
                                }}
                              >
                                {" "}
                                $
                                {Math.trunc(
                                  Number(getFallPrice["Premium"]) + 100
                                )}
                              </Typography> */}
                              {/* <Typography
                                style={{
                                  fontSize: matchesSm ? "14px" : "16px",
                                  backgroundColor: "red",
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                  marginLeft: "5px",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                $100 savings
                              </Typography> */}
                            </Typography>
                          ) : (
                            <CircularProgress />
                          )}

                          <div
                            style={{ display: "grid", placeItems: "center" }}
                          >
                            {/* <div style={{ display: "flex" }}>
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  marginTop: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#423e3e",
                                }}
                              >
                                3 Time Fall Cleanup Service
                              </Typography>
                            </div> */}
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Blow out the lawn of dead leaves.
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Rake out the lawn.
                              </Typography>
                            </div>
                            {/* <div
                                style={{ display: "flex", marginRight: "auto" }}
                              >
                                <Check />
                                <Typography
                                  style={{
                                    marginBottom: "3%",
                                    textAlign: "left",
                                    fontFamily: "poppins",
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Bush Trimming and Plant Removal.
                                </Typography>
                              </div> */}
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Pick up small fallen branches.
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", marginRight: "auto" }}
                            >
                              <Check />
                              <Typography
                                style={{
                                  marginBottom: "3%",
                                  textAlign: "left",
                                  fontFamily: "poppins",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                Aerate the lawn
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                        <button
                          id="Premium"
                          variant="contained"
                          size="small"
                          style={{
                            margin: "2%",
                            marginTop: "5%",
                            marginBottom: "5%",
                            backgroundColor:
                              selectFall === "Premium" ? "#187CAE" : "#d3d3d3",
                            color: selectFall === "Premium" ? "white" : "black",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            border: "none",
                            minWidth: "100px",
                            width: "50%",
                            fontSize: "1.8vh",
                            height: "3vh",
                            cursor: "pointer",
                            minHeight: "40px",
                          }}
                          onClick={(e) =>
                            handleEdit(
                              e,
                              selectFall,
                              setSelectFall,
                              setPriceFall,
                              getFallPrice
                            )
                          }
                        >
                          {selectFall === "Premium" ? (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              Selected{" "}
                              <Check
                                fontSize="small"
                                style={{ marginLeft: "25%" }}
                              />
                            </span>
                          ) : (
                            "Select"
                          )}
                        </button>
                      </Paper>
                    </Grid>
                    <Grid
                      className={classes.responsiveFlex}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={11}
                    >
                      <Grid
                        item
                        lg={11}
                        xl={11}
                        md={11}
                        sm={9}
                        xs={12}
                        className={SignupClass.leftItem}
                        spacing={1}
                      >
                        {/* Addons Fall : */}
                        <Paper elevation={3} style={{ marginBottom: "1%" }}>
                          <CardContent>
                            <>
                              <Typography
                                variant="h6"
                                component="div"
                                className={classes.addonText}
                              >
                                Addons
                              </Typography>

                              <div>
                                {fallAddon !== null ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: matcheslg
                                        ? "column"
                                        : "row",
                                      justifyContent: "center",
                                      height: "100%",
                                    }}
                                  >
                                    {Object.keys(fallAddon).map((key, i) => {
                                      return (
                                        <>
                                          <div
                                            id={fallAddon[key]}
                                            variant="contained"
                                            size="small"
                                            style={{
                                              alignItems: "center",
                                              width: "100%",
                                              margin: "3px",
                                              marginBottom: matchesSm
                                                ? "3%"
                                                : "0%",
                                              background: selectedAddonsFall[
                                                key
                                              ]
                                                ? "#187cae"
                                                : "#d3d3d3",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                              display: "flex",
                                              height: "100%",
                                              maxHeight: !matchesMd
                                                ? "6.3rem"
                                                : "",
                                              minHeight: !matchesMd
                                                ? "6.3rem"
                                                : "",
                                              maxWidth: !matcheslg
                                                ? "20vw"
                                                : "",
                                            }}
                                            onClick={() =>
                                              handleSelect(
                                                key,
                                                fallAddon[key] !== 'TBD' ? Number(fallAddon[key]).toFixed(
                                                  2
                                                ) : 'TBD',
                                                setSelectedAddonsFall,
                                                setTotalFallAddon,
                                                selectedAddonsFall
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                // justifyContent: "space-between",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                width: "100%",
                                                height: "100%",
                                                fontSize: "1.7vh",
                                                fontWeight: "bold",
                                                width: "100%",
                                                textAlign: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  FallCleanupAddons[i]
                                                    .addonName === key &&
                                                  FallCleanupAddons[i].img
                                                }
                                                style={{
                                                  borderRadius: "5px",
                                                  width: "40%",
                                                  maxHeight: "13vh",
                                                  minHeight: "100px",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }}
                                              />
                                              <div style={{ width: "100%" }}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    width: matchesSm
                                                      ? "98%"
                                                      : "90%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      margin: "3%",
                                                      width: "100%",
                                                      marginLeft: "5%",
                                                    }}
                                                  >
                                                    <Typography
                                                      style={{
                                                        color:
                                                          selectedAddonsFall[
                                                            key
                                                          ]
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                      className={classes.addonCardMainHeading}
                                                    >
                                                      {key.includes("&") ? (
                                                        <>
                                                          {key
                                                            .split("&")
                                                            .map(
                                                              (part, index) => (
                                                                <Typography
                                                                  key={index}
                                                                  className={classes.addonCardMainHeading}
                                                                  style={{
                                                                    color:
                                                                      selectedAddonsFall[
                                                                        key
                                                                      ]
                                                                        ? "white"
                                                                        : "black",
                                                                  }}
                                                                >
                                                                  {index ===
                                                                    key.split("&")
                                                                      .length -
                                                                    1
                                                                    ? part
                                                                    : part +
                                                                    " & "}
                                                                </Typography>
                                                              )
                                                            )}
                                                        </>
                                                      ) : (
                                                        <>{key}</>
                                                      )}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.addonCardSubHeading}
                                                      style={{
                                                        color:
                                                          selectedAddonsFall[
                                                            key
                                                          ]
                                                            ? "white"
                                                            : "black",
                                                      }}
                                                    >
                                                      ${fallAddon[key] !== 'TBD' ? Number(fallAddon[key]) : 'TBD'}
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                          marginLeft: "3px",
                                                        }}
                                                      >
                                                        {" "}
                                                        Estimated
                                                      </span>
                                                      {selectedAddonsFall[
                                                        key
                                                      ] && (
                                                          <Check
                                                            style={{
                                                              color: "white",
                                                              fontSize: "2.5vh",
                                                              marginLeft: "5%",
                                                            }}
                                                          />
                                                        )}
                                                    </Typography>
                                                  </div>
                                                  <Tooltip
                                                    arrow
                                                    enterTouchDelay={0}
                                                    leaveTouchDelay={5000}
                                                    title={
                                                      <Typography
                                                        style={{
                                                          fontSize: "1.7vh",
                                                          fontWeight: "bold",
                                                          width: "100%",
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        {
                                                          FallCleanupAddons[i]
                                                            .description
                                                        }
                                                      </Typography>
                                                    }
                                                  >
                                                    <InfoIcon
                                                      style={{
                                                        fontSize: "3vh",
                                                        color:
                                                          selectedAddonsFall[
                                                            key
                                                          ]
                                                            ? "white"
                                                            : "#187cae",
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <CircularProgress />
                                )}
                              </div>
                              {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setfallAddonsPopup(!fallAddonsPopup);
                                }}
                                style={{
                                  margin: "2vh",
                                  textTransform: "capitalize",
                                }}
                              >
                                {fallAddonsPopup
                                  ? "Close Addons"
                                  : "Extra Addons"}
                              </Button>
                              {fallAddonsPopup && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: matchesMd ? "column" : "row",
                                    justifyContent: "space-between",
                                    height: "100%",
                                  }}
                                >
                                  {FallCleanupAdditionalAddons.map((addon) => (
                                    <div
                                      id={addon.id}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "3px",
                                        marginBottom: matchesSm ? "3%" : "0%",
                                        background:
                                          // selectedAddonsFall[key]
                                          //   ? "#187cae":
                                          "#d3d3d3",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          // justifyContent: "space-between",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "1.7vh",
                                          fontWeight: "bold",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <img
                                          src={addon.img}
                                          style={{
                                            borderRadius: "5px",
                                            width: "40%",
                                            maxHeight: "13vh",
                                            minHeight: "100px",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div style={{ width: "100%" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: matchesSm ? "98%" : "90%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                margin: "3%",
                                                width: "100%",
                                                marginLeft: "5%",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontSize: "2.5vh",
                                                  color:
                                                    // selectedAddonsFall[key]? "white":
                                                    "black",
                                                  fontWeight: "bold",
                                                  width: "100%",
                                                  minWidth: "90%",
                                                  whiteSpace: "nowrap",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: "2.5vh",
                                                    color:
                                                      // selectedAddonsFall[key]? "white":
                                                      "black",
                                                    fontWeight: "bold",
                                                    width: "100%",
                                                    minWidth: "90%",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {addon.addonName}
                                                </Typography>
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontSize: "1.7vh",
                                                  fontWeight: "bold",
                                                  width: "100%",
                                                  textAlign: "left",
                                                  color:
                                                    // selectedAddonsFall[key]? "white":
                                                    "black",
                                                }}
                                              >
                                                TBD
                                              </Typography>
                                            </div>

                                            <InfoIcon
                                              style={{
                                                fontSize: "3vh",
                                                color:
                                                  // selectedAddonsFall[key]? "white":
                                                  "#187cae",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )} */}
                            </>
                          </CardContent>
                        </Paper>
                      </Grid>
                    </Grid>
                    <br />

                    <div style={{ width: "100%" }}>
                      <Typography style={{ marginTop: "0px" }}>
                        <>
                          <span>Click to read</span>
                          <span>
                            <Link
                              href="/ServiceAgreementFall"
                              target="_blank"
                              className={SignupClass.link}
                              style={{
                                border: "none",
                                color: "#187CAE",
                                fontWeight: "bold",
                                fontSize: "15px",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginLeft: "1%",
                                fontFamily: "poppins",
                              }}
                            >
                              Full contract details
                            </Link>{" "}
                          </span>
                          <span> - features and responsibilities </span>
                        </>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              )}
            </>
            {/* --------------------------- */}

            {/* Show Enter details below only for active services */}
            {show &&
              (signUpPageSelected === "Lawn Mowing" ||
                signUpPageSelected === "Mulch" ||
                signUpPageSelected === "Spring Cleanup" ||
                signUpPageSelected === "Fall Cleanup" ||
                (signUpPageSelected === "Hedge Trimming" &&
                  hedgeOverShoot === false)) && (
                <>
                  {
                    <Grid
                      xl={12}
                      lg={12}
                      sm={10}
                      xs={12}
                      md={12}
                      container
                      style={{
                        marginTop: "1%",
                        width: "100%",
                      }}
                      alignItems="center"
                    >
                      <Grid item xl={6} lg={5} sm={10} xs={12} md={10}>
                        <h6
                          className={SignupClass.smallHeading}
                          style={{
                            marginLeft: matchesSm
                              ? "5%"
                              : matchesMd
                                ? "5%"
                                : "9vw",
                            textAlign: "left",
                            width: matchesSm ? "100%" : "250px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          2. Enter Account Details
                        </h6>
                      </Grid>

                      <Grid
                        style={{
                          width: "100%",
                          display: "flex",
                          marginLeft: "0%",
                          justifyContent: "center",
                          flexDirection:
                            matchesSm || matchesMd ? "column" : "row",
                          alignItems: "center",
                        }}
                        item
                        xl={6}
                        lg={6}
                        sm={11}
                        xs={12}
                        md={10}
                      >
                        <Button
                          onClick={googleSignIn}
                          endIcon={
                            <>
                              {googleSign && (
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 40 }}
                                />
                              )}
                            </>
                          }
                          startIcon={
                            <img
                              src={GoogleLogo}
                              alt="Google Logo"
                              style={{
                                marginRight: "10px",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          }
                          style={{
                            margin: "5px",
                            border: "2px solid black",
                            background: "white",
                            borderRadius: "4px",
                            maxHeight: "50px",
                            Width: "100%",
                            minWidth: "240px",
                            whiteSpace: "nowrap",
                            fontSize: matchesSm ? "0.9rem" : "1rem",
                            fontWeight: "bold",
                            color: "#333",
                            cursor: "pointer",
                            textTransform: "none",
                            fontFamily: "poppins",
                          }}
                        >
                          Use Google Account
                        </Button>
                        <Typography
                          variant={matchesSm ? "body2" : "h6"}
                          style={{
                            textAlign: "center",
                            margin: "2%",
                            fontWeight: "bold",
                            color: "#333",
                            fontFamily: "Poppins",
                          }}
                        >
                          Or
                        </Typography>
                        <Button
                          disabled={googleSign}
                          onClick={() =>
                            setIsClicked(
                              isClicked === "Manual" ? null : "Manual"
                            )
                          }
                          endIcon={
                            <>
                              {checkPassword.length !== 0 && (
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 40 }}
                                />
                              )}
                            </>
                          }
                          style={{
                            margin: "5px",
                            border: "2px solid black",
                            background: "white",
                            borderRadius: "4px",
                            maxHeight: "50px",
                            Width: "100%",
                            minWidth: "220px",
                            whiteSpace: "nowrap",
                            fontSize: matchesSm ? "0.9rem" : "1rem",
                            fontWeight: "bold",
                            color: "#333",
                            cursor: "pointer",
                            textTransform: "none",
                            fontFamily: "poppins",
                          }}
                        >
                          Enter Manually
                        </Button>
                      </Grid>

                      {isClicked === "Manual" && googleSign === false && (
                        <form
                          autoComplete="off"
                          className="detailsInput"
                          style={{ marginTop: "2%" }}
                        >
                          <div className={classes.masterFlexWithSpaceBetween}>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="firstname"
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                }}
                              >
                                Owner’s First Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="fname"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                                className="EnterDetailsInput"
                              ></input>
                            </div>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="lastname"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Owner’s Last Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="lname"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                                className="EnterDetailsInput"
                              ></input>
                            </div>
                          </div>
                          <div className={classes.masterFlexWithSpaceBetween}>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="phone"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Phone Number
                              </label>
                              <input
                                required
                                className="EnterDetailsInput"
                                type="tel"
                                id="address"
                                placeholder=""
                                value={phoneNumber}
                                onChange={(e) => {
                                  setphoneNumber(e.target.value);
                                }}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                              />
                            </div>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="email2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Address
                              </label>
                              <input
                                readOnly
                                value={prevAddress}
                                type="text"
                                id="email2"
                                placeholder=""
                                onChange={(e) => setCheckEmail(e.target.value)}
                                className="EnterDetailsInput"
                                style={{
                                  backgroundColor: "#E8F0FE",
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                              ></input>
                            </div>
                          </div>
                          <div className={classes.masterFlexWithSpaceBetween}>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="email"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Email
                              </label>
                              <input
                                required
                                autocomplete="off"
                                type="email"
                                value={email}
                                id="email"
                                placeholder=""
                                onChange={(e) => setEmail(e.target.value)}
                                className="EnterDetailsInput"
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                              ></input>
                            </div>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="email2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Re-Enter Email
                              </label>
                              <input
                                required
                                autocomplete="off"
                                type="email"
                                id="email2"
                                value={checkEmail}
                                placeholder=""
                                onChange={(e) => setCheckEmail(e.target.value)}
                                className="EnterDetailsInput"
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                              ></input>
                            </div>
                          </div>

                          {googleSign !== true && (
                            <div className={classes.masterFlexWithSpaceBetween}>
                              <div className={classes.subFlexWithSpaceBetween}>
                                <label
                                  htmlFor="password"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Password
                                </label>
                                <input
                                  required
                                  type="password"
                                  autocomplete="off"
                                  id="password2"
                                  placeholder="Atleast 6 characters"
                                  // value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className="EnterDetailsInput"
                                  style={{
                                    maxWidth: matchesSm
                                      ? "90%"
                                      : matchesMd
                                        ? "40%"
                                        : "170px",
                                    marginLeft: matchesSm ? "15%" : "0px",
                                  }}
                                ></input>
                              </div>
                              <div className={classes.subFlexWithSpaceBetween}>
                                <label
                                  htmlFor="password2"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Re-Enter Password
                                </label>
                                <input
                                  required
                                  type="password"
                                  id="password"
                                  autocomplete="off"
                                  placeholder="Atleast 6 characters"
                                  value={checkPassword}
                                  onChange={(e) =>
                                    setCheckPassword(e.target.value)
                                  }
                                  className="EnterDetailsInput"
                                  style={{
                                    maxWidth: matchesSm
                                      ? "90%"
                                      : matchesMd
                                        ? "40%"
                                        : "170px",
                                    marginLeft: matchesSm ? "15%" : "0px",
                                  }}
                                ></input>
                              </div>
                            </div>
                          )}
                        </form>
                      )}

                      {googleSign === true && (
                        <form
                          autoComplete="off"
                          className="detailsInput"
                          style={{ marginTop: "2%" }}
                        >
                          <div className={classes.masterFlexWithSpaceBetween}>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="firstname"
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                }}
                              >
                                Owner’s First Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="fname"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                                className="EnterDetailsInput"
                              ></input>
                            </div>
                            <div className={classes.subFlexWithSpaceBetween}>
                              <label
                                htmlFor="lastname"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Owner’s Last Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder=""
                                id="lname"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                                className="EnterDetailsInput"
                              ></input>
                            </div>
                          </div>
                          <div
                            className={classes.masterFlexWithSpaceBetween}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="phone"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Phone Number
                            </label>
                            <div
                              className={classes.subFlexWithSpaceBetween}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: matchesSm ? "0%" : "12%",
                              }}
                            >
                              <input
                                required
                                className="EnterDetailsInput"
                                type="tel"
                                id="address"
                                placeholder="Enter the phone number"
                                value={phoneNumber}
                                onChange={(e) => {
                                  setphoneNumber(e.target.value);
                                }}
                                style={{
                                  maxWidth: matchesSm
                                    ? "90%"
                                    : matchesMd
                                      ? "40%"
                                      : "170px",
                                  marginLeft: matchesSm ? "15%" : "0px",
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    </Grid>
                  }
                </>
              )}

            {/* Show Enter Payment Information below only for active services */}
            {show &&
              (signUpPageSelected === "Lawn Mowing" ||
                signUpPageSelected === "Mulch" ||
                signUpPageSelected === "Spring Cleanup" ||
                signUpPageSelected === "Fall Cleanup" ||
                (signUpPageSelected === "Hedge Trimming" &&
                  hedgeOverShoot === false)) && (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    marginTop: "2vh",
                    paddingBottom: matchesSm ? "20px" : "9vh",
                    paddingTop:
                      signUpPageSelected === "Spring Cleanup" ? "3vh" : "2vh",
                  }}
                  alignItems="center"
                >
                  <Grid item xl={6} lg={5} sm={10} xs={12} md={10}>
                    <h6
                      className={SignupClass.smallHeading}
                      style={{
                        marginTop: "0%",
                        paddingTop: "10px",
                        lineHeight: "100%",
                        marginLeft: matchesSm ? "5%" : matchesMd ? "5%" : "9vw",
                        textAlign: "left",
                        width: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      3. Payment Information
                    </h6>
                  </Grid>
                  <Grid
                    style={{
                      width: "100%",
                    }}
                    item
                    xl={6}
                    lg={6}
                    sm={10}
                    xs={12}
                    md={10}
                    justifyContent="center"
                  >
                    <form
                      style={{
                        marginTop: "0%",
                        width: "95%",
                        textAlign: "left",
                        height: "auto",
                        maxHeight: "40px",
                        marginLeft: matchesSm ? "3%" : "0%",
                        width: matchesSm ? "95%" : matchesMd ? "60%" : "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: "40px",
                          display: "grid",
                          placeItems: "center",
                          maxWidth: "520px",
                        }}
                        id="payment-form"
                        className="paymentElement"
                      >
                        <CardElement
                          id="payment-element"
                          className="EnterDetailsInputStripe"
                          options={{
                            hidePostalCode: true,
                          }}
                        />
                        {/* {isCardEmpty && <CheckCircleIcon style={{ color: 'green' }} />} */}

                        {/* Show any error or success messages */}
                        {message && (
                          <div id="payment-message" style={{ color: "red" }}>
                            {message}
                          </div>
                        )}
                      </div>
                    </form>
                  </Grid>
                </Grid>
              )}

            {/* --------- Shows appropriate messages for inactive services -------*/}
            <>
              {show && signUpPageSelected === "Customized Projects" && (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    padding: "7%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "2.5vh",
                        }}
                      >
                        Customized projects are for Lawnber customers to start
                        creating amazing landscape from dream to reality or if
                        there is special service requests to get price
                        estimates.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                        }}
                      >
                        To start a customized project, Please click on the
                        contact us button and use the form to provide us your
                        requirements. We will review your project, provide
                        different recommendations and get back to you promptly.
                      </Typography>
                    </Grid>
                    {/* Add login form and project description input here */}
                  </Grid>
                </Grid>
              )}

              {show && signUpPageSelected === "Multiple Homes" && (
                <Grid
                  xl={12}
                  lg={12}
                  sm={10}
                  xs={12}
                  md={12}
                  container
                  style={{
                    backgroundColor: "#cfe4e8",
                    width: "100%",
                    padding: "7%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xl={12} xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "2.5vh",
                        }}
                      >
                        To sign up for multiple homes, please follow these
                        steps:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ width: "100%" }}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                          width: "100%",
                        }}
                      >
                        1. Use the home screen "Instant Price" lookup feature to
                        check all your home addresses to get service prices for
                        each home.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                        }}
                      >
                        2. Once you decide to sign up for multiple homes, select
                        the primary home address again to begin the signup. You
                        will need to complete the account info and payment
                        method for the primary home address. Select the "Order"
                        button to complete the primary home address signup and
                        your account will be created.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                        }}
                      >
                        3. Once your account is created, you will receive a
                        welcoming email to confirm your account, and you will
                        have access to your account dashboard on the website.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                        }}
                      >
                        4. From your account dashboard website, you can now add
                        the second or third home addresses.
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                        }}
                      >
                        5. Finally, you can select the available services for
                        all your homes.
                        {/* Note: Depending on each home's location,
                      different service crews can be assigned to provide you
                      services. */}
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          fontFamily: "poppins",
                          textAlign: "justify",
                          fontSize: "1.85vh",
                          marginTop: "3%",
                        }}
                      >
                        Note: Depending on each home's location, different
                        service crews can be assigned to provide you services.
                        If you have any questions reach out to us using the
                        Contact us button
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
            {/* ------------------------------------------------------------------- */}

            {/* -- Shows appropriate messages when the conditions dont meet to provide a service --- */}
            <>
              {show === false && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                    height: "auto",
                    width: "100%",
                    textAlign: "left",
                    backgroundColor: "#187CAE",
                    fontFamily: "Poppins",
                    color: "white",
                    "@media (min-width:600px)": {
                      height: "auto",
                    },
                  }}
                >
                  <Typography variant="h5" style={{ fontFamily: "poppins" }}>
                    {infoCases[cases]}
                  </Typography>
                </div>
              )}
              {/* Shows enter details section so that customers who cant get service can signup */}
              {show === false && cases !== "case3" && (
                <h6
                  className={SignupClass.smallHeading}
                  style={{ marginTop: "2%" }}
                >
                  Enter Account Details
                </h6>
              )}
              {show === false && cases !== "case3" && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  spacing={1}
                  style={{ marginBottom: "2%" }}
                >
                  <Grid
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: matchesSm ? "3%" : matchesMd ? "5%" : "0%",
                    }}
                    item
                    xl={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    md={10}
                  >
                    <Button
                      onClick={googleSignIn}
                      endIcon={
                        <>
                          {googleSign && (
                            <CheckCircleIcon
                              style={{ color: "green", fontSize: 40 }}
                            />
                          )}
                        </>
                      }
                      startIcon={
                        <img
                          src={GoogleLogo}
                          alt="Google Logo"
                          style={{
                            marginRight: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      }
                      style={{
                        margin: "5px",
                        border: "2px solid black",
                        background: "white",
                        borderRadius: "4px",
                        maxHeight: "50px",
                        Width: "100%",
                        minWidth: "220px",
                        whiteSpace: "nowrap",
                        fontSize: matchesSm ? "0.9rem" : "1rem",
                        fontWeight: "bold",
                        color: "#333",
                        cursor: "pointer",
                        textTransform: "none",
                        fontFamily: "poppins",
                      }}
                    >
                      Use Google Account
                    </Button>
                    <Typography
                      variant={matchesSm ? "body2" : "h6"}
                      style={{
                        textAlign: "center",
                        margin: "2%",
                        fontWeight: "bold",
                        color: "#333",
                        fontFamily: "Poppins",
                      }}
                    >
                      Or
                    </Typography>
                    <Button
                      onClick={() =>
                        setIsClicked(isClicked === "Manual" ? null : "Manual")
                      }
                      endIcon={
                        <>
                          {checkPassword.length !== 0 && (
                            <CheckCircleIcon
                              style={{ color: "green", fontSize: 40 }}
                            />
                          )}
                        </>
                      }
                      style={{
                        margin: "5px",
                        border: "2px solid black",
                        background: "white",
                        borderRadius: "4px",
                        maxHeight: "50px",
                        Width: "100%",
                        minWidth: "220px",
                        whiteSpace: "nowrap",
                        fontSize: matchesSm ? "0.9rem" : "1rem",
                        fontWeight: "bold",
                        color: "#333",
                        cursor: "pointer",
                        textTransform: "none",
                        fontFamily: "poppins",
                      }}
                    >
                      Enter Manually
                    </Button>
                  </Grid>
                  {isClicked === "Manual" && googleSign === false && (
                    <form
                      autoComplete="off"
                      className="detailsInput"
                      style={{ marginTop: "2%" }}
                    >
                      <div className={classes.masterFlexWithSpaceBetween}>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="firstname"
                            style={{ whiteSpace: "nowrap", textAlign: "left" }}
                          >
                            Owner’s First Name
                          </label>
                          <input
                            required
                            type="text"
                            placeholder=""
                            id="fname"
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                            className="EnterDetailsInput"
                          ></input>
                        </div>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="lastname"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Owner’s Last Name
                          </label>
                          <input
                            required
                            type="text"
                            placeholder=""
                            id="lname"
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                            className="EnterDetailsInput"
                          ></input>
                        </div>
                      </div>
                      <div className={classes.masterFlexWithSpaceBetween}>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="phone"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Phone Number
                          </label>
                          <input
                            required
                            className="EnterDetailsInput"
                            type="tel"
                            id="address"
                            placeholder=""
                            value={phoneNumber}
                            onChange={(e) => {
                              setphoneNumber(e.target.value);
                            }}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                          />
                        </div>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="email2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Address
                          </label>
                          <input
                            readOnly
                            value={prevAddress}
                            type="email"
                            id="email2"
                            placeholder=""
                            onChange={(e) => setCheckEmail(e.target.value)}
                            className="EnterDetailsInput"
                            style={{
                              backgroundColor: "#E8F0FE",
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className={classes.masterFlexWithSpaceBetween}>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="email"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Email
                          </label>
                          <input
                            required
                            autocomplete="off"
                            type="email"
                            value={email}
                            id="email"
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                            className="EnterDetailsInput"
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                          ></input>
                        </div>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="email2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Re-Enter Email
                          </label>
                          <input
                            required
                            autocomplete="off"
                            type="email"
                            id="email2"
                            value={checkEmail}
                            placeholder=""
                            onChange={(e) => setCheckEmail(e.target.value)}
                            className="EnterDetailsInput"
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                  ? "40%"
                                  : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                          ></input>
                        </div>
                      </div>
                      {googleSign !== true && (
                        <div className={classes.masterFlexWithSpaceBetween}>
                          <div className={classes.subFlexWithSpaceBetween}>
                            <label
                              htmlFor="password"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Password
                            </label>
                            <input
                              required
                              type="password"
                              autocomplete="off"
                              id="password2"
                              placeholder="Atleast 6 characters"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="EnterDetailsInput"
                              style={{
                                maxWidth: matchesSm
                                  ? "90%"
                                  : matchesMd
                                    ? "40%"
                                    : "170px",
                                marginLeft: matchesSm ? "15%" : "0px",
                              }}
                            ></input>
                          </div>
                          <div className={classes.subFlexWithSpaceBetween}>
                            <label
                              htmlFor="password2"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Re-Enter Password
                            </label>
                            <input
                              required
                              type="password"
                              id="password"
                              autocomplete="off"
                              placeholder="Atleast 6 characters"
                              value={checkPassword}
                              onChange={(e) => setCheckPassword(e.target.value)}
                              className="EnterDetailsInput"
                              style={{
                                maxWidth: matchesSm
                                  ? "90%"
                                  : matchesMd
                                    ? "40%"
                                    : "170px",
                                marginLeft: matchesSm ? "15%" : "0px",
                              }}
                            ></input>
                          </div>
                        </div>
                      )}
                    </form>
                  )}
                  {/* {googleSign === true && (
                    <form
                      autoComplete="off"
                      className="detailsInput"
                      style={{ marginTop: "2%" }}
                    >
                      <div className={classes.masterFlexWithSpaceBetween}>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="firstname"
                            style={{ whiteSpace: "nowrap", textAlign: "left" }}
                          >
                            Owner’s First Name
                          </label>
                          <input
                            required
                            type="text"
                            placeholder=""
                            id="fname"
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                ? "40%"
                                : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                            className="EnterDetailsInput"
                          ></input>
                        </div>
                        <div className={classes.subFlexWithSpaceBetween}>
                          <label
                            htmlFor="lastname"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Owner’s Last Name
                          </label>
                          <input
                            required
                            type="text"
                            placeholder=""
                            id="lname"
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                ? "40%"
                                : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                            className="EnterDetailsInput"
                          ></input>
                        </div>
                      </div>
                      <div
                        className={classes.masterFlexWithSpaceBetween}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label htmlFor="phone" style={{ whiteSpace: "nowrap" }}>
                          Phone Number
                        </label>
                        <div
                          className={classes.subFlexWithSpaceBetween}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: matchesSm ? "0%" : "12%",
                          }}
                        >
                          <input
                            required
                            className="EnterDetailsInput"
                            type="tel"
                            id="address"
                            placeholder="Enter the phone number"
                            value={phoneNumber}
                            onChange={(e) => {
                              setphoneNumber(e.target.value);
                            }}
                            style={{
                              maxWidth: matchesSm
                                ? "90%"
                                : matchesMd
                                ? "40%"
                                : "170px",
                              marginLeft: matchesSm ? "15%" : "0px",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  )} */}
                </Grid>
              )}
              {/* Shows Create account button to signup */}
              {show === false && cases !== "case3" && (
                <button
                  className="buttonPrimary2"
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
                  style={{
                    fontSize: "1.8vh",
                    backgroundColor: "#187CAE",
                    marginTop: "10%",
                  }}
                  onClick={handleProceed}
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      "Create Account"
                    )}
                  </span>
                </button>
              )}
            </>
            {/* ------------------------------------- */}
          </Grid>
          {/* ------ Left grid item end ------  */}

          {show && <Box flexGrow={1} />}

          {/* ------ Right grid item start ------  */}
          {show === true && (
            <Grid
              xs={12}
              md={3}
              lg={3}
              xl={3}
              className="rightGrid"
              justifyContent="center"
              alignItems="center"
            >
              <h6
                style={{
                  fontWeight: "bolder",
                  color: "white",
                  fontSize: "2vh",
                }}
              >
                Order Summary
              </h6>

              {/* map */}
              {show ? (
                <div
                  style={{
                    height: "140px",
                    marginBottom: "2%",
                    marginTop: "2%",
                  }}
                >
                  <LoadScript
                    key={loadKey}
                    id="script-loader"
                    googleMapsApiKey={"AIzaSyCIsPXTWDGe-Zw4QO-3l7AYdv6duGr-JRs"}
                    language="en"
                    region="us"
                    libraries={googleMapsLibraries}
                  >
                    <GoogleMap
                      mapContainerClassName="newSchedulemaps"
                      map="map"
                      center={{ lat: lati, lng: lngi }}
                      zoom={20}
                      version="weekly"
                      mapTypeId="roadmap"
                      maxZoom={20}
                      options={{
                        mapTypeId: "satellite",
                        zoomControl: false,
                        rotateControl: false,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          background: "grey",
                          width: "100%",
                          height: "3vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                            paddingLeft: "10px",
                            justifyContent: "space-between",
                            width: "95%",
                          }}
                        >
                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "600",
                              fontSize: "1.6vh",
                              paddingLeft: "3%",
                            }}
                          >
                            <LocationOn style={{ fontSize: "1.6vh" }} />{" "}
                            {splitAddr[0]}
                          </h6>

                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "600",
                              fontSize: "1.6vh",
                              paddingLeft: "3%",
                            }}
                          >
                            <SquareFoot style={{ fontSize: "1.6vh" }} />{" "}
                            {propertyDetails["TotalArea"]} Sqft
                          </h6>
                        </div>
                      </div>
                    </GoogleMap>
                  </LoadScript>
                </div>
              ) : (
                <CircularProgress />
              )}

              {/* {subscription !== "" && (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "3%",
                      paddingLeft: "10px",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.6vh",
                        paddingLeft: "3%",
                      }}
                    >
                      Driveway Type
                    </h6>
                    <h6
                      style={{
                        color: "#ECECEC",
                        fontWeight: "600",
                        fontSize: "1.4vh",
                        paddingLeft: "3%",
                      }}
                    >
                      {selectDrv}
                    </h6>
                  </div>
                )} */}
              {/* {subscription !== "" && (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "3%",
                      paddingLeft: "10px",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.6vh",
                        paddingLeft: "3%",
                      }}
                    >
                      Corner House
                    </h6>
                    <h6
                      style={{
                        color: "#ECECEC",
                        fontWeight: "600",
                        fontSize: "1.6vh",
                        paddingLeft: "3%",
                      }}
                    >
                      {cornerHouse}
                    </h6>
                  </div>
                )}
                {subscription !== "" && (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "3%",
                      paddingLeft: "10px",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.6vh",
                        paddingLeft: "3%",
                      }}
                    >
                      {"Slope Driveway > 10°"}
                    </h6>
                    <h6
                      style={{
                        color: "#ECECEC",
                        fontWeight: "600",
                        fontSize: "1.6vh",
                        paddingLeft: "3%",
                      }}
                    >
                      {slopeDrv}
                    </h6>
                  </div>
                )}
                
              </div>
               */}

              {showServiceSel &&
                (signUpPageSelected === "Lawn Mowing" ||
                  signUpPageSelected === "Mulch" ||
                  signUpPageSelected === "Spring Cleanup" ||
                  signUpPageSelected === "Fall Cleanup" ||
                  (signUpPageSelected === "Hedge Trimming" &&
                    hedgeOverShoot === false)) ? (
                <h6
                  className={classes.title}
                  style={{
                    color: "#ECECEC",
                    fontWeight: "600",
                    display: "flex",
                    fontSize: "1.8vh",
                    marginLeft: "10px",
                    paddingLeft: "3%",
                    marginTop: "6%",
                  }}
                >
                  Services Selected
                </h6>
              ) : null}

              {showServiceSel ? (
                <div className={classes.showSelectedDiv}>
                  {selectSpring !== "" ? (
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        alignContent: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "500",
                          fontSize: "1.6vh",
                          paddingLeft: "3%",
                        }}
                      >
                        {selectSpring.length > 0 && selectSpring + " (Spring)"}
                      </h6>

                      {selectSpring !== "" && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getSpringPrice !== null
                            ? `$${Number(getSpringPrice[selectSpring]).toFixed(
                              2
                            )}`
                            : null}
                        </h6>
                      )}
                    </div>
                  ) : null}

                  {select != "" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          {select}(Lawn)
                        </h6>

                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getLawnPrice !== null
                            ? `$${Number(getLawnPrice[select]).toFixed(2)}`
                            : null}
                        </h6>
                      </div>
                      {totalLawnAddon > 0 && (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "10px",
                            paddingLeft: "10px",
                            alignContent: "center",
                            justifyContent: "space-between",
                            width: "90%",
                          }}
                        >
                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "400",
                              fontSize: "1.6vh",
                              paddingLeft: "3%",
                            }}
                          >
                            Addons(Lawn)
                          </h6>

                          {totalLawnAddon > 0 && (
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.3vh",
                              }}
                            >
                              {Object.keys(selectedAddonsLawn)
                                .filter((key) => selectedAddonsLawn[key])
                                .map((addon) => {
                                  return (
                                    <p style={{ textAlign: "right" }}>
                                      {addon} (${Number(lawnAddon[addon])})
                                    </p>
                                  );
                                })}
                            </h6>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {selectFall !== "" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          {selectFall}(Fall)
                        </h6>
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getFallPrice !== null
                            ? `$${Number(getFallPrice[selectFall]).toFixed(2)}`
                            : null}
                        </h6>
                      </div>
                      {totalFallAddon > 0 || (fallAddon['Bush Trim & Plant Removal'] === 'TBD' && selectedAddonsFall['Bush Trim & Plant Removal']) ? (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "10px",
                            paddingLeft: "10px",
                            alignContent: "center",
                            justifyContent: "space-between",
                            width: "90%",
                          }}
                        >
                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "400",
                              fontSize: "1.6vh",
                              paddingLeft: "3%",
                            }}
                          >
                            Addons(Fall)
                          </h6>

                          {totalFallAddon > 0 || (fallAddon['Bush Trim & Plant Removal'] === 'TBD' && selectedAddonsFall['Bush Trim & Plant Removal']) ? (
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.3vh",
                              }}
                            >
                              {Object.keys(selectedAddonsFall)
                                .filter((key) => selectedAddonsFall[key])
                                .map((addon) => {
                                  return (
                                    <p style={{ textAlign: "right" }}>
                                      {addon} (${fallAddon[addon] !== 'TBD' ? Number(fallAddon[addon]) : fallAddon[addon]})
                                    </p>
                                  );
                                })}
                            </h6>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}

                  {selectMulch != "" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          Mulch Color
                        </h6>

                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {selectedOptionMulch}
                        </h6>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          Total Yards selected
                        </h6>

                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {totalYardsSelected}
                        </h6>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          {selectMulch}(Mulch)
                        </h6>

                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getMulchPrice !== null
                            ? `$${Number(getMulchPrice[selectMulch]).toFixed(
                              2
                            )}`
                            : null}
                        </h6>
                      </div>

                      {selectedOptionMulch === "Red" && (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "10px",
                            paddingLeft: "10px",
                            alignContent: "center",
                            justifyContent: "space-between",
                            width: "90%",
                          }}
                        >
                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "500",
                              fontSize: "1.6vh",
                              paddingLeft: "3%",
                            }}
                          >
                            Addon - Red Mulch
                          </h6>

                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "600",
                              fontSize: "1.6vh",
                            }}
                          >
                            ${redMulchAddon}
                          </h6>
                        </div>
                      )}

                      {Object.values(selectedAddonsMulch).some(
                        (value) => value
                      ) && (
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "3%",
                              paddingLeft: "10px",
                              alignContent: "center",
                              justifyContent: "space-between",
                              width: "90%",
                            }}
                          >
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.3vh",
                              }}
                            >
                              Addons(Mulch)
                            </h6>

                            {Object.values(selectedAddonsMulch).some(
                              (value) => value
                            ) && (
                                <h6
                                  className={classes.title}
                                  style={{
                                    color: "#ECECEC",
                                    fontWeight: "600",
                                    fontSize: "1.3vh",
                                  }}
                                >
                                  {Object.keys(selectedAddonsMulch)
                                    .filter((key) => selectedAddonsMulch[key])
                                    .map((addon) => {
                                      return (
                                        <p style={{ textAlign: "right" }}>
                                          {addon} ($
                                          {addon === "5 Additional Yards"
                                            ? Number(mulchAddon[addon])
                                            : Number(mulchAddon[addon]) *
                                            totalYardsSelected}
                                          )
                                        </p>
                                      );
                                    })}
                                </h6>
                              )}
                          </div>
                        )}

                      <div
                        style={{
                          display: "flex",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          alignContent: "center",
                          justifyContent: "space-between",
                          width: "90%",
                        }}
                      >
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.6vh",
                            paddingLeft: "3%",
                          }}
                        >
                          Est. Sales Tax
                        </h6>

                        {allPrices !== null && (
                          <h6
                            className={classes.title}
                            style={{
                              color: "#ECECEC",
                              fontWeight: "600",
                              fontSize: "1.6vh",
                            }}
                          >
                            ${mulchSalesTax}
                          </h6>
                        )}
                      </div>
                    </>
                  )}

                  {selectHedge !== "" && hedgeOverShoot === false ? (
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        alignContent: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "500",
                          fontSize: "1.6vh",
                          paddingLeft: "3%",
                        }}
                      >
                        {selectHedge.length > 0 && selectHedge + " (Hedge)"}
                      </h6>

                      {selectHedge !== "" && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getHedgePrice !== null
                            ? `$${Number(getHedgePrice[selectHedge]).toFixed(
                              2
                            )}`
                            : null}
                        </h6>
                      )}
                    </div>
                  ) : null}

                  {selectDrv !== "" ? (
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        alignContent: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "500",
                          fontSize: "1.6vh",
                          paddingLeft: "3%",
                        }}
                      >
                        {subscription.length > 0 &&
                          subscription === "SeasonPayFull"
                          ? "Snow Season Cleanup"
                          : subscription === "One-Time"
                            ? "One-Time"
                            : ""}
                      </h6>

                      {subscription !== "" && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {getSnowPrice !== null
                            ? `$${Number(getSnowPrice[subscription]).toFixed(
                              2
                            )}`
                            : null}
                        </h6>
                      )}
                    </div>
                  ) : null}

                  {price !== 0 && select ? (
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        alignContent: "center",
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "400",
                          fontSize: "1.6vh",
                          paddingLeft: "3%",
                        }}
                      >
                        Service Charge
                      </h6>

                      {getLawnPrice !== null && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          {select !== ""
                            ? `$${Number(getLawnPrice["Surcharge"]).toFixed(2)}`
                            : `$${0.0}`}
                        </h6>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {showServiceSel && (
                <Divider
                  variant="middle"
                  style={{
                    background: "#B9B3B3",
                    width: "90%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
              )}

              {show &&
                (signUpPageSelected === "Lawn Mowing" ||
                  signUpPageSelected === "Mulch" ||
                  signUpPageSelected === "Spring Cleanup" ||
                  signUpPageSelected === "Fall Cleanup" ||
                  (signUpPageSelected === "Hedge Trimming" &&
                    hedgeOverShoot === false)) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginLeft: "10px",
                      marginBottom: "2%",
                      width: "90%",
                    }}
                  >
                    {show && (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "400",
                          fontSize: "2vh",
                          fontWeight: "bold",
                        }}
                      >
                        Order Total
                      </h6>
                    )}

                    {getLawnPrice !== null ? (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "2.5vh",
                        }}
                      >
                        $
                        {(
                          (selectSpring !== "" ? Number(priceSpring) : 0) +
                          (selectFall !== ""
                            ? Number(priceFall) + Number(totalFallAddon)
                            : 0) +
                          (selectMulch !== "" ? Number(priceMulch) : 0) +
                          (selectHedge !== "" ? Number(priceHedge) : 0) +
                          (select !== ""
                            ? Number(price) + Number(totalLawnAddon)
                            : 0) +
                          (subscription !== "" ? Number(priceSnow) : 0)
                        ).toFixed(2)}
                      </h6>
                    ) : (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "3vh",
                          marginRight: "6%",
                        }}
                      >
                        <CircularProgress
                          size={30}
                          style={{
                            color: "white",
                            alignItems: "center",
                            margin: "auto",
                          }}
                        />
                      </h6>
                    )}
                  </div>
                )}

              <div
              // style={
              //   showContract
              //     ? { height: "75px", overflowY: "scroll", width: "100%" }
              //     : { width: "100%" }
              // }
              >
                {select !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: matchesSm ? "15px" : "15px",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          checked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.4vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                        margin: "0%",
                      }}
                    >
                      <>
                        I Agree to the
                        <span>
                          <Link
                            href="/ServiceAgreementLawn"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Lawn Service Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
                {subscription !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={isCheckedSnow}
                          onChange={() => setIsCheckedSnow(!isCheckedSnow)}
                          value="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementSnow"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Snow Cleanup Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
                {selectSpring !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={isCheckedSpring}
                          onChange={() => setIsCheckedSpring(!isCheckedSpring)}
                          value="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementSpring"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Spring Cleanup Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
                {selectFall !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={isCheckedFall}
                          onChange={() => setIsCheckedFall(!isCheckedFall)}
                          value="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementFall"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Fall Cleanup Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
                {selectMulch !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={isCheckedMulch}
                          onChange={() => setIsCheckedMulch(!isCheckedMulch)}
                          value="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementMulch"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Mulch Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
                {selectHedge !== "" && hedgeOverShoot !== true && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      // color="primary"
                      control={
                        <Checkbox
                          sx={{
                            color: isChecked ? "white" : "white",
                            "& .MuiSvgIcon-root": {
                              color: isChecked ? "white" : "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={isCheckedHedge}
                          onChange={() => setIsCheckedHedge(!isCheckedHedge)}
                          value="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementHedge"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "6px",
                              fontFamily: "poppins",
                            }}
                          >
                            Hedge Trimming Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                )}
              </div>

              {show &&
                (signUpPageSelected === "Lawn Mowing" ||
                  signUpPageSelected === "Mulch" ||
                  signUpPageSelected === "Spring Cleanup" ||
                  signUpPageSelected === "Fall Cleanup" ||
                  (signUpPageSelected === "Hedge Trimming" &&
                    hedgeOverShoot === false)) ? (
                <div id="payment-form" style={{ marginLeft: "20px" }}>
                  <button
                    className="buttonPrimary2"
                    disabled={isLoading || !stripe || !elements}
                    onClick={handleProceed}
                    id="submit"
                    style={{
                      fontSize: "2vh",
                      backgroundColor: "#187CAE",
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    <span id="button-text">
                      {isLoading ? (
                        <div className="spinner" id="spinner"></div>
                      ) : (
                        "Place Order"
                      )}
                    </span>
                  </button>
                </div>
              ) : null}

              {show && (
                <Card
                  style={{
                    margin: "2%",
                    marginTop: "20px",
                    width: matchesSm ? "93%" : "95%",
                    padding: "2%",
                    backgroundColor: "f0f2f2",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "black",
                      fontWeight: "400",
                      fontSize: "1.3vh",
                      textAlign: "justify",
                      textTransform: "none",
                      marginLeft: matchesSm ? "0%" : "2%",
                      marginBottom: "3%",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Note 1:
                    </span>{" "}
                    Risk-free order with no charges. Service will be charged
                    after completion. <br />
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "black",
                      fontWeight: "400",
                      fontSize: "1.3vh",
                      textAlign: "justify",
                      textTransform: "none",
                      marginTop: "3%",
                      marginLeft: matchesSm ? "0%" : "2%",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Note 2:
                    </span>{" "}
                    Extra 25% charge will be applied when the actual grass
                    height exceeds 10 inches
                  </h6>
                </Card>
              )}
            </Grid>
          )}
          {/* ------ Right grid item end ------  */}
        </Grid>
        {/* ------ Parent grid end ------  */}
      </div>
    </>
  );
}

export default Signup_;
