/* eslint-disable */
//                      Login.js
// Purpose: This is the file for Login page.
// Also included with form validator which checks the access level and navigates to the page accordingly

// importing React native components
import React, { useState, useContext, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

//import functionalities
import { UserContext } from "../../components/UserContext";
import { auth, db } from "../../components/Firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import useStyles from "../Dashboard/Styles";
import { Alert } from "@mui/material";
import GoogleLogo from "../../components/assets/images/google-logo.jpeg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import {
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import Logo from "../../components/assets/images/lawnberlogo2.png";

//import styling components
import "./Login.css";
import { Helmet } from "react-helmet";
import Popup from "../../components/Popup";

function Login() {
  const [user, setUser] = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const history = useHistory();
  const [alertopen, setAlertOpen] = React.useState(true);
  const [error, setError] = React.useState();
  const classes = useStyles();
  const provider = new GoogleAuthProvider();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState({});
  const location = useLocation();
  // const { status } = location.state;
  const status = location.state?.status ?? "";

  // Dialog box variables
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState("");
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState("");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloses = () => {
    setAnchorEl(null);
  };

  const handlePageTop = (event) => {
    // console.log(event.target.id)
    // setSignUpPageSelected(event.currentTarget.getAttribute('value'));
    setAnchorEl(null);
  };

  useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          const UserAcct = await getDoc(doc(db, "UserAcct", user.uid));
          if (UserAcct && UserAcct.exists()) {
            setUserDetails({
              name: UserAcct.data().FirstName + " " + UserAcct.data().LastName,
            });
          }
        } else {
          setUser(null);
          // console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Function executed when signIn button is pressed
  async function SignInButton(e) {
    e.preventDefault();
    setUser(user);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (status.length > 0) {
          history.push(`/dashboard?service=${status}`);
        } else {
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/Invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
          case "auth/wrong-password":
            showAutoClosePopup('Error', 'Email or Password is incorrect', 3000)
            break;
          default:
        }
      });
  }

  const GoogleSignIn = async (event) => {
    event.preventDefault();
    try {
      const { user } = await signInWithPopup(auth, provider);

      const userRef = doc(db, "UserAcct", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // Set the user data in the state or perform necessary actions
        if (status) {
          history.push(`/dashboard?service=${status}`);
        } else {
          history.push("/dashboard");
        }
      } else {
        // User data not found, handle the case accordingly
        // alert("User not found");
        showAutoClosePopup('Error', 'User not found', 3000)
        return;
      }
    } catch (error) {
      // Handle the error in a user-friendly way
      console.error(error);
    }
  };

  //Checks Email Format
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleForgotPassword = () => {
    const auth = getAuth();
    if (validateEmail(recoveryEmail) === false) {
      // toast.error("Wrong Email format", {
      //   autoClose: 2000,
      // }
      // );
      showAutoClosePopup('Invalid Email', 'Enter valid email', 3000)
      setOpen(false);
      return;
    } else {
      sendPasswordResetEmail(auth, recoveryEmail)
        .then(() => {
          setOpen(false);
          // toast.info("Password reset email sent");
          showAutoClosePopup('Info', 'Password reset email sent', 3000)
          setRecoveryEmail("");
        })
        .catch((error) => {
          const errorMessage = error.message;
          // alert(errorMessage);
          showAutoClosePopup('Error', `${errorMessage}`, 3000)
        });
    }
  };

  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  return (
    <div className="login" style={{ backgroundColor: "white" }}>
      {/* Header for Seo */}
      <Helmet>
        <title>Lawnber: Login Page</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="This is our Login Page to enter the dashboard. Please enter your email address and password to Login."
        />
        <link rel="canonical" href="https://lawnber.com/login" />
        <meta
          property="og:image"
          content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
        />
        <meta property="og:title" content="Lawnber" />
        <meta
          property="og:description"
          content="Lawnber provides convenient and professional lawn care
                  services at affordable prices. Schedule your service today
                  with a local lawn care expert."
        />
        <meta property="og:url" content="https://lawnber.com/login" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ToastContainer />

      {/* Popup DialogBox */}
      <Popup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        title={popupTitles}
        content={popupContent}
        actions={popupActions}
        alert
      />

      <AppBar
        color="white"
        elevation={3}
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar style={{ display: "flex", alignItems: "center" }}>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>

          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            <Button
              id="demo-positioned-button"
              onClick={handleDropDown}
              style={{
                marginRight: "1rem",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: matchesSm ? "0.9rem" : matchesMd ? "1rem" : "1.1rem",
                color: "black",
              }}
              endIcon={<ArrowDropDownIcon />}
            >
              Services
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloses}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ marginTop: "3%" }}
            >
              <MenuItem
                value="Lawn Service"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                    ? "0.9rem"
                    : "1rem",
                }}
                onClick={handlePageTop}
              >
                Lawn Service
              </MenuItem>
              <MenuItem
                value="Snow Service"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                    ? "0.9rem"
                    : "1rem",
                }}
                onClick={handlePageTop}
              >
                Snow Service
              </MenuItem>
              <MenuItem
                value="Spring Cleanup"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                    ? "0.9rem"
                    : "1rem",
                }}
                onClick={handlePageTop}
              >
                Spring Cleanup
              </MenuItem>
            </Menu>
          </Box>
          {!matchesSm && (
            <Button
              variant="contained"
              color="secondary"
              aria-label="Contact Us"
              onClick={() => history.push("/contact")}
              style={{
                marginRight: "1rem",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: matchesSm ? "0.9rem" : matchesMd ? "0.9rem" : "1rem",
                backgroundColor: "#187cae",
              }}
            >
              Contact Us
            </Button>
          )}

          <Box display="flex" alignItems="center">
            <Box sx={{ flexGrow: 0 }}>
              {
                <Tooltip title="Open More">
                  <div
                    className="navLink"
                    onClick={handleOpenUserMenu}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <AccountCircleIcon
                      sx={{ color: "#187CAE", fontSize: 45 }}
                    />
                    {userDetails["name"]
                      ? userDetails["name"].split(" ")[0]
                      : ""}
                  </div>
                </Tooltip>
              }
              <Menu
                // sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {matchesSm && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                        ? "0.9rem"
                        : "1rem",
                    }}
                    onClick={() => history.push("/Contact")}
                  >
                    Conatct US
                  </MenuItem>
                )}
                {user && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                        ? "0.9rem"
                        : "1rem",
                    }}
                    onClick={() => history.push("/Dashboard")}
                  >
                    Dashboard
                  </MenuItem>
                )}
                {user && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                        ? "0.9rem"
                        : "1rem",
                    }}
                    onClick={() => history.push("/profile")}
                  >
                    Profile
                  </MenuItem>
                )}
                {user && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                        ? "0.9rem"
                        : "1rem",
                    }}
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          setUser(null);
                          localStorage.removeItem("user");
                          history.push("/");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Sign Out
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="login_info">
        <h1 style={{ marginBottom: "50px", marginTop: "20px", color: "black" }}>
          Sign in to your account
        </h1>
        <form className="login_form">
          <label htmlFor="email" style={{ textAlign: "left" }}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            style={{
              maxWidth: "300px",
              fontWeight: "600",
              minWidth: "200px",
              width: "90%",
              borderRadius: "28px",
              minHeight: "40px",
              paddingLeft: "4%",
            }}
            placeholder="Enter the Email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Enter the Password (Min. 6 letter)"
            style={{
              maxWidth: "300px",
              fontWeight: "600",
              minWidth: "200px",
              width: "90%",
              borderRadius: "28px",
              minHeight: "40px",
              paddingLeft: "4%",
            }}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button className="buttonPrimary2" onClick={SignInButton}>
            {" "}
            Sign in{" "}
          </button>


          <div>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              <h4> Forgot Password? </h4>
            </div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <h3>Reset Password</h3>
              </DialogTitle>
              <DialogContent>
                <h4>
                  Enter your email address below and we'll send you a link to
                  reset your password
                </h4>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: {
                      fontWeight: "600",
                      fontSize: "50",
                      fontFamily: "Poppins",
                    },
                  }}
                  InputProps={{
                    style: {
                      fontWeight: "500",
                      fontSize: "40",
                      fontFamily: "Poppins",
                    },
                  }}
                  onChange={(e) => setRecoveryEmail(e.target.value)}
                />
                <h5>Note: Check Spam folder for verification email</h5>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                  onClick={handleForgotPassword}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <button
            className="signup-with-google-button"
            onClick={GoogleSignIn}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px 16px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
              cursor: "pointer",
              margin: "0 auto",
              marginTop: "2%",
            }}
          >
            <img
              src={GoogleLogo}
              alt="Google Logo"
              width="30"
              height="30"
              style={{
                marginRight: "10px",
                width: "30px",
                height: "30px",
              }}
            />
            Sign in with Google
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
