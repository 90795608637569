import {
	Grid, Typography, Container, makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';


export const useStyles = makeStyles(() => ({
	footerTitle: {
		fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'left'
	},
	footerContent: {
		fontFamily: 'Poppins',
		textAlign: 'left',
		cursor: 'pointer',
		textDecoration: 'none',
		display: 'flex',
		justifyContent: 'flexStart',
		fontSize: '1rem',
		transform: 'none',
		transition: 'none',
		lineHeight: '1.5', // or whatever value you want
		color: 'grey', // Set the default link color


		'&:hover': {
			textDecoration: 'none',
			color: '#187cae',
			fontSize: '1rem',
			transform: 'none',
			transition: 'none',
			lineHeight: '1.5', // or whatever value you want

		},
	}
}));

const FooterHome = ({ title1, content1, title2, links, title3, services, disclaimer, handleServiceChange, handleInstantQuote }) => {
	const classes = useStyles();
	return (
		<Grid
			lg={12}
			xs={12}
			sm={12}
			xl={12}
			style={{
				flexDirection: "column",
				backgroundColor: "#d0e3e7",
				padding: "3vh",
				justifyContent: "center",
			}}>
			<Container maxWidth="lg">
				<Grid container spacing={3} justifyContent="space-between" wrap='wrap'>
					<Grid item xs={12} sm={6} md={4} >
						<Typography variant="h5" gutterBottom className={classes.footerTitle}>
							{title1}
						</Typography>
						<Typography variant="subtitle1" color="textSecondary" style={{
							fontFamily: 'Poppins',
							textAlign: 'left',
							cursor: 'pointer',
							textDecoration: 'none',
							display: 'flex',
							justifyContent: 'flexStart',
						}} >
							{content1}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} style={{ maxWidth: '150px', marginRight: '10%' }}>
						<Typography variant="h6" gutterBottom className={classes.footerTitle}>
							{title2}
						</Typography>
						{links.map((link) => (
							<a
								href={link.href}
								className={classes.footerContent}
							>
								<span>{link.text}</span>
							</a>
						))}
						<Typography variant="subtitle1" color="textSecondary" className={classes.footerContent} onClick={handleInstantQuote}>
							Instant Quote
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} style={{ maxWidth: '160px', marginRight: '10%' }}>
						<Typography variant="h6" gutterBottom className={classes.footerTitle}>
							{title3}
						</Typography>
						{services.map((service, index) => (
							<Typography key={index} value={service} variant="subtitle1" color="textSecondary" className={classes.footerContent} onClick={handleServiceChange}>
								{service}
							</Typography>
						))}
					</Grid>
					<Typography variant="body3" color="textSecondary" align="left" gutterBottom style={{ fontFamily: 'poppins', marginTop: '2%' }}>
						{disclaimer}
					</Typography>
				</Grid>
				<Typography variant="body2" color="textSecondary" align="center" gutterBottom style={{ fontFamily: 'poppins', marginTop: '2%', fontWeight: 'bold' }}>
					{`© Copyright Lawnber, ${new Date().getFullYear()} All Rights Reserved`}
				</Typography>
			</Container>
		</Grid>
	);
};

const FooterBlog = ({ title1, content1, title2, content2, flag, URL }) => {
	const classes = useStyles();
	return (
		<Grid
			lg={12}
			xs={12}
			sm={12}
			xl={12}
			style={{
				flexDirection: "column",
				backgroundColor: "#d0e3e7",
				padding: "3vh",
				justifyContent: "center",
				marginTop: '2%'
			}}>
			<Container maxWidth="lg">
				<Grid container spacing={3} justifyContent="space-between" wrap='wrap'>
					<Grid item xs={12} sm={6} md={4} >
						<Typography variant="h6" gutterBottom className={classes.footerTitle}>
							{title1}
						</Typography>
						<Typography variant="subtitle1" color="textSecondary" style={{
							fontFamily: 'Poppins',
							textAlign: 'left',
							cursor: 'pointer',
							textDecoration: 'none',
							display: 'flex',
							justifyContent: 'flexStart',
						}} >
							{content1}
						</Typography>
					</Grid>

					{content2 !== null && <Grid item xs={12} sm={6} md={4} style={{ maxWidth: '100%', marginRight: '10%' }}>
						<Typography variant="h6" gutterBottom className={classes.footerTitle}>
							{title2}
						</Typography>
						{content2.map((link, index) => (
							<Typography key={index} variant="subtitle1" color="textSecondary" className={classes.footerContent} href={flag !== 'Home' ? `${URL}/Home/${link.title.split(' ').join('-')}` : `${link.title.split(' ').join('-')}`} component='a'>
								<span>{link.title}</span>
							</Typography>
						))}
					</Grid>}
				</Grid>
				<Typography variant="body2" color="textSecondary" align="center" gutterBottom style={{ fontFamily: 'poppins', marginTop: '2%', fontWeight: 'bold' }}>
					{`© Copyright Lawnber, ${new Date().getFullYear()} All Rights Reserved`}
				</Typography>
			</Container>
		</Grid>
	);
};

export { FooterHome, FooterBlog };
