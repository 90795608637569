import React from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Carousel from "react-elastic-carousel";
import rev1 from "../../components/assets/images/review-1.jpg";
import rev2 from "../../components/assets/images/review-2.jpg";
import rev3 from "../../components/assets/images/review-3.jpg";
import srev1 from "../../components/assets/images/snowRev1.jpeg";
import srev2 from "../../components/assets/images/snowRev2.jpeg";
import srev3 from "../../components/assets/images/snowRev3.jpeg";
import { Rating } from "@mui/material";
import { useStyles } from "./HomeStyles";

function CustomerReviews({ isWinter }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 850, itemsToShow: 1 },
    { width: 1250, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 2, itemsToScroll: 1 },
    { width: 1900, itemsToShow: 3 },
  ];
  return (
    <Carousel
      breakPoints={breakPoints}
      focusOnSelect={true}
    >
      <div
        style={{
          backgroundImage: `url(${isWinter ? srev1 : rev1})`,
          width: "600px",
          height: "400px",
          padding: "2px",
          opacity: "0.8",
          borderRadius: "8px",
        }}
      >
        <div className={classes.textDiv}>
          <div style={{ marginTop: matchesSm ? "10%" : "3%", paddingBottom: "2%", alignContent: 'center' }}>

            <h2
              style={{
                float: "left",
                marginTop: "-20px",
                fontFamily: "Poppins",
                fontSize: "13px",
                marginLeft: "10px",
                fontWeight: "600",
              }}
            >
              Nathan
            </h2>
            <Rating
              name="half-rating-read"
              defaultValue={4}
              precision={0.5}
              readOnly
              size="small"
              style={{ float: "right", top: "-20px" }}
              sx={{
                fontSize: { sm: "1rem" },
              }}
            />
          </div>
          <Typography className={classes.reviewHeading}>
            It took less than 10 minutes for me to schedule a{" "}
            {isWinter ? "snow removal service" : "lawn mowing service"} online
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "13px",
              fontWeight: "normal",
              textAlign: "left",
              marginTop: "5px",
              marginLeft: "5px",
            }}
          >
            “I like to do everything online these days and don’t want to get
            close contact with strangers. This is the only{" "}
            {isWinter ? "snow removal service" : "lawn mowing service"} on L.I.
            that gives instant pricing online and cheaper than my last guys who
            quit on me. Consider tipping these hard working folks who take care
            our neighborhoods.”
          </Typography>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${isWinter ? srev2 : rev2})`,
          width: "600px",
          height: "400px",
          padding: "2px",
          opacity: "0.8",
          borderRadius: "8px",
        }}
      >
        <div className={classes.textDiv}>
          <div style={{ marginTop: matchesSm ? "10%" : "3%", paddingBottom: "2%" }}>
            <h2
              style={{
                float: "left",
                marginTop: "-20px",
                fontFamily: "Poppins",
                fontSize: "13px",
                marginLeft: "10px",
                fontWeight: "600",
              }}
            >
              Pamala
            </h2>
            <Rating
              name="half-rating-read"
              defaultValue={5}
              precision={0.5}
              readOnly
              size="small"
              style={{ float: "right", top: "-20px" }}
              sx={{
                fontSize: { sm: "1rem" },
              }}
            />
          </div>
          <Typography className={classes.reviewHeading}>
            Everything was easy and got my{" "}
            {isWinter ? "snow removed" : "lawn mowed"} as promised
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "13px",
              fontWeight: "normal",
              textAlign: "left",
              marginTop: "5px",
              marginLeft: "5px",
            }}
          >
            "So easy and no waste of time for some guy to call you back,
            schedule a visit, then give you a high price expecting you to
            negotiate it down! Who needs that and the{" "}
            {isWinter ? "snow" : "lawn"} crew did a fantastic job. Additionally,
            their service is prompt and professional. I highly recommend."
          </Typography>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${isWinter ? srev3 : rev3})`,
          width: "600px",
          height: "400px",
          padding: "2px",
          opacity: "0.8",
          borderRadius: "8px",
        }}
      >
        <div className={classes.textDiv}>
          <div style={{ marginTop: matchesSm ? "10%" : "3%", paddingBottom: "2%" }}>

            <h2
              style={{
                float: "left",
                marginTop: "-20px",
                fontFamily: "Poppins",
                fontSize: "13px",
                marginLeft: "10px",
                fontWeight: "600",
              }}
            >
              Derick
            </h2>
            <Rating
              name="half-rating-read"
              defaultValue={4.5}
              precision={0.5}
              readOnly
              size="small"
              style={{ float: "right", top: "-20px" }}
              sx={{
                fontSize: { sm: "1rem" },
              }}
            />
          </div>
          <Typography className={classes.reviewHeading}>
            It took less than 10 minutes for me to schedule a{" "}
            {isWinter ? "snow removal service" : "lawn mowing service"} online
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "13px",
              fontWeight: "normal",
              textAlign: "left",
              marginTop: "5px",
              marginLeft: "5px",
            }}
          >
            “I like to do everything online these days and don’t want to get
            close contact with strangers. This is the only{" "}
            {isWinter ? "snow removal service" : "lawn mowing service"} on L.I.
            that gives instant pricing online and cheaper than my last guys who
            quit on me. Consider tipping these hard working folks who take care
            our neighborhoods.”
          </Typography>
        </div>
      </div>
    </Carousel>
  );
}

export default CustomerReviews;
