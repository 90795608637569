import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: "100%"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: 'black',
		width: '100%',
		margin: 'auto',
	},
	agreementText: {
		textAlign: "left",
		marginTop: '2em',
		marginLeft: '5%',
		marginRight: '5%',
		fontSize: '18px'
	},
	heading: {
		fontWeight: 'bold',
	},
	fullScreenGrid: {
		width: "100%",
		height: "100%"
	}
}));

const ServiceAgreementSnow = () => {
	const classes = useStyles();

	return (
		<>
			<ToastContainer />
			<div className={classes.root}>
				<Grid container spacing={2} className={classes.fullScreenGrid}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<div>
								<h2>Service Agreement</h2>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> The Service Includes:</Typography>
									<ul>
										<li>Based on your order, the snow cleaning service can be either One-Full-Season subscription or One-Time only service.</li>
										<li>If the order is a One-Full-Season subscription, it includes unlimited snowfall event cleanups from the signup date to the end of the winter season. It also includes unlimited snow accumulation in each snowfall event.</li>
										<li>If the order is a One-Time only service, it includes one snowfall cleanup of snow accumulation from 2 to 12 inches. Additional snow accumulation above 13 inches will be an extra 25% surcharge.</li>
										<li>Snowfall events ending with an average accumulation of less than 2in will not trigger the cleaning service.</li>
										<li>Snow cleaning areas include paved driveway, sidewalk, pathway plus steps to the front door.</li>
										<li>Snow Salt is not included and applied by the customer as needed</li>
										<li>Price may change if the property information provided by the customer during signup is different than the actual property</li>
										<li>Unpaved driveway surface cannot be serviced and will automatically cancel this agreement. If the signup fee is paid upfront, the full refund will be applied.</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText} >
									<Typography variant='p' className={classes.heading}> Customer Responsibilities:</Typography>
									<ul>
										<li>Ensure all areas including driveway, sidewalks, pathways and steps are clear of obstacles, or else Lawnber crew will exclude cleaning around the obstacle areas.</li>
										<li>Ensure car(s) are not on the driveway to allow snow cleaning</li>
										<li>Place large signs to indicate any area that should not be serviced by snow cleaning equipment to avoid damages. Make sure the markers are high above the snow with high visibility.</li>
										<li>Ensure all cleaning surfaces are in good condition free from large cracks, potholes, tripping hazards and uneven paver brick level. Repair surface as needed before the snow cleaning service, or else the hazards can damage snow equipment and your property.</li>
										<li>Ensure no people and no pets are outside during the cleaning service to avoid accidents.</li>
										<li>After the snow is cleared, ensure to apply ice melt products on icy surfaces and re-apply as needed.</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> Lawnber And Service Crew Are  Not Responsible For:</Typography>
									<ul>
										<li>Not responsible to remove any obstacles and objects on or around the cleaning areas and will skip those areas.</li>
										<li>Not responsible for any accidents, damages and injuries from the snow cleaning service. Lawnber crew are professional and will do their best to prevent accidents.</li>
										<li>Not responsible to clean snow off the cars.</li>
										<li>Not responsible for spreading rock salt on ground surfaces.</li>
										<li>Not responsible for delays due to unforeseen situations, traffic and road closures.</li>
									</ul>
								</p>
							</div>

							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}>Indemnifications</Typography>
								</p>
								<p className={classes.agreementText} style={{ marginTop: '1em' }}>
									<Typography variant='p' className={classes.heading}>The Customer Agrees Not To Hold Lawnber And Its Crew Liable For The Following:</Typography>
									<ul>
										<li>Not liable for any property damages due to unintentional accidents from all services including mowing, trimming, blowing, digging, snow clearing and shoveling. Property damages can be considered house exterior structures such as walls, siding, roofing, windows, doors, utility pipes. House interior furniture. Outdoor furniture, all types of hard ground surface and edging, fence structure, lawns, gardens, plants and sheds.</li>
										<li>Not liable for any person or pet injuries due to slippery falls. Lawnber does not provide ice melt salt service on snow and ice grounds. Surface can freeze and refreeze and therefore the customer should always ensure the sidewalks, pathways and stairs are safe to walk on.
										</li>
										<li>Not liable for any person or pet injuries from equipment accidents while Lawnber crew are working in the outdoor areas. All people and pets should stay inside the house during the service period.</li>
										<li>Not liable for any vehicle damages due to unintentional accidents from all services including mowing, trimming, blowing, digging, snow clearing and shoveling.</li>
									</ul>
								</p>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</div>

		</>
	)
}

export { ServiceAgreementSnow }