import React from "react";
import PropTypes from "prop-types";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
import logo from "./assets/images/lawnber_new.png";
import { Close } from "@material-ui/icons";
import {
	Box,
	DialogContentText,
	Divider,
	LinearProgress,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

const Popup = ({
	open,
	onClose,
	title,
	content,
	actions,
	fullWidth,
	alert,
	toast,
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const [toastMessage, setToastMessage] = React.useState();
	const [progress, setProgress] = React.useState(1);

	React.useEffect(() => {
		if (toast) {
			setToastMessage(true);
			findProgress();
			const timer = setTimeout(() => {
				setToastMessage(false);
			}, 3000);
			return () => {
				clearInterval(timer);
			};
		}
	}, []);

	const findProgress = () => {
		const id = setInterval(() => {
			setProgress((progress) => {
				console.log("progress", progress);
				if (progress >= 100) {
					clearInterval(id);
					return progress;
				} else {
					return Math.min(progress + Math.random() * 5, 100);
				}
			});
		}, 50);
	};

	return (
		<>
			{toastMessage && (
				<Dialog maxWidth="md" open={open} onClose={onClose}>
					{title && (
						<>
							<DialogTitle
								style={{
									display: "flex",
									alignItems: "center",
									color: "#212121",
									fontWeight: "500",
									justifyContent: "space-between",
								}}
							>
								<span style={{ display: "flex", alignItems: "center" }}>
									<img
										src={logo}
										alt="logo"
										style={{
											width: matchesSm ? "7%" : "5%",
											height: matchesSm ? "7%" : "5%",
											marginRight: "3%",
										}}
									/>
									{title}
								</span>
								<Close onClick={() => onClose} />
							</DialogTitle>
							<Divider />
						</>
					)}
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{content}
						</DialogContentText>
					</DialogContent>
					<Box style={{ width: "100%" }}>
						<LinearProgress variant="determinate" value={progress} />
					</Box>
				</Dialog>
			)}
			{alert && (
				<Dialog maxWidth="md" open={open} onClose={onClose}>
					{title && (
						<>
							<DialogTitle
								style={{
									display: "flex",
									alignItems: "center",
									color: "#212121",
									fontWeight: "500",
									justifyContent: "space-between",
								}}
							>
								<span style={{ display: "flex", alignItems: "center" }}>
									<img
										src={logo}
										alt="logo"
										style={{
											width: matchesSm ? "7%" : "5%",
											height: matchesSm ? "7%" : "5%",
											marginRight: "3%",
										}}
									/>
									{title}
								</span>
								<Close style={{
									cursor: 'pointer'
								}}
									onClick={onClose} />
							</DialogTitle>
							<Divider />
						</>
					)}
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{content}
						</DialogContentText>
					</DialogContent>
					{actions && (
						<DialogActions>
							{actions?.map((action, index) => (
								<Button
									key={index}
									onClick={action.onClick}
									variant={action.variant}
									color={action.color}
									size={action.size}
									style={{ textTransform: "capitalize", fontWeight: "bold" }}
								>
									{action.label}
								</Button>
							))}
						</DialogActions>
					)}
				</Dialog>
			)}
		</>
	);
};

Popup.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	content: PropTypes.node,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			color: PropTypes.oneOf([
				"inherit",
				"primary",
				"secondary",
				"info",
				"success",
				"warning",
				"error",
			]),
			variant: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	),
};

export default Popup;
