import calendar from "../components/assets/images/date 1.png";
import clipboard from "../components/assets/images/Jobd 1.png";
import receipt from "../components/assets/images/Price 1.png";
import locationIcon from "../components/assets/images/Group 755 1.png";
import SpotSeeding from "../components/assets/images/SpotSeeding.webp";
import BagClipping from "../components/assets/images/BagClippings.jpg";
import PoolEdging from "../components/assets/images/PoolEdging.jpg";
import BushTrimming from "../components/assets/images/BushTrimming.jpg";
import Overseeding from "../components/assets/images/OverSeedingFertilizer.jpg";
import BagOfMulch from "../components/assets/images/BagOfMulch.jpg";
import MulchEdging from "../components/assets/images/MulchEdging.jpg";
import MulchWeeding from "../components/assets/images/MulchWeeds.jpg";

const landingImages = [
  {
    id: 0,
    Imgsrc:
      "https://express-images.franklymedia.com/6222/sites/41/2021/02/11152222/logo_wsmy.png",
    Link: "https://lifestyle.roanokenewstalk.com/story/48556669/lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 1,
    Imgsrc:
      "https://ogardensmart.com/wp-content/uploads/2021/01/digitaljournal-logo.jpg",
    Link: "https://digitaljournal.com/pr/news/lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 2,
    Imgsrc:
      "https://upload.wikimedia.org/wikipedia/en/thumb/f/f8/WPGX.png/263px-WPGX.png",
    Link: "https://www.wpgxfox28.com/story/48556669/lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 3,
    Imgsrc:
      "https://upload.wikimedia.org/wikipedia/en/thumb/d/d1/Fort_Wayne%27s_NBC_logo.png/200px-Fort_Wayne%27s_NBC_logo.png",
    Link: "https://fwnbc.marketminute.com/article/getnews-2023-3-15-lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 4,
    Imgsrc: "https://www.streetinsider.com/images/silogo-new.png",
    Link: "https://fwnbc.marketminute.com/article/getnews-2023-3-15-lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 5,
    Imgsrc:
      "https://pbs.twimg.com/profile_images/988817890718740485/fgn2IzGp_400x400.jpg",
    Link: "https://waow.marketminute.com/article/getnews-2023-3-15-lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 6,
    Imgsrc:
      "http://www.millvalley.com/images/logos/site_1333665964millvalley_logo.png",
    Link: "http://finance.millvalley.com/camedia.millvalley/article/getnews-2023-3-15-lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
  {
    id: 7,
    Imgsrc:
      "https://www.palaceplayland.com/content/uploads/sun-chronicle-logo.jpg",
    Link: "https://thesunchronicle.marketminute.com/article/getnews-2023-3-15-lawnber-launches-exclusive-platform-using-advanced-algorithms-which-provide-online-pricing-for-long-island-counties-lawn-maintenance-services",
  },
];

const socialMediaLinks = [
  {
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/240px-Instagram_logo_2016.svg.png",
    text: "@lawnber",
    link: "https://www.instagram.com/lawnber",
  },
  {
    logo: "https://img.freepik.com/free-icon/youtube_318-566773.jpg",
    text: "@lawnber",
    link: "https://www.youtube.com/results?search_query=lawnber",
  },
  {
    logo: "https://img.freepik.com/free-icon/linkedin_318-183415.jpg?w=2000",
    text: "@lawnber",
    link: "https://www.linkedin.com/company/lawnber/",
  },
];

const steps = [
  {
    id: 0,
    step: "Enter the Address",
    image: locationIcon,
    color: "#FFDED4",
  },
  {
    id: 1,
    step: "Select the service",
    image: receipt,
    color: "#FFEED4",
  },
  {
    id: 2,
    step: "Create an account",
    image: calendar,
    color: "#D4F0FF",
  },
  {
    id: 3,
    step: "Subscribe a service",
    image: clipboard,
    color: "#FFD4D4",
  },
];

const LawnAddons = [
  {
    id: 0,
    addonName: "Spot Seeding",
    img: SpotSeeding,
    description:
      "Spot seeding is a technique used to address bare areas within an existing grassy area. It involves the precise application of grass seed to specific spots that require rejuvenation. This targeted approach promotes healthy grass growth and helps fill in gaps, resulting in a more uniform and lush lawn.",
  },
  {
    id: 1,
    addonName: "Pool Edging",
    img: PoolEdging,
    description:
      "Pool edging refers to the boundary material used to enclose and define the area around a swimming pool. It serves both functional and aesthetic purpose, providing a barrier to prevent pool water from splashing onto surrounding surfaces and adding a pleasing element to the poolside environment.",
  },
  {
    id: 2,
    addonName: "Bag Clippings",
    img: BagClipping,
    description:
      "Bag clipping is a process used in packaging and agriculture where a clip or fastener is applied to seal bags or sacks. These clips are usually made of plastic or metal and are designed to securely hold the bag's open end closed, preventing the contents from spilling out.",
  },
];

const FallCleanupAddons = [
  {
    id: 0,
    addonName: "Bush Trim & Plant Removal",
    description:
      "Bush trimming is a horticultural practice that involves selectively pruning or cutting back the branches and foliage of shrubs and bushes to maintain their shape, size, and overall appearance.",
    img: BushTrimming,
  },
  {
    id: 1,
    addonName: "Over Seeding & Fertilizer",
    description:
      "Overseeding and fertilizer application are essential techniques in lawn maintenance. Overseeding involves spreading grass seed over an existing lawn to fill in bare patches, improve density, and introduce new, more resilient grass varieties.",
    img: Overseeding,
    cost: "$10",
  },
];

const MulchAddons = [
  {
    id: 0,
    addonName: "5 Additional Yards",
    img: BagOfMulch,
    description:
      "Expanding the yard areas with mulch to create visually appealing outdoor spaces. Enhance landscapes by extending mulch coverage for improved aesthetics and weed control.",
  },
  {
    id: 1,
    addonName: "Remove Extra Weed",
    img: MulchWeeding,
    description:
     "Remove extra weed involves the process of eliminating unwanted and overgrown plants from a garden or agricultural field. This task helps maintain the health of desired crops.",
  },
  {
    id: 2,
    addonName: "Reshape The Edge",
    img: MulchEdging,
    description:
      "Reshaping landscape edges to define and enhance garden boundary. Proficient in creating clean and visually appealing transition between different outdoor spaces.",
  },
];

export {
  landingImages,
  socialMediaLinks,
  steps,
  LawnAddons,
  FallCleanupAddons,
  MulchAddons,
};
