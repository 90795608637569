import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // fontFamily: "Poppins",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {},
  },
  appBar: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingLeft:  "2%",
    paddingRight: "1%",
    maxHeight:  "75px",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      maxHeight: "60px",
    }
  },
  logo:{
    maxWidth: "150px",
    minWidth: "150px",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
    minWidth: "100px",
    maxHeight: "100px",
    minHeight: "100px",
    },
  },
  h1: {
    fontSize: "48px",
    textAlign: "left",
    color: "#1B1C57",
    fontFamily: "Poppins",
    fontWeight: "bold",
    lineHeight: "60px",
    // marginLeft: isWinter ? "-12%" : '0%',
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "28px",
      textAlign: "center",
      marginLeft: "0%",
    },
  },
}));
