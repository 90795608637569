import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Logo from "../../components/assets/images/lawnberlogo2.png";
// import {  toast } from "react-toastify";
import validator from "validator";
import "./contact.css";
import {
  Typography,
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { UserContext } from "../../components/UserContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { authQA, dbQA } from "../../components/Firebase";
import { doc, getDoc } from "@firebase/firestore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FooterHome } from "../../components/Footer";
import Popup from "../../components/Popup";

const useStyles = makeStyles((theme) => ({
  masterFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      width: "90%",
    },
  },

  subFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "118vw",
      paddingLeft: "20% !important",
    },
  },
  dashboardHeading: {
    fontSize: "1.4rem",
    fontWeight: "bold",
    textAlign: "left",
    color: "black",
    marginBottom: "0px",
    fontFamily: "poppins",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.2rem",
    },
  },
  submitButtons: {
    width: "100%",
    maxWidth: "50%",
    fontFamily: "Poppins",
    borderWidth: "3px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    textAlign: "center",
    textTransform: "none",
    marginLeft: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5rem",
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [submit, setSubmit] = useState(false);
  const theme = useTheme();
  const matchesXl = useMediaQuery(theme.breakpoints.down("xl"));
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [userDetails, setUserDetails] = React.useState({});
  const [emailError, setEmailError] = useState("");

  // Dialog box variables
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState('');
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState('')

  const validateEmail = (e) => {
    var email = e;

    if (validator.isEmail(email)) {
      setEmailError("Valid Email");
    } else {
      setEmailError("Invalid Email");
    }
  };

  React.useEffect(() => {
    const checkUser = async () => {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userQA) => {
        if (userQA) {
          setUserQA(userQA);
          const userInfo = await getDoc(doc(dbQA, "UserAcct", userQA.uid));
          setUserDetails({
            name: userInfo.data().FirstName + " " + userInfo.data().LastName,
            uid: userQA.uid
          });
          setName(userInfo.data().FirstName + " " + userInfo.data().LastName)
          setEmail(userInfo.data().EmailID)
          setPhone(userInfo.data().Phone)
        } else {
          setUserQA(null);
          console.log("No userQA");
          return;
        }
      });
    };
    checkUser();
  }, []);

  const handleChange_name = (event) => {
    setName(event.target.value);
  };

  const handleChange_email = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const handleChange_phone = (event) => {
    setPhone(event.target.value);
  };

  const handleChange_subject = (event) => {
    setSubject(event.target.value);
  };

  const handleChange_content = (event) => {
    setContent(event.target.value);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick_submit = async (url) => {
    // console.log("name:", name, "Phone", phone, "email", email, "cont", content);
    try {
      if (name === "" || content === "" || email === "") {
        // toast.error("Name, Email, Message is mandatory");
        showAutoClosePopup('Missing Contact Details', 'Name, Email, Message is mandatory', 3000)
        return;
      } else {
        validateEmail(email);
        // console.log(emailError);
        if (emailError == "Invalid Email") {
          // toast.error("Enter valid email");
          showAutoClosePopup('Error', 'Enter valid email', 3000)
          return;
        } else {
          // toast.success(
          //   "Thanks for writing to us, Lawnber service team will get back within 24hrs."
          // );
          showAutoClosePopup('Info', 'Thanks for writing to us, Lawnber service team will get back within 24hrs.', 3000)
          setTimeout(() => {
            history.goBack();
          }, 3000);
        }
      }

      const { data } = await axios.post(url, {
        email: "services@Lawnber.com",
        subject: subject,
        message: content,
        name: name,
        email_from: email,
        phone: phone,
        uid: userDetails.uid,
      });
      setSubmit(data);
    } catch (err) {
      console.error(err);
    }
  };

  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  return (
    <>
      <div>

        {/* ------ Navbar component start ------  */}
        <AppBar
          color="white"
          elevation={3}
          position="sticky"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingLeft: "2%",
            paddingRight: "1%",
            maxHeight: "75px",
          }}
        >
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  maxWidth: matchesSm ? "100px" : "150px",
                  minWidth: matchesSm ? "100px" : "150px",
                  maxHeight: matchesSm ? "100px" : "150px",
                  minHeight: matchesSm ? "100px" : "150px",
                  margin: "auto",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/QAHome")}
              />
            </Box>
            <Box flexGrow={1} />
            <Box display="flex" alignItems="center">
              <Box>
                <Tooltip title="Open More">
                  <div
                    className="navLink"
                    onClick={handleOpenUserMenu}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <AccountCircleIcon
                      sx={{ color: "#187CAE", fontSize: 45 }}
                    />
                    {userDetails["name"]
                      ? userDetails["name"].split(" ")[0]
                      : ""}
                  </div>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <>
                    {userQA && (
                      <MenuItem
                        value="Lawn Service"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          fontSize: matchesSm
                            ? "0.9rem"
                            : matchesMd
                              ? "0.9rem"
                              : "1rem",
                        }}
                        onClick={() => history.push("/QADashboard")}
                      >
                        QADashboard
                      </MenuItem>
                    )}
                    {userQA && (
                      <MenuItem
                        value="Lawn Service"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          fontSize: matchesSm
                            ? "0.9rem"
                            : matchesMd
                              ? "0.9rem"
                              : "1rem",
                        }}
                        onClick={() => history.push("/QAprofile")}
                      >
                        QAProfile
                      </MenuItem>
                    )}
                    {userQA && (
                      <MenuItem
                        value="Lawn Service"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          fontSize: matchesSm
                            ? "0.9rem"
                            : matchesMd
                              ? "0.9rem"
                              : "1rem",
                        }}
                        onClick={() => {
                          signOut(authQA)
                            .then(() => {
                              // Sign-out successful.
                              console.log("signed out");
                              setUserQA(null);
                              localStorage.removeItem("userQA");
                              history.push("/QAHome");
                            })
                            .catch((error) => {
                              // An error happened.
                              console.log(error);
                            });
                        }}
                      >
                        Sign Out
                      </MenuItem>
                    )}
                    {!userQA && (
                      <MenuItem
                        value="Lawn Service"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          fontSize: matchesSm
                            ? "0.9rem"
                            : matchesMd
                              ? "0.9rem"
                              : "1rem",
                        }}
                        onClick={() => history.push("/QAlogin")}
                      >
                        QALogin
                      </MenuItem>
                    )}
                  </>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Popup DialogBox */}
        <Popup
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          title={popupTitles}
          content={popupContent}
          actions={popupActions}
          alert
        />

        {/* Parent Grid */}
        <Grid container sm={12} xs={12} md={12} lg={12} xl={12} spacing={0}>
          {/* ------ Left grid item start ------  */}
          <Grid container justifyContent="center">
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                style={{
                  marginTop: "10px",
                  width: "99%",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{
                    padding: "10px 0px 0px 10px",
                    textAlign: matchesXs ? "center" : "left",
                  }}
                >
                  Contact Us
                </Typography>
                {!matchesXs && (
                  <Typography
                    variant="subtitle1"
                    style={{
                      textAlign: "left",
                      fontFamily: "poppins",
                      padding: "0px 0px 5px 10px",
                    }}
                  >
                    Enter your details and queries below.
                  </Typography>
                )}
              </div>

              {/* Form Parent Grid */}
              <Grid
                item
                sm={10}
                xs={10}
                md={10}
                lg={11}
                xl={11}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: matchesSm ? "5px" : "3vh",
                }}
              >
                <form
                  autoComplete="off"
                  className="detailsInput"
                  style={{
                    maxWidth: "1000px",
                    marginLeft: "0px",
                    paddingLeft: "1.5%",
                  }}
                >
                  <div className={classes.masterFlexWithSpaceBetween}>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <Typography
                        htmlFor="firstname"
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          paddingRight: matchesSm ? "0px" : "20px",
                          fontFamily: "poppins",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                      >
                        Name
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter your name *"
                        id="fname"
                        value={name}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "90%"
                              : "170px",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                        className="EnterDetailsInput"
                        onChange={handleChange_name}
                      ></input>
                    </div>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <Typography
                        htmlFor="phone"
                        variant="subtitle1"

                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          paddingRight: matchesSm ? "0px" : "20px",
                          fontFamily: "poppins",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                      >
                        Phone
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter phone number"
                        id="pno"
                        value={phone}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "90%"
                              : "170px",
                          marginLeft: matchesSm ? "10vw" : "7px",
                        }}
                        className="EnterDetailsInput"
                        onChange={handleChange_phone}
                      ></input>
                    </div>
                  </div>

                  <div className={classes.masterFlexWithSpaceBetween}>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <Typography
                        htmlFor="email"
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          paddingRight: matchesSm ? "0px" : "20px",
                          fontFamily: "poppins",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                      >
                        Email
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter valid email *"
                        id="lname"
                        value={email}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "90%"
                              : "620px",
                          marginLeft: matchesSm ? "10vw" : "5px",
                        }}
                        className="EnterDetailsInput"
                        onChange={handleChange_email}
                      ></input>
                    </div>
                  </div>

                  <div className={classes.masterFlexWithSpaceBetween}>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <Typography
                        htmlFor="subject"
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          paddingRight: matchesSm ? "0px" : "20px",
                          fontFamily: "poppins",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                      >
                        Subject
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter the subject"
                        id="lname"
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "90%"
                              : "620px",
                          marginLeft: matchesSm ? "10vw" : "-9px",
                        }}
                        className="EnterDetailsInput"
                        onChange={handleChange_subject}
                      ></input>
                    </div>
                  </div>

                  <div className={classes.masterFlexWithSpaceBetween}>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <Typography
                        htmlFor="message"
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          paddingRight: matchesSm ? "0px" : "20px",
                          fontFamily: "poppins",
                          marginLeft: matchesSm ? "10vw" : "0px",
                        }}
                      >
                        Message
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.masterFlexWithSpaceBetween}>
                    <div className={classes.subFlexWithSpaceBetween}>
                      <textarea
                        className="EnterDetailsInput"
                        type="text"
                        id="standard-basic"
                        placeholder="Tell us more about the problem or query *"
                        label="Filled"
                        name="Message"
                        style={{
                          minWidth: matchesXs ? "89.5vw" : "",
                          maxWidth: matchesSm
                            ? "95%"
                            : matchesMd
                              ? "90%"
                              : "690px",
                          marginLeft: matchesSm ? "10vw" : "0px",
                          height: 240,
                          marginTop: "0.8%",
                          width: matchesMd
                            ? "59%"
                            : matchesXs
                              ? "100%"
                              : "100%",
                        }}
                        value={content}
                        onChange={handleChange_content}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.masterFlexWithSpaceBetween}
                    style={{ maxWidth: "800px" }}
                  >
                    <div
                      className={classes.subFlexWithSpaceBetween}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        className={classes.submitButtons}
                        style={{
                          marginTop: matchesSm ? "5%" : "0%",
                          minWidth: "60%",
                        }}
                        onClick={
                          () =>
                            handleClick_submit(
                              "https://be-operation-services.herokuapp.com/Email"
                            )
                          //onClick={() => handleClick_submit('http://localhost:4008/Email')
                        }
                      >
                        Submit
                      </Button>

                      <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        className={classes.submitButtons}
                        style={{
                          marginBottom: "5%",
                          marginTop: "5%",
                          maxWidth: matchesSm ? "30%" : "20%",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => history.goBack()}
                      >
                        Go Back
                      </Button>
                    </div>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Grid>

          {!matchesMd
            ? (
              <div style={{ width: matchesLg ? "75%" : "80%" }}>
                <FooterHome
                  title1="Lawnber"
                  content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
                  title2="Links"
                  links={[
                    { text: "Blog", href: "/QAblog" },
                    { text: "Privacy Policy", href: "/PrivacyPolicy" },
                  ]}
                  title3="Services"
                  services={["Lawn Mowing", "Snow Removal"]}
                  disclaimer="* By submitting my contact information, I agree that Lawnber or its representatives may contact me by email, phone, or SMS at the email address or phone number I provide, including for marketing purposes and by automatic dialing systems. I also agree to Lawnber’s terms of service and privacy policy"
                  handleServiceChange={(event) => {
                    history.push("/QAHome");
                  }}
                  handleInstantQuote={() => {
                    history.push("/QAHome");
                  }}
                />
              </div>
            ) : null}

          {/* ------ Right grid item start ------  */}

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={3}
            xl={3}
            className="rightGrid"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                padding: "10px 0px 0px 10px",
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "poppins",
                marginBottom: "20px",
              }}
            >
              FAQ
            </Typography>
            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>What services does Lawnber provide?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Lawnber offers a comprehensive range of lawn maintenance services, including weekly/bi-weekly lawn mowing, 
                spring cleanup, mulch installation, hedge trimming, fall cleanup, and snow cleanup. 
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                How can I book a Lawn mowing service with Lawnber?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Booking a lawn mowing service with Lawnber is a simple and quick process. All you need to do
                is enter your address, select the service plan and subscription type, provide payment details
                to complete the sign-up, and you will be ready to receive services from Lawnber.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>What if my address is not found?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                If your address is not found, please double-check that you have entered your full address 
                correctly. Enter your contact details, and we will respond within 24 hours to resolve any
                address lookup issues and provide a quote.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                What additional services does Lawnber offer?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Lawnber provides various add-on services such as overseeding and fertilizer application, 
                pool edging, bag clippings, weed removal, aeration, and bush trimming/plant removal. 
                You can log in to our website with your credentials, select the desired add-on services, 
                and submit your request. Alternatively, you can contact us through the "Contact Us" option, 
                and our service team will add the required add-on services to your service list.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Does Lawnber offer one-time services?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Yes, Lawnber provides both one-time job services and weekly/bi-weekly mowing services. 
                We recommend the weekly service, which offers the best-discounted price.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Can I change the service frequency later?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Lawnber allows you to change the job frequency at any time. Simply log in with
                your credentials on our website, update the job frequency on the signup page, and save the 
                changes. You can also reach out to us through the "Contact Us" option, and our service team will assist you 24/7.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                Can I pause the service temporarily?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Yes, you can pause the service at any time. Just inform us through the "Contact Us" option, 
                and our service team will suspend and resume the service as per your request.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>When can I expect the first service after signing up?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Typically, we aim to complete the first service within a week. However, please note that 
                scheduling the first service may experience delays depending on the location distance and service type.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                How can I contact you for issues or clarifications?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                For any issues or clarifications, you can reach out to us through the "Contact Us" link on our website. 
                Our service team is available 24/7 and will be delighted to assist you.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ maxWidth: "450px", margin: "2%", textAlign: "left" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                In which areas does Lawnber provide services?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Lawnber currently offers its services in the Long Island, NY area and 
                is actively working towards expanding to other locations in the near future.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* ------ Right grid item end ------  */}
        </Grid>
      </div>
    </>
  );
};

export default Contact;
