import React from "react";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardActions,
  Container,
  Backdrop,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserContext } from "../../components/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { authQA, dbQA, storage } from "../../components/Firebase";
import { doc, getDoc } from "@firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { FooterBlog } from "../../components/Footer";
import { Helmet } from "react-helmet";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import dayjs from 'dayjs';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Poppins",
  },
  card: {
    maxWidth: "100%",
    margin: "auto",
    marginBottom: "2%",
  },
  media: {
    height: 140,
  },

  genericButton: {
    marginRight: "1rem",
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "1.1rem",
    color: "#187cae",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  blogTitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "left",
  },
  blogDescription: {
    fontFamily: "Poppins",
    fontWeight: "lightweight",
    textAlign: "left",
  },
  rootService: {
    padding: theme.spacing(3),
    maxWidth: "100%",
    fontFamily: "Poppins",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  iconService: {
    fontSize: "3rem",
    fontFamily: "Poppins",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "black",
  },
}));

const BlogHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [userDetails, setUserDetails] = React.useState({});
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const [accountMenu, setAccountMenu] = React.useState(null);
  const [serviceMenu, setServiceMenu] = React.useState(null);
  const [LandscapeMenu, setLandscapeMenu] = React.useState(null);
  const [allHome, setAllHome] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [paginatedObjects, setPaginatedObjects] = React.useState([]);
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');

  //Generic functions
  const handleOpenPopover = (event, state) => {
    state(event.currentTarget);
  };

  const handleClosePopover = (event, state) => {
    state(null);
  };

  const handlePageTop = (event) => {
    setServiceMenu(null);
  };

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userQA) => {
        if (userQA) {
          setUserQA(userQA);
          const UserAcct = await getDoc(doc(dbQA, "UserAcct", userQA.uid));
          setUserDetails({
            name: UserAcct.data().FirstName + " " + UserAcct.data().LastName,
          });
        } else {
          setUserQA(null);
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(async () => {
    // axios
    //   .post("https://blogs-server-lawnber.herokuapp.com/HomeAll")
    //   .then((response) => {
    //     setLoading(false);
    //     console.log(response.data, "Response data");
    //     setAllHome(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // getDownloadURL(ref(storage, "230514MaintainingGrassinHotandDryClimates.html")).then((url)=> {
    //   console.log(url, "URL")
    // })
    //   const listRef = ref(storage, 'Blogs');
    //   listAll(listRef)
    // .then((res) => {
    //   res.items.forEach((itemRef) => {
    //     // All the items under listRef.
    //     console.log("Item", itemRef)
    //   });
    // }).catch((error) => {
    //   // Uh-oh, an error occurred!
    //   console.log("Error",error)
    // });

    // const storageRef = await ref(storage, "Blogs");
    // const result = await listAll(storageRef);
    // // console.log("🚀 ~ file: BlogHome.js:173 ~ React.useEffect ~ resul̥t:", result)
    // result.items.map((html) => getDownloadURL(html).then((result) => {
    //   setPaginatedObjects((prev) => [...prev, result]);
    // }));
    // // setPaginatedObjects(urlPromises);
    // console.log("Pagination", paginatedObjects)
  }, []);

  // React.useEffect(() => {
  //   if (allHome.length > 0) {
  //     const startIndex = page * rowsPerPage;
  //     setPaginatedObjects(allHome.slice(startIndex, startIndex + rowsPerPage));
  //   }
  // }, [allHome, page, rowsPerPage]);

  // console.log(paginatedObjects, "paginationObjects");


  // New firebase method to get all blogs:
  React.useEffect(async () => {
    const currentDate = dayjs();

    // Get the current month (e.g., "January", "February", etc.)
    const currentMonth = currentDate.month() + 1;
    setMonth(currentMonth.toString())

    // Get the current year (e.g., 2023)
    const currentYear = currentDate.format('YYYY');
    setYear(currentYear.toString());

    const TITLEREF = await getDoc(doc(dbQA, "Blogs", "Titles"));
    const all = TITLEREF.data().All
    // Sort the array based on the 'date' property
    all.sort((a, b) => {
      // Parse the date strings into Date objects for comparison
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // Compare the dates
      return dateA - dateB;
    });
    setAllHome(all)
  }, []);

  return (
    <>
      {/* Header for Seo */}
      <Helmet>
        <title>Lawnber Blogs</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Access all our lawn maintainence blogs under one roof. We have blogs about landscaping, custom design, lawn maintenance."
        />
        <link rel="canonical" href="https://lawnber.com/blog" />
        <meta
          property="og:image"
          content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
        />
        <meta property="og:title" content="Lawnber Blogs" />
        <meta
          property="og:description"
          content="Access all our lawn maintainence blogs under one roof. We have blogs about landscaping, custom design, lawn maintenance.."
        />
        <meta property="og:url" content="https://lawnber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <AppBar
        color="white"
        elevation={3}
        position="sticky"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "2%",
          paddingRight: "1%",
          maxHeight: "75px",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                maxWidth: matchesSm ? "100px" : "150px",
                minWidth: matchesSm ? "100px" : "150px",
                maxHeight: matchesSm ? "100px" : "150px",
                minHeight: matchesSm ? "100px" : "150px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={() => history.push("/QAHome")}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setServiceMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Services
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={serviceMenu}
                open={Boolean(serviceMenu)}
                onClose={(event) => handleClosePopover(event, setServiceMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Lawn Service
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Snow Service
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  Spring Cleanup
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="demo-positioned-button"
                onClick={(event) => handleOpenPopover(event, setLandscapeMenu)}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : "1.1rem",
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                Landscaping
              </Button>
              <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button"
                anchorEl={LandscapeMenu}
                open={Boolean(LandscapeMenu)}
                onClose={(event) => handleClosePopover(event, setLandscapeMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "3%" }}
              >
                <MenuItem
                  value="Lawn Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Custom Designs
                </MenuItem>
                <MenuItem
                  value="Snow Service"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  Features v/s Cost
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  High vs Low Maintenance
                </MenuItem>
                <MenuItem
                  value="Spring Cleanup"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                >
                  {" "}
                  Hardscape
                </MenuItem>
              </Menu>
            </Box>
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/QAcontact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "0.9rem"
                      : "1rem",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box>
              <div
                className="navLink"
                onClick={(event) => handleOpenPopover(event, setAccountMenu)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                {userDetails["name"] ? userDetails["name"].split(" ")[0] : ""}
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={accountMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: "1%" }}
                open={Boolean(accountMenu)}
                onClose={(event) => handleClosePopover(event, setAccountMenu)}
              >
                <>
                  {matchesSm && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QAContact")}
                    >
                      Contact US
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QADashboard")}
                    >
                      QADashboard
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QAprofile")}
                    >
                      QAProfile
                    </MenuItem>
                  )}
                  {userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => {
                        signOut(authQA)
                          .then(() => {
                            // Sign-out successful.
                            setUserQA(null);
                            localStorage.removeItem("userQA");
                            history.push("/QAHome");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  )}
                  {!userQA && (
                    <MenuItem
                      value="Lawn Service"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: matchesSm
                          ? "0.9rem"
                          : matchesMd
                            ? "0.9rem"
                            : "1rem",
                      }}
                      onClick={() => history.push("/QAlogin")}
                    >
                      QALogin
                    </MenuItem>
                  )}
                </>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress
          color="inherit"
          indeterminate
          size={50}
          thickness={5}
        />
      </Backdrop> */}

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} style={{ marginTop: "3%" }}>

            {allHome !== null ? (
              allHome.map((post, i) => (
                <Card key={i} className={classes.card}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.blogTitle}
                    >
                      {allHome[i].Title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.blogDescription}
                    >
                      {allHome[i].Description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      className={classes.genericButton}
                      onClick={() => {
                        history.push({
                          pathname: `QABlog/${year}-${month}/${post.Index}/${post.Title
                            .split(" ")
                            .join("-")}`,
                        })
                      }}
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}

            {/* {paginatedObjects !== null ? (
              paginatedObjects.map((post) => (
                <Card key={post.id} className={classes.card}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.blogTitle}
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.blogDescription}
                    >
                      {post.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      className={classes.genericButton}
                      onClick={() =>
                        history.push({
                          pathname: `QAblog/Home/${post.title
                            .split(" ")
                            .join("-")}`,
                          state: {
                            Title: post.title,
                            Description: post.description,
                          },
                        })
                      }
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <div>
                <CircularProgress />
              </div>
            )} */}
            {/* <span dangerouslySetInnerHTML={template} /> */}

            {/* <TablePagination
              component="div"
              count={allHome.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            /> */}
          </Grid>

          <Grid item xs={12} md={4} style={{ marginTop: "3%" }}>
            <div className={classes.rootService}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.blogTitle}
                style={{ textAlign: "center", padding: "2%" }}
              >
                Lawn Maintenance
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Poppins",
                  textAlign: "justify",
                  letterSpacing: "-0.05em",
                }}
              >
                Lawnber provides convenient and professional lawn care services
                at affordable prices. Schedule your service today with a local
                lawn care expert.
              </Typography>
              <button
                className="buttonPrimary2"
                id="submit"
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#ff9900",
                  marginTop: "10%",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
                onClick={() => history.push("/")}
              >
                <span id="button-text">Get Instant Quote</span>
              </button>
            </div>
          </Grid>
        </Grid>
      </Container>

      {/* <FooterBlog
        title1="Lawnber"
        content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
        title2="Recent Posts"
        content2={allHome.slice(0, 5)}
        flag={"Home"}
        URL={"QAblog"}
      /> */}
    </>
  );
};

export default BlogHome;
