require('dotenv').config();

/**
 * @file config.js
 * @author PRAMODH UGARGOL
 * @date January 24, 2023
 * @version 1.0
 * 
 * This file contains the configuration settings for the application for prod.
 */

/**
 * @constant config
 * @type {Object}
 * 
 * This object contains various properties such as the URL for the API, 
 * lookup data, assigning jobs, changing jobs, registering users, managing saved cards,
 * admin login, getting snow prices, and processing new user accounts. 
 * */
const config = {
	FETCH_DATA_URL: process.env.REACT_APP_FETCH_DATA,
	LOOKUP_DATA_URL: process.env.REACT_APP_LOOK_UP_DATA,
	JOB_ASSIGN_URL: process.env.REACT_APP_JOB_ASSIGN,
	JOB_CHANGE_URL: process.env.REACT_APP_JOB_CHANGE,
	REGISTER_USER_URL: process.env.REACT_APP_REGISTER_USER,
	SAVED_CARDS_URL: process.env.REACT_APP_CARDS,
	ADD_NEW_CARD_URL: process.env.REACT_APP_ADD_CARD,
	SET_DEFAULT_CARD_URL: process.env.REACT_APP_SET_DEFUALT,
	DELETE_CARD_URL: process.env.REACT_APP_DELETE,
	GET_SNOW_PRICE_URL: process.env.REACT_APP_GET_SNOW_PRICE,
	GET_LAWN_PRICE_URL: process.env.REACT_APP_GET_LAWN_PRICE,
	PROCESS_NEW_USER_ACCOUNT_URL: process.env.REACT_APP_PROCESS_NEW_USER_ACCOUNT,
	PUBLIC_KEY_URL: process.env.REACT_APP_PUBLISH_KEY_LIVE,
	ALL_PRICES_URL: process.env.REACT_APP_GET_ALL_PRICES
};

/**
 * @exports config
 */
export default config;