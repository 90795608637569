/***************************************************************************************************
Dashboard.js           Created: 10/10/2022         Rev 1.0.3

This file will fetch data from different points to show user history

  getService - 
    Callback to get service related information for the user from firestore db

***************************************************************************************************/

//Imports

// React and React Hooks
import React, { useRef, useState } from "react";
import { allServices } from "../../Constant/Variables";
import { useLocation } from "react-router-dom";

// Firebase SDK and Configurations
import {
  auth, // Authentication SDK
  db, // Firestore Database SDK
} from "../../components/Firebase"; // Firebase configurations

// Firestore SDK Functions
import {
  doc,
  addDoc,
  getDoc,
  setDoc,
  getDocs,
  arrayUnion,
  query,
  where,
  collection,
  serverTimestamp,
  updateDoc,
  deleteField,
} from "firebase/firestore";

// Firebase Authentication Functions
import { onAuthStateChanged, signOut } from "firebase/auth";

// Images

import Logo from "../../components/assets/images/lawnberlogo2.png";
import BagOfMulch from "../../components/assets/images/BagOfMulch.jpg";
import MulchEdging from "../../components/assets/images/MulchEdging.jpg";
import MulchWeeding from "../../components/assets/images/MulchWeeds.jpg";
import Aeration from "../../components/assets/images/Aeration.jpg";
import Overseeding from "../../components/assets/images/OverSeedingFertilizer.jpg";
import Fertilizer from "../../components/assets/images/Fertilizrs.jpg";
import logo from "../../components/assets/images/lawnber_new.png";
import SpotSeeding from "../../components/assets/images/SpotSeeding.webp";
import BagClipping from "../../components/assets/images/BagClippings.jpg";
import BushTrimming from "../../components/assets/images/BushTrimming.jpg";
import PoolEdging from "../../components/assets/images/PoolEdging.jpg";
import BlackMulch from "../../components/assets/images/BlackMulch.jpg";
import RedMulch from "../../components/assets/images/RedMulch.jpg";
import BrownMulch from "../../components/assets/images/BrownMulch.jpg";

// React Context
import { UserContext } from "../../components/UserContext";

// Material-UI Components
import {
  Grid,
  Button,
  Container,
  Box,
  Typography,
  Paper,
  Divider,
  styled,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Backdrop,
  Link,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Avatar,
  CardHeader,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Tabs,
  Tab,
  IconButton,
  ThemeProvider,
  createTheme,
  FormLabel,
} from "@material-ui/core";

// Material-UI Icons
import Tooltip from "@mui/material/Tooltip";
import {
  DoDisturb,
  ThumbUp,
  ContactSupport,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Close,
} from "@material-ui/icons";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import InfoIcon from "@material-ui/icons/Info";

// Material Table Component
import MaterialTable from "material-table";

// React-Router Components
import { NavLink, useHistory } from "react-router-dom";

// Toast Notifications Component
import { toast } from "react-toastify";

// Axios HTTP Library
import axios from "axios";

// Application Configuration
import config from "../Config";

// Signup Styles
import SignupStyles from "../Signup/SignupStyles";

// Custom Styles
import { useStyles } from "./Styles.js";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";

// Unique Identifier Library
import { v4 as unique_id } from "uuid";

// Home Page Footer Component
import { FooterHome } from "../../components/Footer";
import { Stack, autocompleteClasses } from "@mui/material";
import { CheckCircleOutline, Key } from "@mui/icons-material";
import { async } from "@firebase/util";
import Popup from "../../components/Popup";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteIcon style={{ color: "black" }} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft fontSize="12px" {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export function Dashboard_() {
  //variables
  const initialPrice = {
    Standard: parseFloat(0.0),
    Premium: parseFloat(0.0),
    Basic: parseFloat(0.0),
    Surcharge: parseFloat(0.0),
  };
  const history = useHistory();

  //Inline Styles
  const classes = useStyles();
  const SignupClass = SignupStyles();
  const theme = useTheme();
  const defaultMaterialTheme = createTheme();
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesXl = useMediaQuery(theme.breakpoints.down("xl"));

  //General
  const [user, setUser] = React.useContext(UserContext);
  const [address, setAddress] = React.useState();
  const [userDetails, setUserDetails] = React.useState({});
  const [serHistory, setSerHistory] = React.useState([]);
  const [UUID, setUUID] = React.useState();
  const [checkState, setCheckState] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const addressRef = useRef(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [review, setReview] = useState();
  const [files, setFile] = useState();
  const [fileName, setFileName] = React.useState();
  const location = useLocation();
  const newUser = location.state?.newUser ?? "";

  const Mulch = [
    {
      id: 0,
      mulchColor: "Brown",
      Color: "#433937",
      img: BrownMulch,
    },
    {
      id: 1,
      mulchColor: "Black",
      img: BlackMulch,
      Color: "black",
    },
    {
      id: 2,
      mulchColor: "Red",
      img: RedMulch,
      Color: "Red",
    },
  ];

  //All Services Price hooks
  //Lawn Moowing hooks
  const [lawnDBAddons, setLawnDBAddons] = useState({});
  const [lawnBEAddons, setLawnBEAddons] = React.useState(null);
  const [lawnDBPrice, setLawnDBPrice] = useState(initialPrice);
  const [lawnBEPrice, setLawnBEPrice] = React.useState(initialPrice);
  const [lawnDashSelected, setLawnDashSelected] = useState("");
  const [selectedAddonsLawn, setSelectedAddonsLawn] = useState({});
  const [selectedAddonsLawnCopy, setSelectedAddonsLawnCopy] = useState({});
  const [totalLawnAddon, setTotalLawnAddon] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Weekly");

  //Spring cleanup hooks
  const [springDBPrice, setSpringDBPrice] = useState(initialPrice);
  const [springBEPrice, setSpringBEPrice] = useState(initialPrice);

  //Mulch service hooks
  const [mulchPrices, setMulchPrices] = useState({
    mulchDBPrice: initialPrice,
    mulchBEPrice: initialPrice,
    mulchDBSalesTax: 0,
    mulchDBRedAddonPrice: 0,
    mulchDashSalesTax: 0,
    mulchDashRedAddonPrice: 0,
    mulchTotalPrice: 0,
    totalYards: 0,
  });
  const [mulchDBAddons, setMulchDBAddons] = useState({});
  const [mulchBEAddons, setMulchBEAddons] = useState({});
  const [selectedAddonsMulch, setSelectedAddonsMulch] = useState({
    "5 Additional Yards": false,
    "Remove Extra Weed": false,
    "Reshape The Edge": false,
  });
  const [selectedAddonsMulchCopy, setSelectedAddonsMulchCopy] = useState({});
  const [totalMulchAddon, setTotalMulchAddon] = useState(0);

  //Hedge Service hooks
  const [hedgeService, setHedgeService] = useState({
    hedgeDBPrice: initialPrice,
    hedgeBEPrice: initialPrice,
    hedgeDbSelected: "",
    hedgeDashSelected: "",
    hedgeOverShoot: false,
    hedgeOverShootEmail: {
      Email: "",
      Name: "",
      Address: "",
      Subject: "Requesting Hedge Trimming Quote",
      Content: "I need a quote for Hedge Trimming",
    },
  });

  //Fall Cleanup Hooks
  const [fallService, setFallService] = useState({
    fallDBPrice: initialPrice,
    fallBEPrice: initialPrice,
    fallDbSelected: "",
    fallDashSelected: "",
  });
  const [fallDBAddons, setFallDBAddons] = useState({});
  const [fallBEAddons, setFallBEAddons] = useState({});
  const [selectedAddonsFall, setSelectedAddonsFall] = useState({});
  const [selectedAddonsFallCopy, setSelectedAddonsFallCopy] = useState({});
  const [totalFallAddon, setTotalFallAddon] = useState(0);

  //All Services Hooks (MISC)
  const [signUpPageSelected, setSignUpPageSelected] = useState("");
  const [clickedLawnService, setClickedLawnService] = useState("");
  const [clickedSpringService, setClickedSpringService] = useState("");
  const [clickedFallService, setClickedFallService] = useState("");
  const [clickedMulchService, setClickedMulchService] = useState("");

  const [lawnDBSelected, setLawnDBSelected] = React.useState("");
  const [springDBSelected, setSpringDBSelected] = React.useState([]);
  const [mulchSelected, setMulchSelected] = React.useState({
    dBPlanSelected: "",
    dashPlanSelected: "",
    colorSelected: "Brown",
  });
  const [currentServices, setCurrentServices] = React.useState([]);
  const [serviceStatuses, setServiceStatuses] = useState({});
  const [allPrices, setAllPrices] = useState(null);
  const [proID, setProID] = useState("");
  const [serviceAgreementTick, setSericeAgreementTick] = React.useState(false);
  const [trackSelectedAddons, setTrackSelectedAddons] = React.useState({
    LawnMowing: false,
    Mulch: false,
    FallCleanup: false,
  });
  const [trackAddonChange, setTrackAddonChange] = React.useState({
    LawnMowing: false,
    Mulch: false,
    FallCleanup: false,
  });

  //Dialog box hooks (boolean - open/close)
  const [lawnSubscribeDialogBox, setLawnSubscribeDialogBox] =
    React.useState(false);
  const [springSubscribeDialogBox, setSpringSubscribeDialogBox] =
    React.useState(false);
  const [fallSubscribeDialogBox, setFallSubscribeDialogBox] =
    React.useState(false);
  const [mulchSubscribeDialogBox, setMulchSubscribeDialogBox] =
    React.useState(false);
  const [hedgeSubscribeDialogBox, setHedgeSubscribeDialogBox] =
    React.useState(false);

  //Customized Projects
  const [customizedRequirement, setCustomizedRequirement] = useState();
  const [base64dataArray, setbase64dataArray] = useState([]);
  const [submitCustomizedProj, setSubmitCustomizedProj] = useState(false);
  const [getCustomizedData, setGetCustomizedData] = useState([]);
  const [selectedCustomizedProject, setSelectedCustomizedProject] = useState(
    []
  );

  // Dialog box variables
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState("");
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState("");
  const [openPopupAlert, setOpenPopupAlert] = React.useState(false);

  //ThumbsUp local storage
  const thumbCount = doc(db, "Reviews", "thumbsUp");
  const [oneTimeMowCount, setOneTimeMowCount] = useState();
  const [weeklyMowCount, setWeeklyMowCount] = useState();
  const [biweeklyMowCount, setBiWeeklyMowCount] = useState();
  const [seasonalCount, setSeasonalCount] = useState();
  const [firstTimeCustomer, setFirstTimeCustomer] = useState();
  const [ip, setIP] = React.useState("");
  const [city, setCity] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [lat, setLat] = React.useState("");
  const [long, setLong] = React.useState("");

  //Local addons for description
  const MulchAddonsObject = [
    {
      id: 0,
      addonName: "5 Additional Yards",
      img: BagOfMulch,
      description:
        "Expanding yard areas with mulch application to create visually appealing and low-maintenance outdoor spaces. Proficient in selecting and installing various mulch types tailored to specific landscaping needs. Enhancing landscapes by extending mulch coverage to additional yards for improved aesthetics and weed control.",
    },
    {
      id: 1,
      addonName: "Remove Extra Weed",
      img: MulchWeeding,
      description:
        "Well-versed in careful weed-removal techniques; committed to preserve the life and beauty of outdoor areas.  Skilled in both chemical and manual weed treatment, assuring the wellbeing of plants and lawns.  Capable of converting overgrown areas into landscapes that are kept up.",
    },
    {
      id: 2,
      addonName: "Reshape The Edge",
      img: MulchEdging,
      description:
        "Reshaping landscape edges to define and enhance garden boundaries. Proficient in creating clean and visually appealing transitions between different outdoor spaces. Transforming ordinary edges into aesthetically pleasing and well-defined borders for a polished landscape.",
    },
  ];

  const FallCleanupAddons = [
    {
      id: 0,
      addonName: "Bush Trim & Plant Removal",
      description:
        "Expertly removing unwanted plants and skillfully trimming bushes, fostering both health and beauty.",
      img: BushTrimming,
    },
    // {
    //   id: 1,
    //   addonName: "Aeration",
    //   description:
    //     "Helps the soil and the grass roots get air, water and nutrients by making small holes in the soil.",
    //   img: Aeration,
    // },
    {
      id: 1,
      addonName: "Over Seeding & Fertilizer",
      description:
        "Adding grass seed to make it thicker, healthier and resistant to pests and diseases.",
      img: Overseeding,
      cost: "$10",
    },
  ];

  const LawnAddons = [
    {
      id: 0,
      addonName: "Spot Seeding",
      img: SpotSeeding,
      description:
        "Spot seeding is a technique used to address bare areas within an existing grassy area. It involves the precise application of grass seed to specific spots that require rejuvenation. This targeted approach promotes healthy grass growth and helps fill in gaps, resulting in a more uniform and lush lawn.",
    },
    {
      id: 1,
      addonName: "Pool Edging",
      img: PoolEdging,
      description:
        "Pool edging refers to the boundary or border material used to enclose and define the area around a swimming pool. It serves both functional and aesthetic purposes, providing a barrier to prevent pool water from splashing onto surrounding surfaces and adding a visually pleasing element to the poolside environment.",
    },
    {
      id: 2,
      addonName: "Bag Clippings",
      img: BagClipping,
      description:
        "Bag clipping is a process commonly used in packaging and agriculture where a clip or fastener is applied to seal bags or sacks. These clips are usually made of plastic or metal and are designed to securely hold the bag's open end closed, preventing the contents from spilling out.",
    },
  ];

  // Render dom for the first time or when state changes
  React.useEffect(() => {
    const checkUser = async () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
          history.push({
            pathname: "/Login",
            state: {
              status: "Mulch",
            },
          });
          return;
        }
      });
    };
    checkUser();
  }, []);

  // DialogBox Popup feature
  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    const checkFirstTimeUser = localStorage.getItem("FIRSTTIMEUSER");
    console.log(
      newUser === true && checkFirstTimeUser === "true",
      "Checking first time user"
    );
    console.log(newUser, "FirstnewUser");
    console.log(checkFirstTimeUser, "checkFistTimeUSer");
    if (newUser === true && checkFirstTimeUser === "true") {
      setFirstTimeCustomer(true);
    } else {
      setFirstTimeCustomer(false);
    }

    console.log("First time customer", firstTimeCustomer);

    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("service")) {
      const service = searchParams.get("service");
      const convertedService = service.replace("-", " ");
      setSignUpPageSelected(convertedService);
    } else {
      setSignUpPageSelected("Lawn Mowing");
      // setSignUpPageSelected("Mulch");
    }
  }, []);

  // Grabs like count
  React.useEffect(() => {
    function thumbsUpCount() {
      getDoc(thumbCount).then((Snapdata) => {
        setOneTimeMowCount(Snapdata.data().OneTimeMowCount);
        setWeeklyMowCount(Snapdata.data().WeeklyMowCount);
        setBiWeeklyMowCount(Snapdata.data().BiWeeklyMowCount);
        setSeasonalCount(Snapdata.data().SeasonalSnowCleanUp);
      });
    }
    thumbsUpCount();
  }, []);

  //Go to firestore db and get necessary information
  React.useEffect(() => {
    getIP();
    sendIP();
  }, [ip, city, region, lat, long, UUID]);

  React.useEffect(() => {
    setTimeout(async () => {
      await getServices();
    }, 3500);
  }, [checkState]);

  //Gets IP address of the visitor to our website
  const getIP = async () => {
    //const res = await axios.get("https://api.ipify.org/?format=json");
    //setIP(res.data.ip);
    const res = await axios.get("https://ipapi.co/json/");
    setCity(res.data.city);
    setRegion(res.data.region);
    setLat(res.data.latitude);
    setLong(res.data.longitude);
    setIP(res.data.ip);
  };

  //Sending IP address to Backend
  const sendIP = async () => {
    if (typeof UUID !== "undefined") {
      console.log("sendIP:", ip, city, region, lat, long, UUID);
      const { data } = await axios.post(
        "https://be-operation-services.herokuapp.com/DashboardIP",
        //"http://localhost:4008/DashboardIP",
        {
          ip: ip,
          uid: UUID,
          city: city,
          region: region,
          lat: lat,
          long: long,
        }
      );
    }
  };

  const copyAddons = (addonsLocal, addonsDB) => {
    const addonsObject = {};
    // Loop through the fallBEAddons array and check if each addon name exists in serviceData["FallCleanup"].Services.Addons
    addonsLocal.forEach((object) => {
      // Check if the addonName exists in the serviceData["FallCleanup"].Services.Addons array
      const isAddonSelected = addonsDB.some(
        (addon) => addon === object.addonName
      );
      // Set the value in the fallAddonsObject
      addonsObject[object.addonName] = isAddonSelected;
    });

    return addonsObject;
  };

  const sendHedgeOverShootEmail = async (url) => {
    if (
      hedgeService.hedgeOverShootEmail["Name"].trim() &&
      hedgeService.hedgeOverShootEmail["Email"].trim() &&
      hedgeService.hedgeOverShootEmail["Content"].trim() &&
      hedgeService.hedgeOverShootEmail["Address"].trim()
    ) {
      const { data } = await axios.post(url, {
        email: "services@Lawnber.com",
        subject: hedgeService.hedgeOverShootEmail["Subject"],
        message: hedgeService.hedgeOverShootEmail["Content"],
        name: hedgeService.hedgeOverShootEmail["Name"],
        Address: hedgeService.hedgeOverShootEmail["Address"],
        email_from: hedgeService.hedgeOverShootEmail["Email"],
        phone: "NA",
        uid: UUID,
      });

      if (data) {
        // toast.success("We will get back to you within 24hrs");
        showAutoClosePopup(
          "Info",
          "We will get back to you within 24hrs",
          3000
        );
      }
    } else {
      // toast.error("Name, Email, Message is mandatory");
      showAutoClosePopup("Error", "Name, Email, Message is mandatory", 3000);
      return;
    }
  };

  // Fetches service selected by the user
  const getServices = async () => {
    // Save user data to local storage
    localStorage.setItem("user", JSON.stringify(user));

    // Listen for changes in the user authentication state
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setTrackAddonChange((service) => ({
          ...service,
          Mulch: false,
          LawnMowing: false,
          FallCleanup: false,
        }));

        // Get user account data from Firebase
        const userAccountData = await getDoc(doc(db, "UserAcct", user.uid));

        // Set the user UUID
        setUUID(user.uid);

        // Get user name from account data
        const firstName = userAccountData.data().FirstName;
        const lastName = userAccountData.data().LastName;

        // Set the user details state with the full name
        setUserDetails({ name: firstName });

        // Get user address from account data
        const completeAddress = userAccountData.data().Address;

        // Split the address string into a list
        const completeAddressList = completeAddress.split(",");

        // Get the first element of the address list (the street address) and convert it to uppercase
        const sheetAddress = completeAddressList[0].toUpperCase();

        // Set the user address state with the street address
        setAddress(completeAddressList[0]);

        // Get Services selected by User(such as Snow Removal & Lawn Service)
        const CustomizedProjRef = doc(
          db,
          "UserAcct",
          user.uid,
          "Services",
          "CustomizedProjects"
        );

        const customizedDoc = await getDoc(CustomizedProjRef);
        var hedgeServiceData;

        if (customizedDoc.exists()) {
          setSubmitCustomizedProj(true);
          getCustomizedData.push(customizedDoc.data().Projects[0]);

          console.log(getCustomizedData, "Get customized data");
        } else {
          console.log("No customized project");
        }

        // Fetch data from the first API
        await axios
          .post(config.FETCH_DATA_URL, { address: sheetAddress })
          .then(async (response) => {
            // Extract data from the response
            var holdDBPriceForMulch = {};
            try {
              const userServices = doc(db, "UserAcct", user.uid);
              const Snapdata = await getDoc(userServices);

              if (Snapdata.exists()) {
                setCurrentServices(Snapdata.data().ActiveService);
                const serviceData = {};
                await Promise.all(
                  Snapdata.data().ActiveService.map(async (serviceId) => {
                    const serviceDoc = doc(
                      db,
                      "UserAcct",
                      user.uid,
                      "Services",
                      serviceId
                    );
                    const docs = await getDoc(serviceDoc);
                    const proServices = doc(
                      db,
                      "ProServices",
                      docs.data()["ServiceID"]
                    );
                    if (docs.exists()) {
                      // Setting service status
                      const ProServicesDoc = await getDoc(proServices);
                      let serviceDate = ProServicesDoc.data().ServiceDate;
                      let serviceDay = ProServicesDoc.data().ServiceDay;

                      if (
                        serviceDate !== null &&
                        serviceDate.length > 0 &&
                        serviceDay !== null &&
                        serviceDay.length > 0
                      ) {
                        setServiceStatuses((prevStatuses) => ({
                          ...prevStatuses,
                          [serviceId]:
                            "on" +
                            " " +
                            serviceDay.slice(3) +
                            " " +
                            serviceDate,
                        }));
                      } else {
                        setServiceStatuses((prevStatuses) => ({
                          ...prevStatuses,
                          [serviceId]:
                            serviceId === "Mulch"
                              ? "Service Date will be scheduled once we have the material ordered"
                              : "Service date will be available soon",
                        }));
                      }
                      serviceData[serviceId] = docs.data();
                    } else {
                      serviceData[serviceId] = null;
                    }
                  })
                );
                //LawnMowing:
                if (serviceData.hasOwnProperty("LawnMowing")) {
                  setProID(
                    serviceData["LawnMowing"].ProID.length > 0
                      ? serviceData["LawnMowing"].ProID
                      : ""
                  );
                  setLawnDBPrice(serviceData["LawnMowing"].Services.Pricing);
                  setLawnDBSelected(serviceData["LawnMowing"].Services["Main"]);
                  setClickedLawnService(
                    serviceData["LawnMowing"].Services["Main"]
                  );
                  setSelectedOption(
                    serviceData["LawnMowing"].Services.SubscriptionType
                  );
                  if (
                    serviceData["LawnMowing"].Services.hasOwnProperty("Addons")
                  ) {
                    if (
                      serviceData["LawnMowing"].Services.Addons === undefined
                    ) {
                      setLawnDBAddons({});
                      setSelectedAddonsLawn({});
                      setSelectedAddonsLawnCopy({});
                    } else {
                      setLawnDBAddons(
                        serviceData["LawnMowing"].Services.Addons
                      );
                      const addonsObject = Object.keys(
                        serviceData["LawnMowing"].Services.Addons
                      );
                      const lawnAddonObject = copyAddons(
                        LawnAddons,
                        addonsObject
                      );
                      console.log("LawnAddons:", lawnAddonObject);
                      setSelectedAddonsLawn(lawnAddonObject);
                      setSelectedAddonsLawnCopy(lawnAddonObject);
                    }
                  } else {
                    setLawnDBAddons({});
                    setSelectedAddonsLawn({});
                    setSelectedAddonsLawnCopy({});
                  }
                }

                //SpringCleanup:
                if (serviceData.hasOwnProperty("SpringCleanup")) {
                  setProID(
                    serviceData["SpringCleanup"].ProID.length > 0
                      ? serviceData["SpringCleanup"].ProID
                      : ""
                  );
                  setSpringDBPrice(
                    serviceData["SpringCleanup"].Services.Pricing
                  );
                  setSpringDBSelected([
                    serviceData["SpringCleanup"].Services["Main"],
                  ]);
                }

                //Mulch
                if (serviceData.hasOwnProperty("Mulch")) {
                  setProID(
                    serviceData["Mulch"].ProID.length > 0
                      ? serviceData["Mulch"].ProID
                      : ""
                  );
                  var holdDBPriceForMulch =
                    serviceData["Mulch"].Services.Pricing;
                  setMulchPrices((prevPrices) => ({
                    ...prevPrices,
                    mulchDBPrice: holdDBPriceForMulch,
                    mulchDBSalesTax: serviceData["Mulch"].Services.SalesTax,
                  }));
                  setMulchSelected((prevSelected) => ({
                    ...prevSelected,
                    dBPlanSelected: serviceData["Mulch"].Services.Main,
                    colorSelected:
                      serviceData["Mulch"].Services.SubscriptionType,
                    mulchDBRedAddonPrice:
                      serviceData["Mulch"].Services.SubscriptionType ===
                        "Red" &&
                        serviceData["Mulch"].Services.Main === "Premium"
                        ? 100
                        : serviceData["Mulch"].Services.SubscriptionType ===
                          "Red" &&
                          serviceData["Mulch"].Services.Main === "Standard"
                          ? 50
                          : 0,
                  }));
                  setClickedMulchService(serviceData["Mulch"].Services.Main);
                  // setTotalMulchAddon(serviceData["Mulch"].Services.Addons)
                  if (serviceData["Mulch"].Services.hasOwnProperty("Addons")) {
                    if (serviceData["Mulch"].Services.Addons === undefined) {
                      setMulchDBAddons({});
                      setSelectedAddonsMulch({});
                      setSelectedAddonsMulchCopy({});
                    } else {
                      setMulchDBAddons(serviceData["Mulch"].Services.Addons);
                      const addonsObject = Object.keys(
                        serviceData["Mulch"].Services.Addons
                      );
                      const mulchAddonObject = copyAddons(
                        MulchAddonsObject,
                        addonsObject
                      );
                      console.log("Fall Addons:", mulchAddonObject);
                      setSelectedAddonsMulch(mulchAddonObject);
                      setSelectedAddonsMulchCopy(mulchAddonObject);
                    }
                  } else {
                    setMulchDBAddons({});
                    setSelectedAddonsMulch({});
                    setSelectedAddonsMulchCopy({});
                  }
                }

                //Hedge:
                if (serviceData.hasOwnProperty("HedgeTrimming")) {
                  hedgeServiceData =
                    serviceData.hasOwnProperty("HedgeTrimming");
                  setProID(
                    serviceData["HedgeTrimming"].ProID.length > 0
                      ? serviceData["HedgeTrimming"].ProID
                      : ""
                  );
                  var holdDBPriceForHedge =
                    serviceData["HedgeTrimming"].Services.Pricing;
                  setHedgeService((prevPrices) => ({
                    ...prevPrices,
                    hedgeDBPrice: holdDBPriceForHedge,
                    hedgeDbSelected: serviceData["HedgeTrimming"].Services.Main,
                    hedgeOverShootEmail: {
                      ...prevPrices.hedgeOverShootEmail,
                      Email: userAccountData.data().EmailID,
                      Name:
                        userAccountData.data().FirstName +
                        " " +
                        userAccountData.data().LastName,
                      Address: userAccountData.data().Address,
                    },
                  }));
                }

                //Fall Cleanup:
                if (serviceData.hasOwnProperty("FallCleanup")) {
                  setProID(
                    serviceData["FallCleanup"].ProID.length > 0
                      ? serviceData["FallCleanup"].ProID
                      : ""
                  );
                  var holdDBPriceForFall =
                    serviceData["FallCleanup"].Services.Pricing;

                  setFallService((prevPrices) => ({
                    ...prevPrices,
                    fallDBPrice: holdDBPriceForFall,
                    fallDbSelected: serviceData["FallCleanup"].Services.Main,
                  }));

                  setClickedFallService(
                    serviceData["FallCleanup"].Services.Main
                  );

                  if (
                    serviceData["FallCleanup"].Services.hasOwnProperty("Addons")
                  ) {
                    if (
                      serviceData["FallCleanup"].Services.Addons === undefined
                    ) {
                      setFallDBAddons({});
                      setSelectedAddonsFall({});
                      setSelectedAddonsFallCopy({});
                    } else {
                      setFallDBAddons(
                        serviceData["FallCleanup"].Services.Addons
                      );
                      const addonsDB = Object.keys(
                        serviceData["FallCleanup"].Services.Addons
                      );
                      const fallAddonsObject = copyAddons(
                        FallCleanupAddons,
                        addonsDB
                      );

                      setSelectedAddonsFall(fallAddonsObject);
                      setSelectedAddonsFallCopy(fallAddonsObject);
                    }
                  } else {
                    setFallDBAddons({});
                    setSelectedAddonsFall({});
                    setSelectedAddonsFallCopy({});
                  }
                }

                const historySnap = await getDoc(
                  doc(db, "UserAcct", user.uid, "Services", "History2023")
                );

                if (historySnap.exists()) {
                  //console.log("History Data", Snapdata.data());
                  const serviceHistory = historySnap.data().History;
                  setSerHistory(serviceHistory.reverse());
                } else {
                  setSerHistory(null);
                }
              }
            } catch (error) {
              console.error(error);
            }
            const data = response.data;
            try {
              // Make the second API call with the extracted data
              const allPriceResponse = await axios.post(
                config.ALL_PRICES_URL,
                // "http://localhost:4008/getAllPrice",
                {
                  Address: data["Address"],
                  Zipcode: data["Zipcode"],
                  PropertySize: data["TotalArea"],
                });

              var hedgeShow = false;
              if (Number(data["TotalArea"]) > 12000) {
                if (
                  !isNaN(Number(allPriceResponse.data["HedgePremium"])) ||
                  hedgeServiceData
                ) {
                  hedgeShow = false;
                } else {
                  hedgeShow = true;
                }
                setHedgeService((overShoot) => ({
                  ...overShoot,
                  hedgeOverShoot: hedgeShow,
                  hedgeOverShootEmail: {
                    ...overShoot.hedgeOverShootEmail,
                    Email: userAccountData.data().EmailID,
                    Name:
                      userAccountData.data().FirstName +
                      " " +
                      userAccountData.data().LastName,
                    Address: userAccountData.data().Address,
                  },
                }));
              }

              // Handle the response from the second API call here
              const allPrice = allPriceResponse.data;
              console.log(allPrice, "all Price");
              setAllPrices(allPriceResponse.data);

              if (allPrice) {
                // Set the lawn price state with the extracted and formatted prices
                setLawnBEPrice({
                  Standard: +Number(allPrice.MowStandard).toFixed(2),
                  Premium: +Number(allPrice.MowPremium).toFixed(2),
                  Basic: +Number(allPrice.MowBasic).toFixed(2),
                  Surcharge: +Number(allPrice.Surcharge).toFixed(2),
                });
                // Set the spring price state with the extracted and formatted prices
                setSpringBEPrice({
                  Basic: +Number(allPrice["SpringBasic"]).toFixed(2),
                  Standard: +Number(allPrice["SpringStandard"]).toFixed(2),
                  Premium: +Number(allPrice["SpringPremium"]).toFixed(2),
                  Surcharge: +Number(0).toFixed(2),
                });
                //Mulch Prices BE
                var holdBEPriceForMulch = {
                  Basic: +Number(allPrice["MulchBasic"]).toFixed(2),
                  Standard: +Number(allPrice["MulchStandard"]).toFixed(2),
                  Premium: +Number(allPrice["MulchPremium"]).toFixed(2),
                  Surcharge: +Number(0).toFixed(2),
                };
                setMulchPrices((prevPrices) => ({
                  ...prevPrices,
                  mulchBEPrice: holdBEPriceForMulch,
                }));

                //hedge Prices BE
                var holdBEPriceForHedge = {
                  Basic: +Number(allPrice["HedgeBasic"]).toFixed(2),
                  Standard: +Number(allPrice["HedgeStandard"]).toFixed(2),
                  Premium: +Number(allPrice["HedgePremium"]).toFixed(2),
                  Surcharge: +Number(0).toFixed(2),
                };

                //set the variable:
                setHedgeService((prevPrices) => ({
                  ...prevPrices,
                  hedgeBEPrice: holdBEPriceForHedge,
                }));

                //Fall prices BE
                var holdBEPriceForFall = {
                  Basic: +Number(allPrice["FallBasic"]).toFixed(2),
                  Standard: +Number(allPrice["FallStandard"]).toFixed(2),
                  Premium: +Number(allPrice["FallPremium"]).toFixed(2),
                  Surcharge: +Number(0).toFixed(2),
                };
                //set the variable:
                setFallService((prevPrices) => ({
                  ...prevPrices,
                  fallBEPrice: holdBEPriceForFall,
                }));

                setFallBEAddons(allPrice["FallAddons"]);

                setLawnBEAddons(allPrice.MowAddons);

                setMulchBEAddons(
                  allPrice["MulchAddons"] === "NA" ||
                    !allPrice.hasOwnProperty("MulchAddons")
                    ? {}
                    : allPrice["MulchAddons"]
                );

                setLoading(false);
              } else {
                console.log("Error: allPrice is undefined");
              }
            } catch (error) {
              // Handle errors from the second API call here
              console.log("Error in getAllPrice API", error);
            }
          })
          .catch((error) => {
            // Handle errors from the first API call here
            console.log("Error in fetchData API", error);
          });
      } else {
        console.log("No user documnent");
      }
    });
  };

  //General Service Dialog box trigger use effects
  //Fall
  React.useEffect(() => {
    if (trackSelectedAddons["FallCleanup"] !== false) {
      if (
        fallService.fallBEPrice !== null &&
        (fallService.fallDbSelected.length > 0 ||
          fallService.fallDashSelected.length > 0)
      ) {
        setTotalFallAddon(0);
        setTotalFallAddon(calculateTotal(selectedAddonsFall, fallBEAddons));
        if (clickedFallService === fallService.fallDbSelected) {
          setClickedFallService(fallService.fallDbSelected);
        }
        genericDialogBoxOpen(setFallSubscribeDialogBox);
      }
    }
  }, [trackSelectedAddons["FallCleanup"]]);

  //Lawn
  React.useEffect(() => {
    if (trackSelectedAddons["LawnMowing"] !== false) {
      if (
        lawnBEPrice !== null &&
        (lawnDBSelected.length > 0 || lawnDashSelected.length > 0)
      ) {
        setTotalLawnAddon(0);
        setTotalLawnAddon(calculateTotal(selectedAddonsLawn, lawnBEAddons));
        if (clickedLawnService === lawnDBSelected) {
          setClickedLawnService(lawnDBSelected);
        }
        genericDialogBoxOpen(setLawnSubscribeDialogBox);
      }
    }
  }, [trackSelectedAddons["LawnMowing"]]);

  //Mulch
  React.useEffect(() => {
    if (trackSelectedAddons["Mulch"] !== false) {
      if (
        mulchPrices.mulchBEPrice !== null &&
        (mulchSelected.dBPlanSelected.length > 0 ||
          mulchSelected.dashPlanSelected.length > 0)
      ) {
        var clickedMulchServiceCopy = clickedMulchService;

        if (clickedMulchService === mulchSelected.dBPlanSelected) {
          clickedMulchServiceCopy = mulchSelected.dBPlanSelected;
          setClickedMulchService(mulchSelected.dBPlanSelected);
        }

        const premiumYardMultiplier = 4;
        const standardYardMultiplier = 2;
        let totalSelectedAddons = 0;
        let additionalAddons = 0;
        let redMulchAddon = 0;

        const baseYards = {
          Premium: premiumYardMultiplier,
          Standard: standardYardMultiplier,
        };

        if (mulchSelected.colorSelected === "Red") {
          redMulchAddon =
            allPrices.MulchRed * baseYards[clickedMulchServiceCopy] || 0;
        }

        let totalYards = baseYards[clickedMulchServiceCopy] || 0;
        if (selectedAddonsMulch["5 Additional Yards"]) {
          totalYards += 5;
        }

        Object.entries(selectedAddonsMulch).forEach(([addon, selected]) => {
          if (addon === "Remove Extra Weed" || addon === "Reshape the Edge") {
            if (selected) {
              totalSelectedAddons++;
            }
          } else if (selected && addon !== "5 Additional Yards") {
            totalSelectedAddons++;
          }

          if (selected && addon === "5 Additional Yards") {
            additionalAddons += 400;
          } else if (!selected && addon !== "5 Additional Yards") {
            additionalAddons += 0;
          }
        });

        additionalAddons +=
          Number(mulchBEAddons["Reshape The Edge"]) *
          totalYards *
          totalSelectedAddons;

        const mulchPrice =
          Number(mulchPrices["mulchBEPrice"][clickedMulchServiceCopy]) || 0;
        const mulchSalesTax =
          (mulchPrice + redMulchAddon + additionalAddons) * allPrices.MulchTax;

        console.log(
          "Mulch Prices:",
          "Tax:",
          mulchSalesTax,
          "Red:",
          redMulchAddon,
          "Plan Price:",
          mulchPrice,
          "Additional Addons:",
          additionalAddons
        );

        const totalPriceMulch = (
          mulchPrice +
          redMulchAddon +
          mulchSalesTax +
          additionalAddons
        ).toFixed(2);

        setMulchPrices((prevPrice) => ({
          ...prevPrice,
          mulchDashRedAddonPrice: redMulchAddon,
          mulchDashSalesTax: mulchSalesTax,
          mulchTotalPrice: totalPriceMulch,
          totalYards: totalYards,
        }));

        genericDialogBoxOpen(setMulchSubscribeDialogBox);
      }
    }
  }, [trackSelectedAddons["Mulch"]]);
  ///

  const handleSelect = (
    addonName,
    addonPrice,
    SetSelectedAddonState,
    TotalAddonState,
    selectedAddonState,
    clickedService,
    service
  ) => {
    if (clickedService.length === 0) {
      showAutoClosePopup(
        "Warning",
        "Please select a Standard or Premium plan first",
        3000
      );
      return;
    }
    // Update the selected addons for the lawn
    SetSelectedAddonState((prevSelectedAddons) => ({
      ...prevSelectedAddons,
      [addonName]: !prevSelectedAddons[addonName],
    }));

    if (addonPrice === 'TBD') {
      addonPrice = 0
    }

    var updatedAddonPrice = parseFloat(addonPrice);

    if (service !== "Mulch") {
      TotalAddonState((prevTotalPrice) =>
        selectedAddonState[addonName]
          ? prevTotalPrice - updatedAddonPrice
          : prevTotalPrice + updatedAddonPrice
      );
    }

    if (service === "Fall") {
      if (selectedAddonState[addonName] === selectedAddonsFallCopy[addonName]) {
        setTrackAddonChange((service) => ({
          ...service,
          FallCleanup: true,
        }));
      }
      if (selectedAddonState[addonName] !== selectedAddonsFallCopy[addonName]) {
        setTrackAddonChange((service) => ({
          ...service,
          FallCleanup: false,
        }));
      }
    }

    if (service === "Lawn") {
      if (selectedAddonState[addonName] === selectedAddonsLawnCopy[addonName]) {
        setTrackAddonChange((service) => ({
          ...service,
          LawnMowing: true,
        }));
      }
      if (selectedAddonState[addonName] !== selectedAddonsLawnCopy[addonName]) {
        setTrackAddonChange((service) => ({
          ...service,
          LawnMowing: false,
        }));
      }
    }

    if (service === "Mulch") {
      if (
        selectedAddonState[addonName] === selectedAddonsMulchCopy[addonName]
      ) {
        setTrackAddonChange((service) => ({
          ...service,
          Mulch: true,
        }));
      }
      if (
        selectedAddonState[addonName] !== selectedAddonsMulchCopy[addonName]
      ) {
        setTrackAddonChange((service) => ({
          ...service,
          Mulch: false,
        }));
      }
    }
  };

  function calculateTotal(selectedAddons, addonPrices) {
    let total = 0.0;
    var addonPriceCopy = { ...addonPrices };
    Object.keys(selectedAddons).forEach((key) => {
      if (selectedAddons[key]) {

        if (addonPrices[key] === 'TBD') {
          addonPriceCopy[key] = 0;
        }
        total += Number(addonPriceCopy[key]);
      }
    });

    return Number(total);
  }

  function assignPriceAccordingly(BEPrice, DBPrice, service, pricingType) {
    if (BEPrice !== null) {
      if (currentServices.includes(service)) {
        return Number(DBPrice[pricingType]).toFixed(2);
      } else {
        return Number(BEPrice[pricingType]).toFixed(2);
      }
    } else {
      return "0.00"; // Return a default value if fallService.fallBEPrice is null
    }
  }

  // To subscribe to Lawn Service
  const handleLawnSubscribeButton = (e, option) => {
    e.preventDefault();
    // console.log("E:", e.target.id, "option:", option);
    const priceAdjustment =
      option === "Bi-Weekly" ? allPrices["MowBiWeekly"] : 0;
    const updatedPrices = {
      Standard: +Number(allPrices.MowStandard) + priceAdjustment,
      Premium: +Number(allPrices.MowPremium) + priceAdjustment,
      Basic: +Number(allPrices.MowBasic) + priceAdjustment,
      Surcharge: +Number(allPrices["Surcharge"]).toFixed(2),
    };
    if (currentServices.includes("LawnMowing")) {
      setLawnDBPrice(updatedPrices);
    } else {
      setLawnBEPrice(updatedPrices);
    }
    setClickedLawnService(
      e.target.id.length === 0 ? lawnDBSelected : e.target.id
    );
    setLawnDashSelected(
      e.target.id.length === 0 ? lawnDBSelected : e.target.id
    );
    setSelectedOption(option);
  };

  // To subscribe to Spring Service
  const handleSpringSubscribeButton = (e) => {
    e.preventDefault();
    // setCheckState(Math.random().toString(36).slice(2));
    if (hedgeService.hedgeDbSelected.length === 0) {
      setClickedSpringService(e.target.id);
      genericDialogBoxOpen(setSpringSubscribeDialogBox);
    } else {
      alert(
        'To pause your plan, please contact us through email or by using the "Contact Us" button. We are here to help and guide you through the process.'
      );
      return;
    }
  };

  // To subscribe to Mulch Service
  const handleMulchSubscribeButton = (e) => {
    e.preventDefault();
    setClickedMulchService(
      e.target.id.length === 0 ? mulchSelected.dBPlanSelected : e.target.id
    );
    setMulchSelected((prevSelected) => ({
      ...prevSelected,
      dashPlanSelected:
        e.target.id.length === 0 ? mulchSelected.dBPlanSelected : e.target.id,
    }));
  };

  // To subscribe to Hedge Service => Button
  const handleHedgeSubscribeButton = (e) => {
    e.preventDefault();
    setHedgeService((plan) => ({
      ...plan,
      hedgeDashSelected: e.target.id,
    }));
    genericDialogBoxOpen(setHedgeSubscribeDialogBox);
  };

  // To subscribe to Fall Cleanup Service => Button
  const handleFallSubscribeButton = (e) => {
    e.preventDefault();
    setClickedFallService(
      e.target.id.length === 0 ? fallService.fallDbSelected : e.target.id
    );
    setFallService((prevSelect) => ({
      ...prevSelect,
      fallDashSelected:
        e.target.id.length === 0 ? fallService.fallDbSelected : e.target.id,
    }));
    // genericDialogBoxOpen(setFallSubscribeDialogBox);
  };

  // Subscribe to Lawn Service
  const handleSubscribeLawn = async () => {
    if (!serviceAgreementTick) {
      // toast.error("Please accept the terms and conditions!");
      showAutoClosePopup(
        "Error",
        "Please accept the terms and conditions!",
        3000
      );

      return;
    } else {
      // Case1: When the service name only has to change
      if (currentServices.includes("LawnMowing")) {
        var serviceId;
        //Get document ref
        const LawnMowingRef = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "LawnMowing"
        );
        await getDoc(LawnMowingRef)
          .then(async (response) => {
            if (response.exists()) {
              serviceId = response.data().ServiceID;
              const ServiceContractRef = doc(db, "ProServices", serviceId);

              const selectedLawnAddonsWithPrice = {};
              Object.keys(selectedAddonsLawn).forEach((key) => {
                if (selectedAddonsLawn[key]) {
                  selectedLawnAddonsWithPrice[key] = +Number(lawnBEAddons[key]);
                }
              });

              var totalPrice =
                Number(lawnDBPrice[clickedLawnService]) +
                Number(lawnDBPrice["Surcharge"]) +
                Number(totalLawnAddon);

              const updatedLawnMowingData = {
                Main: clickedLawnService,
                Pricing: lawnDBPrice,
                Addons: selectedLawnAddonsWithPrice,
                SubscriptionType: selectedOption,
              };

              const updatedServiceContractData = {
                JobRequest: clickedLawnService,
                Price: +totalPrice.toFixed(2),
                Services: updatedLawnMowingData,
              };

              // Step 1: Delete the Addons Field
              const removeAddonField = {
                Services: {
                  Addons: deleteField(),
                },
              };

              await Promise.all([
                setDoc(LawnMowingRef, removeAddonField, { merge: true }),
                setDoc(ServiceContractRef, removeAddonField, { merge: true }),
                setDoc(
                  LawnMowingRef,
                  { Services: updatedLawnMowingData },
                  { merge: true }
                ),
                setDoc(ServiceContractRef, updatedServiceContractData, {
                  merge: true,
                }),
              ]);
              setLawnSubscribeDialogBox(false);
              showAutoClosePopup(
                "Info",
                "Successfully initiated changes to Lawn Service!",
                3000
              );
              setCheckState(Math.random().toString(36).slice(2));
              setTrackAddonChange((service) => ({
                ...service,
                LawnMowing: false,
              }));
            } else {
              console.log("No such document");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Case2: When the service has to be created from scratch
      else {
        // step1: update active Service
        const Account = doc(db, "UserAcct", UUID);
        await updateDoc(Account, {
          ActiveService: arrayUnion("LawnMowing"),
        }).catch((err) => toast.error(err));

        const selectedLawnAddonsWithPrice = {};

        Object.keys(selectedAddonsLawn).forEach((key) => {
          if (selectedAddonsLawn[key]) {
            selectedLawnAddonsWithPrice[key] = +Number(lawnBEAddons[key]);
          }
        });

        var totalPrice =
          Number(lawnBEPrice[clickedLawnService]) +
          Number(lawnBEPrice["Surcharge"]) +
          Number(totalLawnAddon);

        // Data structure for Selected service
        const selectedServices = {
          Main: clickedLawnService,
          Addons: selectedLawnAddonsWithPrice,
          Pricing: lawnBEPrice,
          ServiceCat: "LawnMowing",
          SubscriptionType: selectedOption,
          Status: "Pending",
        };

        // create ref
        const LawnMowing = doc(db, "UserAcct", UUID, "Services", "LawnMowing");
        const newProService = await addDoc(collection(db, "ProServices"), {
          name: "My Pro Service",
          description: "This is my Pro Service",
          price: 10.99,
        });
        var ServiceId = newProService.id;
        const ServiceContractRef = await doc(db, "ProServices", ServiceId);
        //Set the Spring cleanup document
        await setDoc(LawnMowing, {
          ServiceID: ServiceId,
          Services: selectedServices,
          ProID: proID,
          ServiceDate: "",
          NextService: "",
          ServiceDay: "",
        }).catch((err) => alert(err));

        try {
          const docSnap = await getDoc(Account); // fetch the document snapshot
          if (docSnap.exists()) {
            const u_id = unique_id();
            const orderID = u_id.slice(0, 8);
            //Set lawnMowing service Contract
            await setDoc(ServiceContractRef, {
              UserEmail: docSnap.data().EmailID,
              UserName:
                docSnap.data().FirstName + " " + docSnap.data().LastName,
              QuoteDate: serverTimestamp(),
              ServiceDate: null,
              JobRequest: clickedLawnService,
              FinalStatus: "Pending",
              OrderNumber: orderID,
              ProID: proID,
              ServiceID: ServiceId,
              Price: +Number(totalPrice).toFixed(2),
              ServiceCharge: +Number(lawnBEPrice["Surcharge"]),
              UID: UUID,
              Services: selectedServices,
              ServiceAddr: docSnap.data().Address,
              NextService: "",
              ServiceDay: "",
              JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
              ServiceStatus: "", //[Active, Service cancelled on 'date']
            }).catch((err) => alert(err));
            setLawnDBSelected(clickedLawnService);
            setLawnSubscribeDialogBox(false);
            // toast.success("Successfully subscribed to Lawn Service");
            showAutoClosePopup(
              "Info",
              "Successfully subscribed to Lawn Service!",
              3000
            );
            setTrackAddonChange((service) => ({
              ...service,
              LawnMowing: false,
            }));
            setCheckState(Math.random().toString(36).slice(2));
          } else {
            console.log("No such document!");
          }
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  //Subscribe to Spring Service
  const handleSpringSubscribe = async () => {
    if (!serviceAgreementTick) {
      // toast.error("Please accept the terms and conditions!");
      showAutoClosePopup(
        "Error",
        "Please accept the terms and conditions!",
        3000
      );
      return;
    } else {
      // Case1: When the service name only has to change
      if (currentServices.includes("SpringCleanup")) {
        var serviceId;
        //Get document ref
        const SpringCleanupRef = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "SpringCleanup"
        );
        await getDoc(SpringCleanupRef)
          .then((response) => {
            if (response.exists()) {
              serviceId = response.data().ServiceID;
              const ServiceContractRef = doc(db, "ProServices", serviceId);
              var totalPrice = Number(springDBPrice[clickedSpringService]);
              setDoc(
                SpringCleanupRef,
                {
                  Services: {
                    Main: clickedSpringService,
                    SubscriptionType: clickedSpringService,
                  },
                },
                { merge: true }
              );
              setDoc(
                ServiceContractRef,
                {
                  JobRequest: clickedSpringService,
                  Price: +Number(totalPrice).toFixed(2),
                  Services: {
                    Main: clickedSpringService,
                    SubscriptionType: clickedSpringService,
                  },
                },
                { merge: true }
              );
              setSpringDBSelected([clickedSpringService]);
              setSpringSubscribeDialogBox(false);
              // toast.success("Successfully subscribed to Spring Service");
              showAutoClosePopup(
                "Info",
                "Successfully subscribed to Spring Service",
                3000
              );
              setCheckState(Math.random().toString(36).slice(2));
            } else {
              console.log("No such document");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Case2: When the service has to be created from scratch
      else {
        // step1: update active Service
        const Account = doc(db, "UserAcct", UUID);
        await updateDoc(Account, {
          ActiveService: arrayUnion("SpringCleanup"),
        }).catch((err) => toast.error(err));

        // Data structure for Selected service
        const selectedServices = {
          Main: clickedSpringService,
          Addons: {},
          Pricing: springBEPrice,
          ServiceCat: "SpringCleanup",
          SubscriptionType: clickedSpringService,
          Status: "Pending",
        };

        // create ref
        const SpringCleanup = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "SpringCleanup"
        );
        const newProService = await addDoc(collection(db, "ProServices"), {
          name: "My Pro Service",
          description: "This is my Pro Service",
          price: 10.99,
        });
        var ServiceId = newProService.id;
        const ServiceContractRef = await doc(db, "ProServices", ServiceId);
        //Set the Spring cleanup document
        await setDoc(SpringCleanup, {
          ServiceID: ServiceId,
          Services: selectedServices,
          ProID: proID,
          ServiceDate: "",
          NextService: "",
          ServiceDay: "",
        }).catch((err) => alert(err));

        try {
          const docSnap = await getDoc(Account); // fetch the document snapshot
          if (docSnap.exists()) {
            const u_id = unique_id();
            const orderID = u_id.slice(0, 8);
            //Set lawnMowing service Contract
            await setDoc(ServiceContractRef, {
              UserEmail: docSnap.data().EmailID,
              UserName:
                docSnap.data().FirstName + " " + docSnap.data().LastName,
              QuoteDate: serverTimestamp(),
              ServiceDate: null,
              JobRequest: clickedSpringService,
              FinalStatus: "Pending",
              OrderNumber: orderID,
              ProID: " ",
              ServiceID: ServiceId,
              Price: +Number(springBEPrice[clickedSpringService]),
              ServiceCharge: null,
              UID: UUID,
              Services: selectedServices,
              ServiceAddr: docSnap.data().Address,
              NextService: "",
              ServiceDay: "",
              JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
              ServiceStatus: "", //[Active, Service cancelled on 'date']
            }).catch((err) => alert(err));
            setSpringDBSelected([clickedSpringService]);
            setSpringSubscribeDialogBox(false);
            // toast.success("Successfully subscribed to Spring Service");
            showAutoClosePopup(
              "Info",
              "Successfully subscribed to Spring Service",
              3000
            );
            setCheckState(Math.random().toString(36).slice(2));
          } else {
            console.log("No such document!");
          }
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  // Subscribe to Mulch Service
  const handleSubscribeMulch = async () => {
    if (!serviceAgreementTick) {
      // toast.error("Please accept the terms and conditions!");
      showAutoClosePopup(
        "Error",
        "Please accept the terms and conditions!",
        3000
      );
      return;
    } else {
      // Case1: When the service name only has to change
      if (currentServices.includes("Mulch")) {
        var serviceId;
        //Get document ref
        const MulchRef = doc(db, "UserAcct", UUID, "Services", "Mulch");
        await getDoc(MulchRef)
          .then(async (response) => {
            if (response.exists()) {
              serviceId = response.data().ServiceID;
              const ServiceContractRef = doc(db, "ProServices", serviceId);

              const selectedMulchAddonsWithPrice = {};
              Object.keys(selectedAddonsMulch).forEach((key) => {
                if (selectedAddonsMulch[key]) {
                  if (key === "5 Additional Yards")
                    selectedMulchAddonsWithPrice[key] = +Number(
                      mulchBEAddons[key]
                    );
                  else {
                    selectedMulchAddonsWithPrice[key] =
                      +Number(mulchBEAddons[key]) * mulchPrices.totalYards;
                  }
                }
              });

              const updatedMulchData = {
                Main: clickedMulchService,
                Pricing: mulchPrices.mulchDBPrice,
                Addons: selectedMulchAddonsWithPrice,
                SubscriptionType: mulchSelected.colorSelected,
                SalesTax: +Number(mulchPrices.mulchDashSalesTax).toFixed(2),
              };

              const updatedServiceContractData = {
                JobRequest: clickedMulchService,
                Price: +Number(mulchPrices.mulchTotalPrice).toFixed(2),
                Services: updatedMulchData,
              };

              // Step 1: Delete the Addons Field
              const removeAddonField = {
                Services: {
                  Addons: deleteField(),
                },
              };

              await Promise.all([
                setDoc(MulchRef, removeAddonField, { merge: true }),
                setDoc(ServiceContractRef, removeAddonField, { merge: true }),
                setDoc(
                  MulchRef,
                  { Services: updatedMulchData },
                  { merge: true }
                ),
                setDoc(ServiceContractRef, updatedServiceContractData, {
                  merge: true,
                }),
              ]);
              setMulchPrices((prevSelected) => ({
                ...prevSelected,
                dBPlanSelected: clickedMulchService,
              }));
              setMulchSubscribeDialogBox(false);
              showAutoClosePopup(
                "Info",
                "Successfully subscribed to Mulch Service",
                3000
              );
              setCheckState(Math.random().toString(36).slice(2));
              setTrackAddonChange((service) => ({
                ...service,
                Mulch: false,
              }));
            } else {
              console.log("No such document");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Case2: When the service has to be created from scratch
      else {
        // step1: update active Service
        const Account = doc(db, "UserAcct", UUID);
        await updateDoc(Account, {
          ActiveService: arrayUnion("Mulch"),
        }).catch((err) => toast.error(err));

        const selectedMulchAddonsWithPrice = {};
        Object.keys(selectedAddonsMulch).forEach((key) => {
          if (selectedAddonsMulch[key]) {
            if (key === "5 Additional Yards")
              selectedMulchAddonsWithPrice[key] = +Number(mulchBEAddons[key]);
            else {
              selectedMulchAddonsWithPrice[key] =
                +Number(mulchBEAddons[key]) * mulchPrices.totalYards;
            }
          }
        });

        var totalPrice = Number(mulchPrices.mulchTotalPrice).toFixed(2);

        const selectedServices = {
          // Data structure for Selected service
          Main: mulchSelected.dashPlanSelected,
          Addons: selectedMulchAddonsWithPrice,
          Pricing: mulchPrices["mulchBEPrice"],
          SalesTax: +Number(mulchPrices.mulchDashSalesTax).toFixed(2),
          ServiceCat: "Mulch",
          SubscriptionType: mulchSelected.colorSelected,
          Status: "Pending",
        };

        // create ref
        const Mulch = doc(db, "UserAcct", UUID, "Services", "Mulch");
        const newProService = await addDoc(collection(db, "ProServices"), {
          QouteDate: null,
        });
        var ServiceId = newProService.id;
        const ServiceContractRef = await doc(db, "ProServices", ServiceId);
        //Set the Spring cleanup document
        await setDoc(Mulch, {
          Services: selectedServices,
          ServiceID: ServiceId,
          ProID: proID,
          ServiceDate: "",
          NextService: "",
          ServiceDay: "",
        }).catch((err) => alert(err));

        try {
          const docSnap = await getDoc(Account); // fetch the document snapshot
          if (docSnap.exists()) {
            const u_id = unique_id();
            const orderID = u_id.slice(0, 8);
            //Set lawnMowing service Contract
            await setDoc(ServiceContractRef, {
              UserEmail: docSnap.data().EmailID,
              UserName:
                docSnap.data().FirstName + " " + docSnap.data().LastName,
              QuoteDate: serverTimestamp(),
              ServiceDate: "",
              JobRequest: mulchSelected.dashPlanSelected,
              FinalStatus: "Pending",
              OrderNumber: orderID,
              ProID: proID,
              ServiceID: ServiceId,
              Price: +Number(totalPrice).toFixed(2),
              ServiceCharge: 0,
              UID: UUID,
              Services: selectedServices,
              ServiceAddr: docSnap.data().Address,
              NextService: "",
              ServiceDay: "",
              JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
              ServiceStatus: "", //[Active, Service cancelled on 'date']
            })
              .then(async () => {
                await axios.post(
                  "https://be-operation-services.herokuapp.com/ServiceAddDash",
                  {
                    serviceId: ServiceId,
                    proFound: false,
                    serviceType: selectedServices.ServiceCat,
                  }
                );
              })
              .catch((err) => alert(err));
            setMulchPrices((prevSelected) => ({
              ...prevSelected,
              dashPlanSelected: mulchPrices.dashPlanSelected,
            }));
            setMulchSubscribeDialogBox(false);
            // toast.success("Successfully subscribed to Mulch Service");
            showAutoClosePopup(
              "Info",
              "Successfully initiated changes for Mulch Service",
              3000
            );
            setTrackAddonChange((service) => ({
              ...service,
              Mulch: false,
            }));
            setCheckState(Math.random().toString(36).slice(2));
          } else {
            console.log("No such document!");
          }
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  // Subscribe to Hedge Service
  const handleSubscribeHedge = async () => {
    if (!serviceAgreementTick) {
      // toast.error("Please accept the terms and conditions!");
      showAutoClosePopup(
        "Error",
        "Please accept the terms and conditions!",
        3000
      );
      return;
    } else {
      // Case1: When the service name only has to change
      if (currentServices.includes("HedgeTrimming")) {
        var serviceId;
        let total = 0.0;
        //Get document ref
        const HedgeTrimmingRef = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "HedgeTrimming"
        );
        await getDoc(HedgeTrimmingRef)
          .then((response) => {
            if (response.exists()) {
              serviceId = response.data().ServiceID;
              const ServiceContractRef = doc(db, "ProServices", serviceId);
              //From DB:
              for (const key in response.data().Services.Addons) {
                total += response.data().Services.Addons[key];
              }
              var totalPrice = Number(
                hedgeService["hedgeDBPrice"][hedgeService["hedgeDbSelected"]]
              );

              // const selectedLawnAddonsWithPrice = {};
              // Object.keys(selectedAddonsLawn).forEach((key) => {
              //   if (selectedAddonsLawn[key]) {
              //     selectedLawnAddonsWithPrice[key] = +Number(lawnBEAddons[key]);
              //   }
              // });

              // setDoc(
              //   MulchRef,
              //   {
              //     Services: {
              //       Main: "",
              //       SubscriptionType: selectMulch,
              //       Pricing: mulchDBPrice,
              //     },
              //   },
              //   { merge: true }
              // );

              // setDoc(
              //   ServiceContractRef,
              //   {
              //     JobRequest: clickedLawnService,
              //     Price: +Number(totalPrice).toFixed(2),
              //     Services: {
              //       Main: clickedLawnService,
              //       SubscriptionType: selectedOption,
              //       Pricing: lawnDBPrice,
              //       Addons: selectedLawnAddonsWithPrice,
              //     },
              //   },
              //   { merge: true }
              // );
              setHedgeService((prevSelected) => ({
                ...prevSelected,
                hedgeDashSelected: hedgeService.hedgeDashSelected,
              }));
              setHedgeSubscribeDialogBox(false);
              // toast.success("Successfully subscribed to Hedge Service");
              showAutoClosePopup(
                "Info",
                "Successfully subscribed to Hedge Service",
                3000
              );
              setCheckState(Math.random().toString(36).slice(2));
            } else {
              console.log("No such document");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Case2: When the service has to be created from scratch
      else {
        // step1: update active Service
        const Account = doc(db, "UserAcct", UUID);
        await updateDoc(Account, {
          ActiveService: arrayUnion("HedgeTrimming"),
        }).catch((err) => toast.error(err));

        const selectedHedgeAddonsWithPrice = {};

        //No addons for a while
        // Object.keys(selectedAddonsMulch).forEach((key) => {
        //   if (selectedAddonsMulch[key]) {
        //     selectedHedgeAddonsWithPrice[key] = +Number(
        //       allPrices.MulchAddons[key]
        //     );
        //   }
        // });

        var totalPrice = +Number(
          hedgeService["hedgeBEPrice"][hedgeService["hedgeDashSelected"]]
        );

        const selectedServices = {
          // Data structure for Selected service
          Main: hedgeService.hedgeDashSelected,
          Addons: selectedHedgeAddonsWithPrice,
          Pricing: hedgeService["hedgeBEPrice"],
          ServiceCat: "HedgeTrimming",
          SubscriptionType: "One-Time",
        };

        // create ref
        const HedgeTrimming = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "HedgeTrimming"
        );
        const newProService = await addDoc(collection(db, "ProServices"), {
          QouteDate: null,
        });
        var ServiceId = newProService.id;
        const ServiceContractRef = await doc(db, "ProServices", ServiceId);
        //Set the Spring cleanup document
        await setDoc(HedgeTrimming, {
          Services: selectedServices,
          ServiceID: ServiceId,
          ProID: proID,
          ServiceDate: "",
          NextService: "",
          ServiceDay: "",
        }).catch((err) => alert(err));

        try {
          const docSnap = await getDoc(Account); // fetch the document snapshot
          if (docSnap.exists()) {
            const u_id = unique_id();
            const orderID = u_id.slice(0, 8);
            //Set Hedge service Contract
            await setDoc(ServiceContractRef, {
              UserEmail: docSnap.data().EmailID,
              UserName:
                docSnap.data().FirstName + " " + docSnap.data().LastName,
              QuoteDate: serverTimestamp(),
              ServiceDate: "",
              JobRequest: hedgeService.hedgeDashSelected,
              FinalStatus: "Pending",
              OrderNumber: orderID,
              ProID: proID,
              ServiceID: ServiceId,
              Price: +Number(totalPrice).toFixed(2),
              ServiceCharge: 0,
              UID: UUID,
              Services: selectedServices,
              ServiceAddr: docSnap.data().Address,
              NextService: "",
              ServiceDay: "",
              JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
              ServiceStatus: "", //[Active, Service cancelled on 'date']
            })
              .then(async () => {
                await axios.post(
                  "https://be-operation-services.herokuapp.com/ServiceAddDash",
                  {
                    serviceId: ServiceId,
                    proFound: false,
                    serviceType: selectedServices.ServiceCat,
                  }
                );
              })
              .catch((err) => alert(err));
            setHedgeService((prevSelected) => ({
              ...prevSelected,
              hedgeDashSelected: hedgeService.hedgeDashSelected,
            }));
            setHedgeSubscribeDialogBox(false);
            // toast.success("Successfully subscribed to Hedge Service");
            showAutoClosePopup(
              "Info",
              "Successfully subscribed to Hedge Service",
              3000
            );
            setCheckState(Math.random().toString(36).slice(2));
          } else {
            console.log("No such document!");
          }
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  // Subscribe to Fall Service
  const handleSubscribeFall = async () => {
    if (!serviceAgreementTick) {
      // toast.error("Please accept the terms and conditions!");
      showAutoClosePopup(
        "Error",
        "Please accept the terms and conditions!",
        3000
      );
      return;
    } else {
      // Case1: When the service name only has to change
      if (currentServices.includes("FallCleanup")) {
        var serviceId;
        //Get document ref
        const FallCleanupRef = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "FallCleanup"
        );
        await getDoc(FallCleanupRef)
          .then(async (response) => {
            if (response.exists()) {
              serviceId = response.data().ServiceID;
              const ServiceContractRef = doc(db, "ProServices", serviceId);

              var selectedFallAddonsWithPrice = {};

              Object.keys(selectedAddonsFall).forEach((key) => {
                if (selectedAddonsFall[key]) {
                  selectedFallAddonsWithPrice[key] = fallBEAddons[key] !== 'TBD' ? +fallBEAddons[key] : fallBEAddons[key];
                }
              });

              var totalPrice = Number(
                fallService["fallDBPrice"][clickedFallService] + totalFallAddon
              );

              const updatedFallCleanupData = {
                Main: clickedFallService,
                Pricing: fallService.fallDBPrice,
                Addons: selectedFallAddonsWithPrice,
              };

              const updatedServiceContractData = {
                JobRequest: clickedFallService,
                Price: +totalPrice.toFixed(2),
                Services: updatedFallCleanupData,
              };

              // Step 1: Delete the Addons Field
              const removeAddonField = {
                Services: {
                  Addons: deleteField(),
                },
              };

              await Promise.all([
                setDoc(FallCleanupRef, removeAddonField, { merge: true }),
                setDoc(ServiceContractRef, removeAddonField, { merge: true }),
                setDoc(
                  FallCleanupRef,
                  { Services: updatedFallCleanupData },
                  { merge: true }
                ),
                setDoc(ServiceContractRef, updatedServiceContractData, {
                  merge: true,
                }),
              ]);

              setFallSubscribeDialogBox(false);
              showAutoClosePopup(
                "Info",
                `Successfully initiated changes`,
                3000
              );
              setCheckState(Math.random().toString(36).slice(2));
              setTrackAddonChange((service) => ({
                ...service,
                FallCleanup: false,
              }));
            } else {
              console.log("No such document");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Case2: When the service has to be created from scratch
      else {
        // step1: update active Service
        const Account = doc(db, "UserAcct", UUID);
        await updateDoc(Account, {
          ActiveService: arrayUnion("FallCleanup"),
        }).catch((err) => toast.error(err));

        const selectedFallAddonsWithPrice = {};

        //No addons for a while
        Object.keys(selectedAddonsFall).forEach((key) => {
          if (selectedAddonsFall[key]) {
            selectedFallAddonsWithPrice[key] = allPrices.FallAddons[key] !== 'TBD' ? +allPrices.FallAddons[key] : allPrices.FallAddons[key];
          }
        });


        var totalPrice = +Number(
          fallService.fallBEPrice[clickedFallService] + totalFallAddon
        ).toFixed(2);

        const selectedServices = {
          // Data structure for Selected service
          Main: clickedFallService,
          Addons: selectedFallAddonsWithPrice,
          Pricing: fallService["fallBEPrice"],
          ServiceCat: "FallCleanup",
          SubscriptionType: "One-Time",
        };

        // create ref
        const FallCleanup = doc(
          db,
          "UserAcct",
          UUID,
          "Services",
          "FallCleanup"
        );
        const newProService = await addDoc(collection(db, "ProServices"), {
          QouteDate: null,
        });
        var ServiceId = newProService.id;
        const ServiceContractRef = await doc(db, "ProServices", ServiceId);
        //Set the Spring cleanup document
        await setDoc(FallCleanup, {
          Services: selectedServices,
          ServiceID: ServiceId,
          ProID: proID,
          ServiceDate: "",
          NextService: "",
          ServiceDay: "",
        }).catch((err) => alert(err));

        try {
          const docSnap = await getDoc(Account); // fetch the document snapshot
          if (docSnap.exists()) {
            const u_id = unique_id();
            const orderID = u_id.slice(0, 8);
            //Set Hedge service Contract
            await setDoc(ServiceContractRef, {
              UserEmail: docSnap.data().EmailID,
              UserName:
                docSnap.data().FirstName + " " + docSnap.data().LastName,
              QuoteDate: serverTimestamp(),
              ServiceDate: "",
              JobRequest: clickedFallService,
              FinalStatus: "Pending",
              OrderNumber: orderID,
              ProID: proID,
              ServiceID: ServiceId,
              Price: +Number(totalPrice).toFixed(2),
              ServiceCharge: 0,
              UID: UUID,
              Services: selectedServices,
              ServiceAddr: docSnap.data().Address,
              NextService: "",
              ServiceDay: "",
              JobStatus: "", //[Job completed on 'date', payment Completed on 'date']
              ServiceStatus: "", //[Active, Service cancelled on 'date']
            })
              .then(async () => {
                await axios.post(
                  "https://be-operation-services.herokuapp.com/ServiceAddDash",
                  {
                    serviceId: ServiceId,
                    proFound: false,
                    serviceType: selectedServices.ServiceCat,
                  }
                );
              })
              .catch((err) => alert(err));
            setFallService((prevSelected) => ({
              ...prevSelected,
              fallDashSelected: fallService.fallDashSelected,
            }));
            setFallSubscribeDialogBox(false);
            showAutoClosePopup(
              "Info",
              "Successfully subscribed to Fall Service",
              3000
            );
            setCheckState(Math.random().toString(36).slice(2));
            setTrackAddonChange((service) => ({
              ...service,
              FallCleanup: false,
            }));
          } else {
            console.log("No such document!");
          }
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  const genericDialogBoxOpen = (dialogBoxOpenState) => {
    dialogBoxOpenState(true);
  };

  const genericDialogBoxClose = (dialogBoxCloseState) => {
    dialogBoxCloseState(false);
  };

  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageTop = (event) => {
    setSignUpPageSelected(event.currentTarget.getAttribute("value"));
    setAnchorEl(null);
  };

  const handleClick_DownloadReceipt = async (url) => {
    try {
      await axios
        .post(
          url,
          {
            Uid: UUID,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const data = response.data;
          let blob = new Blob([data], { type: "application/pdf" });
          let link = URL.createObjectURL(blob);
          window.open(link, "_blank");
        });
    } catch (err) {
      console.error(err);
    }
  };

  // To attach file and view the document
  const handlerFile = (e) => {
    console.log(e.target.files);
    // setFile(e.target.files);
    // setFileName(e.target.files.name);

    let allfiles = [];
    let allfilenames = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
      allfilenames.push(e.target.files[i].name);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
      setFileName(allfilenames);
    }

    console.log("Set FIle", files);
  };

  // Handle Customized Projects selection:
  const handleCustomizedProjButton = (e) => {
    e.preventDefault();
    console.log(e.target.id, "ID");
    var index = selectedCustomizedProject.indexOf(e.target.id);

    if (index === -1) {
      setSelectedCustomizedProject((data) => [...data, e.target.id]);
    } else {
      setSelectedCustomizedProject(
        selectedCustomizedProject.filter((v) => v !== e.target.id)
      );
    }
  };

  //console.log("base64dataArray", base64dataArray);
  const handleSubmitCustomizedProj = async () => {
    if (selectedCustomizedProject.length < 1) {
      // toast.error("Select atleast one Project");
      showAutoClosePopup("Error", "Select atleast one Project", 3000);
      return;
    }
    if (!customizedRequirement) {
      // toast.error("Write a few words about your requirement");
      showAutoClosePopup(
        "Error",
        "Write a few words about your requirement",
        3000
      );
      return;
    }
    if (!files) {
      // toast.error("Select atleast one photo");

      return;
    } else {
      setSubmitCustomizedProj(true);
      toast.success(
        "Your requirement has been sent. We'll get back to you soon!"
      );
      return new Promise(async (resolve) => {
        try {
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onloadend = async () => {
              const base64data = reader.result;
              resolve(base64data);
              base64dataArray.push(base64data);
              console.log("base64dataArray", base64dataArray);

              await axios
                .post("http://localhost:4008/CustomizedProjects", {
                  UID: UUID,
                  file: base64dataArray,
                  selectedCustomizedProject: selectedCustomizedProject,
                  customizedRequirement: customizedRequirement,
                  fileName: fileName,
                })
                .then(() => {
                  console.log("success");
                })
                .catch((error) => {
                  console.log(error);
                  return;
                });
            };
          }
        } catch (error) {
          console.log("eroor in try catch", error);
        }
        console.log("base64dataArray Outside", base64dataArray);
      });
    }
  };

  return (
    <>
      {/* ------ Navbar component start ------  */}
      <AppBar
        color="white"
        elevation={3}
        position="sticky"
        className={classes.appbar}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: matchesSm ? "0rem" : "1rem" }}
          >
            <img
              src={Logo}
              alt="Logo"
              className={classes.appbarLogo}
              onClick={() => history.push("")}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" alignItems="center">
            {!matchesMd &&
              allServices.map((value, i) => (
                <button
                  id={value}
                  key={i}
                  variant="contained"
                  size="small"
                  className={classes.appbarButtons}
                  style={{
                    backgroundColor:
                      value === signUpPageSelected ? "#187CAE" : "#d3d3d3",
                    color: value === signUpPageSelected ? "white" : "black",
                    fontSize: isBetweenMdAndLg ? "1.6vh" : "1.6vh",
                  }}
                  onClick={(e) => setSignUpPageSelected(e.target.id)}
                >
                  {value}
                  {(value === "Hedge Trimming" || value === "Fall Cleanup") && (
                    <Typography
                      style={{
                        background: "red",
                        color: "white",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        marginLeft: "3px",
                        marginTop: "-5px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        pointerEvents: "none",
                      }}
                    >
                      New
                    </Typography>
                  )}
                </button>
              ))}
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ marginTop: matchesSm ? "8%" : "4%" }}
            >
              {allServices.map((value, index) => (
                <MenuItem
                  value={value}
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: matchesSm
                      ? "0.9rem"
                      : matchesMd
                        ? "0.9rem"
                        : "1rem",
                  }}
                  onClick={handlePageTop}
                >
                  {value}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box flexGrow={2} />
          <Box display="flex" alignItems="center">
            <>
              {matchesMd && (
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleDropDown}
                  className={classes.appbarButtonsMd}
                  endIcon={<ArrowDropDownIcon />}
                >
                  Services
                </Button>
              )}
            </>
          </Box>
          {!matchesSm && (
            <Button
              variant="contained"
              color="secondary"
              aria-label="Contact Us"
              onClick={() => history.push("/contact")}
              className={classes.appbarButtonsSm}
            >
              Contact Us
            </Button>
          )}
          <Box display="flex" alignItems="center">
            {matchesSm && (
              <Box sx={{ flexGrow: 0 }}>
                <div
                  className="navLink"
                  onClick={() => {
                    history.push("/contact");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: matchesSm ? "0.9rem" : "1.1rem",
                  }}
                >
                  <ContactSupport
                    fontSize="large"
                    style={{ color: "#187CAE", fontSize: 45 }}
                  />
                </div>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <div
                className="navLink"
                onClick={handleOpenUserMenu}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bolder",
                  cursor: "pointer",
                  fontSize: matchesSm ? "0.9rem" : "1.1rem",
                }}
              >
                <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                {userDetails.name}
              </div>
              <Menu
                // sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {matchesSm && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={() => history.push("/Contact")}
                  >
                    Conatct US
                  </MenuItem>
                )}
                {user && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={() => history.push("/profile")}
                  >
                    Profile
                  </MenuItem>
                )}
                {user && (
                  <MenuItem
                    value="Lawn Service"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUser(null);
                          localStorage.removeItem("user");
                          history.push("/");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Sign Out
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* ------ Navbar component end ------  */}

      <Dialog
        open={openPopupAlert}
        maxWidth="md"
        onClose={() => {
          setOpenPopupAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogTitle
            style={{
              fontWeight: "500",
            }}
            id="alert- dialog-title"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#212121",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: matchesSm ? "7%" : "5%",
                    height: matchesSm ? "7%" : "5%",
                    marginRight: "3%",
                  }}
                />
                Alert
              </span>
              <Close />
            </div>
          </DialogTitle>
          <Divider />
        </>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {popupContent} */}
            Please save your changes by clicking on submit button below.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button>Okay</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for First time Account created users */}
      <Dialog
        open={firstTimeCustomer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Paper style={{ padding: "1%" }}>
          <Typography
            style={{
              display: "grid",
              placeItems: "center",
              textAlign: "center",
              fontSize: matchesSm ? "1rem" : "",
              marginBottom: "0%",
              paddingBottom: "0%",
            }}
            className={classes.dashboardHeading}
          >
            <CheckCircleOutline
              color="primary"
              style={{ fontSize: "8vh", margin: "3%" }}
            />
            Your account has been created!
          </Typography>
          <CardContent
            style={{
              marginTop: "0%",
              paddingTop: "0%",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                fontFamily: "poppins",
                padding: "10px 0px 5px 10px",
                fontSize: matchesSm ? "1.2rem" : "1.4rem",
              }}
            >
              Welcome greetings has been sent to your email address. Now you can
              check your services's status in the dashboard.
            </Typography>
          </CardContent>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingBottom: "5%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.addressButton}
              onClick={() => {
                setFirstTimeCustomer(false);
                var firstTimeCustomer = "false";
                localStorage.setItem("FIRSTTIMEUSER", firstTimeCustomer);
              }}
            >
              Get Started
            </Button>
          </div>
        </Paper>
      </Dialog>

      {/* Popup DialogBox */}
      <Popup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        title={popupTitles}
        content={popupContent}
        actions={popupActions}
        alert
      />

      {/* Multiple address tab */}
      <div
        style={{
          display: "flex",
          paddingLeft: matchesSm ? "3%" : "8.3vw",
          marginTop: "15px",
        }}
        ref={addressRef}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.addressButton}
        >
          {address}
        </Button>
      </div>

      {/* Upcomming Services and Service History Parent Grid */}
      <Grid
        container
        justifyContent="center"
        style={{
          paddingTop: "1%",
          margin: "0 auto",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={serHistory !== null ? "5" : "10"}
          xl={serHistory !== null ? "5" : "10"}
        >
          <Paper
            elevation={0}
            style={{
              // background: "#e3ecb3",
              background: "#E5EBEB",
              padding: "1%",
              minHeight: matchesLg ? "310px" : matchesXl ? "345px" : "",
            }}
          >
            {/* upcoming service content goes here */}
            <Typography className={classes.dashboardHeading}>
              Upcoming Services
            </Typography>
            <Grid container spacing={0}>
              {currentServices.length > 0 &&
                currentServices.map((value, index) => (
                  <Grid
                    style={{
                      background:
                        value === "SpringCleanup" ? "#dad9fc" : "#D4F0FE",
                      marginBottom: "2%",
                      borderRadius: "5px",
                      boxShadow: "1px 6px 5px #acb0b0, -6px -6px 14px #ffffff",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                    item
                    lg={12}
                    xs={11}
                  >
                    {/* upcoming service item 1 goes here */}
                    <div
                      className={classes.flexSpaceBetweenRow}
                      style={{
                        flexDirection: matchesSm ? "column" : "row",
                        padding: "10px 10px 0px 10px",
                        width: "99%",
                        alignItems: matchesSm ? "center" : "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        className={classes.dashboardSecondaryHeading}
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {`${value.replace(/([a-z])([A-Z])/g, "$1 $2")}: ${""} ${serviceStatuses[value]
                          //"This Week "
                          }`}
                      </Typography>
                      <Button
                        variant="contained"
                        className={classes.sendMsgButton}
                        onClick={() => history.push("/contact")}
                      >
                        Send Message to Crew
                      </Button>
                    </div>
                    <Typography
                      variant="subtitle1"
                      style={{
                        textAlign: "left",
                        fontFamily: "poppins",
                        padding: "10px 0px 5px 10px",
                      }}
                    >
                      Note: Please remove outdoor items not part of the cleanup.
                      Please keep all family members and pets inside during
                      service.
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </Grid>

        {serHistory !== null && (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            style={{ height: "100%" }}
          >
            {/* right part content goes here */}
            <Paper
              elevation={0}
              style={{
                background: "#D0E3E7",
                padding: "1%",
                marginLeft: matchesSm ? "0%" : "3%",
                paddingLeft: "1%",
                minHeight: matchesLg
                  ? "310px"
                  : matchesXl
                    ? "345px"
                    : matchesSm
                      ? "auto"
                      : "",
              }}
            >
              {/* Service History goes here */}
              <Typography className={classes.dashboardHeading}>
                Service History
              </Typography>
              <Grid container spacing={0}>
                {serHistory !== null ? (
                  serHistory.slice(0, 4).map((value, index) => (
                    <Grid item xs={12}>
                      {/*  service History item 1 goes here */}
                      <div
                        style={{
                          padding: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          // backgroundColor: "#F7FAFA",
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                      >
                        <div className={classes.flexSpaceBetweenRow}>
                          <Typography
                            variant="h6"
                            className={classes.dashboardSecondaryHeading}
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {`${value["Service"].replace(
                              /([a-z])([A-Z])/g,
                              "$1 $2"
                            )}: ${" "} ${"on"} ${value["Date"]}`}
                          </Typography>
                          <ThumbUp
                            style={{
                              marginRight: "5px",
                              color:
                                value.Review === "Liked"
                                  ? "#187cae"
                                  : "#a5a4a4",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              value.Review = "Liked";
                              setReview("Liked");
                              updateDoc(
                                doc(
                                  db,
                                  "UserAcct",
                                  user.uid,
                                  "Services",
                                  "History2023"
                                ),
                                {
                                  History: value,
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "poppins",
                        textAlign: "center",
                      }}
                    >
                      No service has been completed yet
                    </Typography>
                  </div>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Loading Screen Backdrop */}
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress
          color="inherit" // Adjust the color as per your theme
          size={50}
          thickness={5}
        />
        <Typography variant="h6" color="inherit" style={{ fontWeight: 'bolder', marginLeft: '1%', fontSize: '3vh' }}>
          Hang tight, we're almost there!
        </Typography>
      </Backdrop>

      {/* Spring Cleanup: Manage Service List  */}
      {signUpPageSelected === "Spring Cleanup" && (
        <>
          {/* Dialog box to confirm Subscribe */}
          {lawnBEPrice !== null && (
            <Dialog
              open={springSubscribeDialogBox}
              onClose={() => {
                genericDialogBoxClose(setSpringSubscribeDialogBox);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
            >
              <Paper style={{ backgroundColor: "#233d53", padding: "1%" }}>
                <DialogTitle id="alert- dialog-title">
                  <h6
                    style={{
                      fontWeight: "bolder",
                      color: "white",
                      fontSize: "2.5vh",
                    }}
                  >
                    Order Summary
                  </h6>
                </DialogTitle>
                <DialogContent>
                  <h6
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      display: "flex",
                      fontSize: "1.8vh",
                      marginTop: "3%",
                    }}
                  >
                    Services Selected
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "10px",
                      alignContent: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "500",
                        fontSize: "1.6vh",
                      }}
                    >
                      {clickedSpringService}
                    </h6>

                    {springBEPrice?.[clickedSpringService] !== undefined && (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "1.6vh",
                        }}
                      >
                        $
                        {currentServices.includes("SpringCleanup")
                          ? Number(springDBPrice[clickedSpringService]) || 0
                          : Number(springBEPrice[clickedSpringService]) || 0}
                      </h6>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                      paddingTop: "10px",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.6vh",
                      }}
                    >
                      Service Charge
                    </h6>

                    {springBEPrice?.[clickedSpringService] !== undefined && (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "1.6vh",
                        }}
                      >
                        ${Number(springBEPrice["Surcharge"]).toFixed(2)}
                      </h6>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "2%",
                      marginTop: "3%",
                      width: "100%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "2.2vh",
                        fontWeight: "bold",
                      }}
                    >
                      Order Total
                    </h6>

                    {springBEPrice?.[clickedSpringService] !== undefined && (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "1.6vh",
                        }}
                      >
                        $
                        {currentServices.includes("SpringCleanup")
                          ? Number(springDBPrice[clickedSpringService]) +
                          Number(springDBPrice["Surcharge"]) || 0
                          : Number(springBEPrice[clickedSpringService]) +
                          Number(springBEPrice["Surcharge"]) || 0}
                      </h6>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: matchesSm ? "0%" : "1%",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      color="primary"
                      control={
                        <Checkbox
                          style={{
                            color: "white",
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={serviceAgreementTick}
                          onChange={() =>
                            setSericeAgreementTick(!serviceAgreementTick)
                          }
                          value="primary"
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementSpring"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "2%",
                              fontFamily: "poppins",
                            }}
                          >
                            Spring Service Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      genericDialogBoxClose(setSpringSubscribeDialogBox);
                    }}
                    style={{ color: "white" }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleSpringSubscribe}
                    variant="contained"
                    style={{
                      color: serviceAgreementTick ? "white" : "grey",
                      backgroundColor: serviceAgreementTick
                        ? "#187CAE"
                        : "#d3d3d3",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Subscribe
                  </Button>
                </DialogActions>
              </Paper>
            </Dialog>
          )}

          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>

                <Button
                  className={classes.addressButton}
                  onClick={() => history.push("/Contact")}
                  variant="contained"
                  disableElevation
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                >
                  Q & A
                </Button>
              </div>
              {/* Parent Grid for Services Card */}
              <Grid
                className={classes.responsiveFlex}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={11}
              >
                {/* 1st Card: Basic Spring Cleanup */}
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={9}
                  xl={4}
                  xs={11}
                  spacing={1}
                  className={SignupClass.leftItem}
                >
                  <Paper elevation={10} style={{ maxWidth: "400px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        className={classes.currentServicesHeading}
                      >
                        Standard Plan
                      </Typography>

                      {springBEPrice?.["Standard"] !== undefined ? (
                        <Typography className={classes.currentServicesPrice}>
                          <Box flexGrow={0.8} />$
                          {springBEPrice !== null
                            ? currentServices.includes("SpringCleanup")
                              ? springDBPrice["Standard"]
                              : springBEPrice["Standard"]
                            : 0.0}
                          <Box flexGrow={0.4} />
                          <Tooltip title="Do you like the price?">
                            <ThumbUp
                              style={{
                                color: localStorage.getItem("WeeklyMowCount")
                                  ? "#187cae"
                                  : "#a5a4a4",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("WeeklyMowCount")) {
                                  return;
                                } else {
                                  setWeeklyMowCount(Number(weeklyMowCount) + 1);
                                  updateDoc(thumbCount, {
                                    WeeklyMowCount: Number(weeklyMowCount) + 1,
                                  });
                                  localStorage.setItem(
                                    "WeeklyMowCount",
                                    JSON.stringify(weeklyMowCount)
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          {weeklyMowCount ? (
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "normal",
                                paddingLeft: "6px",
                              }}
                            >
                              {weeklyMowCount}
                            </p>
                          ) : (
                            "10"
                          )}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}
                      {/* Weekly Mowing: Feature */}
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Blow out the lawn of dead leaves
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Trim out small plants upto 3ft tall
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Rake out the lawn
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Dethatch the lawn
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Overseeding the lawn
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Throw away additional debris and fallen branch
                        </Typography>
                      </div>
                    </CardContent>

                    {springDBSelected[0] === "Standard" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        disabled={springDBSelected[0] === "Standard"}
                        id="Standard"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor: "#d3d3d3",
                          color: "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          fontSize: "1.8vh",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          cursor: "pointer",
                          height: "3vh",
                          minHeight: "40px",
                        }}
                        onClick={(e) => handleSpringSubscribeButton(e)}
                      >
                        Select
                      </button>
                    )}
                  </Paper>
                </Grid>

                {/* 2nd Card: Popular Premium Cleanup */}
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={9}
                  xl={4}
                  xs={11}
                  spacing={1}
                  style={{ marginBottom: "0%" }}
                  className={SignupClass.leftItem}
                >
                  <Paper elevation={10} style={{ maxWidth: "400px" }}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "5px 5px 0px 0px",
                        fontSize: "16px",
                        fontFamily: "poppins",
                        fontWeight: "bold",
                      }}
                    >
                      Most Popular
                    </div>
                    <CardContent>
                      <Typography
                        variant="h6"
                        className={classes.currentServicesHeading}
                      >
                        Premium Plan
                      </Typography>

                      {springBEPrice?.["Premium"] !== undefined ? (
                        <Typography className={classes.currentServicesPrice}>
                          <Box flexGrow={0.8} />
                          <span>
                            {" "}
                            $
                            {springBEPrice !== null
                              ? currentServices.includes("SpringCleanup")
                                ? springDBPrice["Premium"]
                                : springBEPrice["Premium"]
                              : 0.0}
                          </span>
                          <Box flexGrow={0.4} />
                          <Tooltip title="Do you like the price?">
                            <ThumbUp
                              style={{
                                color: localStorage.getItem("BiWeeklyMowCount")
                                  ? "#187cae"
                                  : "#a5a4a4",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("BiWeeklyMowCount")) {
                                  return;
                                } else {
                                  setBiWeeklyMowCount(
                                    Number(biweeklyMowCount) + 1
                                  );
                                  updateDoc(thumbCount, {
                                    BiWeeklyMowCount:
                                      Number(biweeklyMowCount) + 1,
                                  });
                                  localStorage.setItem(
                                    "BiWeeklyMowCount",
                                    JSON.stringify(biweeklyMowCount)
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          {biweeklyMowCount ? (
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "normal",
                                paddingLeft: "6px",
                              }}
                            >
                              {biweeklyMowCount}
                            </p>
                          ) : (
                            "10"
                          )}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}

                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Blow out the lawn of dead leaves
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Trim out small plants upto 3ft tall
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Rake out the lawn
                        </Typography>
                      </div>
                      {/* <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Dethatch the lawn
                        </Typography>
                      </div> */}
                      {/* <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Overseeding the lawn
                        </Typography>
                      </div> */}
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Throw away additional debris
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Dethatch the lawn
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Areate the lawn
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check fontSize="small" />
                        <Typography className={classes.currentServicesFeatures}>
                          Trim back bushes less than 6ft
                        </Typography>
                      </div>
                    </CardContent>

                    {springDBSelected[0] === "Premium" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        id="Premium"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor: "#d3d3d3",
                          color: "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          fontSize: "1.8vh",
                          height: "3vh",
                          cursor: "pointer",
                          minHeight: "40px",
                        }}
                        onClick={(e) => handleSpringSubscribeButton(e)}
                      >
                        Select
                      </button>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <br />

              <div style={{ width: "100%" }}>
                <Typography style={{ paddingBottom: "2%" }}>
                  <>
                    <span>Click to read</span>
                    <span>
                      <Link
                        href="/ServiceAgreementSpring"
                        target="_blank"
                        className={SignupClass.link}
                        style={{
                          border: "none",
                          color: "#187CAE",
                          fontWeight: "bold",
                          fontSize: "15px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontFamily: "poppins",
                        }}
                      >
                        Full contract details
                      </Link>{" "}
                    </span>
                    <span> - features and responsibilities </span>
                  </>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* Lawn Mowing: Manage Service List  */}
      {signUpPageSelected === "Lawn Mowing" && (
        <>
          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>

              {/* Parent Grid for Services Card */}
              <Grid
                lg={12}
                xl={12}
                md={12}
                sm={12}
                xs={12}
                container
                style={{
                  width: "100%",
                  backgroundColor: "#b3e2ec",
                  paddingBottom: "3%",
                  maxWidth: !matchesSm ? "98%" : "",
                }}
                alignItems="center"
                justifyContent={matchesSm ? "flex-start" : "center"}
              >
                {/* 1st Card: Weekly Mowing */}
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={5}
                  xl={3}
                  xs={11}
                  spacing={1}
                  className={SignupClass.leftItem}
                  style={{ marginBottom: "3%" }}
                >
                  <Paper
                    elevation={10}
                    style={{ maxWidth: !matchesSm ? "400px" : "" }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        className={classes.currentServicesHeading}
                      >
                        Standard Plan
                      </Typography>

                      {/* Weekly Mowing: Price  */}
                      {lawnBEPrice?.["Standard"] !== undefined ? (
                        <Typography className={classes.currentServicesPrice}>
                          <Box flexGrow={0.8} />$
                          {assignPriceAccordingly(
                            lawnBEPrice,
                            lawnDBPrice,
                            "LawnMowing",
                            "Standard"
                          )}
                          <Box flexGrow={0.4} />
                          <Tooltip title="Do you like the price?">
                            <ThumbUp
                              style={{
                                color: localStorage.getItem("WeeklyMowCount")
                                  ? "#187cae"
                                  : "#a5a4a4",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("WeeklyMowCount")) {
                                  return;
                                } else {
                                  setWeeklyMowCount(Number(weeklyMowCount) + 1);
                                  updateDoc(thumbCount, {
                                    WeeklyMowCount: Number(weeklyMowCount) + 1,
                                  });
                                  localStorage.setItem(
                                    "WeeklyMowCount",
                                    JSON.stringify(weeklyMowCount)
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          {weeklyMowCount ? (
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "normal",
                                paddingLeft: "6px",
                              }}
                            >
                              {weeklyMowCount}
                            </p>
                          ) : (
                            "10"
                          )}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}

                      {/* Weekly Mowing: Feature */}
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          Discounted prices based on weekly mowing service
                          subscription from April 15 till the end of the Fall
                          mowing season usually on Nov 30th.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          This plan ensures availability and mowing service once
                          a week where weather premits.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          You agreed to pay this weekly price after each
                          service. there is no risk to you and noting to pay at
                          signup.
                        </Typography>
                      </div>
                    </CardContent>

                    {/* Weekly Mowing: Action Button */}
                    {lawnDBSelected === "Standard" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        disabled={lawnDBSelected === "Standard"}
                        id="Standard"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            clickedLawnService === "Standard"
                              ? "#187CAE"
                              : "#d3d3d3",
                          color:
                            clickedLawnService === "Standard"
                              ? "white"
                              : "black",
                          cursor: "pointer",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          height: "3vh",
                          fontSize: "1.8vh",
                          minHeight: "40px",
                        }}
                        onClick={(e) => {
                          handleLawnSubscribeButton(e, selectedOption);
                        }}
                      >
                        {clickedLawnService === "Standard" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Subscribed{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    )}
                  </Paper>
                </Grid>

                {/* 2nd Card: Bi-Weekly Mowing */}
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={5}
                  xl={3}
                  xs={11}
                  spacing={1}
                  style={{ marginBottom: "1%" }}
                  className={SignupClass.leftItem}
                >
                  <Paper
                    elevation={10}
                    style={{ maxWidth: !matchesSm ? "400px" : "" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "5px 5px 0px 0px",
                        fontSize: "16px",
                        fontFamily: "poppins",
                        fontWeight: "bold",
                      }}
                    >
                      Most Popular
                    </div>
                    <CardContent>
                      <Typography
                        variant="h6"
                        className={classes.currentServicesHeading}
                      >
                        Premium Plan
                      </Typography>

                      {/* Bi-Weekly Mowing: Price */}
                      {lawnBEPrice?.["Premium"] !== undefined ? (
                        <Typography className={classes.currentServicesPrice}>
                          <Box flexGrow={0.8} />
                          <span>
                            $
                            {assignPriceAccordingly(
                              lawnBEPrice,
                              lawnDBPrice,
                              "LawnMowing",
                              "Premium"
                            )}
                          </span>
                          <Box flexGrow={0.4} />
                          <Tooltip title="Do you like these price?">
                            <ThumbUp
                              style={{
                                color: localStorage.getItem("BiWeeklyMowCount")
                                  ? "#187cae"
                                  : "#a5a4a4",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("BiWeeklyMowCount")) {
                                  return;
                                } else {
                                  setBiWeeklyMowCount(
                                    Number(biweeklyMowCount) + 1
                                  );
                                  updateDoc(thumbCount, {
                                    BiWeeklyMowCount:
                                      Number(biweeklyMowCount) + 1,
                                  });
                                  localStorage.setItem(
                                    "BiWeeklyMowCount",
                                    JSON.stringify(biweeklyMowCount)
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          {biweeklyMowCount ? (
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "normal",
                                paddingLeft: "6px",
                              }}
                            >
                              {biweeklyMowCount}
                            </p>
                          ) : (
                            "10"
                          )}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}

                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          Discounted prices based on Bi-Weekly mowing service
                          subscription from April 15 till the end of the Fall
                          mowing season usually on Nov 30th.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          This plan ensures availability and mowing service once
                          every 2 weeks where weather premits.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography className={classes.currentServicesFeatures}>
                          You agreed to pay this Bi-weekly price after each
                          service. there is no risk to you and noting to pay at
                          signup.
                        </Typography>
                      </div>
                    </CardContent>

                    {lawnDBSelected === "Premium" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        id="Premium"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            clickedLawnService === "Premium"
                              ? "#187CAE"
                              : "#d3d3d3",
                          color:
                            clickedLawnService === "Premium"
                              ? "white"
                              : "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          height: "3vh",
                          cursor: "pointer",
                          minHeight: "40px",
                          fontSize: "1.8vh",
                        }}
                        onClick={(e) => {
                          handleLawnSubscribeButton(e, selectedOption);
                        }}
                      >
                        {clickedLawnService === "Premium" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Subscribed{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    )}
                  </Paper>
                </Grid>

                <Grid
                  item
                  lg={3}
                  xl={3}
                  md={8}
                  sm={11}
                  xs={11}
                  spacing={2}
                  className={SignupClass.leftItem}
                >
                  {
                    <Paper elevation={3}>
                      <CardContent>
                        {
                          <>
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                lineHeight: "22px",
                                fontFamily: "poppins",
                                fontWeight: "bold",
                                marginBottom: "2vh",
                              }}
                            >
                              Select Mowing frequency
                            </Typography>
                            {["Weekly", "Bi-Weekly"].map((key) => {
                              return (
                                <button
                                  id={clickedLawnService}
                                  key={key}
                                  style={{
                                    margin: "2%",
                                    marginBottom: "2vh",
                                    background:
                                      selectedOption === key
                                        ? "#187cae"
                                        : "#d3d3d3",
                                    color:
                                      selectedOption === key
                                        ? "white"
                                        : "black",
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    border: "none",
                                    minWidth: "150px",
                                    height: "3vh",
                                    cursor: "pointer",
                                    minHeight: "60px",
                                    fontSize: "2.3vh",
                                    width: "90%",
                                    padding: "1%",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={(e) =>
                                    handleLawnSubscribeButton(e, key)
                                  }
                                >
                                  {key}{" "}
                                  {key === "Bi-Weekly" &&
                                    allPrices !== null && (
                                      <span>
                                        (+${allPrices["MowBiWeekly"]}/yd)
                                      </span>
                                    )}
                                  {selectedOption === key && (
                                    <Check
                                      style={{
                                        color: "white",
                                        fontSize: "3vh",
                                        marginLeft: "3%",
                                      }}
                                    />
                                  )}
                                </button>
                              );
                            })}
                          </>
                        }
                      </CardContent>
                    </Paper>
                  }
                </Grid>

                {/* Addons Lawn : */}
                <Grid
                  className={classes.responsiveFlex}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={11}
                >
                  <Grid
                    item
                    lg={11}
                    xl={11}
                    md={8}
                    sm={11}
                    xs={11}
                    className={SignupClass.leftItem}
                    spacing={1}
                  >
                    {
                      <Paper elevation={3}>
                        <CardContent>
                          <>
                            <CardContent>
                              <Typography
                                variant="h6"
                                component="div"
                                style={{
                                  lineHeight: "22px",
                                  fontFamily: "poppins",
                                  marginBottom: "2vh",
                                  fontWeight: "bold",
                                }}
                              >
                                Addons
                                {clickedLawnService !== lawnDBSelected ||
                                  trackAddonChange["LawnMowing"] !== false ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "16px",
                                        color: "red",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: "10px",
                                        border: "1px solid red",
                                        borderRadius: "4px",
                                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                                        maxWidth: "500px", // Set a maximum width for the element
                                        marginTop: "2%",
                                      }}
                                    >
                                      Click on 'Save Changes' Button to Update
                                      Plan!
                                    </Typography>
                                  </div>
                                ) : null}
                              </Typography>
                              {lawnBEAddons !== null ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: matchesMd ? "column" : "row",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    paddingBottom: "2vh",
                                  }}
                                >
                                  {Object.keys(lawnBEAddons).map((key, i) => {
                                    return (
                                      <div
                                        id={key.id}
                                        style={{
                                          alignItems: "center",
                                          width: "100%",
                                          margin: "3px",
                                          marginBottom: matchesSm ? "5%" : "0%",
                                          background: selectedAddonsLawn[key]
                                            ? "#187cae"
                                            : "#d3d3d3",
                                          color: selectedAddonsLawn[key]
                                            ? "white"
                                            : "black",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                          display: "flex",
                                          height: "100%",
                                          maxHeight: !matchesMd ? "6.3rem" : "",
                                          minHeight: !matchesMd ? "6.3rem" : "",
                                          maxWidth: !matchesMd ? "21.2vw" : "",
                                        }}
                                        onClick={() =>
                                          handleSelect(
                                            key,
                                            Number(lawnBEAddons[key]).toFixed(
                                              2
                                            ),
                                            setSelectedAddonsLawn,
                                            setTotalLawnAddon,
                                            selectedAddonsLawn,
                                            clickedLawnService,
                                            "Lawn"
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            // justifyContent: "space-between",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "100%",
                                            fontSize: "1.7vh",
                                            fontWeight: "bold",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <img
                                            src={LawnAddons[i].img}
                                            style={{
                                              borderRadius: "5px",
                                              width: "40%",
                                              maxHeight: "13vh",
                                              minHeight: "100px",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                          <div style={{ width: "100%" }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: matchesSm
                                                  ? "98%"
                                                  : "90%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "3%",
                                                  width: "100%",
                                                  marginLeft: "5%",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: "2.5vh",
                                                    color: selectedAddonsLawn[
                                                      key
                                                    ]
                                                      ? "white"
                                                      : "black",
                                                    fontWeight: "bold",
                                                    width: "100%",
                                                    minWidth: "90%",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {key}
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    fontSize: "1.7vh",
                                                    fontWeight: "bold",
                                                    width: "100%",
                                                    textAlign: "left",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: selectedAddonsLawn[
                                                      key
                                                    ]
                                                      ? "white"
                                                      : "black",
                                                  }}
                                                >
                                                  ${Number(lawnBEAddons[key])}
                                                  <span
                                                    style={{
                                                      fontWeight: "normal",
                                                      marginLeft: "3px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Estimated
                                                  </span>
                                                  {selectedAddonsLawn[key] && (
                                                    <Check
                                                      style={{
                                                        color: "white",
                                                        fontSize: "2.5vh",
                                                        marginLeft: "5%",
                                                      }}
                                                    />
                                                  )}
                                                </Typography>
                                              </div>
                                              <Tooltip
                                                arrow
                                                title={
                                                  <Typography
                                                    style={{
                                                      fontSize: "1.7vh",
                                                      fontWeight: "bold",
                                                      width: "100%",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {LawnAddons[i].description}
                                                  </Typography>
                                                }
                                              >
                                                <InfoIcon
                                                  style={{
                                                    fontSize: "3vh",
                                                    color: selectedAddonsLawn[
                                                      key
                                                    ]
                                                      ? "white"
                                                      : "#187cae",
                                                  }}
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <CircularProgress />
                              )}
                            </CardContent>
                          </>
                        </CardContent>
                      </Paper>
                    }
                  </Grid>
                </Grid>
                <br />
                <div style={{ width: "100%" }}>
                  <Button
                    className={
                      clickedLawnService !== lawnDBSelected ||
                        trackAddonChange["LawnMowing"] !== false
                        ? "holderButton"
                        : ""
                    }
                    size="large"
                    variant="contained"
                    style={{
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#314A87",
                    }}
                    onClick={() => {
                      setTrackSelectedAddons((service) => ({
                        ...service,
                        LawnMowing: Math.random().toString(36).slice(2),
                      }));
                    }}
                  >
                    Save Changes
                  </Button>
                  <Typography style={{ marginTop: "20px" }}>
                    <>
                      <span>Click to read</span>
                      <span>
                        <Link
                          href="/ServiceAgreementLawn"
                          target="_blank"
                          className={SignupClass.link}
                          style={{
                            border: "none",
                            color: "#187CAE",
                            fontWeight: "bold",
                            fontSize: "15px",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginLeft: "1%",
                            fontFamily: "poppins",
                          }}
                        >
                          Full contract details
                        </Link>{" "}
                      </span>
                      <span> - features and responsibilities </span>
                    </>
                  </Typography>
                </div>

                {/* Dialog box to confirm Subscribe */}
                {lawnBEPrice && (
                  <>
                    <Dialog
                      open={lawnSubscribeDialogBox}
                      onClose={() => {
                        genericDialogBoxClose(setLawnSubscribeDialogBox);
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth
                    >
                      <Paper
                        style={{ backgroundColor: "#233d53", padding: "1%" }}
                      >
                        <DialogTitle id="alert-dialog-title">
                          <h6
                            style={{
                              fontWeight: "bolder",
                              color: "white",
                              fontSize: "2.5vh",
                            }}
                          >
                            Order Summary
                          </h6>
                        </DialogTitle>
                        <DialogContent>
                          <h6
                            style={{
                              color: "#ECECEC",
                              fontWeight: "600",
                              display: "flex",
                              fontSize: "1.8vh",
                              marginTop: "3%",
                            }}
                          >
                            Services Selected
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "10px",
                              alignContent: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "500",
                                fontSize: "1.6vh",
                              }}
                            >
                              Mowing Frequency
                            </h6>

                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.6vh",
                              }}
                            >
                              {selectedOption}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "10px",
                              alignContent: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "500",
                                fontSize: "1.6vh",
                              }}
                            >
                              {clickedLawnService}
                            </h6>

                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.6vh",
                              }}
                            >
                              $
                              {assignPriceAccordingly(
                                lawnBEPrice,
                                lawnDBPrice,
                                "LawnMowing",
                                clickedLawnService
                              )}
                            </h6>
                          </div>
                          {totalLawnAddon > 0 && (
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "10px",
                                alignContent: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <h6
                                className={classes.title}
                                style={{
                                  color: "#ECECEC",
                                  fontWeight: "500",
                                  fontSize: "1.6vh",
                                }}
                              >
                                Addons (Lawn)
                              </h6>

                              {totalLawnAddon > 0 && (
                                <h6
                                  className={classes.title}
                                  style={{
                                    color: "#ECECEC",
                                    fontWeight: "500",
                                    fontSize: "1.3vh",
                                  }}
                                >
                                  {Object.keys(selectedAddonsLawn)
                                    .filter((key) => selectedAddonsLawn[key])
                                    .map((addon) => {
                                      return (
                                        <p style={{ textAlign: "right" }}>
                                          {addon} ($
                                          {Number(lawnBEAddons[addon])})
                                        </p>
                                      );
                                    })}
                                </h6>
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "space-between",
                              paddingTop: "10px",
                            }}
                          >
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "400",
                                fontSize: "1.6vh",
                              }}
                            >
                              Service Charge
                            </h6>

                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "1.6vh",
                              }}
                            >
                              $
                              {lawnBEPrice &&
                                Number(lawnBEPrice["Surcharge"]).toFixed(2)}
                            </h6>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "2%",
                              marginTop: "3%",
                              width: "100%",
                            }}
                          >
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "400",
                                fontSize: "2.2vh",
                                fontWeight: "bold",
                              }}
                            >
                              Order Total
                            </h6>

                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "600",
                                fontSize: "3vh",
                              }}
                            >
                              $
                              {lawnBEPrice
                                ? currentServices.includes("LawnMowing")
                                  ? (
                                    Number(lawnDBPrice[clickedLawnService]) +
                                    Number(lawnDBPrice["Surcharge"]) +
                                    Number(totalLawnAddon)
                                  ).toFixed(2)
                                  : (
                                    Number(lawnBEPrice[clickedLawnService]) +
                                    Number(lawnBEPrice["Surcharge"]) +
                                    Number(totalLawnAddon)
                                  ).toFixed(2)
                                : 0.0}
                            </h6>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: matchesSm ? "0%" : "1%",
                              marginTop: "-2%",
                            }}
                          >
                            <FormControlLabel
                              color="primary"
                              control={
                                <Checkbox
                                  style={{
                                    color: "white",
                                    "& .MuiSvgIcon-root": {
                                      color: "white",
                                    },
                                  }}
                                  required
                                  color="primary"
                                  checked={serviceAgreementTick}
                                  onChange={() =>
                                    setSericeAgreementTick(
                                      !serviceAgreementTick
                                    )
                                  }
                                  value="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              }
                            />
                            <h6
                              className={classes.title}
                              style={{
                                color: "#ECECEC",
                                fontWeight: "400",
                                fontSize: "1.3vh",
                                textAlign: "left",
                                padding: "0px",
                                width: "90%",
                              }}
                            >
                              <>
                                <span>I agree to the</span>
                                <span>
                                  <Link
                                    href="/ServiceAgreementLawn"
                                    target="_blank"
                                    className={SignupClass.link}
                                    style={{
                                      border: "none",
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "1.6vh",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      marginLeft: "2%",
                                      fontFamily: "poppins",
                                    }}
                                  >
                                    Lawn Service Contract
                                  </Link>
                                </span>
                              </>
                            </h6>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              genericDialogBoxClose(setLawnSubscribeDialogBox);
                              setLoading(true);
                              setCheckState(
                                Math.random().toString(36).slice(2)
                              );
                            }}
                            style={{ color: "white" }}
                          >
                            No
                          </Button>
                          <Button
                            onClick={handleSubscribeLawn}
                            variant="contained"
                            style={{
                              color: serviceAgreementTick ? "white" : "grey",
                              backgroundColor: serviceAgreementTick
                                ? "#187CAE"
                                : "#d3d3d3",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            Subscribe
                          </Button>
                        </DialogActions>
                      </Paper>
                    </Dialog>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/*  */}
        </>
      )}

      {/* Fall Cleanup: Manage Service List  */}
      {signUpPageSelected === "Fall Cleanup" && (
        <>
          {/* Dialog box to confirm Subscribe */}
          {lawnBEPrice !== null && (
            <Dialog
              open={fallSubscribeDialogBox}
              onClose={() => {
                genericDialogBoxClose(setFallSubscribeDialogBox);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
            >
              <Paper style={{ backgroundColor: "#233d53", padding: "1%" }}>
                <DialogTitle id="alert- dialog-title">
                  <h6
                    style={{
                      fontWeight: "bolder",
                      color: "white",
                      fontSize: "2.5vh",
                    }}
                  >
                    Order Summary
                  </h6>
                </DialogTitle>
                <DialogContent>
                  <h6
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      display: "flex",
                      fontSize: "1.8vh",
                      marginTop: "3%",
                    }}
                  >
                    Services Selected
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "10px",
                      alignContent: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "500",
                        fontSize: "1.6vh",
                      }}
                    >
                      {clickedFallService}
                    </h6>

                    {fallService?.fallDBPrice[clickedFallService] !==
                      undefined && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "600",
                            fontSize: "1.6vh",
                          }}
                        >
                          $
                          {assignPriceAccordingly(
                            fallService.fallBEPrice,
                            fallService.fallDBPrice,
                            "FallCleanup",
                            clickedFallService
                          )}
                        </h6>
                      )}
                  </div>
                  {Object.keys(selectedAddonsFall).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px",
                        alignContent: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "500",
                          fontSize: "1.6vh",
                        }}
                      >
                        Addons (Fall)
                      </h6>

                      {Object.keys(selectedAddonsFall).length > 0 && (
                        <h6
                          className={classes.title}
                          style={{
                            color: "#ECECEC",
                            fontWeight: "500",
                            fontSize: "1.3vh",
                          }}
                        >
                          {Object.keys(selectedAddonsFall)
                            .filter((key) => selectedAddonsFall[key])
                            .map((addon) => {
                              return (
                                <p style={{ textAlign: "right" }}>
                                  {addon} (${fallBEAddons[addon] !== 'TBD' ? Number(fallBEAddons[addon]) : fallBEAddons[addon]})
                                </p>
                              );
                            })}
                        </h6>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "2%",
                      marginTop: "3%",
                      width: "100%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "2.2vh",
                        fontWeight: "bold",
                      }}
                    >
                      Order Total
                    </h6>

                    {fallService?.fallBEPrice[clickedFallService] !==
                      undefined ? (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "600",
                          fontSize: "1.6vh",
                        }}
                      >
                        ${" "}
                        {Number(
                          Number(
                            assignPriceAccordingly(
                              fallService.fallBEPrice,
                              fallService.fallDBPrice,
                              "FallCleanup",
                              clickedFallService
                            )
                          ) + Number(totalFallAddon)
                        ).toFixed(2)}
                      </h6>
                    ) : (
                      "0"
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: matchesSm ? "0%" : "1%",
                      marginTop: "-2%",
                    }}
                  >
                    <FormControlLabel
                      color="primary"
                      control={
                        <Checkbox
                          style={{
                            color: "white",
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                          }}
                          required
                          color="primary"
                          checked={serviceAgreementTick}
                          onChange={() =>
                            setSericeAgreementTick(!serviceAgreementTick)
                          }
                          value="primary"
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      }
                    />
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "400",
                        fontSize: "1.3vh",
                        textAlign: "left",
                        padding: "0px",
                        width: "90%",
                      }}
                    >
                      <>
                        <span>I agree to the</span>
                        <span>
                          <Link
                            href="/ServiceAgreementFall"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.6vh",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "2%",
                              fontFamily: "poppins",
                            }}
                          >
                            Fall Service Contract
                          </Link>
                        </span>
                      </>
                    </h6>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      genericDialogBoxClose(setFallSubscribeDialogBox);
                      setLoading(true);
                      setCheckState(Math.random().toString(36).slice(2));
                    }}
                    style={{ color: "white" }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleSubscribeFall}
                    variant="contained"
                    style={{
                      color: serviceAgreementTick ? "white" : "grey",
                      backgroundColor: serviceAgreementTick
                        ? "#187CAE"
                        : "#d3d3d3",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    Select
                  </Button>
                </DialogActions>
              </Paper>
            </Dialog>
          )}

          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>

                <Button
                  className={classes.addressButton}
                  onClick={() => history.push("/Contact")}
                  variant="contained"
                  disableElevation
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                >
                  Q & A
                </Button>
              </div>
              {/* Parent Grid for Services Card */}
              <Grid
                className={classes.responsiveFlex}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={11}
              >
                {/* 1st Card: Basic Fall Cleanup */}
                <Grid
                  item
                  xl={3}
                  lg={5}
                  md={5}
                  sm={11}
                  xs={12}
                  spacing={1}
                  className={SignupClass.leftItem}
                >
                  <Paper elevation={10} style={{ maxWidth: "350px" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        className={classes.currentServicesHeading}
                      >
                        Standard Plan
                      </Typography>

                      {fallService.fallBEPrice !== null ? (
                        <Typography
                          color="text.secondary"
                          className={classes.currentServicesPrice}
                        >
                          $
                          {assignPriceAccordingly(
                            fallService.fallBEPrice,
                            fallService.fallDBPrice,
                            "FallCleanup",
                            "Standard"
                          )}
                          {/* <Typography
                            style={{
                              fontSize: matchesSm ? "14px" : "16px",
                              backgroundColor: "red",
                              paddingLeft: "3px",
                              paddingRight: "3px",
                              marginLeft: "5px",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            $50 savings
                          </Typography> */}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}
                      <div style={{ display: "grid", placeItems: "center" }}>
                        {/* <div style={{ display: "flex" }}>
                          <Typography
                            style={{
                              marginBottom: "5%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#423e3e",
                            }}
                          >
                            1 Time Fall Cleanup Service
                          </Typography>
                        </div> */}
                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Blow out the lawn of dead leaves.
                          </Typography>
                        </div>
                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Rake out the lawn.
                          </Typography>
                        </div>

                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Pick up small fallen branches.
                          </Typography>
                        </div>
                      </div>
                    </CardContent>

                    {fallService.fallDbSelected === "Standard" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        disabled={fallService.fallDbSelected === "Standard"}
                        id="Standard"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            clickedFallService === "Standard"
                              ? "#187CAE"
                              : "#d3d3d3",
                          color:
                            clickedFallService === "Standard"
                              ? "white"
                              : "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          fontSize: "1.8vh",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          cursor: "pointer",
                          height: "3vh",
                          minHeight: "40px",
                        }}
                        onClick={(e) => handleFallSubscribeButton(e)}
                      >
                        {clickedFallService === "Standard" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Selected{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Select"
                        )}
                      </button>
                    )}
                  </Paper>
                </Grid>

                {/* 2nd Card: Popular Fall Cleanup */}
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={11}
                  xl={3}
                  xs={12}
                  spacing={1}
                  style={{ marginBottom: "0%" }}
                  className={SignupClass.leftItem}
                >
                  <Paper elevation={8} style={{ maxWidth: "350px" }}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "5px 5px 0px 0px",
                        fontSize: "16px",
                        fontFamily: "poppins",
                        fontWeight: "bold",
                      }}
                    >
                      Most Popular
                    </div>
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        className={classes.currentServicesHeading}
                      >
                        Premium Plan
                      </Typography>

                      {/* Premium Mulch */}
                      {fallService.fallBEPrice !== null ? (
                        <Typography
                          color="text.secondary"
                          className={classes.currentServicesPrice}
                        >
                          <span>
                            {" "}
                            $
                            {assignPriceAccordingly(
                              fallService.fallBEPrice,
                              fallService.fallDBPrice,
                              "FallCleanup",
                              "Premium"
                            )}
                          </span>

                          {/* <Typography
                            style={{
                              textDecoration: "line-through",
                              fontSize: matchesSm ? "14px" : "16px",
                            }}
                          >
                            {" "}
                            $
                            {Math.trunc(
                              assignPriceAccordingly(fallService.fallBEPrice, fallService.fallDBPrice, 'FallCleanup', 'Premium') + 100
                            )}
                          </Typography> */}
                          {/* <Typography
                            style={{
                              fontSize: matchesSm ? "14px" : "16px",
                              backgroundColor: "red",
                              paddingLeft: "3px",
                              paddingRight: "3px",
                              marginLeft: "5px",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            $100 savings
                          </Typography> */}
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}

                      <div style={{ display: "grid", placeItems: "center" }}>
                        {/* <div style={{ display: "flex" }}>
                          <Typography
                            style={{
                              marginBottom: "5%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#423e3e",
                            }}
                          >
                            3 Time Fall Cleanup Service
                          </Typography>
                        </div> */}
                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Blow out the lawn of dead leaves.
                          </Typography>
                        </div>
                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Rake out the lawn.
                          </Typography>
                        </div>

                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Pick up small fallen branches.
                          </Typography>
                        </div>
                        <div style={{ display: "flex", marginRight: "auto" }}>
                          <Check />
                          <Typography
                            style={{
                              marginBottom: "3%",
                              textAlign: "left",
                              fontFamily: "poppins",
                              paddingLeft: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Aerate the lawn.
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                    {fallService.fallDbSelected === "Premium" ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          fontWeight: "bold",
                          border: "2px solid #187CAE",
                          textTransform: "capitalize",
                          minWidth: "100px",
                        }}
                      >
                        Your Current Service
                      </Button>
                    ) : (
                      <button
                        id="Premium"
                        variant="contained"
                        size="small"
                        style={{
                          margin: "2%",
                          marginBottom: "5%",
                          backgroundColor:
                            clickedFallService === "Premium"
                              ? "#187CAE"
                              : "#d3d3d3",
                          color:
                            clickedFallService === "Premium"
                              ? "white"
                              : "black",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          border: "none",
                          minWidth: "100px",
                          width: "50%",
                          fontSize: "1.8vh",
                          height: "3vh",
                          cursor: "pointer",
                          minHeight: "40px",
                        }}
                        onClick={(e) => handleFallSubscribeButton(e)}
                      >
                        {clickedFallService === "Premium" ? (
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            Selected{" "}
                            <Check
                              fontSize="small"
                              style={{ marginLeft: "25%" }}
                            />
                          </span>
                        ) : (
                          "Select"
                        )}
                      </button>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                className={classes.responsiveFlex}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={11}
              >
                <Grid
                  item
                  lg={11}
                  xl={11}
                  md={8}
                  sm={11}
                  xs={11}
                  className={SignupClass.leftItem}
                  spacing={1}
                >
                  {/* Addons Fall : */}
                  <Paper elevation={3}>
                    <CardContent>
                      <>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            lineHeight: "22px",
                            fontFamily: "poppins",
                            marginBottom: "2vh",
                            fontWeight: "bold",
                            paddingTop: "2vh",
                          }}
                        >
                          Addons
                          {clickedFallService !== fallService.fallDbSelected ||
                            trackAddonChange["FallCleanup"] !== false ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "16px",
                                  color: "red",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  padding: "10px",
                                  border: "1px solid red",
                                  borderRadius: "4px",
                                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                                  maxWidth: "500px", // Set a maximum width for the element
                                  marginTop: "2%",
                                }}
                              >
                                Click on 'Save Changes' Button to Update Plan!
                              </Typography>
                            </div>
                          ) : null}
                        </Typography>
                        <div>
                          {fallBEAddons !== null ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: matchesMd ? "column" : "row",
                                justifyContent: "center",
                                height: "100%",
                                paddingBottom: "2vh",
                              }}
                            >
                              {Object.keys(fallBEAddons).map((key, i) => {
                                return (
                                  <>
                                    <div
                                      id={fallBEAddons[key]}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "3px",
                                        marginBottom: matchesSm ? "3%" : "0%",
                                        background: selectedAddonsFall[key]
                                          ? "#187cae"
                                          : "#d3d3d3",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        height: "100%",
                                        maxHeight: !matchesMd ? "6.3rem" : "",
                                        minHeight: !matchesMd ? "6.3rem" : "",
                                        maxWidth: !matchesMd ? "22.3vw" : "",
                                      }}
                                      onClick={() =>
                                        handleSelect(
                                          key,
                                          fallBEAddons[key] !== 'TBD' ? Number(fallBEAddons[key]).toFixed(2) : fallBEAddons[key],
                                          setSelectedAddonsFall,
                                          setTotalFallAddon,
                                          selectedAddonsFall,
                                          clickedFallService,
                                          "Fall"
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          // justifyContent: "space-between",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "1.7vh",
                                          fontWeight: "bold",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <img
                                          src={
                                            FallCleanupAddons[i].addonName ===
                                            key && FallCleanupAddons[i].img
                                          }
                                          style={{
                                            borderRadius: "5px",
                                            width: "40%",
                                            maxHeight: "13vh",
                                            minHeight: "100px",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div style={{ width: "100%" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: matchesSm ? "98%" : "90%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                margin: "3%",
                                                width: "100%",
                                                marginLeft: "5%",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontSize: "2.5vh",
                                                  color: selectedAddonsFall[key]
                                                    ? "white"
                                                    : "black",
                                                  fontWeight: "bold",
                                                  width: "100%",
                                                  minWidth: "90%",
                                                  whiteSpace: "nowrap",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {key.includes("&") ? (
                                                  <>
                                                    {key
                                                      .split("&")
                                                      .map((part, index) => (
                                                        <Typography
                                                          key={index}
                                                          style={{
                                                            fontSize: "2.5vh",
                                                            color:
                                                              selectedAddonsFall[
                                                                key
                                                              ]
                                                                ? "white"
                                                                : "black",
                                                            fontWeight: "bold",
                                                            width: "100%",
                                                            minWidth: "90%",
                                                            whiteSpace:
                                                              "nowrap",
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {index ===
                                                            key.split("&")
                                                              .length -
                                                            1
                                                            ? part
                                                            : part + " & "}
                                                        </Typography>
                                                      ))}
                                                  </>
                                                ) : (
                                                  <>{key}</>
                                                )}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontSize: "1.7vh",
                                                  fontWeight: "bold",
                                                  width: "100%",
                                                  textAlign: "left",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  color: selectedAddonsFall[key]
                                                    ? "white"
                                                    : "black",
                                                }}
                                              >
                                                ${fallBEAddons[key] !== 'TBD' ? Number(fallBEAddons[key]) : 'TBD'}
                                                <span
                                                  style={{
                                                    fontWeight: "normal",
                                                    marginLeft: "3px",
                                                  }}
                                                >
                                                  {" "}
                                                  Estimated
                                                </span>
                                                {selectedAddonsFall[key] && (
                                                  <Check
                                                    style={{
                                                      color: "white",
                                                      fontSize: "2.5vh",
                                                      marginLeft: "5%",
                                                    }}
                                                  />
                                                )}
                                              </Typography>
                                            </div>
                                            <Tooltip
                                              arrow
                                              title={
                                                <Typography
                                                  style={{
                                                    fontSize: "1.7vh",
                                                    fontWeight: "bold",
                                                    width: "100%",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {
                                                    FallCleanupAddons[i]
                                                      .description
                                                  }
                                                </Typography>
                                              }
                                            >
                                              <InfoIcon
                                                style={{
                                                  fontSize: "3vh",
                                                  color: selectedAddonsFall[key]
                                                    ? "white"
                                                    : "#187cae",
                                                }}
                                              />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </>
                    </CardContent>
                  </Paper>
                </Grid>
              </Grid>
              <Button
                size="large"
                variant="contained"
                className={
                  clickedFallService !== fallService.fallDbSelected ||
                    trackAddonChange["FallCleanup"] !== false
                    ? "holderButton"
                    : ""
                }
                style={{
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#314A87",
                }}
                onClick={() => {
                  setTrackSelectedAddons((service) => ({
                    ...service,
                    FallCleanup: Math.random().toString(36).slice(2),
                  }));
                }}
              >
                Save Changes
              </Button>
              <div style={{ width: "100%" }}>
                <Typography style={{ padding: "2%" }}>
                  <>
                    <span>Click to read</span>
                    <span>
                      <Link
                        href="/ServiceAgreementFall"
                        target="_blank"
                        className={SignupClass.link}
                        style={{
                          border: "none",
                          color: "#187CAE",
                          fontWeight: "bold",
                          fontSize: "15px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontFamily: "poppins",
                        }}
                      >
                        Full contract details
                      </Link>{" "}
                    </span>
                    <span> - features and responsibilities </span>
                  </>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {/* Snow Cleanup: Manage Service List  */}
      {signUpPageSelected === "Snow Cleanup" && (
        <>
          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>

              {/* Parent Grid for Services Card */}
              <Grid xl={12} lg={12} md={12} sm={12} xs={11}>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "poppins",
                    textAlign: "left",
                    fontSize: matchesSm ? "18px" : "2.5vh",
                    marginTop: "1%",
                    marginBottom: "3%",
                    marginLeft: matchesSm ? "3%" : "1%",
                  }}
                >
                  Snow cleanup services will be available in November for early
                  signup.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/* Snow Cleanup: Manage Service List  */}
      {signUpPageSelected === "Customized Projects" && (
        <>
          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>

              {/* Parent Grid  */}
              <Grid xl={12} lg={12} md={12} sm={12} xs={11}>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                >
                  <Stack
                    style={{ display: "flex", justifyContent: "center" }}
                    direction="row"
                  >
                    <button
                      id="Mulch Service"
                      disabled={submitCustomizedProj}
                      className={classes.CustomizedProjButton}
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Mulch Service"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Mulch Service"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Mulch Service
                    </button>
                    <button
                      className={classes.CustomizedProjButton}
                      id="Hedge Trimming"
                      disabled={submitCustomizedProj}
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Hedge Trimming"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Hedge Trimming"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Hedge Trimming
                    </button>
                    <button
                      className={classes.CustomizedProjButton}
                      disabled={submitCustomizedProj}
                      id="Flower Garden Planting"
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Flower Garden Planting"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Flower Garden Planting"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Flower Garden Planting
                    </button>
                  </Stack>

                  <Stack
                    style={{ display: "flex", justifyContent: "center" }}
                    direction="row"
                  >
                    <button
                      className={classes.CustomizedProjButton}
                      disabled={submitCustomizedProj}
                      id="Rocks Services"
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Rocks Services"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Rocks Services"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Rocks Services
                    </button>
                    <button
                      className={classes.CustomizedProjButton}
                      disabled={submitCustomizedProj}
                      id="Fertilizers for lawn"
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Fertilizers for lawn"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Fertilizers for lawn"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Fertilizers for lawn
                    </button>
                    <button
                      className={classes.CustomizedProjButton}
                      disabled={submitCustomizedProj}
                      id=" Customized Projects"
                      onClick={(e) => handleCustomizedProjButton(e)}
                      style={{
                        background: selectedCustomizedProject.includes(
                          "Customized Projects"
                        )
                          ? "#187CAE"
                          : "",
                        color: selectedCustomizedProject.includes(
                          "Customized Projects"
                        )
                          ? "white"
                          : "",
                      }}
                    >
                      Customized Projects
                    </button>
                  </Stack>

                  {/* Features */}
                  {/* <div
                    style={{
                      margin: "1%",
                      border: "2px solid #187CAE",
                      borderRadius: "5px",
                      padding: "1%",
                      background: "white",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.6vh",
                        textAlign: "left",
                      }}
                    >
                      Standard Mulch Service
                    </Typography>
                    <Typography
                      style={{ fontSize: "1.6vh", textAlign: "left" }}
                    >
                      1. Feature A
                    </Typography>
                    <Typography
                      style={{ fontSize: "1.6vh", textAlign: "left" }}
                    >
                      2. Feature B
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.6vh",
                        textAlign: "left",
                      }}
                    >
                      Hedge Trimming Service
                    </Typography>
                    <Typography
                      style={{ fontSize: "1.6vh", textAlign: "left" }}
                    >
                      1. Feature A
                    </Typography>
                    <Typography
                      style={{ fontSize: "1.6vh", textAlign: "left" }}
                    >
                      2. Feature B
                    </Typography>
                  </div> */}
                  {selectedCustomizedProject?.includes("Mulch Service") && (
                    <FormControl
                      color="primary"
                      style={{ display: "flex", padding: "1%" }}
                    >
                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Area to be covered by Mulch (in sqft)
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter the area in sqft"
                        id="fname"
                        // onChange={(e) => setFname(e.target.value)}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "40%"
                              : "300px",
                          marginLeft: matchesSm ? "15%" : "0px",
                          background: "white",
                          color: "black",
                        }}
                        className="EnterDetailsInput"
                      ></input>

                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Choose a color for your mulch
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Black"
                          control={<Radio />}
                          label="Black"
                        />
                        <FormControlLabel
                          value="Brown"
                          control={<Radio />}
                          label="Brown"
                        />
                        <FormControlLabel
                          value="Red"
                          control={<Radio />}
                          label="Red (Premium) "
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  {selectedCustomizedProject?.includes("Mulch Service") && (
                    <FormControl
                      color="primary"
                      style={{ display: "flex", padding: "1%" }}
                    >
                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Area to be trimmed (Hedge) (in sqft)
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter the area in sqft"
                        id="fname"
                        // onChange={(e) => setFname(e.target.value)}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "40%"
                              : "300px",
                          marginLeft: matchesSm ? "15%" : "0px",
                          background: "white",
                          color: "black",
                        }}
                        className="EnterDetailsInput"
                      ></input>
                    </FormControl>
                  )}

                  {selectedCustomizedProject?.includes("Mulch Service") && (
                    <FormControl
                      color="primary"
                      style={{ display: "flex", padding: "1%" }}
                    >
                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Area to be covered by Flowers (in sqft)
                      </Typography>
                      <input
                        required
                        type="text"
                        placeholder="Enter the area in sqft"
                        id="fname"
                        // onChange={(e) => setFname(e.target.value)}
                        style={{
                          maxWidth: matchesSm
                            ? "90%"
                            : matchesMd
                              ? "40%"
                              : "300px",
                          marginLeft: matchesSm ? "15%" : "0px",
                          background: "white",
                          color: "black",
                        }}
                        className="EnterDetailsInput"
                      ></input>

                      <Typography
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Choose a Primary Plant (Specific requirements can be
                        written below)
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Rose"
                          control={<Radio />}
                          label="Rose"
                        />
                        <FormControlLabel
                          value="Brown"
                          control={<Radio />}
                          label="Hydrangea macrophylla - Nymphe"
                        />
                        <FormControlLabel
                          value="Red"
                          control={<Radio />}
                          label="Rudbeckia fulgida - Goldsturm"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <textarea
                    disabled={submitCustomizedProj}
                    type="text"
                    id="standard-basic"
                    placeholder="Enter your requirements"
                    label="Filled"
                    name="Message"
                    onChange={(e) => setCustomizedRequirement(e.target.value)}
                    style={{
                      display: "flex",
                      marginLeft: "1%",
                      padding: "1%",
                      border: "2px solid #187CAE",
                      borderRadius: "5px",
                      height: 240,
                      marginTop: "0.8%",
                      width: matchesMd ? "59%" : matchesXs ? "100%" : "98%",
                      background: "white",
                    }}
                  />

                  <input
                    disabled={submitCustomizedProj}
                    multiple
                    type="file"
                    // value="Upload Required Documents"
                    onChange={handlerFile}
                    style={{
                      display: "flex",
                      marginLeft: "1%",
                      maxWidth: matchesSm ? "95%" : matchesMd ? "90%" : "98%",
                    }}
                  />

                  {/* {files.map((file, key) => {
                      return (
                        <div key={key} className="Row">
                          <span className="Filename">
                            {file.name}
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              width="20%"
                            />
                          </span>
                        </div>
                      );
                    })} */}
                  <Stack
                    style={{
                      display: "flex",
                      marginLeft: "1%",
                      justifyContent: "center",
                      minWidth: matchesXs ? "89.5vw" : "",
                      maxWidth: matchesSm ? "95%" : matchesMd ? "90%" : "98%",
                    }}
                    direction="row"
                  >
                    <Button
                      size="small"
                      disabled={submitCustomizedProj}
                      className={classes.CustomizedProjButton}
                      variant="outlined"
                      color="primary"
                      style={{
                        backgroundColor: submitCustomizedProj
                          ? "#D3D3D3"
                          : "#187CAE",
                        color: "white",
                      }}
                      onClick={() => handleSubmitCustomizedProj()}
                    >
                      Submit
                    </Button>
                  </Stack>
                  <div>
                    {getCustomizedData[0]?.Price > 0 && (
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Price: {getCustomizedData[0].Price}$
                      </Typography>
                    )}
                    {getCustomizedData[0]?.ServiceDate > 0 && (
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Service Date: {getCustomizedData[0].ServiceDate}
                      </Typography>
                    )}
                  </div>

                  <div style={{ width: "100%" }}>
                    <Typography style={{ paddingBottom: "2%" }}>
                      <>
                        <span>Click to read</span>
                        <span>
                          <Link
                            href="/ServiceAgreementSpring"
                            target="_blank"
                            className={SignupClass.link}
                            style={{
                              border: "none",
                              color: "#187CAE",
                              fontWeight: "bold",
                              fontSize: "15px",
                              textDecoration: "underline",
                              cursor: "pointer",
                              marginLeft: "5px",
                              fontFamily: "poppins",
                            }}
                          >
                            Full contract details
                          </Link>{" "}
                        </span>
                        <span> - features and responsibilities </span>
                      </>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/* Mulch SECTION*/}
      {signUpPageSelected === "Mulch" && (
        <>
          {/* Dialog box to confirm Subscribe */}
          <Dialog
            open={mulchSubscribeDialogBox}
            onClose={() => {
              genericDialogBoxClose(setMulchSubscribeDialogBox);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <Paper style={{ backgroundColor: "#233d53", padding: "1%" }}>
              <DialogTitle id="alert- dialog-title">
                <h6
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    fontSize: "2.5vh",
                  }}
                >
                  Order Summary
                </h6>
              </DialogTitle>
              <DialogContent>
                <h6
                  style={{
                    color: "#ECECEC",
                    fontWeight: "600",
                    display: "flex",
                    fontSize: "1.8vh",
                    marginTop: "3%",
                  }}
                >
                  Services Selected
                </h6>
                <div
                  style={{
                    display: "flex",
                    paddingTop: "10px",
                    alignContent: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "500",
                      fontSize: "1.6vh",
                    }}
                  >
                    Mulch Color
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    {mulchSelected.colorSelected}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    paddingTop: "10px",
                    alignContent: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "500",
                      fontSize: "1.6vh",
                    }}
                  >
                    {clickedFallService}(Mulch)
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    $
                    {assignPriceAccordingly(
                      mulchPrices.mulchBEPrice,
                      mulchPrices.mulchDBPrice,
                      "Mulch",
                      clickedMulchService
                    )}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "1.6vh",
                    }}
                  >
                    Total Yards Selected
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    {mulchPrices.totalYards}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "1.6vh",
                    }}
                  >
                    Est. Sales Tax
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    ${Number(mulchPrices.mulchDashSalesTax).toFixed(2)}
                  </h6>
                </div>

                {mulchBEAddons !== null && (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "10px",
                      alignContent: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h6
                      className={classes.title}
                      style={{
                        color: "#ECECEC",
                        fontWeight: "500",
                        fontSize: "1.6vh",
                      }}
                    >
                      Addons (Mulch)
                    </h6>

                    {mulchBEAddons !== null && (
                      <h6
                        className={classes.title}
                        style={{
                          color: "#ECECEC",
                          fontWeight: "500",
                          fontSize: "1.3vh",
                        }}
                      >
                        {Object.keys(selectedAddonsMulch)
                          .filter((key) => selectedAddonsMulch[key])
                          .map((addon) => {
                            return (
                              <p style={{ textAlign: "right" }}>
                                {addon} ($
                                {addon === "5 Additional Yards"
                                  ? Number(mulchBEAddons[addon])
                                  : Number(mulchBEAddons[addon]) *
                                  mulchPrices.totalYards}
                                )
                              </p>
                            );
                          })}
                      </h6>
                    )}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "1.6vh",
                    }}
                  >
                    Addon - Red Mulch
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    ${Number(mulchPrices.mulchDashRedAddonPrice).toFixed(2)}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                    marginTop: "3%",
                    width: "100%",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "2.2vh",
                      fontWeight: "bold",
                    }}
                  >
                    Order Total
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    {Number(mulchPrices["mulchTotalPrice"]).toFixed(2)}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: matchesSm ? "0%" : "1%",
                    marginTop: "-2%",
                  }}
                >
                  <FormControlLabel
                    color="primary"
                    control={
                      <Checkbox
                        style={{
                          color: "white",
                          "& .MuiSvgIcon-root": {
                            color: "white",
                          },
                        }}
                        required
                        color="primary"
                        checked={serviceAgreementTick}
                        onChange={() =>
                          setSericeAgreementTick(!serviceAgreementTick)
                        }
                        value="primary"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                  />
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "1.3vh",
                      textAlign: "left",
                      padding: "0px",
                      width: "90%",
                    }}
                  >
                    <>
                      <span>I agree to the</span>
                      <span>
                        <Link
                          href="/ServiceAgreementMulch"
                          target="_blank"
                          className={SignupClass.link}
                          style={{
                            border: "none",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.6vh",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginLeft: "2%",
                            fontFamily: "poppins",
                          }}
                        >
                          Mulch Service Contract
                        </Link>
                      </span>
                    </>
                  </h6>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    genericDialogBoxClose(setMulchSubscribeDialogBox);
                    setLoading(true);
                    setCheckState(Math.random().toString(36).slice(2));
                  }}
                  style={{ color: "white" }}
                >
                  No
                </Button>
                <Button
                  onClick={handleSubscribeMulch}
                  variant="contained"
                  style={{
                    color: serviceAgreementTick ? "white" : "grey",
                    backgroundColor: serviceAgreementTick
                      ? "#187CAE"
                      : "#d3d3d3",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Select
                </Button>
              </DialogActions>
            </Paper>
          </Dialog>

          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>
              <span
                className={SignupClass.smallHeading}
                style={{
                  marginTop: "0px",
                  marginLeft: matchesSm ? "5%" : "10px",
                  textAlign: "left",
                  width: matchesSm ? "90%" : "100%",
                  marginBottom: "0px",
                  paddingBottom: "3px",
                  fontWeight: "500",
                }}
              >
                Service Date will be decided once we have the material order.
              </span>
              <span
                className={SignupClass.smallHeading}
                style={{
                  marginTop: "0px",
                  marginLeft: matchesSm ? "5%" : "10px",
                  textAlign: "left",
                  width: matchesSm ? "90%" : "100%",
                  marginBottom: "0px",
                  paddingBottom: "3px",
                  fontWeight: "500",
                }}
              >
                Note: Each yard can cover upto 160 sqft at 2 inches thick.
              </span>
            </Grid>
            {/* Parent Grid for Services Card */}
            <Grid
              lg={10}
              xl={10}
              md={12}
              sm={12}
              xs={12}
              container
              style={{
                width: "100%",
                backgroundColor: "#b3e2ec",
                paddingBottom: "3%",
              }}
              alignItems="center"
              justifyContent={matchesSm ? "flex-start" : "center"}
            >
              {/* 1st Card: Mulch */}
              <Grid
                item
                lg={3}
                md={5}
                sm={9}
                xl={3}
                xs={11}
                className={SignupClass.leftItem}
                spacing={1}
              >
                <Paper
                  elevation={10}
                  style={{ maxWidth: !matchesSm ? "400px" : "" }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      className={classes.currentServicesHeading}
                    >
                      Standard Plan
                    </Typography>

                    {mulchPrices["mulchBEPrice"]["Basic"] > 0 ? (
                      <Typography
                        color="text.secondary"
                        className={classes.currentServicesPrice}
                      >
                        $
                        {assignPriceAccordingly(
                          mulchPrices.mulchBEPrice,
                          mulchPrices.mulchDBPrice,
                          "Mulch",
                          "Standard"
                        )}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                    <div style={{ display: "grid", placeItems: "center" }}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#423e3e",
                          }}
                        >
                          Get coverage of 2 Yards
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Choose from a variety of colors: Brown, Black, Red.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Add color, control weeds, and improve soil quality.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Enhances soil quality for optimal plant growth.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Our service includes top-notch mulch, hassle-free
                          delivery, and professional installation.
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  {mulchSelected.dBPlanSelected === "Standard" ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{
                        margin: "2%",
                        marginBottom: "5%",
                        fontWeight: "bold",
                        border: "2px solid #187CAE",
                        textTransform: "capitalize",
                        minWidth: "100px",
                      }}
                    >
                      Your Current Service
                    </Button>
                  ) : (
                    <button
                      disabled={mulchSelected.dBPlanSelected === "Standard"}
                      id="Standard"
                      variant="contained"
                      size="small"
                      style={{
                        margin: "2%",
                        marginBottom: "5%",
                        backgroundColor:
                          clickedMulchService === "Standard"
                            ? "#187CAE"
                            : "#d3d3d3",
                        color:
                          clickedMulchService === "Standard"
                            ? "white"
                            : "black",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        fontSize: "1.8vh",
                        border: "none",
                        minWidth: "100px",
                        width: "50%",
                        cursor: "pointer",
                        height: "3vh",
                        minHeight: "40px",
                      }}
                      onClick={(e) => handleMulchSubscribeButton(e)}
                    >
                      {clickedMulchService === "Standard" ? (
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Selected{" "}
                          <Check
                            fontSize="small"
                            style={{ marginLeft: "25%" }}
                          />
                        </span>
                      ) : (
                        "Select"
                      )}
                    </button>
                  )}
                </Paper>
              </Grid>
              <Grid
                item
                lg={3}
                md={5}
                sm={9}
                xl={3}
                xs={11}
                className={SignupClass.leftItem}
                spacing={1}
              >
                <Paper
                  elevation={3}
                  style={{ maxWidth: !matchesSm ? "400px" : "" }}
                >
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "5px 5px 0px 0px",
                      fontSize: "16px",
                      fontFamily: "poppins",
                      fontWeight: "bold",
                    }}
                  >
                    Most Popular
                  </div>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      className={classes.currentServicesHeading}
                    >
                      Premium Plan
                    </Typography>

                    {/* Premium Mulch */}
                    {mulchPrices["mulchBEPrice"]["Basic"] > 0 ? (
                      <Typography
                        color="text.secondary"
                        className={classes.currentServicesPrice}
                      >
                        $
                        {assignPriceAccordingly(
                          mulchPrices.mulchBEPrice,
                          mulchPrices.mulchDBPrice,
                          "Mulch",
                          "Premium"
                        )}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}

                    <div style={{ display: "grid", placeItems: "center" }}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#423e3e",
                          }}
                        >
                          Get coverage of 4 Yards
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Choose from a variety of colors: Brown, Black, Red.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Add color, control weeds, and improve soil quality.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Enhances soil quality for optimal plant growth.
                        </Typography>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Check />
                        <Typography
                          style={{
                            marginBottom: "5%",
                            textAlign: "left",
                            fontFamily: "poppins",
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          Our service includes top-notch mulch, hassle-free
                          delivery, and professional installation.
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  {mulchSelected.dBPlanSelected === "Premium" ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{
                        margin: "2%",
                        marginBottom: "5%",
                        fontWeight: "bold",
                        border: "2px solid #187CAE",
                        textTransform: "capitalize",
                        minWidth: "100px",
                      }}
                    >
                      Your Current Service
                    </Button>
                  ) : (
                    <button
                      disabled={mulchSelected.dBPlanSelected === "Premium"}
                      id="Premium"
                      variant="contained"
                      size="small"
                      style={{
                        margin: "2%",
                        marginBottom: "5%",
                        backgroundColor:
                          clickedMulchService === "Premium"
                            ? "#187CAE"
                            : "#d3d3d3",
                        color:
                          clickedMulchService === "Premium" ? "white" : "black",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        fontSize: "1.8vh",
                        border: "none",
                        minWidth: "100px",
                        width: "50%",
                        cursor: "pointer",
                        height: "3vh",
                        minHeight: "40px",
                      }}
                      onClick={(e) => handleMulchSubscribeButton(e)}
                    >
                      {clickedMulchService === "Premium" ? (
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Selected{" "}
                          <Check
                            fontSize="small"
                            style={{ marginLeft: "25%" }}
                          />
                        </span>
                      ) : (
                        "Select"
                      )}
                    </button>
                  )}
                </Paper>
              </Grid>
              <Grid
                item
                lg={3}
                xl={3}
                md={8}
                sm={11}
                xs={11}
                spacing={2}
                className={SignupClass.leftItem}
              >
                {
                  <Paper elevation={3}>
                    <CardContent>
                      {
                        <>
                          <Typography
                            variant="h6"
                            component="div"
                            className={classes.currentServicesHeading}
                            style={{ marginbottom: "2%" }}
                          >
                            Select Mulch Color
                          </Typography>
                          {Mulch.map((key) => {
                            return (
                              <>
                                <div
                                  id={key.mulchColor}
                                  key={key.id}
                                  // className={classes.addonCardLayout}
                                  style={{
                                    marginBottom: "1.5vh",
                                    borderRadius: "5px",
                                    background:
                                      mulchSelected.colorSelected ===
                                        key.mulchColor
                                        ? "#187cae"
                                        : "#d3d3d3",
                                    color:
                                      mulchSelected.colorSelected ===
                                        key.mulchColor
                                        ? "white"
                                        : "black",
                                  }}
                                  onClick={() => {
                                    setMulchSelected((prevSelected) => ({
                                      ...prevSelected,
                                      colorSelected: key.mulchColor,
                                    }));

                                    setTrackAddonChange((service) => ({
                                      ...service,
                                      Mulch: true,
                                    }));
                                  }}
                                >
                                  <div className={classes.fullWidthFlexCenter}>
                                    <img
                                      className={classes.addonImages}
                                      src={key.img}
                                    />
                                    <div style={{ marginLeft: "5%" }}>
                                      <Typography
                                        className={classes.addonCardMainHeading}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {key.mulchColor}
                                        {mulchSelected.colorSelected ===
                                          key.mulchColor && (
                                            <Check
                                              style={{
                                                color: "white",
                                                fontSize: "2.5vh",
                                                marginLeft: "5%",
                                              }}
                                            />
                                          )}
                                      </Typography>
                                      {key.mulchColor === "Red" && (
                                        <Typography
                                          className={
                                            classes.addonCardSubHeading
                                          }
                                        >
                                          {allPrices !== null ? (
                                            <Typography
                                              style={{
                                                color:
                                                  mulchSelected.colorSelected ===
                                                    key.mulchColor
                                                    ? "white"
                                                    : "black",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              +${allPrices["MulchRed"]}/yd
                                            </Typography>
                                          ) : (
                                            " "
                                          )}
                                          {/* $200 */}
                                        </Typography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      }
                    </CardContent>
                  </Paper>
                }
              </Grid>
              {/* Addons Mulch : */}
              <Grid
                className={classes.responsiveFlex}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={11}
              >
                <Grid
                  item
                  lg={11}
                  xl={11}
                  md={8}
                  sm={11}
                  xs={11}
                  className={SignupClass.leftItem}
                  spacing={1}
                >
                  {
                    <Paper elevation={3}>
                      <CardContent>
                        <>
                          {/* Addons: */}
                          <CardContent>
                            <>
                              <Typography
                                variant="h6"
                                component="div"
                                className={classes.currentServicesHeading}
                              >
                                Select Addons
                                {clickedMulchService !==
                                  mulchSelected.dBPlanSelected ||
                                  trackAddonChange["Mulch"] !== false ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "16px",
                                        color: "red",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: "10px",
                                        border: "1px solid red",
                                        borderRadius: "4px",
                                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                                        maxWidth: "500px", // Set a maximum width for the element
                                        marginTop: "2%",
                                      }}
                                    >
                                      Click on 'Save Changes' Button to Update
                                      Plan!
                                    </Typography>
                                  </div>
                                ) : null}
                              </Typography>
                              <div>
                                {mulchBEAddons !== null ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: matchesMd
                                        ? "column"
                                        : "row",
                                      justifyContent: "space-between",
                                      height: "100%",
                                    }}
                                  >
                                    {Object.keys(mulchBEAddons).map(
                                      (key, i) => {
                                        return (
                                          <>
                                            <div
                                              id={mulchBEAddons[key]}
                                              variant="contained"
                                              size="small"
                                              style={{
                                                alignItems: "center",
                                                width: "100%",
                                                margin: "3px",
                                                marginBottom: matchesSm
                                                  ? "3%"
                                                  : "0%",
                                                background: selectedAddonsMulch[
                                                  key
                                                ]
                                                  ? "#187cae"
                                                  : "#d3d3d3",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                                display: "flex",
                                                height: "100%",
                                                maxHeight: !matchesMd
                                                  ? "6.3rem"
                                                  : "",
                                                minHeight: !matchesMd
                                                  ? "6.3rem"
                                                  : "",
                                                maxWidth: !matchesMd
                                                  ? "21.7vw"
                                                  : "",
                                              }}
                                              onClick={() =>
                                                handleSelect(
                                                  key,
                                                  Number(
                                                    mulchBEAddons[key]
                                                  ).toFixed(2),
                                                  setSelectedAddonsMulch,
                                                  setTotalMulchAddon,
                                                  selectedAddonsMulch,
                                                  clickedMulchService,
                                                  "Mulch"
                                                )
                                              }
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  // justifyContent: "space-between",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  height: "100%",
                                                  fontSize: "1.7vh",
                                                  fontWeight: "bold",
                                                  width: "100%",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    MulchAddonsObject[i]
                                                      .addonName === key &&
                                                    MulchAddonsObject[i].img
                                                  }
                                                  style={{
                                                    borderRadius: "5px",
                                                    width: "40%",
                                                    maxHeight: "13vh",
                                                    minHeight: "100px",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                                <div style={{ width: "100%" }}>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      width: matchesSm
                                                        ? "98%"
                                                        : "90%",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        margin: "3%",
                                                        width: "100%",
                                                        marginLeft: "5%",
                                                      }}
                                                    >
                                                      {key ===
                                                        "5 Additional Yards" ? (
                                                        <>
                                                          <Typography
                                                            style={{
                                                              fontSize: "2.5vh",
                                                              color:
                                                                selectedAddonsMulch[
                                                                  key
                                                                ]
                                                                  ? "white"
                                                                  : "black",
                                                              fontWeight:
                                                                "bold",
                                                              width: "100%",
                                                              minWidth: "90%",
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            {key}
                                                          </Typography>
                                                          <Typography
                                                            style={{
                                                              fontSize: "1.7vh",
                                                              fontWeight:
                                                                "bold",
                                                              width: "100%",
                                                              textAlign: "left",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              color:
                                                                selectedAddonsMulch[
                                                                  key
                                                                ]
                                                                  ? "white"
                                                                  : "black",
                                                            }}
                                                          >
                                                            $
                                                            {Number(
                                                              mulchBEAddons[key]
                                                            )}
                                                            <span
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              Estimated
                                                            </span>
                                                            {selectedAddonsMulch[
                                                              key
                                                            ] && (
                                                                <Check
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontSize:
                                                                      "2.5vh",
                                                                    marginLeft:
                                                                      "5%",
                                                                  }}
                                                                />
                                                              )}
                                                          </Typography>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <Typography
                                                            style={{
                                                              fontSize: "2.5vh",
                                                              color:
                                                                selectedAddonsMulch[
                                                                  key
                                                                ]
                                                                  ? "white"
                                                                  : "black",
                                                              fontWeight:
                                                                "bold",
                                                              width: "100%",
                                                              minWidth: "90%",
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            {key}
                                                          </Typography>
                                                          <Typography
                                                            style={{
                                                              fontSize: "1.7vh",
                                                              fontWeight:
                                                                "bold",
                                                              width: "100%",
                                                              textAlign: "left",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              color:
                                                                selectedAddonsMulch[
                                                                  key
                                                                ]
                                                                  ? "white"
                                                                  : "black",
                                                            }}
                                                          >
                                                            $
                                                            {Number(
                                                              mulchBEAddons[key]
                                                            )}
                                                            /Yd
                                                            <span
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              Estimated
                                                            </span>
                                                            {selectedAddonsMulch[
                                                              key
                                                            ] && (
                                                                <Check
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontSize:
                                                                      "2.5vh",
                                                                    marginLeft:
                                                                      "5%",
                                                                  }}
                                                                />
                                                              )}
                                                          </Typography>
                                                        </>
                                                      )}
                                                    </div>
                                                    <Tooltip
                                                      arrow
                                                      title={
                                                        <Typography
                                                          style={{
                                                            fontSize: "1.7vh",
                                                            fontWeight: "bold",
                                                            width: "100%",
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {
                                                            MulchAddonsObject[i]
                                                              .description
                                                          }
                                                        </Typography>
                                                      }
                                                    >
                                                      <InfoIcon
                                                        style={{
                                                          fontSize: "3vh",
                                                          color:
                                                            selectedAddonsMulch[
                                                              key
                                                            ]
                                                              ? "white"
                                                              : "#187cae",
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <CircularProgress />
                                )}
                              </div>
                            </>
                          </CardContent>
                        </>
                      </CardContent>
                    </Paper>
                  }
                </Grid>
              </Grid>
              <div style={{ width: "100%" }}>
                <Button
                  className={
                    clickedMulchService !== mulchSelected.dBPlanSelected ||
                      trackAddonChange["Mulch"] !== false
                      ? "holderButton"
                      : ""
                  }
                  size="large"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#314A87",
                  }}
                  onClick={() => {
                    setTrackSelectedAddons((service) => ({
                      ...service,
                      Mulch: Math.random().toString(36).slice(2),
                    }));
                  }}
                >
                  Save Changes
                </Button>
                <Typography style={{ marginTop: "20px" }}>
                  <>
                    <span>Click to read</span>
                    <span>
                      <Link
                        href="/ServiceAgreementMulch"
                        target="_blank"
                        className={SignupClass.link}
                        style={{
                          border: "none",
                          color: "#187CAE",
                          fontWeight: "bold",
                          fontSize: "15px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginLeft: "1%",
                          fontFamily: "poppins",
                        }}
                      >
                        Full contract details
                      </Link>{" "}
                    </span>
                    <span> - features and responsibilities </span>
                  </>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* Hedge Trimming section  */}
      {signUpPageSelected === "Hedge Trimming" ? (
        <Grid
          container
          alignContent="flex-start"
          justifyContent="center"
          style={{
            margin: "0 auto",
            marginTop: "10px",
          }}
        >
          {/* Dialog box to confirm Subscribe */}

          <Dialog
            open={hedgeSubscribeDialogBox}
            onClose={() => {
              genericDialogBoxClose(setHedgeSubscribeDialogBox);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <Paper style={{ backgroundColor: "#233d53", padding: "1%" }}>
              <DialogTitle id="alert- dialog-title">
                <h6
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    fontSize: "2.5vh",
                  }}
                >
                  Order Summary
                </h6>
              </DialogTitle>
              <DialogContent>
                <h6
                  style={{
                    color: "#ECECEC",
                    fontWeight: "600",
                    display: "flex",
                    fontSize: "1.8vh",
                    marginTop: "3%",
                  }}
                >
                  Services Selected
                </h6>
                <div
                  style={{
                    display: "flex",
                    paddingTop: "10px",
                    alignContent: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "500",
                      fontSize: "1.6vh",
                    }}
                  >
                    Subscription
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    {hedgeService.hedgeDashSelected}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                    marginTop: "3%",
                    width: "100%",
                  }}
                >
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "2.2vh",
                      fontWeight: "bold",
                    }}
                  >
                    Order Total
                  </h6>

                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "600",
                      fontSize: "1.6vh",
                    }}
                  >
                    {Number(
                      hedgeService.hedgeBEPrice[hedgeService.hedgeDashSelected]
                    ).toFixed(2)}
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: matchesSm ? "0%" : "1%",
                    marginTop: "-2%",
                  }}
                >
                  <FormControlLabel
                    color="primary"
                    control={
                      <Checkbox
                        style={{
                          color: "white",
                          "& .MuiSvgIcon-root": {
                            color: "white",
                          },
                        }}
                        required
                        color="primary"
                        checked={serviceAgreementTick}
                        onChange={() =>
                          setSericeAgreementTick(!serviceAgreementTick)
                        }
                        value="primary"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                  />
                  <h6
                    className={classes.title}
                    style={{
                      color: "#ECECEC",
                      fontWeight: "400",
                      fontSize: "1.3vh",
                      textAlign: "left",
                      padding: "0px",
                      width: "90%",
                    }}
                  >
                    <>
                      <span>I agree to the</span>
                      <span>
                        <Link
                          href="/ServiceAgreementHedge"
                          target="_blank"
                          className={SignupClass.link}
                          style={{
                            border: "none",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.6vh",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginLeft: "2%",
                            fontFamily: "poppins",
                          }}
                        >
                          Hedge Service Contract
                        </Link>
                      </span>
                    </>
                  </h6>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHedgeSubscribeDialogBox(false);
                  }}
                  style={{ color: "white" }}
                >
                  No
                </Button>
                <Button
                  onClick={handleSubscribeHedge}
                  variant="contained"
                  style={{
                    color: serviceAgreementTick ? "white" : "grey",
                    backgroundColor: serviceAgreementTick
                      ? "#187CAE"
                      : "#d3d3d3",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Select
                </Button>
              </DialogActions>
            </Paper>
          </Dialog>

          {/* ============================= */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            style={{
              backgroundColor: "#b3e2ec",
              paddingTop: "10px",
            }}
            justifyContent="center"
          >
            <Grid
              item
              style={{
                width: "100%",
              }}
              xl={12}
              lg={12}
              sm={10}
              xs={12}
              md={10}
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid
            lg={10}
            xl={10}
            sm={12}
            xs={12}
            md={12}
            container
            style={{
              width: "100%",
              backgroundColor: "#b3e2ec",
              paddingBottom: "3%",
            }}
            alignItems="center"
            justifyContent="center"
          >
            {
              <>
                {hedgeService.hedgeOverShoot === false ? (
                  <>
                    <Grid
                      item
                      lg={3}
                      md={5}
                      sm={9}
                      xl={3}
                      xs={12}
                      className={SignupClass.leftItem}
                      spacing={1}
                    >
                      <Paper elevation={3} style={{ maxWidth: "500px" }}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px 5px 0px 0px",
                            fontSize: "16px",
                            fontFamily: "poppins",
                          }}
                        >
                          Most Popular
                        </div>
                        <CardContent>
                          <Typography
                            variant="h6"
                            component="div"
                            className={classes.currentServicesHeading}
                          >
                            Premium Plan
                          </Typography>

                          {/* Biweekly */}
                          {hedgeService.hedgeBEPrice !== null ? (
                            <Typography
                              color="text.secondary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "30px",
                                alignItems: "center",
                                display: "flex",
                                marginBottom: "5px",
                              }}
                            >
                              <Box flexGrow={0.8} />
                              <span>
                                {" "}
                                $
                                {Number(
                                  hedgeService.hedgeBEPrice["Premium"]
                                ).toFixed(2)}{" "}
                              </span>
                              <Box flexGrow={0.4} />
                              <Tooltip title="Do you like the price?">
                                <ThumbUpIcon
                                  style={{
                                    color: localStorage.getItem(
                                      "BiWeeklyMowCount"
                                    )
                                      ? "#187cae"
                                      : "#a5a4a4",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      localStorage.getItem("BiWeeklyMowCount")
                                    ) {
                                      return;
                                    } else {
                                      setBiWeeklyMowCount(
                                        Number(biweeklyMowCount) + 1
                                      );
                                      updateDoc(thumbCount, {
                                        BiWeeklyMowCount:
                                          Number(biweeklyMowCount) + 1,
                                      });
                                      localStorage.setItem(
                                        "BiWeeklyMowCount",
                                        JSON.stringify(biweeklyMowCount)
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                              {biweeklyMowCount ? (
                                <p
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "normal",
                                    paddingLeft: "6px",
                                  }}
                                >
                                  {biweeklyMowCount}
                                </p>
                              ) : (
                                "10"
                              )}
                            </Typography>
                          ) : (
                            <CircularProgress />
                          )}
                          <div style={{ display: "flex" }}>
                            <Check fontSize="small" />
                            <Typography
                              style={{
                                marginBottom: "3%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: matchesSm ? "14px" : "16px",
                                maxWidth: "99%",
                              }}
                            >
                              We specialize in trimming hedges up to 8 feet tall
                              for a polished appearance.
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check fontSize="small" />
                            <Typography
                              style={{
                                marginBottom: "3%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: matchesSm ? "14px" : "16px",
                                maxWidth: "99%",
                              }}
                            >
                              Our comprehensive service includes removing all
                              debris and branches, leaving your yard pristine.
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check fontSize="small" />
                            <Typography
                              style={{
                                marginBottom: "3%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: matchesSm ? "14px" : "16px",
                                maxWidth: "99%",
                              }}
                            >
                              Email us your specific instructions and
                              conveniently attach pictures at{" "}
                              <a
                                style={{
                                  textTransform: "none",
                                  // textDecoration: 'none'
                                }}
                              // href="mailto:services@lawnber.com"
                              >
                                services@lawnber.com.
                              </a>
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Check fontSize="small" />
                            <Typography
                              style={{
                                marginBottom: "3%",
                                textAlign: "left",
                                fontFamily: "poppins",
                                paddingLeft: "10px",
                                fontSize: matchesSm ? "14px" : "16px",
                                maxWidth: "99%",
                              }}
                            >
                              Our plan does not include removing bushes
                            </Typography>
                          </div>
                        </CardContent>
                        {hedgeService.hedgeDbSelected === "Premium" ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{
                              margin: "2%",
                              marginBottom: "5%",
                              fontWeight: "bold",
                              border: "2px solid #187CAE",
                              textTransform: "capitalize",
                              minWidth: "100px",
                            }}
                          >
                            Your Current Service
                          </Button>
                        ) : (
                          <button
                            disabled={
                              hedgeService.hedgeDbSelected === "Premium"
                            }
                            id="Premium"
                            variant="contained"
                            size="small"
                            style={{
                              margin: "2%",
                              marginBottom: "5%",
                              backgroundColor: "#d3d3d3",
                              color: "black",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              fontSize: "1.8vh",
                              border: "none",
                              minWidth: "100px",
                              width: "50%",
                              cursor: "pointer",
                              height: "3vh",
                              minHeight: "40px",
                            }}
                            onClick={(e) => handleHedgeSubscribeButton(e)}
                          >
                            Select
                          </button>
                        )}
                      </Paper>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}
                    md={12}
                    container
                    style={{
                      backgroundColor: "#b3e2ec",
                      width: "100%",
                      paddingLeft: "7%",
                      paddingRight: "7%",
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid container xl={12} alignItems="center">
                      <Grid item xs={12}>
                        <form
                          autoComplete="off"
                          style={{
                            display: "grid",
                            placeItems: "center",
                            padding: "3vh",
                            width: matchesSm ? "100%" : "60%",
                            margin: "3% auto 0",
                            background: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontFamily: "poppins",
                              textAlign: matchesSm ? "justify" : "center",
                              fontSize: "2.8vh",
                              lineHeight: "30px",
                            }}
                          >
                            Your property exceeds 12,000 sqft.{" "}
                            {!matchesSm && <br />}
                            We will provide you a quote within 24 hours.
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "poppins",
                              textAlign: "center",
                              marginTop: "1vh",
                              marginBottom: "2vh",
                            }}
                          >
                            Check the information below and add any comments,
                            Then press "GET QUOTE"
                          </Typography>
                          <Grid
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              width: "100%",
                            }}
                            lg={12}
                            item
                          >
                            <label
                              htmlFor="firstname"
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Name
                            </label>
                            <input
                              required
                              type="text"
                              placeholder="Enter your name"
                              id="fname"
                              className="greyInput"
                              value={hedgeService.hedgeOverShootEmail["Name"]}
                              onChange={(e) => {
                                setHedgeService((key) => ({
                                  ...key,
                                  hedgeOverShootEmail: {
                                    ...key.hedgeOverShootEmail,
                                    Name: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </Grid>
                          <Grid
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              width: "100%",
                            }}
                            lg={12}
                            item
                          >
                            <label
                              htmlFor="lastname"
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Email
                            </label>
                            <input
                              required
                              type="text"
                              placeholder=""
                              id="lname"
                              className="greyInput"
                              value={hedgeService.hedgeOverShootEmail["Email"]}
                              onChange={(e) => {
                                setHedgeService((key) => ({
                                  ...key,
                                  hedgeOverShootEmail: {
                                    ...key.hedgeOverShootEmail,
                                    Email: e.target.value,
                                  },
                                }));
                              }}
                            ></input>
                          </Grid>
                          <Grid
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              width: "100%",
                            }}
                            lg={12}
                            item
                          >
                            <label
                              htmlFor="lastname"
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Address
                            </label>
                            <input
                              required
                              type="text"
                              placeholder=""
                              id="lname"
                              className="greyInput"
                              value={
                                hedgeService.hedgeOverShootEmail["Address"]
                              }
                              onChange={(e) => {
                                setHedgeService((key) => ({
                                  ...key,
                                  hedgeOverShootEmail: {
                                    ...key.hedgeOverShootEmail,
                                    Address: e.target.value,
                                  },
                                }));
                              }}
                            ></input>
                          </Grid>
                          <Grid
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              width: "100%",
                            }}
                            lg={12}
                            item
                          >
                            <label
                              htmlFor="lastname"
                              style={{ fontWeight: "bold", textAlign: "left" }}
                            >
                              Request
                            </label>
                            <input
                              required
                              type="text"
                              placeholder=""
                              id="lname"
                              className="greyInput"
                              style={{
                                minHeight: "60px",
                                whiteSpace: "nowrap",
                              }}
                              value={
                                hedgeService.hedgeOverShootEmail["Content"]
                              }
                              onChange={(e) => {
                                setHedgeService((key) => ({
                                  ...key,
                                  hedgeOverShootEmail: {
                                    ...key.hedgeOverShootEmail,
                                    Content: e.target.value,
                                  },
                                }));
                              }}
                            />
                            <button
                              className="buttonPrimary2"
                              id="submit"
                              style={{
                                fontSize: "2vh",
                                backgroundColor: "#187CAE",
                                marginTop: "20px",
                                marginBottom: "10px",
                                borderRadius: "5px",
                                width: matchesSm ? "100%" : "30%",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              onClick={() => {
                                sendHedgeOverShootEmail(
                                  "https://be-operation-services.herokuapp.com/Email"
                                  // "http://localhost:4008/Email",
                                );
                              }}
                            >
                              GET QUOTE
                            </button>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            }
          </Grid>
        </Grid>
      ) : null}

      {/* Snow Cleanup: Manage Service List  */}
      {signUpPageSelected === "Multiple Homes" && (
        <>
          {/* Parent Grid */}
          <Grid
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            {/* Grid for margin and Background Color */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={10}
              style={{
                backgroundColor: "#b3e2ec",
              }}
              justifyContent="center"
            >
              {/* Heading and subtitle for Manage Service */}
              <div
                className={classes.flexSpaceBetweenRow}
                style={{
                  flexDirection: matchesSm ? "column" : "row",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: matchesSm ? "flex-start" : "center",
                }}
              >
                <Typography
                  className={classes.dashboardHeading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Manage Services: {signUpPageSelected}
                  <span style={{ fontWeight: "normal" }}>
                    {" "}
                    (Select different services from the top menu)
                  </span>
                </Typography>
                <Button
                  className={classes.addressButton}
                  variant="contained"
                  color="primary"
                  style={{
                    maxWidth: "200px",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Q & A
                </Button>
              </div>

              {/* Parent Grid for Services Card */}
              <Grid xl={11} lg={11} md={11} sm={11} xs={11}>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "poppins",
                    textAlign: "left",
                    fontSize: matchesSm ? "18px" : "2.5vh",
                    marginTop: "1%",
                    marginBottom: "1%",
                    marginLeft: matchesSm ? "3%" : "1%",
                  }}
                >
                  To sign up for multiple homes, please follow these steps:
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontFamily: "poppins",
                    textAlign: "justify",
                    fontSize: "1.85vh",
                    marginBottom: "1%",
                    marginLeft: matchesSm ? "3%" : "1%",
                  }}
                >
                  To start a customized project, Please click on the contact us
                  button and use the form to provide us your requirements. We
                  will review your project, provide different recommendations
                  and get back to you promptly.
                </Typography>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: "100%",
                    marginBottom: "3%",
                    paddingLeft: matchesSm ? "3%" : "1%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                      width: "100%",
                    }}
                  >
                    1. Use the home screen "Instant Price" lookup feature to
                    check all your home addresses to get service prices for each
                    home.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                    }}
                  >
                    2. Once you decide to sign up for multiple homes, select the
                    primary home address again to begin the signup. You will
                    need to complete the account info and payment method for the
                    primary home address. Select the "Order" button to complete
                    the primary home address signup and your account will be
                    created.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                    }}
                  >
                    3. Once your account is created, you will receive a
                    welcoming email to confirm your account, and you will have
                    access to your account dashboard on the website.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                    }}
                  >
                    4. From your account dashboard website, you can now add the
                    second or third home addresses.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                    }}
                  >
                    5. Finally, you can select the available services for all
                    your homes.
                    {/* Note: Depending on each home's location,
                      different service crews can be assigned to provide you
                      services. */}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "poppins",
                      textAlign: "justify",
                      fontSize: "1.85vh",
                      marginTop: "3%",
                    }}
                  >
                    Note: Depending on each home's location, different service
                    crews can be assigned to provide you services. If you have
                    any questions reach out to us using the Contact us button
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {currentServices && (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={10}
            xl={10}
            justifyContent="center"
            style={{ marginBottom: "3%" }}
          >
            {/* ------------SERVICE HISTORY--------------*/}
            {/* show service history table of user */}
            {currentServices && (
              <div>
                <div
                  className={classes.flexSpaceBetweenRow}
                  style={{
                    flexDirection: matchesSm ? "column" : "row",
                    marginTop: "10px",
                    alignItems: matchesSm ? "flex-start" : "center",
                    width: "99%",
                  }}
                >
                  <h4
                    className={classes.dashboardHeading}
                    style={{
                      marginTop: "10px",
                      marginBottom: matchesSm ? "0%" : "-2%",
                      marginLeft: "10px",
                    }}
                  >
                    Service History
                  </h4>

                  <div>
                    <button
                      onClick={() => {
                        handleClick_DownloadReceipt(
                          "https://be-operation-services.herokuapp.com/Reciept"
                        );
                      }}
                      disabled={!serHistory}
                      className={classes.addressButton}
                      variant="contained"
                      style={{
                        backgroundColor: serHistory ? "#187CAE" : "#d3d3d3",
                        color: serHistory ? "white" : "grey",
                        marginTop: "10px",
                        cursor: serHistory ? "pointer" : "not-allowed",
                        marginBottom: "-2%",
                        maxWidth: "300px",
                        fontSize: "1rem",
                        border: "none",
                        padding: "1%",
                        minWidth: matchesSm ? "80vw" : "300px",
                        minHeight: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      Download Payment History
                    </button>
                  </div>
                </div>

                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: "Date",
                        field: "Date",
                        width: "1%",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontSize: "1rem",
                        },
                      },
                      {
                        title: "Order #",
                        field: "OrderId",
                        width: "1%",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        },
                        render: (rowData) => (
                          <Typography>
                            {rowData.OrderId} <br />
                          </Typography>
                        ),
                      },
                      {
                        title: "Service",
                        field: "Service",
                        width: "90%",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        },
                        render: (rowData) => (
                          <Typography>{rowData.Service}</Typography>
                        ),
                      },
                      {
                        title: "Price",
                        field: "Price",
                        width: "10px",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        },
                        render: (rowData) => (
                          <Typography>${rowData.Price}</Typography>
                        ),
                      },
                      {
                        title: "Review",
                        width: "10px",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                        render: (rowData) => (
                          <>
                            <ThumbUp
                              style={{
                                color:
                                  serHistory[rowData.tableData.id].Review ===
                                    "Liked"
                                    ? "#187cae"
                                    : "#a5a4a4",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                serHistory[rowData.tableData.id].Review =
                                  "Liked";
                                setReview("Liked");
                                //console.log("Updated ServHis", serHistory);
                                setDoc(
                                  doc(
                                    db,
                                    "UserAcct",
                                    user.uid,
                                    "Services",
                                    "History2023"
                                  ),
                                  {
                                    History: serHistory,
                                  }
                                );
                              }}
                            />
                          </>
                        ),
                      },
                    ]}
                    data={serHistory}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <>
                            <h4
                              style={{
                                color: "black",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              No service has been completed yet.
                            </h4>
                          </>
                        ),
                      },
                    }}
                    components={{
                      Container: (props) => (
                        <Paper
                          {...props}
                          elevation={0}
                          style={{ width: "83vw" }}
                        />
                      ),
                    }}
                    options={{
                      paging: true,
                      sorting: false,
                      draggable: false,
                      pageSize: 4,
                      pageSizeOptions: [4, 10, 30],
                      headerStyle: {
                        fontWeight: "600",
                        color: "#252424",
                        fontSize: "1rem",
                      },
                      rowStyle: {
                        backgroundColor: "#F7FAFA",
                        color: "black",
                      },
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      showTitle: false,
                      search: false,
                    }}
                  />
                </ThemeProvider>
              </div>
            )}

            {!currentServices && (
              <>
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "30px",
                    margin: "auto",
                    justifyContent: "center",
                  }}
                >
                  Thanks for signing up!!
                </div>
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "auto",
                    justifyContent: "center",
                  }}
                >
                  We will contact you soon.
                </div>{" "}
              </>
            )}
            {/* --------------------------------------------------------------*/}
          </Grid>
          <FooterHome
            title1="Lawnber"
            content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
            title2="Links"
            links={[
              { text: "Blog", href: "/blog" },
              { text: "Privacy Policy", href: "/PrivacyPolicy" },
            ]}
            title3="Services"
            services={[
              "Spring Cleanup",
              "Lawn Mowing",
              "Fall Cleanup",
              "Snow Removal",
              "Mulch",
            ]}
            disclaimer="* By submitting my contact information, I agree that Lawnber or its representatives may contact me by email, phone, or SMS at the email address or phone number I provide, including for marketing purposes and by automatic dialing systems. I also agree to Lawnber’s terms of service and privacy policy"
            handleServiceChange={(event) => {
              history.push("/");
            }}
            handleInstantQuote={() => {
              history.push("/");
            }}
          />
        </Grid>
      )}
    </>
  );
}

export default Dashboard_;
