import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//import functionalities
import { UserContext } from "../../components/UserContext";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

//import styling components
import "../Login/Login.css";
import config from '../Config'

const AdminLogin = () => {
  const [adminUser, setAdminUser] = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  //Function executed when signIn button is pressed
  const SignInButton = async (e) => {
    e.preventDefault();
    if (validateEmail(email) === true) {
      const { data } = await axios.post(
        config.ADMIN_LOGIN_URL,
        {
          email: email,
          password: password,
        }
      );
      if (data.status === "Success") {
        setAdminUser(data);
        localStorage.setItem("adminUser", JSON.stringify(data.email));
        history.push("/QAHome");
      } else {
        toast.error(data.error);
        return;
      }
    } else {
      toast.error("Wrong Email format");
      return;
    }
  };

  //Checks Email Format
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="login" style={{ backgroundColor: "white" }}>

        <ToastContainer />
        <div className="login_info">
          <h1
            style={{ marginBottom: "50px", marginTop: "20px", color: "black" }}
          >
            Sign in to your admin account
          </h1>
          <form className="login_form">
            <label
              htmlFor="email"
              style={{ textAlign: "left", color: "black" }}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              style={{
                maxWidth: "300px",
                fontWeight: "600",
                minWidth: "200px",
                width: "90%",
                borderRadius: "28px",
                minHeight: "40px",
                paddingLeft: "4%",
              }}
              placeholder="Enter the Email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>

            <label
              htmlFor="password"
              style={{ textAlign: "left", color: "black" }}
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              placeholder="Enter the Password (Min. 6 letter)"
              style={{
                maxWidth: "300px",
                fontWeight: "600",
                minWidth: "200px",
                width: "90%",
                borderRadius: "28px",
                minHeight: "40px",
                paddingLeft: "4%",
              }}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <button className="buttonPrimary2" onClick={SignInButton}>
              {" "}
              Sign in{" "}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
