import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: "100%"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: 'black',
		width: '100%',
		margin: 'auto',
	},
	agreementText: {
		textAlign: "left",
		marginTop: '2em',
		marginLeft: '5%',
		marginRight: '5%',
		fontSize: '18px'
	},
	heading: {
		fontWeight: 'bold',
	},
	fullScreenGrid: {
		width: "100%",
		height: "100%"
	}
}));

const ServiceAgreementMulch = () => {
	const classes = useStyles();

	return (
		<>
			<ToastContainer />
			<div className={classes.root}>
				<Grid container spacing={2} className={classes.fullScreenGrid}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<div>
								<h2>Mulch Service Agreement</h2>
								<ul className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}>
										Standard Plan:
									</Typography>
									<li>
										<ul>
											<li>Includes 2 yards of Mulch which covers upto 320 sqft.</li>
											<li>Choose from a variety of colors: Brown, Black, Red.</li>
											<li>Add color, control weeds, and improve soil quality.</li>
											<li>Enhances soil quality for optimal plant growth.</li>
											<li>Our service includes top-notch mulch, hassle-free delivery, and professional installation.</li>
										</ul>
									</li>
									<Typography variant='p' className={classes.heading}>
										Premium Plan:
									</Typography>
									<li>
										<ul>
											<li>Includes 4 yards of Mulch which covers upto 640 sqft.</li>
											<li>Choose from a variety of colors: Brown, Black, Red.</li>
											<li>Add color, control weeds, and improve soil quality.</li>
											<li>Enhances soil quality for optimal plant growth.</li>
											<li>Our service includes top-notch mulch, hassle-free delivery, and professional installation.</li>
										</ul>
									</li>
								</ul>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> Customer Responsibilities:</Typography>
									<ul>
										<li>Ensure all outdoor areas are clear of obstacles, or else Lawnber crews will exclude
											areas around the obstacles.
										</li>
										<li>Ensure access to the back yard for the crew and equipment to get through. Gates should be
											unlocked and pathways should be unblocked.
										</li>
										<li>Ensure no children and no pets are outside during the mulching service to avoid accidents.
										</li>
										<li>Ensure irrigation sprinklers are turned off before and during the mulching service to avoid 
											slippery lawn accidents.</li>
										<li>Provide pictures or clear descriptive instructions where mulch to be installed.
										</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}> Lawnber And Service Crew Are Not
										Responsible For:</Typography>
									<ul>
										<li>Not responsible to remove any obstacles and objects on or around the mulching areas and will skip those areas.</li>
										<li>Not responsible for any accidents, damages and injuries from the mulch service. Lawnber crew are professional
											and will do their best to prevent accidents.</li>
										<li>Not responsible for cleaning up pet and animal waste.</li>
										<li>Not responsible for delays due to unforeseen situations, traffic and road closures.</li>
										<li>Not responsible for Mulching over unspecified areas which contain newly grown plants.</li>
									</ul>
								</p>
							</div>
							<div>
								<p className={classes.agreementText}>
									<Typography variant='p' className={classes.heading}>Indemnifications</Typography>
								</p>
								<p className={classes.agreementText} style={{ marginTop: '1em' }}>
									<Typography variant='p' className={classes.heading}>The Customer Agrees Not To Hold Lawnber
										And Its Crew Liable For The Following:</Typography>
									<ul>
										<li>Not liable for any property damages due to unintentional accidents from mulch service.
											Property damages can be considered house exterior structures such as walls, siding, roofing,
											windows, doors, utility pipes. House interior furniture. Outdoor furniture, all types of hard
											ground surface and edging, fence structure, lawns, gardens, plants and sheds.</li>
										<li>Not liable for any person or pet injuries from equipment accidents while Lawnber crew are
											working in the outdoor areas. All people and pets should stay inside the house during the
											service period.</li>
										<li>Not liable for any vehicle damages due to unintentional accidents from mulch services.</li>
									</ul>
								</p>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export { ServiceAgreementMulch }