import React from "react";
import { useStyles } from "./AboutUsStyle";
import { Helmet } from "react-helmet";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { serviceWeProvide } from "../../Constant/Variables";

// Import Image assets:
import { ContactSupport } from "@material-ui/icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logo from "../../components/assets/images/lawnberlogo2.png";
import LawnMowingService from "../../components/assets/images/LawnMowingCollage.jpeg";
import Image from "../../components/assets/images/FallCleanupService.jpg";

function AboutUs() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <>
      {/* Header for Seo */}
      <Helmet>
        <title>Lawnber: About US</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Get to know more about Lawnber as a company, how we operate and our history."
        />
        <link rel="canonical" href="https://lawnber.com/AboutUs" />
        <meta
          property="og:image"
          content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
        />
        <meta property="og:title" content="Lawnber: About US" />
        <meta
          property="og:description"
          content="Get to know more about Lawnber as a company, how we operate and our history."
        />
        <meta property="og:url" content="https://lawnber.com/AboutUs" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className={classes.root}>
        {/* Navbar */}
        <AppBar elevation={3} position="sticky" className={classes.appBar}>
          <Toolbar>
            <Box style={{ marginTop: "1rem" }}>
              <img
                src={Logo}
                alt="Logo"
                className={classes.logo}
                onClick={() => history.push("/QAHome")}
              />
            </Box>
            <Box flex={1} />
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                onClick={() => history.push("/QAcontact")}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  minHeight: "40px",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                    ? "1rem"
                    : isBetweenMdAndLg
                    ? "1.1vh"
                    : "1.6vh",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box display="flex" alignItems="center">
              {matchesSm && (
                <Box sx={{ flexGrow: 0 }}>
                  <div
                    className="navLink"
                    onClick={() => {
                      history.push("/QAcontact");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <ContactSupport
                      fontSize="large"
                      style={{ color: "#187CAE", fontSize: 45 }}
                    />
                  </div>
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <div
                  className="navLink"
                  onClick={() => {
                    history.push("/QALogin");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: matchesSm ? "0.9rem" : "1.1rem",
                  }}
                >
                  <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                </div>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Grid container lg={12} xs={12} sm={12} xl={12} justifyContent="center">
          <h1
            className={classes.h1}
            style={{
              textAlign: "center",
              marginTop: matchesSm ? "6vh" : "2vh",
              padding: "1%",
            }}
          >
            About Us
          </h1>
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            alignItems="center"
            style={{
              marginTop: matchesSm ? "0%" : "3vh",
              display: "flex",
              flexDirection: matchesSm ? "column-reverse" : "",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid
              item
              style={{ paddingLeft: matchesSm ? "1rem" : "3rem" }}
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  color: "black",
                  fontSize: matchesSm ? "16px" : "20px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  maxLines: "2",
                  textAlign: "left",
                  paddingBottom: "5vh",
                }}
              >
                Welcome to Lawnber
              </p>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                  textAlign: "justify",
                }}
              >
                Lawnber provides the best lawn services to New York and Nassau
                county residents. We make online booking, direct pricing in less
                than 10 seconds. Our experienced lawn maintenance professionals
                are ready to provide weekly lawn maintenance services so you can
                focus on the rest of your schedule.
              </p>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                  textAlign: "justify",
                }}
              >
                Simple-to-use online tools keep you connected with service crews
                and manage your accounts automatically. Our software runs
                real-time status updates to help you stay on top of your lawn
                care needs Signup with us to get all of your lawn care needs
                taken care of right now!
              </p>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                  textAlign: "justify",
                }}
              >
                Our Team of Professionals and Experts are capable of handling
                any concerns you may have regarding your lawn. Their expert
                recommendations and professional help will let you achieve an
                amazing aesthetic for your residential and commercial property.
              </p>
            </Grid>
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                style={{
                  width: "100%",
                  maxWidth: matchesSm ? "90vw" : "40vw",
                  marginTop: matchesSm ? "1rem" : "0%",
                  height: matchesSm ? "50vh" : "70vh",
                  objectFit: "contain",
                }}
                src={LawnMowingService}
                alt="Lawn Cleanup image"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container lg={12} xs={12} sm={12} xl={12} justifyContent="center">
          <h1
            className={classes.h1}
            style={{
              textAlign: "center",
              marginTop: matchesSm ? "6vh" : "3vh",
              padding: "1%",
            }}
          >
            What can Lawnber offer?
          </h1>
          <Grid
            className={classes.stepsGrid}
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            style={{ padding: "2%" }}
          >
            {serviceWeProvide.map((data) => (
              <Grid
                item
                xl={4}
                lg={4}
                md={3}
                sm={6}
                xs={12}
                style={{ width: "100%" }}
                key={data.id}
                spacing={1}
              >
                <Card
                  style={{
                    boxShadow: "0px 4px 10px rgba(86, 61, 24, 0.2)",
                    borderRadius: "7.34448px",
                    background: "#FFFFFF",
                  }}
                >
                  <img
                    alt={data.image}
                    //   title={data.image}
                    loading="lazy"
                    className="center"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      maxHeight: "20vh",
                    }}
                    src={Image}
                  />
                  <CardContent>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: matchesSm ? "16px" : "20px",
                        fontWeight: "bold",
                        marginTop: "10px",
                        maxLines: "2",
                      }}
                    >
                      {data.serviceName}
                    </p>
                    <p
                      style={{
                        color: "black",
                        fontSize: matchesSm ? "14px" : "16px",
                        marginTop: "10px",
                        maxLines: "2",
                        textAlign: "justify",
                      }}
                    >
                      {data.description}
                    </p>
                  </CardContent>
                  <CardActionArea>
                    <Button
                      style={{ marginBottom: "3%" }}
                      variant="contained"
                      color="primary"
                    >
                      Read More
                    </Button>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AboutUs;
