import { makeStyles } from "@material-ui/core/styles";
import "@fontsource/poppins";
import "@fontsource/lexend";

export const SignupStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "2%",
    marginLeft: "2%",
    marginBottom: "2%",
    width: "100%",
    maxWidth: "100%",
    fontFamily: "Lexend",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "1%",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      maxWidth: "80%",
    },
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  smallHeading: {
    paddingBottom: "2%",
    fontWeight: "bolder",
    display: "flex",
    // marginTop: "3%",
    fontSize: "20px",
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      paddingBottom: "5%",
      fontSize: "15px",
      marginTop: "8%",
      marginBottom: "1%",
    },
  },

  toggleButton: {
    backgroundColor: "#d3d3d3!important",
    margin: "10px",
    fontWeight: "bold!important",
    width: "100%",
    minWidth: "40px",
    borderRadius: "5px!important",
    border: "none!important",
    color: "black!important",
    "&.Mui-selected": {
      backgroundColor: "#187CAE!important",
      color: "White!important",
      fontWeight: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      padding: "3%"
    }
  },

  leftItem: {
    margin: "0% 0% 0% 2%",
    padding: "2% 0% 2% 0%",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      // width: "100vw",
      marginBottom: "1%",
      padding: "4% 0% 4% 0%",
    },
  },

  AddonCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0% 5% 0% 0%",
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "center",
    color: "black",
    // minWidth: matchesSm ? "100%" : matchesMd ? "90%" : "30%",
    // maxWidth: matchesSm
    //   ? "100%"
    //   : matchesMd
    //   ? "100%"
    //   : "200px",
    textTransform: "none",
    borderRadius: "10px",
    // minHeight: "100px",
    [theme.breakpoints.down("sm")]: {
      // width: "100vw",
      flexDirection: "column",
      padding: "0% 0% 0% 0%",
    },
  },

  cardDiv: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    padding: "8%",
    border: "none",
    borderRadius: "10px",
    marginBottom: "0%",
    [theme.breakpoints.down("sm")]: {
      // height: "10%",
    },
  },

  cardHeader: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "0px 10px 0px 10px",
      wordSpacing: "100vw",
    },
  },

  date: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10%",
      marginLeft: "3%",
    },
  },

  checkBox: {
    margin: "0px",
    fontWeight: "bolder",
    display: "flex",
    fontSize: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
  },

  menuButton: {
    marginRight: '1rem',
  },
  drawer: {
    width: 200,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 200,
    height: '20%',
    margin: 'auto',
  },
  menuButtonContainer: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
    marginBottom: '2%'
  },
  menuButton: {
    margin: 'auto'
  },
}));

export default SignupStyles;
