/*global google*/

// NewHome : Landing Page of Lawnber

// Import React Components
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { allServices, places } from "../../Constant/Variables";

// Import Firebase Components:
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
} from "firebase/auth";
import { auth, db } from "../../components/Firebase";
import { useLocation } from "react-router-dom";

// Import Material UI components and Icons:
import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardActionArea,
} from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Email, LocationOn } from "@material-ui/icons";
import { Skeleton, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { ClearOutlined } from "@material-ui/icons";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ContactSupport } from "@material-ui/icons";

// Import Styling
import "./NewHome.css";
import "@fontsource/poppins";

// Import Pages and Components:
import CustomerReviews from "./CustomerReviews";
import { UserContext } from "../../components/UserContext";
import config from "../Config";
import ReCAPTCHA from "react-google-recaptcha";
import { FooterHome } from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper.css";
import { Autoplay } from "swiper";
import { Helmet } from "react-helmet";

// Import Image assets:
import Logo from "../../components/assets/images/lawnberlogo2.png";
import Captcha from "../../components/assets/images/Recaptcha.png";
import lawnhelp from "../../components/assets/images/helpLawn.png";
import lawnhelpsnow from "../../components/assets/images/how-we-can-help.png";

import SpringCleanupService from "../../components/assets/images/SpringCleaningService.jpg";
import LawnMowingService from "../../components/assets/images/LawnMowingService.jpg";
import FallCleanupService from "../../components/assets/images/FallCleanupService.jpg";
import MulchService from "../../components/assets/images/MulchService.jpg";
import HedgeService from "../../components/assets/images/HedgeTrimmingService.jpg";

import whoWeServe1 from "../../components/assets/images/whoWeServe1.png";
import whoWeServe2 from "../../components/assets/images/whoWeServe2.png";
import whoWeServe3 from "../../components/assets/images/whoWeServe3.png";
import whoWeServe4 from "../../components/assets/images/whoWeServe4.png";

import springbgplaceholder from "../../components/assets/images/Landing_Spring_image_placeholder.jpg";
import springbg from "../../components/assets/images/Landing_Spring_image.jpg";
import springbgmobile from "../../components/assets/images/Landing_Spring_image_mobile.png";
import bg from "../../components/assets/images/Landing_Mowing_image_only_cyan.jpg";
import bgmobile from "../../components/assets/images/Landing_Lawn_image_mobile.png";
import fallbg from "../../components/assets/images/Landing_Fall_image.jpg";
import fallbgmobile from "../../components/assets/images/Landing_Fall_image_mobile.png";
import mulchbg from "../../components/assets/images/Landing_Mulch_image.jpg";
import mulchbgmobile from "../../components/assets/images/Landing_Mulch_image_mobile.png";
import hedgebg from "../../components/assets/images/Landing_Hedge_image.jpg";
import hedgebgmobile from "../../components/assets/images/Landing_Hedge_image_mobile.png";
import BushTrimming from "../../components/assets/images/BushTrimming.jpg";
import Overseeding from "../../components/assets/images/OverSeedingFertilizer.jpg";

import Popup from "../../components/Popup";
import { useStyles } from "./HomeStyles";
import {
  FallCleanupAddons,
  LawnAddons,
  MulchAddons,
  landingImages,
  socialMediaLinks,
  steps,
} from "../../Constant/LandingPageVariable";
import { ExpandCircleDown } from "@mui/icons-material";
import { collection, getDocs, limit, query } from "firebase/firestore";
import dayjs from "dayjs";

const NewHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  // References:
  const imageRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const swiperRef = useRef(null);

  const API_KEY = process.env.REACT_APP_POSITION_STACK_API_KEY;

  // Variable States:
  const [address, setAddress] = React.useState();
  const [user, setUser] = React.useContext(UserContext);
  const [badaddress, setbadaddress] = React.useState();
  const [lati, setLati] = React.useState("");
  const [lngi, setLngi] = React.useState("");
  const [isWinter, setIsWinter] = React.useState([]);
  const [pageVisits, setPageVisits] = React.useState([]);
  const [apiLoaded, setApiLoaded] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [captcha, Setcaptcha] = React.useState(false);
  const RE_CAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const [signUpPageSelected, setSignUpPageSelected] = React.useState("Mulch");
  const [rotatingIndex, setRotaingIndex] = React.useState(0);
  const [loadingCarousel, setLoadingCarousel] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [ip, setIP] = React.useState("");
  const [visitor_city, setCity] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [lat, setLat] = React.useState("");
  const [long, setLong] = React.useState("");
  const [googleUser, setGoogleUser] = React.useState([]);
  const location = useLocation();
  const [extVar, setExtVar] = React.useState("");
  const [skipEmail, setSkipEmail] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState("");
  const [popupActions, setPopupActions] = React.useState([]);
  const [popupTitles, setPopupTitles] = React.useState("");
  const [blogDetails, setBlogDetails] = React.useState([]);
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  //Gets IP address of the visitor to our website
  const getIP = async () => {
    //const res = await axios.get("https://api.ipify.org/?format=json");
    //setIP(res.data.ip);

    const res = await axios.get("https://ipapi.co/json/");
    setCity(res.data.city);
    setRegion(res.data.region);
    setLat(res.data.latitude);
    setLong(res.data.longitude);
    setIP(res.data.ip);
  };

  React.useEffect(() => {
    if (!apiLoaded) {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCIsPXTWDGe-Zw4QO-3l7AYdv6duGr-JRs&libraries=places";
      script.async = true;
      script.defer = true;
      script.addEventListener("load", () => {
        setApiLoaded(true);
      });
      script.addEventListener("error", (error) => {
        setError(error);
      });
      document.body.appendChild(script);
    } else {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        if (!place.formatted_address) {
          return;
        } else {
          var address = place.formatted_address;
          var lat = place.geometry.location.lat();
          var lng = place.geometry.location.lng();
          handleAddressSelect(place.formatted_address);
          setLati(lat);
          setLngi(lng);
          // console.log(address, lat, lng);
        }
      });
    }
  }, [apiLoaded]);

  React.useEffect(() => {
    function checkUser() {
      var Email = JSON.parse(window.localStorage.getItem("EMAIL"));
      setEmail(Email);
      localStorage.setItem("user", JSON.stringify(user));
      const el = document.createElement("script");
      el.setAttribute("src", "https://accounts.google.com/gsi/client");
      el.onload = () => {
        initializeGSI();
      };
      document.querySelector("body").appendChild(el);

      onAuthStateChanged(auth, async (user) => {
        if (user !== null || user === "") {
          setUser(user);
        } else {
          setUser(null);
          return;
        }
      });
    }
    checkUser();
    setIsWinter(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize Google Auto Email Popup
  const initializeGSI = () => {
    const localEmail = JSON.parse(window.localStorage.getItem("EMAIL"));
    if (localEmail) {
      return;
    } else {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        cancel_on_tap_outside: true,
        callback: onOneTapSignedIn,
      });
      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          console.log(notification.getNotDisplayedReason());
        } else if (notification.isSkippedMoment()) {
          console.log(notification.getSkippedReason());
        } else if (notification.isDismissedMoment()) {
          console.log(notification.getDismissedReason());
        }
      });
    }
  };

  React.useEffect(() => {
    async function SetandCount() {
      if (window.location.hostname !== "localhost") {
        await axios
          .post("https://be-operation-services.herokuapp.com/SetAndGetCount")
          .then((response) => {
            if (response.data.status === "Success") {
              setPageVisits(response.data.value);
            }
          })
          .catch((error) => console.log(error));
      }
    }

    SetandCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("service")) {
      const service = searchParams.get("service");
      const convertedService = service.replace("-", " ");
      setExtVar(convertedService);
      setRotaingIndex(allServices.indexOf(convertedService));
      setSignUpPageSelected(convertedService);
    } else {
      setRotaingIndex(0);
      setSignUpPageSelected("Spring Cleanup");
    }
  }, []);

  // fetch recent blogs
  // React.useEffect(async () => {
  //   const currentDate = dayjs();
  //   const currentMonth = currentDate.month() + 1;
  //   setMonth(currentMonth.toString());
  //   const currentYear = currentDate.format("YYYY");
  //   setYear(currentYear.toString());
  //   const POSTREF = collection(db, "Blogs", "2023", "September");
  //   const q = query(POSTREF, limit(3));

  //   const querySnapshot = await getDocs(q);

  //   querySnapshot.forEach((doc) => {
  //     const id = doc.id;
  //     const prunedId = id.slice(8);
  //     setBlogDetails((data) => [
  //       ...data,
  //       {
  //         id: prunedId,
  //         Title: doc.data().Title,
  //         Description: doc.data().Description,
  //         ImageURL: doc.data().ImageURL,
  //       },
  //     ]);
  //   });
  // }, []);

  const handleInputChange = (event) => {
    setAddress(event.target.value);

    //Gets IP address of the visitor to our website
    getIP();
  };

  const handleAddressSelect = (address) => {
    setAddress(address);
  };

  const handleWriteAddress = () => {
    // Function to post address and zip code to backend
    const writeAddress = async () => {
      // Split the address into its individual components
      if (address) {
        const addressComponents = address.split(",");
        const streetAddress = addressComponents[0];
        const city = addressComponents[1];
        // Extract zip code from address
        const zipCode = addressComponents[2].match(/\d+/)[0];
        // Extract state from address
        const state = addressComponents[2].slice(0, -6).trim();
        const service = isWinter ? "Snow" : "Lawn";

        // Make a POST request to the backend with the address and zipcode
        console.log("Enter3ed Email", email);
        console.log("ipaddress:", ip);
        const { data } = await axios.post(
          config.LOOKUP_DATA_URL,
          // 'http://localhost:4008/lookUpData',
          {
            address: streetAddress,
            zipcode: zipCode,
            service: service,
            email: email,
            ip: visitor_city + "/" + ip,
          }
        );

        // Save the returned data in local storage
        localStorage.setItem("Property_Details", JSON.stringify(data));
      }
    };
    writeAddress();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Checks Email Format
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleInstantQoute = async () => {
    if (skipEmail === false) {
      if (email.length === 0) {
        setSkipEmail(true);
        // setOpenPopup(true)
        // setPopupContent('Email field is empty. You can skip the field or enter the email.')
        // setPopupTitles('Please enter your email')
        // setPopupActions([
        // {
        //   label: 'Skip',
        //   color: 'warning',
        //   onClick: () => {
        //     handleInstantQoute()
        //   },
        //   variant: "text",

        // },

        // {
        //   label: 'Enter the email',
        //   color: 'primary',
        //   onClick: () => {
        //     setSkipEmail(false)
        //     handleClosePopup()
        //   },
        //   variant: 'contained',
        // }

        // ])
        return;
      }

      if (validateEmail(email) == false) {
        // toast.error("Incorrect Email format");
        showAutoClosePopup("Error", "Enter valid email", 3000);
        return;
      }

      if (address === "undefined") {
        // toast.error("Please enter or select your address");
        showAutoClosePopup(
          "Error",
          "Please enter or select your address",
          3000
        );
        return;
      } else {
        // Handling "I am not a robot feature: Posting the token to the backend for validation"

        // In-visible Recaptcha implementaion
        const token = await captchaRef.current.executeAsync();
        await axios.post("https://be-operation-services.herokuapp.com/Robot", {
          token: token,
        });
        captchaRef.current.reset();

        //---------------------------------------------------------------------

        // Save address and location data to local storage
        await new Promise((resolve) => setTimeout(resolve, 1));
        const regex =
          /^\d{1,5} [\w\s]{1,20}, [\w\s]{1,20}, [A-Za-z]{2} \d{5}(, [\w\s]{2,20})?$/;
        if (regex.test(address)) {
          // console.log('Complete address')
          console.log(lati, lngi, "HQ");
          localStorage.setItem("EMAIL", JSON.stringify(email));
          localStorage.setItem("Prev_Address", JSON.stringify(address));
          if (lati !== "" || lngi !== "") {
            localStorage.setItem("Latitude", JSON.stringify(lati));
            localStorage.setItem("Longitude", JSON.stringify(lngi));
          }
          handleWriteAddress();
          history.push({
            pathname: "signup",
            state: {
              googleUser: googleUser,
              emailFromLanding: email,
              Service: signUpPageSelected,
            },
          });
        } else {
          // toast.error("Please enter or select your address");
          showAutoClosePopup(
            "Error",
            "Please enter or select your address",
            3000
          );
          return;
        }
      }
    } else {
      if (address === "undefined") {
        // toast.error("Please enter or select your address");
        showAutoClosePopup(
          "Error",
          "Please enter or select your address",
          3000
        );
        return;
      } else {
        // Handling "I am not a robot feature: Posting the token to the backend for validation"

        // In-visible Recaptcha implementaion
        const token = await captchaRef.current.executeAsync();
        await axios.post("https://be-operation-services.herokuapp.com/Robot", {
          token: token,
        });
        captchaRef.current.reset();

        //---------------------------------------------------------------------

        // Save address and location data to local storage
        await new Promise((resolve) => setTimeout(resolve, 1));
        const regex =
          /^\d{1,5} [\w\s]{1,20}, [\w\s]{1,20}, [A-Za-z]{2} \d{5}(, [\w\s]{2,20})?$/;
        if (regex.test(address)) {
          // console.log('Complete address')
          console.log(lati, lngi, "HQ");
          localStorage.setItem("EMAIL", JSON.stringify(email));
          localStorage.setItem("Prev_Address", JSON.stringify(address));
          if (lati !== "" || lngi !== "") {
            localStorage.setItem("Latitude", JSON.stringify(lati));
            localStorage.setItem("Longitude", JSON.stringify(lngi));
          }
          handleWriteAddress();
          history.push({
            pathname: "signup",
            state: {
              googleUser: googleUser,
              emailFromLanding: "",
              Service: signUpPageSelected,
            },
          });
        } else {
          // toast.error("Please enter or select your address");
          showAutoClosePopup(
            "Error",
            "Please enter or select your address",
            3000
          );
          return;
        }
      }
    }
  };

  const handleKeyPress = async (event) => {
    // Check if the key pressed is the "Enter" key
    if (event.key === "Enter") {
      event.preventDefault();
      buttonRef.current.focus();
      // Give focus to the button and trigger the instant quote function
      const regex =
        /^\d{1,5} [\w\s]{1,20}, [\w\s]{1,20}, [A-Za-z]{2} \d{5}(, [\w\s]{2,20})?$/;
      if (regex.test(address)) {
        const params = {
          apiKey: API_KEY,
          searchtext: address,
        };
        await axios
          .get("https://geocoder.ls.hereapi.com/6.2/geocode.json", {
            params,
          })
          .then((response) => {
            var Position =
              response.data.Response.View[0].Result[0].Location.DisplayPosition;
            console.log("Positions", Position.Latitude, Position.Longitude);
            setLati(Position.Latitude);
            setLngi(Position.Longitude);
            localStorage.setItem("Latitude", JSON.stringify(Position.Latitude));
            localStorage.setItem(
              "Longitude",
              JSON.stringify(Position.Longitude)
            );
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(lati, lngi);
        handleInstantQoute();
      } else {
        return;
      }
    }
  };

  const captchaRef = useRef(null);

  const onOneTapSignedIn = async (response) => {
    console.log(response, "Response");
    const cred = GoogleAuthProvider.credential(response.credential);
    const result = await signInWithCredential(auth, cred).catch((err) =>
      console.log(err)
    );
    googleUser.push(
      result.user.uid,
      result.user.emailVerified,
      result.user.displayName,
      result.user.email,
      result.user.phoneNumber
    );

    setEmail(result.user.email);
    console.log(googleUser, "Result");
  };

  const showAutoClosePopup = (title, content, duration) => {
    setOpenPopup(true);
    setPopupTitles(title);
    setPopupContent(content);
    setPopupActions([]);

    setTimeout(() => {
      setOpenPopup(false);
    }, duration);
  };

  return (
    <>
      <div className={classes.root}>
        {/* Header for Seo */}
        <Helmet>
          <title>Lawnber: Complete Lawn Maintenance At A Reasonable Cost</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Lawnber provides convenient and professional lawn care
                  services at affordable prices. Schedule your service today
                  with a local lawn care expert."
          />
          <link rel="canonical" href="https://lawnber.com/" />
          <meta
            property="og:image"
            content="https://lawnber.com/static/media/lawnberlogo2.5b43f6f2.png"
          />
          <meta property="og:title" content="Lawnber" />
          <meta
            property="og:description"
            content="Lawnber provides convenient and professional lawn care
                  services at affordable prices. Schedule your service today
                  with a local lawn care expert."
          />
          <meta property="og:url" content="https://lawnber.com/" />
          <meta property="og:type" content="website" />
        </Helmet>

        {/* Dialog Box skipping email */}
        <Dialog
          open={skipEmail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please enter your email
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Email field is empty. You can skip the field or enter the email.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleInstantQoute}
              style={{ color: "red", textTransform: "capitalize" }}
            >
              Skip
            </Button>
            <Button
              onClick={() => setSkipEmail(false)}
              variant="contained"
              color="primary"
              autoFocus
              style={{ textTransform: "capitalize" }}
            >
              Enter the email
            </Button>
          </DialogActions>
        </Dialog>

        <Popup
          open={openPopup}
          onClose={handleClosePopup}
          title={popupTitles}
          content={popupContent}
          actions={popupActions}
          alert
        />

        {/* Navbar */}
        <AppBar elevation={0} position="sticky" className={classes.appBar}>
          <Toolbar>
            <Box style={{ marginTop: "1rem" }}>
              <p>
                <a href="/">
                  <img src={Logo} alt="Logo" className={classes.logo} />
                </a>
              </p>
            </Box>
            <Box flexGrow={1} />
            <Box display="flex" alignItems="center">
              {!matchesMd &&
                allServices.map((value, i) => (
                  <button
                    id={value}
                    key={i}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor:
                        i === rotatingIndex ? "#187CAE" : "#d3d3d3",
                      color: i === rotatingIndex ? "white" : "black",
                      fontWeight: "bold",
                      fontSize: isBetweenMdAndLg ? "1vh" : "1.6vh",
                      borderRadius: "5px",
                      border: "none",
                      cursor:
                        value === "Customized Projects" ||
                          value === "Multiple Homes"
                          ? null
                          : "pointer",
                      minHeight: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      textAlign: "center",
                      textTransform: "none",
                      marginLeft: "0.5rem",
                    }}
                    onClick={(e) => {
                      if (
                        e.target.id === "Customized Projects" ||
                        e.target.id === "Multiple Homes"
                      ) {
                        return;
                      } else {
                        setSignUpPageSelected(e.target.id);
                        console.log(e.target.id);
                        setIsWinter(e.target.id === "Snow Cleanup");
                        swiperRef.current.swiper.slideTo(i, 0, true);
                        setRotaingIndex(swiperRef.current.swiper.activeIndex);
                        console.log("Index i:", i);
                        console.log("Rotaing index:", rotatingIndex);
                        console.log(
                          "Active index:",
                          swiperRef.current.swiper.activeIndex
                        );
                      }
                    }}
                  >
                    {value}
                    {(value === "Fall Cleanup" ||
                      value === "Hedge Trimming") && (
                        <Typography
                          style={{
                            background: "red",
                            color: "white",
                            paddingLeft: "3px",
                            paddingRight: "3px",
                            marginLeft: "3px",
                            marginTop: "-5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            pointerEvents: "none",
                          }}
                        >
                          New
                        </Typography>
                      )}
                  </button>
                ))}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginTop: matchesSm ? "8%" : "4%" }}
              >
                {allServices.map((value, i) => (
                  <MenuItem
                    key={i}
                    value={value}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: i === rotatingIndex ? "#187CAE" : "black",
                      fontSize: matchesSm
                        ? "0.9rem"
                        : matchesMd
                          ? "0.9rem"
                          : "1rem",
                    }}
                    onClick={(e) => {
                      setSignUpPageSelected(
                        e.currentTarget.getAttribute("value")
                      );
                      swiperRef.current.swiper.slideTo(i, 0, true);
                      setRotaingIndex(i);
                      setAnchorEl(null);
                    }}
                  >
                    {value}
                    {value === "Mulch" && (
                      <Typography
                        style={{
                          background: "red",
                          color: "white",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          marginLeft: "3px",
                          marginTop: "-5px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        New
                      </Typography>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box flexGrow={1} />
            <Box display="flex" alignItems="center">
              <>
                {matchesMd && (
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleDropDown}
                    style={{
                      marginTop: matchesSm ? "0.5rem" : "0.5rem",
                      marginRight: "1rem",
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: matchesSm
                        ? "1rem"
                        : matchesMd
                          ? "1.1rem"
                          : "1.2rem",
                    }}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Services
                  </Button>
                )}
              </>
            </Box>
            {!matchesSm && (
              <Button
                variant="contained"
                color="secondary"
                aria-label="Contact Us"
                component="a"
                href={"/Contact"}
                style={{
                  marginRight: "1rem",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: "bold",
                  minHeight: "40px",
                  fontSize: matchesSm
                    ? "0.9rem"
                    : matchesMd
                      ? "1rem"
                      : isBetweenMdAndLg
                        ? "1.1vh"
                        : "1.6vh",
                  textDecoration: "none",
                }}
              >
                Contact Us
              </Button>
            )}
            <Box display="flex" alignItems="center">
              {matchesSm && (
                <Box sx={{ flexGrow: 0 }}>
                  <div
                    className="navLink"
                    onClick={() => {
                      history.push("contact");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "bolder",
                      cursor: "pointer",
                      fontSize: matchesSm ? "0.9rem" : "1.1rem",
                    }}
                  >
                    <ContactSupport
                      fontSize="large"
                      style={{ color: "#187CAE", fontSize: 45 }}
                    />
                  </div>
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <a
                  href="Login"
                  className="navLink"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: matchesSm ? "0.9rem" : "1.1rem",
                  }}
                >
                  <AccountCircleIcon sx={{ color: "#187CAE", fontSize: 45 }} />
                </a>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Address and Email Input (only for mobile device) */}
        {matchesSm && (
          <div style={{ padding: "2%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                paddingLeft: "3%",
                borderRadius: "5px",
                boxShadow: "1px 2px 9px #a5a4a4",
              }}
            >
              <LocationOn
                style={{ paddingRight: "10px" }}
                fontSize="large"
                aria-label="menu"
                color="primary"
              />
              <InputBase
                inputRef={inputRef}
                sx={{ ml: 1, flex: 1 }}
                style={{
                  width: "96%",
                  fontFamily: "Poppins",
                }}
                placeholder="Enter your address"
                inputProps={{ "aria-label": "Enter your address" }}
                onKeyPress={handleKeyPress}
                onChange={handleInputChange}
                value={address}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                paddingLeft: "3%",
                marginTop: "3%",
                borderRadius: "5px",
                boxShadow: "1px 2px 9px #a5a4a4",
              }}
            >
              <Email
                style={{ paddingRight: "10px" }}
                fontSize="large"
                aria-label="menu"
                color="primary"
              />

              <InputBase
                sx={{ ml: 1, flex: 1 }}
                style={{
                  width: "96%",
                  fontFamily: "Poppins",
                  cursor: googleUser.length > 2 ? "not-allowed" : "",
                  color: googleUser.length > 2 ? "grey" : "",
                }}
                readOnly={googleUser.length > 2}
                placeholder="Enter your email"
                inputProps={{ "aria-label": "email address" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>

            <Button
              color="primary"
              disableElevation
              type="button"
              className={classes.InstantQuoteButton}
              variant="contained"
              onClick={handleInstantQoute}
              ref={buttonRef}
              style={{
                marginTop: "3%",
                width: "60%",
                fontFamily: "Poppins",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
            >
              Get Instant Quote
            </Button>
          </div>
        )}

        {/* Rotating Carousel */}
        <div
          style={{
            marginTop: matchesXs ? "1%" : "0%",
          }}
          ref={imageRef}
          onLoadCapture={() => {
            setLoadingCarousel(false);
          }}
        >
          {loadingCarousel && (
            <Skeleton
              className="heroCarousel"
              variant="rounded"
              animation="wave"
              style={{ height: matchesSm ? "30vh" : "60vh" }}
            >
              <div>
                <img
                  loading="eager"
                  src={springbgplaceholder}
                  className="heroCarousel"
                  alt="Placeholder"
                />
              </div>
            </Skeleton>
          )}
        </div>

        {!loadingCarousel && (
          <Swiper
            ref={swiperRef}
            modules={[Autoplay]}
            initialSlide={extVar ? allServices.indexOf(extVar) : "0"}
            autoplay={
              extVar
                ? false
                : {
                  delay: 8000,
                  disableOnInteraction: true,
                  reverseDirection: false,
                }
            }
            speed="2500"
            onSlideChange={(selectedIndex) => {
              setRotaingIndex(selectedIndex.realIndex);
            }}
          >
            <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={springbgmobile}
                    className="heroCarousel"
                    onLoad={() => {
                      setLoadingCarousel(false);
                    }}
                    loading="eager"
                    alt="Spring Background"
                    title="Spring Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={springbg}
                    onLoad={() => {
                      setLoadingCarousel(false);
                    }}
                    loading="eager"
                    alt="Spring Background"
                    title="Spring Background"
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={bgmobile}
                    className="heroCarousel"
                    loading="eager"
                    alt="Lawn Background"
                    title="Lawn Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={bg}
                    className="heroCarousel"
                    loading="eager"
                    alt="Lawn Background"
                    title="Lawn Background"
                  />
                </div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={fallbgmobile}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Fall Background"
                    title="Fall Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={fallbg}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Fall Background"
                    title="Fall Background"
                  />
                </div>
              )}
            </SwiperSlide>
            {/* <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={snowbgmobile}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Snow Background"
                    title="Snow Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={snowbg}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Snow Background"
                    title="Snow Background"
                  />
                </div>
              )}
            </SwiperSlide> */}
            <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={mulchbgmobile}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Mulch Background"
                    title="Mulch Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={mulchbg}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Mulch Background"
                    title="Mulch Background"
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {matchesSm ? (
                <div>
                  <img
                    src={hedgebgmobile}
                    className="heroCarousel"
                    loading="lazy"
                    alt="Hedge Background"
                    title="Hedge Background"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={hedgebg}
                    className="heroCarousel"
                    loading="lazy"
                    alt="hedge Background"
                    title="hedge Background"
                  />
                </div>
              )}
            </SwiperSlide>
          </Swiper>
        )}

        {/* Address and Email Input */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: matchesSm ? "column" : matchesMd ? "column" : "row",
          }}
        >
          <div>
            <ReCAPTCHA
              //style= {{transform: "scale(0.6)", marginTop: "70px", marginLeft: "-700px",theme: "white"}}
              ref={captchaRef}
              sitekey={RE_CAPTCHA_SITEKEY}
              size="invisible"
            />
          </div>

          {!matchesSm && (
            <Paper component="form" elevation={0} className={classes.searchBox}>
              <LocationOn
                style={{ paddingRight: "10px" }}
                fontSize="large"
                aria-label="menu"
                color="primary"
              />
              <InputBase
                inputRef={inputRef}
                sx={{ ml: 1, flex: 1 }}
                style={{
                  width: "40%",
                  fontFamily: "Poppins",
                }}
                placeholder="Enter your address"
                inputProps={{ "aria-label": "Enter your address" }}
                onKeyPress={handleKeyPress}
                onChange={handleInputChange}
                value={address}
              />

              <Box sx={{ flex: 1 }} />
              {badaddress ? <ClearOutlined style={{ color: "red" }} /> : null}

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ color: "black", opacity: "1" }}
              />

              <Email
                style={{ paddingRight: "10px" }}
                fontSize="large"
                aria-label="menu"
                color="primary"
              />

              <InputBase
                sx={{ ml: 1, flex: 1 }}
                style={{
                  width: "30%",
                  fontFamily: "Poppins",
                  color: googleUser.length > 2 ? "grey" : "",
                  cursor: googleUser.length > 2 ? "none" : "",
                }}
                readOnly={googleUser.length > 2}
                placeholder="Enter email"
                inputProps={{ "aria-label": "email address" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <Button
                color="primary"
                disableElevation
                type="button"
                className={classes.InstantQuoteButton}
                variant="contained"
                onClick={handleInstantQoute}
                ref={buttonRef}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                Get Instant Quote
              </Button>
            </Paper>
          )}
        </div>
        <div>
          <img
            src={Captcha}
            loading="eager"
            alt="Captcha"
            title="Captcha"
            style={{
              maxWidth: matchesSm ? "50px" : "50px",
              minWidth: matchesSm ? "50px" : "50px",
              maxHeight: matchesSm ? "50px" : "50px",
              minHeight: matchesSm ? "50px" : "50px",
              marginTop: "22px",
              //marginLeft: matchesSm ? "0px" : "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></img>
        </div>

        {/* <div>
          <video
            controls
            autoPlay
            style={{ width: "90%", marginTop: "10vh", borderRadius: "10px" }}
          >
            <source src={StepsVideo} type="video/mp4" />
          </video>
        </div> */}

        {/* ======== Steps heading ========= */}
        <h2
          className={classes.h1}
          style={{
            textAlign: "center",
            marginTop: matchesSm ? "6vh" : "3vh",
            padding: "1%",
          }}
        >
          Schedule Your Service In
          <br />
          <span style={{ fontWeight: "500" }}>A Few </span>
          <span className={classes.aHeading}> Easy Steps</span>
        </h2>
        {/* ================================ */}

        {/* ======== Steps component ======= */}
        <Container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5vh",
          }}
        >
          <Grid
            className={classes.stepsGrid}
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            {steps.map((data) => (
              <Grid
                item
                lg={3}
                md={3}
                xl={3}
                sm={6}
                xs={6}
                style={{ width: "100%" }}
                key={data.id}
                spacing={1}
              >
                <Card
                  style={{
                    boxShadow: "0px 4px 10px rgba(86, 61, 24, 0.2)",
                    borderRadius: "7.34448px",
                    background: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardContent>
                    <img
                      alt={data.image}
                      title={data.image}
                      loading="lazy"
                      className="center"
                      style={{ maxHeight: "75px" }}
                      src={data.image}
                    />
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: matchesSm ? "16px" : "20px",
                        fontWeight: "bold",
                        marginTop: "10px",
                        maxLines: "2",
                      }}
                    >
                      {data.step}
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* ================================ */}

        {/* =======  Button ================ */}

        <Button
          variant="contained"
          color="primary"
          style={{
            fontFamily: "Poppins",
            fontSize: matchesSm ? "0.9rem" : "1rem",
            fontWeight: "bold",
            marginTop: "8vh",
            textTransform: "none",
          }}
          onClick={() =>
            window.scrollTo({
              top: imageRef.current.offsetTop,
              behavior: "smooth",
            })
          }
        >
          Get Started
        </Button>
        {/* ==================================*/}

        {/* Services we provide */}
        <h2
          className={classes.h1}
          style={{
            textAlign: "center",
            marginTop: matchesSm ? "6vh" : "3vh",
            padding: "1%",
            marginBottom: '3vh'
          }}
        >
          Services We Provide
        </h2>

        <Grid container lg={12} xs={12} sm={12} xl={12}>
          {/* 1st: Spring */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            style={{
              display: "flex",
              flexDirection: matchesSm ? "column" : "",
              marginBottom: '3vh'
            }}
            alignItems="center"
          >
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                src={SpringCleanupService}
                style={{
                  width: "100%",
                  marginTop: matchesSm ? "1rem" : "0%",
                  maxWidth: matchesSm ? "90vw" : "40vw",
                }}
                alt="Spring Cleanup image"
              />
            </Grid>
            <Grid
              style={{ marginLeft: matchesSm ? "1rem" : "" }}
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <a
                className={classes.aLinkParagraph}
                href="https://lawnber.com/Blog/2023-9/57/-Spring-Cleanup:-Preparing-Your-Yard-for-the-Growing-Season"
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: matchesSm ? "16px" : "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    maxLines: "2",
                    textAlign: "left",
                  }}
                >
                  Spring Cleanup
                </p>
              </a>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                }}
              >
                Spring cleanup clear the lawn of any debris, leaving it open to
                absorb nutrients. During winter, winds and snow may bring down
                extra leaves, branches, and other debris that can suffocate the
                grass and prevent it from growing properly. It can help
                eliminate pests that may have taken up residence in the lawn
                during the winter. Removing debris and dead leaves can help
                prevent pests from making a home in the lawn. Price starts from
                $200, signup now.
              </p>
              {/* <Button color="primary" variant="contained">
                Signup Now
              </Button> */}
            </Grid>
          </Grid>

          {/* 2nd: Lawn */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            alignItems="center"
            style={{
              marginTop: matchesSm ? "0%" : "3vh",
              display: "flex",
              flexDirection: matchesSm ? "column-reverse" : "",
              marginBottom: '3vh'
            }}
          >
            <Grid
              item
              style={{ paddingLeft: matchesSm ? "1rem" : "3rem" }}
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <a
                className={classes.aLinkParagraph}
                href="https://lawnber.com/Blog/2023-9/56/How-to-Achieve-the-Perfect-Lawn-Mowing-Mastery"
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: matchesSm ? "16px" : "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    maxLines: "2",
                    textAlign: "left",
                  }}
                >
                  Lawn Mowing
                </p>
              </a>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                }}
              >
                Mowing helps to keep the blades at the ideal height for
                photosynthesis and nutrient absorption. Regular{" "}
                <a
                  className={classes.aLinkParagraph}
                  style={{ textDecoration: "none" }}
                  href="https://lawnber.com/Blog/2023-9/75/Lawn-Care-Challenges:-Dealing-with-Common-Grass-Problems"
                >
                  grass cutting
                </a>{" "}
                also ensures the grass grows evenly and maintains adequate
                density. We have well-maintained equipment to ensure that the{" "}
                <a
                  className={classes.aLinkParagraph}
                  style={{ textDecoration: "none" }}
                  href="https://lawnber.com/Blog/2023-9/97/Creating-a-Low-Maintenance-Landscape:-Time-Saving-Tips"
                >
                  grass maintenance
                </a>{" "}
                is done efficiently and effectively to all types of lawn. To get
                the best price, signup now.
              </p>
              {/* <Button color="primary" variant="contained">
                Signup Now
              </Button> */}
            </Grid>
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                style={{
                  width: "100%",
                  maxWidth: matchesSm ? "90vw" : "40vw",
                  marginTop: matchesSm ? "1rem" : "0%",
                }}
                src={LawnMowingService}
                alt="Lawn Cleanup image"
              />
            </Grid>
          </Grid>

          {/* Lawn Addons */}
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: matchesSm ? "16px" : "18px",
              fontWeight: "bold",
              marginTop: "2vh",
              maxLines: "2",
              paddingLeft: "3vw",
              paddingBottom: "2%",
            }}
          >
            Additional Lawn Services
          </p>
          <Grid
            className={classes.stepsGrid}
            item
            lg={12}
            sm={11}
            md={12}
            xl={12}
            xs={11}
            container
            justifyContent="center"
            style={{
              marginBottom: "3vh",
            }}
          >
            {LawnAddons?.map((data, i) => (
              <Grid
                item
                lg={3}
                xl={3}
                md={3}
                sm={12}
                xs={12}
                style={{
                  width: "100%",
                  marginRight: !matchesSm ? "3vh" : "",
                  marginLeft: matchesSm ? "5vw" : "",
                }}
                key={data.id}
                // spacing={3}
                justifyContent="space-between"
              >
                <Card className={classes.SameHeightCards}>
                  <img
                    src={data.img}
                    title={data.addonName}
                    loading="lazy"
                    alt={data.addonName}
                    style={{
                      width: "100%",
                      height: "30%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <CardContent>
                    <p
                      style={{
                        fontSize: matchesSm ? "16px" : "20px",
                        fontFamily: "Poppins",
                        color: "black",
                        fontWeight: "bold",
                        marginTop: "10px",
                        maxLines: "2",
                        textAlign: "center",
                      }}
                    >
                      {data.addonName}
                    </p>
                    <p className={classes.whoWeArep}>{data.description}</p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* 3rd Fall */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            alignItems="center"
            style={{
              marginTop: "3vh",
              display: "flex",
              flexDirection: matchesSm ? "column" : "row",
              marginBottom: '3vh'
            }}
          >
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                style={{ width: "100%", maxWidth: matchesSm ? "90vw" : "40vw" }}
                src={FallCleanupService}
                alt="fall Cleanup image"
              />
            </Grid>
            <Grid
              style={{ marginLeft: matchesSm ? "1rem" : "" }}
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <a
                className={classes.aLinkParagraph}
                href="https://lawnber.com/Blog/2023-9/22/Caring-For-Cool-Season-Fescue-Lawns-This-Fall"
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: matchesSm ? "16px" : "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    maxLines: "2",
                    textAlign: "left",
                  }}
                >
                  Fall Cleanup
                </p>
              </a>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                }}
              >
                Get your lawn ready for the{" "}
                <a
                  className={classes.aLinkParagraph}
                  style={{ textDecoration: "none" }}
                  href="https://lawnber.com/Blog/2023-9/1/The-Lawn-Care-Guide-for-Fall"
                >
                  fall
                </a>{" "}
                season with our comprehensive Fall Clean-Up Services. We'll
                clear away fallen leaves, trim bushes, and remove debris to
                ensure a tidy and vibrant yard. Our expert team will help your
                lawn breathe, absorb sunlight, and stay resilient against pests
                and diseases by using insecticide. Through a lawn care
                guidelines, prepare your outdoor space for the changing seasons
                and enjoy a well-maintained landscape and increase lawn quality
                throughout the year.
              </p>
              {/* <Button color="primary" variant="contained">
                Signup Now
              </Button> */}
            </Grid>
          </Grid>

          {/* Fall Addons */}
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: matchesSm ? "16px" : "18px",
              fontWeight: "bold",
              marginTop: "15px",
              maxLines: "2",
              paddingLeft: "3vw",
              paddingBottom: "2%",
            }}
          >
            Additional Fall Cleanup Services
          </p>
          <Grid
            className={classes.stepsGrid}
            item
            lg={12}
            sm={11}
            md={12}
            xl={12}
            xs={11}
            container
            justifyContent="center"
            style={{
              marginBottom: "3vh",
            }}
          >
            <Grid
              lg={4}
              xl={4}
              md={4}
              sm={12}
              xs={12}
              style={{
                width: "100%",
                marginRight: !matchesSm ? "3vh" : "",
                marginLeft: matchesSm ? "5vw" : "",
                marginBottom: matchesSm ? "5vw" : "",
              }}
              justifyContent="center"
            >
              <Card className={classes.SameHeightCards}>
                <img
                  src={BushTrimming}
                  title="Bush Trimming"
                  loading="lazy"
                  alt="Bush Trim pic"
                  style={{
                    width: "100%",
                    height: "35%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
                <CardContent>
                  <p
                    style={{
                      fontSize: matchesSm ? "16px" : "20px",
                      fontFamily: "Poppins",
                      color: "black",
                      fontWeight: "bold",
                      marginTop: "10px",
                      maxLines: "2",
                      textAlign: "center",
                    }}
                  >
                    Bush Trim & Plant Removal
                  </p>
                  <p className={classes.whoWeArep}>
                    <a
                      className={classes.aLinkParagraph}
                      style={{ textDecoration: "none" }}
                      href="https://lawnber.com/Blog/2023-9/27/How-to-Properly-Fertilize-Your-Lawn"
                    >
                      Bush Trimming
                    </a>{" "}
                    is a horticultural practice that involves selectively
                    pruning or cutting back the branches and foliage of shrubs
                    and bushes to maintain their shape, size, and overall
                    appearance. This process is done for both aesthetic and
                    functional reasons, such as enhancing the appearance and
                    prevent overgrowth.
                  </p>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              lg={4}
              xl={4}
              md={4}
              sm={12}
              xs={12}
              style={{
                width: "100%",
                marginRight: !matchesSm ? "3vh" : "",
                marginLeft: matchesSm ? "5vw" : "",
                marginBottom: matchesSm ? "5vw" : "",
              }}
              justifyContent="center"
            >
              <Card className={classes.SameHeightCards}>
                <img
                  src={Overseeding}
                  title="Over Seeding"
                  loading="lazy"
                  alt="OverSeeding pic"
                  style={{
                    width: "100%",
                    height: "35%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
                <CardContent>
                  <p
                    style={{
                      fontSize: matchesSm ? "16px" : "20px",
                      fontFamily: "Poppins",
                      color: "black",
                      fontWeight: "bold",
                      marginTop: "10px",
                      maxLines: "2",
                      textAlign: "center",
                    }}
                  >
                    Over Seeding & Fertilizer
                  </p>
                  <p className={classes.whoWeArep}>
                    <a
                      className={classes.aLinkParagraph}
                      style={{ textDecoration: "none" }}
                      href="https://lawnber.com/Blog/2023-9/87/The-Power-of-Overseeding:-Rejuvenating-Your-Lawn's-Health"
                    >
                      Overseeding lawn
                    </a>{" "}
                    and{" "}
                    <a
                      className={classes.aLinkParagraph}
                      style={{ textDecoration: "none" }}
                      href="https://lawnber.com/Blog/2023-9/27/How-to-Properly-Fertilize-Your-Lawn"
                    >
                      Lawn Fertilizer
                    </a>{" "}
                    application are essential techniques in yard maintenance.
                    Overseeding involves spreading grass seed over an existing
                    lawn to fill in bare patches, improve density, and introduce
                    new, more resilient grass varieties. Grass Fertilizer
                    provide crops with nutrients which allow crops to grow
                    bigger, faster.
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* 4th: Mulch */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            alignItems="center"
            style={{
              marginTop: "3vh",
              display: "flex",
              flexDirection: matchesSm ? "column-reverse" : "",
              marginBottom: "3vh",
            }}
          >
            <Grid
              item
              style={{
                paddingLeft: matchesSm ? "1rem" : "3rem",
                maxWidth: matchesSm ? "95%" : "90%",
              }}
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <a
                className={classes.aLinkParagraph}
                href="https://lawnber.com/Blog/2023-9/59/-Mulching-Matters:-The-Benefits"
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: matchesSm ? "16px" : "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    maxLines: "2",
                    textAlign: "left",
                  }}
                >
                  Mulch
                </p>
              </a>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                }}
              >
                Mulch is a layer of colored bark chips applied to the surface of
                soil. Reasons for applying mulch include conservation of soil
                moisture, improving fertility and health of the soil, kill weed,
                reducing weed growth and enhancing the visual appeal of the
                area. It may be applied to top soil for lawn or around existing
                plants. The recommendation is 2 inch thick over existing mulch
                or 3 inch thick over bare soil. The process is usually
                straightforward, but you should let Lawnber know to avoid areas
                with young plants. As a reference 1 yard of mulch can cover
                approximately 160 sqft at 2 inch thick. Service Date will be
                scheduled once we have the material ordered.
              </p>
              {/* <Button color="primary" variant="contained">
                Signup Now
              </Button> */}
            </Grid>
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                style={{ width: "100%", maxWidth: matchesSm ? "90vw" : "40vw" }}
                src={MulchService}
                alt="Mulch image"
              />
            </Grid>
          </Grid>

          {/* Mulch Addons */}
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: matchesSm ? "16px" : "18px",
              fontWeight: "bold",
              marginTop: "15px",
              maxLines: "2",
              paddingLeft: "3vw",
              paddingBottom: "2%",
            }}
          >
            Additional Mulch Services
          </p>
          <Grid
            className={classes.stepsGrid}
            item
            lg={12}
            sm={11}
            md={12}
            xl={12}
            xs={11}
            container
            justifyContent="center"
            style={{
              marginBottom: "3vh",
              height: "100%",
            }}
          >
            {MulchAddons?.map((data, i) => (
              <Grid
                item
                lg={3}
                xl={3}
                md={3}
                sm={12}
                xs={12}
                style={{
                  width: "100%",
                  marginRight: !matchesSm ? "3vh" : "",
                  marginLeft: matchesSm ? "5vw" : "",
                  marginBottom: matchesSm ? "0vw" : "",
                  // maxHeight: "404px"
                }}
                key={data.id}
                spacing={3}
                justifyContent="center"
              >
                <Card className={classes.SameHeightCards}>
                  <img
                    src={data.img}
                    title={data.addonName}
                    loading="lazy"
                    alt={data.addonName}
                    style={{
                      width: "100%",
                      height: "25%",
                      maxHeight: "100px",
                      objectFit: "scale-down",
                    }}
                  />
                  <CardContent>
                    <p
                      style={{
                        fontSize: matchesSm ? "16px" : "20px",
                        fontFamily: "Poppins",
                        color: "black",
                        fontWeight: "bold",
                        marginTop: "10px",
                        maxLines: "2",
                        textAlign: "center",
                      }}
                    >
                      {data.addonName}
                    </p>
                    <p className={classes.whoWeArep}>{data.description}</p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* 5rd Hedge */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            justifyContent={matchesMd ? "center" : "space-between"}
            alignItems="center"
            style={{
              marginTop: "3vh",
              display: "flex",
              flexDirection: matchesSm ? "column" : "",
              marginBottom: "3vh",
            }}
          >
            <Grid item xl={6} lg={6} md={12} xs={12} sm={12}>
              <img
                style={{ width: "100%", maxWidth: matchesSm ? "90vw" : "40vw" }}
                src={HedgeService}
                alt="Hedge Cleanup image"
              />
            </Grid>
            <Grid
              item
              style={{ marginLeft: matchesSm ? "1rem" : "" }}
              xl={6}
              lg={6}
              md={12}
              xs={12}
              sm={12}
            >
              <a
                className={classes.aLinkParagraph}
                href="https://lawnber.com/Blog/2023-9/61/Hedge-Trimming-Techniques-for-a-Well-Manicured-Landscape"
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: matchesSm ? "16px" : "20px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    maxLines: "2",
                    textAlign: "left",
                  }}
                >
                  Hedge Trimming
                </p>
              </a>
              <p
                className={classes.whoWeArep}
                style={{
                  paddingBottom: "4vh",
                  maxWidth: matchesSm ? "95%" : "90%",
                }}
              >
                Summer offers an excellent opportunity to trim back any
                overgrown bushes and hedges in your yard. If your property is
                less than 12,000 square feet, take advantage of Lawnber's
                special discount this month for hedge trimming services. For
                properties greater than 12,000 square feet, simply press the
                "Get price" button, and we will promptly email you the price
                within 24 hours.
              </p>
              {/* <Button color="primary" variant="contained">
                Signup Now
              </Button> */}
            </Grid>
          </Grid>
        </Grid>

        {/* =============================== */}

        {/* FAQ (Frequently Asked Questions) */}
        <h2
          className={classes.h1}
          style={{
            textAlign: "center",
            marginTop: matchesSm ? "6vh" : "3vh",
            padding: "1%",
            marginBottom: "3vh"
          }}
        >
          Frequently Asked Questions
        </h2>
        <Grid
          container
          xl={12}
          lg={12}
          sm={12}
          spacing={5}
          justifyContent="center"
        >
          <Grid item xl={4} lg={4} sm={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  What services does Lawnber provide?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  Lawnber offers a comprehensive range of lawn maintenance
                  services, including weekly/bi-weekly lawn mowing, spring
                  cleanup, mulch installation, hedge trimming, fall cleanup, and
                  snow cleanup.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  How can I book a Lawn mowing service with Lawnber?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  Booking a lawn mowing service with Lawnber is a simple and
                  quick process. All you need to do is enter your address,
                  select the service plan and subscription type, provide payment
                  details to complete the sign-up, and you will be ready to
                  receive services from Lawnber.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  What if my address is not found?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  If your address is not found, please double-check that you
                  have entered your full address correctly. Enter your contact
                  details, and we will respond within 24 hours to resolve any
                  address lookup issues and provide a quote.
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xl={4} lg={4} sm={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  What additional services does Lawnber offer?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  Lawnber provides various add-on services such as overseeding
                  and fertilizer application, pool edging, bag clippings, weed
                  removal, aeration, and bush trimming/plant removal. You can
                  log in to our website with your credentials, select the
                  desired add-on services, and submit your request.
                  Alternatively, you can contact us through the "Contact Us"
                  option, and our service team will add the required add-on
                  services to your service list.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  Does Lawnber offer one-time services?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  Yes, Lawnber provides both one-time job services and
                  weekly/bi-weekly mowing services. We recommend the weekly
                  service, which offers the best-discounted price.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandCircleDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p style={{ textAlign: "left" }}>
                  Can I change the service frequency later?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={{ textAlign: "left" }}>
                  Lawnber allows you to change the job frequency at any time.
                  Simply log in with your credentials on our website, update the
                  job frequency on the signup page, and save the changes. You
                  can also reach out to us through the "Contact Us" option, and
                  our service team will assist you 24/7.
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* =============================== */}

        {/* Related Blogs */}
        {/* <Grid
          container
          xl={12}
          lg={12}
          sm={12}
          justifyContent="center"
          style={{
            marginBottom: "3%",
            marginTop: "3vh",
          }}
        >
          <a href="blog" style={{ textDecoration: "none", width: "100%" }}>
            <h2
              className={classes.h1}
              style={{
                textAlign: "center",
                marginTop: matchesSm ? "6vh" : "3vh",
                padding: "1%",
                marginBottom: '3vh'
              }}
            >
              Related Blogs
            </h2>
          </a>
          <Grid
            lg={12}
            sm={11}
            md={12}
            xl={12}
            xs={11}
            container
            justifyContent="center"
          >
            {blogDetails?.map((data, i) => (
              <Grid
                item
                lg={3}
                xl={3}
                md={3}
                sm={12}
                xs={12}
                style={{
                  width: "100%",
                  marginRight: !matchesSm ? "3vh" : "",
                  marginLeft: matchesSm ? "5vw" : "",
                  marginBottom: matchesSm ? "5vw" : "",
                }}
                key={data.id}
                spacing={3}
                justifyContent="center"
              >
                <Card
                  className={classes.SameHeightCards}
                  style={{ maxHeight: "88%" }}
                >
                  <img
                    src={data.ImageURL}
                    title={data.Title}
                    loading="lazy"
                    alt={data.Title}
                    style={{
                      width: "100%",
                      height: "30%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <CardContent>
                    <p
                      style={{
                        fontSize: matchesSm ? "16px" : "20px",
                        fontFamily: "Poppins",
                        color: "black",
                        fontWeight: "bold",
                        marginTop: "10px",
                        maxLines: "2",
                        textAlign: "center",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.Title}
                    </p>
                    <p
                      className={classes.whoWeArep}
                      style={{
                        fontSize: "2vh",
                        maxWidth: matchesSm ? "100%" : "99%",
                        lineHeight: "20px",
                        height: !matchesMd ? "105px" : "",
                      }}
                    >
                      {data.Description}
                    </p>
                  </CardContent>
                  <Button
                    style={{
                      marginTop: matchesSm ? "10px" : "5%",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(
                        `/Blog/2023-9/${data.id}/${data.Title.split(" ").join(
                          "-"
                        )}`
                      )
                    }
                  >
                    Read More
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Button
            href="blog"
            color="primary"
            size="large"
            variant="contained"
            style={{
              width: matchesSm ? "50vw" : "30vw",
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "18px",
              color: "white",
            }}
          >
            View All Blogs
          </Button>
        </Grid> */}

        {/* Who are we component */}
        <Grid
          className={classes.grid2}
          lg={12}
          xs={12}
          sm={12}
          xl={12}
          style={{
            flexDirection: "column",
            backgroundColor: "#d0e3e7",
            marginTop: matchesSm ? "6vh" : "10vh",
            padding: "3vh",
            justifyContent: "center",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <h2 className={classes.h1} style={{ textAlign: "center" }}>
            Who{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              {""}
            </span>{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              we
            </span>{" "}
            <span
              className={classes.aHeading}
              style={{
                textTransform: "lowercase",
                textUnderlineOffset: "-2%",
              }}
            >
              {" "}
              are
            </span>
          </h2>
          {/* <p className={classes.whoWeArep}>
            Lawnber provides the best {isWinter ? "snow removal" : "lawn"}{" "}
            services to New York and Long Island residents. We make online
            booking, direct pricing in less than 10 seconds. Our experienced{" "}
            {isWinter ? "snow removal" : "lawn maintenance"} professionals are
            ready to provide weekly{" "}
            {isWinter ? "snow removal" : "lawn maintenance"} services so that
            you can focus on the rest of your schedule
          </p> */}
          <p
            className={classes.whoWeArep}
            style={{
              paddingBottom: "2vh",
            }}
          >
            Lawnber serves New York Long Island residents. It is easy, quick and
            reliable for online pricing and booking. Online direct pricing in
            less than 10 seconds after you enter the service address. Lawnber's
            professional crews are experienced in{" "}
            {isWinter ? "snow removal" : "lawn care"} and ready to provide you
            weekly services after you signup. Getting add-on{" "}
            {isWinter ? "snow removal" : "lawn care"} is very easy after your
            first {isWinter ? "snow" : "lawn"} maintenance. Lawnber provides
            simple to use online features to help you connect with the service
            crews and manage your services. Lawnber provides real-time status
            updates to you for a peace of mind that your{" "}
            {isWinter ? "snow" : "lawn"} is getting taken care of. The online
            payment is completely automated and secure with Stripe.
          </p>
        </Grid>

        {/* Who we Serve Help your Lawn component */}
        <Grid
          // className={classes.helpContainer}
          lg={12}
          md={12}
          xl={12}
          sm={12}
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Poppins",
            backgroundImage: `url(${isWinter ? lawnhelpsnow : lawnhelp})`,
            marginTop: matchesSm ? "0%" : "5%",
            backgroundSize: "1480px 550px",
            backgroundRepeat: "no-repeat",
            // backgroundPosition: 'center',
            height: "100%",
            width: "100%",
          }}
        >
          <h1
            className={classes.h1}
            style={{
              marginTop: "6%",
              lineHeight: "50px",
              color: isWinter ? "black" : "white",
              textTransform: "none",
              textAlign: "center",
            }}
          >
            Who{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              {""}
            </span>{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              we
            </span>{" "}
            <span
              className={classes.aHeading}
              style={{
                textTransform: "lowercase",
                textUnderlineOffset: "-2%",
                color: isWinter ? "black" : "white",
              }}
            >
              {" "}
              serve
            </span>
          </h1>
          <Stack
            direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}
            className={classes.cardStack}
            spacing={5}
            justifyContent="center"
          >
            <Card className={classes.card}>
              <img
                src={whoWeServe1}
                title="Who we serve1"
                alt="Who we serve"
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "150px",
                }}
              />
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
                style={{ textAlign: "center" }}
              >
                Busy Travelers
              </Typography>
              <Typography className={classes.p} style={{ textAlign: "center" }}>
                People busy traveling and not home to maintain the property.
              </Typography>
            </Card>

            {/* 2nd card */}
            <Card className={classes.card}>
              <img
                src={whoWeServe2}
                title="Who we serve2"
                alt="Who we serve2"
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "150px",
                }}
              ></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
                style={{ textAlign: "center" }}
              >
                Do-It-For-Me
              </Typography>
              <p className={classes.p} style={{ textAlign: "center" }}>
                People enjoying a leisure or retired lifestyle or physically
                can't do it themselves.
              </p>
            </Card>

            {/* 3rdcard */}
            <Card className={classes.card}>
              <img
                src={whoWeServe3}
                title="Who we serve3"
                alt="Who we serve3"
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "150px",
                }}
              ></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
                style={{ textAlign: "center" }}
              >
                Work Remote
              </Typography>
              <p className={classes.p} style={{ textAlign: "center" }}>
                Professionals who are busy working and don't have the time.
              </p>
            </Card>
            {/* 4th card */}
            <Card className={classes.card}>
              <img
                src={whoWeServe4}
                title="Who we serve4"
                alt="Who we serve4"
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "150px",
                }}
              ></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
                style={{ textAlign: "center" }}
              >
                Rental homeowners
              </Typography>
              <p className={classes.p} style={{ textAlign: "center" }}>
                People who have rental properties away from them.
              </p>
            </Card>
          </Stack>

          {/* Where we serve */}
          <h2
            className={classes.h1}
            style={{
              marginTop: matchesSm ? "6vh" : "6vh",
              padding: "1%",
              lineHeight: "50px",
              textTransform: "none",
              textAlign: "center",
            }}
          >
            Where{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              {""}
            </span>{" "}
            <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
              we
            </span>{" "}
            <span
              className={classes.aHeading}
              style={{
                textTransform: "lowercase",
                textUnderlineOffset: "-2%",
              }}
            >
              {" "}
              serve
            </span>
          </h2>

          <Grid
            container
            xl={10}
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ padding: "1%", marginLeft: "3%" }}
          >
            {places.map((data) => {
              return (
                <Grid item lg={2} sm={4} xs={4}>
                  <p
                    className={classes.whoWeArep}
                    key={data.id}
                    style={{ maxWidth: "95%" }}
                  >
                    {data.placeName}
                  </p>
                </Grid>
              );
            })}
          </Grid>

          {/* ======== Featured Blog Post ========= */}
          <h2
            className={classes.h1}
            style={{
              textAlign: "center",
              marginTop: matchesSm ? "6vh" : "6vh",
              padding: "1%",
            }}
          >
            We Are
            <br />
            <span className={classes.aHeading}>Featured In</span>
          </h2>
          <Grid
            className={classes.stepsGrid}
            lg={12}
            sm={12}
            xl={10}
            md={11}
            xs={11}
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
            style={{
              marginTop: "3%",
            }}
          >
            {landingImages.map((index) => (
              <Grid
                item
                lg={1}
                xl={1}
                md={3}
                sm={3}
                xs={5}
                spacing={3}
                key={index}
                alignItems="center"
                justifyContent="space-around"
              >
                <img
                  title={index.Imgsrc}
                  alt={index.Imgsrc}
                  loading="lazy"
                  className="center"
                  style={{ width: "100%", padding: "0%", objectFit: "cover" }}
                  src={index.Imgsrc}
                  onClick={() => {
                    window.open(index.Link, "_blank", "noopener,noreferrer");
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {/* ================================ */}

          {/* ======  Reviews Heading  =========*/}
          <h2
            className={classes.h1}
            style={{
              textAlign: "center",
              padding: "2%",
            }}
          >
            What Our Customers
            <br />
            <span style={{ fontWeight: "500" }}>Say </span>
            <span className={classes.aHeading}> About Us</span>
          </h2>
          {/* ======================= ========== */}

          {/* ======= Customer Reviews =======  */}
          <div
            style={{
              width: "90%",
              height: "100%",
              marginTop: "3%",
              marginLeft: "4%",
            }}
          >
            <CustomerReviews isWinter={isWinter} />
          </div>
          <Grid>
            <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
              <Typography align="center" className={classes.footer}>
                {pageVisits}
              </Typography>
            </Grid>
          </Grid>
          {/* ================================= */}

          {/* ======== Connect with US component heading ========= */}
          <h2
            className={classes.h1}
            style={{
              textAlign: "center",
              marginTop: matchesSm ? "6vh" : "6vh",
              padding: "1%",
            }}
          >
            Connect with
            <span className={classes.aHeading}> Us</span>
          </h2>
          {/* ================================ */}

          {/* ======== Connect with US component ======= */}
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5vh",
            }}
          >
            <Grid
              className={classes.stepsGrid}
              item
              lg={12}
              sm={11}
              md={12}
              xl={12}
              xs={10}
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
              style={{
                marginBottom: "3%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {socialMediaLinks?.map((data, i) => (
                <Grid
                  item
                  lg={2}
                  md={2}
                  xl={2}
                  sm={6}
                  xs={6}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  key={data.i}
                  spacing={1}
                >
                  <Card
                    style={{
                      boxShadow: "0px 4px 10px rgba(86, 61, 24, 0.2)",
                      borderRadius: "7.34448px",
                      background: "#FFFFFF",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        src={data.logo}
                        title={data.logo}
                        loading="lazy"
                        alt={`Logo ${i}`}
                        style={{ width: "40%", paddingTop: "5%" }}
                      />
                      <p style={{ margin: "5px 0", color: "black" }}>
                        {data.text}
                      </p>
                    </a>
                  </Card>
                </Grid>
              ))}

              <Grid
                item
                lg={2}
                md={2}
                xl={2}
                sm={6}
                xs={6}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Card
                  style={{
                    boxShadow: "0px 4px 10px rgba(86, 61, 24, 0.2)",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-around",
                    border: "3px solid #D0D0D0",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography style={{ padding: "10px", fontWeight: "bold" }}>
                      4.6/5
                    </Typography>
                  </div>
                  <a
                    href="https://www.google.com/maps/place/Lawnber/@40.8057703,-73.5209423,17z/data=!3m2!4b1!5s0x89c2817df8b63c55:0x686d94b0da6eaa26!4m6!3m5!1s0xe6343f318b6c7c3:0xaf3143600a94171e!8m2!3d40.8057663!4d-73.5183674!16s%2Fg%2F11jyjnvgbp?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      loading="lazy"
                      title="GMaps icon"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Google_Maps_icon_%282015-2020%29.svg/2048px-Google_Maps_icon_%282015-2020%29.svg.png"
                      alt="GMaps"
                      style={{ width: "40%", paddingTop: "5%" }}
                    />
                    <Typography style={{ margin: "5px 0", color: "black" }}>
                      Google Maps
                    </Typography>
                  </a>
                </Card>
              </Grid>

              {/* <p style={{ margin: "0", fontSize: "16px" }}>
                   4.6/5
                  </p> */}
            </Grid>
          </Container>
          {/* ================================ */}

          <Grid
            // className={classes.helpContainer}
            justifyContent="flex-start"
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Poppins",
              // backgroundImage: `url(${isWinter ? lawnhelpsnow : lawnhelp})`,
              marginTop: matchesSm ? "0%" : "5%",
              // backgroundSize: "1480px 550px",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: 'center',
              height: "100%",
              width: "100%",
            }}
          >
            <FooterHome
              title1="Lawnber"
              content1="Lawnber serves New York Long Island residents. It is easy, quick and reliable for online pricing and booking. Online direct pricing in less than 10 seconds after you enter the service address. Lawnber's professional crews are experienced in lawn care and ready to provide you weekly services after you signup."
              title2="Links"
              links={[
                { text: "Blog", href: "/Blog" },
                { text: "Privacy Policy", href: "/PrivacyPolicy" },
              ]}
              title3="Services"
              services={allServices}
              disclaimer="* By submitting my contact information, I agree that Lawnber or its representatives may contact me by email, phone, or SMS at the email address or phone number I provide, including for marketing purposes and by automatic dialing systems. I also agree to Lawnber’s terms of service and privacy policy"
              handleServiceChange={(event) => {
                const selectedService =
                  event.currentTarget.getAttribute("value");
                setIsWinter(selectedService === "Snow Cleanup");
                setAnchorEl(null);
              }}
              handleInstantQuote={() => {
                window.scrollTo({
                  top: imageRef.current.offsetTop,
                  behavior: "smooth",
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NewHome;
