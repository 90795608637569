import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Signup_QA from "./Signup";
import config from "../Config"

const stripePromise = loadStripe(config.PUBLIC_KEY_URL);

export default function StripeContainer() {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Signup_QA />
      </Elements>
    </div>
  );
}
