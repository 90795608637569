const allServices = [
  "Spring Cleanup",
  "Lawn Mowing",
  "Fall Cleanup",
  // "Snow Cleanup",
  "Mulch",
  "Hedge Trimming",
];

const allServicesQA = [
  "Spring Cleanup",
  "Lawn Mowing",
  "Fall Cleanup",
  // "Snow Cleanup",
  "Mulch",
  "Hedge Trimming",
];

const targetWords = [
  { word: "Lawnber", link: "https://lawnber.com" },
  {
    word: "Lawn Care",
    link: "https://lawnber.com/Blog/2023-9/44/Lawn-care-for-homeowners-associations",
  },
  {
    word: "spring",
    link: "https://lawnber.com/Blog/2023-9/64/A-Seasonal-Approach-to-Lawn-Care:-Spring,-Summer,-Fall,-and-Winter-Tips",
  },
  {
    word: "summer",
    link: "https://lawnber.com/Blog/2023-9/64/A-Seasonal-Approach-to-Lawn-Care:-Spring,-Summer,-Fall,-and-Winter-Tips",
  },
  {
    word: "fall",
    link: "https://lawnber.com/Blog/2023-9/64/A-Seasonal-Approach-to-Lawn-Care:-Spring,-Summer,-Fall,-and-Winter-Tips",
  },
  {
    word: "winter",
    link: "https://lawnber.com/Blog/2023-9/64/A-Seasonal-Approach-to-Lawn-Care:-Spring,-Summer,-Fall,-and-Winter-Tips",
  },
  {
    word: "mulch",
    link: "https://lawnber.com/Blog/2023-9/65/The-Art-of-Mulch-Selection:-Which-Type-Is-Right-for-Your-Garden",
  },
  {
    word: "compost",
    link: "https://lawnber.com/Blog/2023-9/66/Sustainable-Lawn-Care-Practices:-Eco-Friendly-Tips-for-a-Greener-Yard",
  },
  {
    word: "yard",
    link: "https://lawnber.com/Blog/2023-9/66/Sustainable-Lawn-Care-Practices:-Eco-Friendly-Tips-for-a-Greener-Yard",
  },
  {
    word: "hedge trimming",
    link: "https://lawnber.com/Blog/2023-9/67/-Hedge-Trimming-Safety:-Protecting-Yourself-and-Your-Landscape",
  },
  {
    word: "hedge trim",
    link: "https://lawnber.com/Blog/2023-9/67/-Hedge-Trimming-Safety:-Protecting-Yourself-and-Your-Landscape",
  },
  {
    word: "aeration",
    link: "https://lawnber.com/Blog/2023-9/68/The-Role-of-Aeration-in-Lawn-Health:-Why-It-Matters",
  },
  {
    word: "aerate",
    link: "https://lawnber.com/Blog/2023-9/68/The-Role-of-Aeration-in-Lawn-Health:-Why-It-Matters",
  },
  {
    word: "curb",
    link: "https://lawnber.com/Blog/2023-9/69/Creating-Curb-Appeal:-Landscaping-Tips-for-a-Beautiful-Home-Exterior",
  },
  {
    word: "landscaping",
    link: "https://lawnber.com/Blog/2023-9/69/Creating-Curb-Appeal:-Landscaping-Tips-for-a-Beautiful-Home-Exterior",
  },
  {
    word: "edge",
    link: "https://lawnber.com/Blog/2023-9/69/Creating-Curb-Appeal:-Landscaping-Tips-for-a-Beautiful-Home-Exterior",
  },
  {
    word: "pruning",
    link: "https://lawnber.com/Blog/2023-9/74/The-Fall-Cleanup-Arsenal:-Essential-Tools-and-Equipment-You'll-Need",
  },
  {
    word: "leaf rake",
    link: "https://lawnber.com/Blog/2023-9/74/The-Fall-Cleanup-Arsenal:-Essential-Tools-and-Equipment-You'll-Need",
  },
  {
    word: "mower",
    link: "https://lawnber.com/Blog/2023-9/74/The-Fall-Cleanup-Arsenal:-Essential-Tools-and-Equipment-You'll-Need",
  },
  {
    word: "blower",
    link: "https://lawnber.com/Blog/2023-9/74/The-Fall-Cleanup-Arsenal:-Essential-Tools-and-Equipment-You'll-Need",
  },
  {
    word: "shears",
    link: "https://lawnber.com/Blog/2023-9/74/The-Fall-Cleanup-Arsenal:-Essential-Tools-and-Equipment-You'll-Need",
  },
  {
    word: "watering",
    link: "https://lawnber.com/Blog/2023-9/73/Proper-Irrigation-Techniques:-Watering-Your-Lawn-the-Right-Way",
  },
  {
    word: "irrigation",
    link: "https://lawnber.com/Blog/2023-9/73/Proper-Irrigation-Techniques:-Watering-Your-Lawn-the-Right-Way",
  },
  {
    word: "lush lawn",
    link: "https://lawnber.com/Blog/2023-9/73/Proper-Irrigation-Techniques:-Watering-Your-Lawn-the-Right-Way",
  },
  {
    word: "weeds",
    link: "https://lawnber.com/Blog/2023-9/70/Weeding:-How-to-Keep-Unwanted-Guests-Out-of-Your-Garden",
  },
];

const serviceWeProvide = [
  {
    id: 0,
    serviceName: "Spring Cleanup",
    description:
      "With several lawn mowing packages to choose from, Lawnber helps eliminate your outdoor chores.",
  },
  {
    id: 1,
    serviceName: "Lawn Mowing",
    description:
      "Our easy, on-demand services cover all of your outdoor yard maintenance and lawn care.",
  },
  {
    id: 1,
    serviceName: "Fall Cleanup",
    description:
      "Our easy, on-demand services cover all of your outdoor yard maintenance and lawn care.",
  },
  {
    id: 2,
    serviceName: "Mulch",
    description:
      "We specialize in Mulch services that fit your personal style and the aesthetic of your home.",
  },
  {
    id: 3,
    serviceName: "Hedge Trimming",
    description:
      "Our easy, on-demand services cover all of your outdoor yard maintenance and lawn care.",
  },
  {
    id: 4,
    serviceName: "Snow removal",
    description:
      "With no contracts or seasonal fees, Lawnber only charges for snow removal when you need it.",
  },
];

const months = [
  { monthName: "January", id: 0 },
  { monthName: "February", id: 1 },
  { monthName: "March", id: 2 },
  { monthName: "April", id: 3 },
  { monthName: "May", id: 4 },
  { monthName: "June", id: 5 },
  { monthName: "July", id: 6 },
  { monthName: "August", id: 7 },
  { monthName: "September", id: 8 },
  { monthName: "October", id: 9 },
  { monthName: "November", id: 10 },
  { monthName: "December", id: 11 },
];

const places = [
  { id: 0, placeName: "Albertson", zip: "NY 11507" },
  { id: 1, placeName: "Baldwin", zip: "NY 11510" },
  { id: 2, placeName: "Bayport", zip: "NY 11705" },
  { id: 3, placeName: "Bay Shore", zip: "NY 11706" },
  { id: 4, placeName: "Bethpage", zip: "NY 11714" },
  { id: 5, placeName: "Brooklyn", zip: "NY 11221" },
  { id: 6, placeName: "East Meadow", zip: "NY 11554" },
  { id: 7, placeName: "Elmont ", zip: "NY 11003" },
  { id: 8, placeName: "Freeport", zip: "NY 11520" },
  { id: 9, placeName: "Great Neck", zip: "NY 11020" },
  { id: 10, placeName: "Glen Head", zip: "NY 11545" },
  { id: 11, placeName: "Glen Clove", zip: "NY 11542" },
  { id: 12, placeName: "Hewlett", zip: "NY 11557" },
  { id: 13, placeName: "Levittown", zip: "NY 11756" },
  { id: 14, placeName: "Lynbrook", zip: "NY 11563" },
  { id: 15, placeName: "Montauk", zip: "NY 11791" },
  { id: 16, placeName: "Mineola", zip: "NY 11501" },
  { id: 17, placeName: "New Hyde Park", zip: "NY 11040" },
  { id: 18, placeName: "North Bellmore", zip: "NY 11710" },
  { id: 19, placeName: "Oyster Bay", zip: "NY 11771" },
  { id: 20, placeName: "Patchogue", zip: "NY 11772" },
  { id: 21, placeName: "Plainview", zip: "NY 11803" },
  { id: 22, placeName: "Patchogue", zip: "NY 11772" },
  { id: 23, placeName: "Queens", zip: "NY 11435" },
  { id: 24, placeName: "Selden", zip: "NY 11782" },
  { id: 25, placeName: "Syosset", zip: "NY 11791" },
  { id: 26, placeName: "South Hempstead", zip: "NY 115500" },
  { id: 27, placeName: "Uniondale", zip: "NY 11553" },
  { id: 28, placeName: "Valley Stream", zip: "NY 11581" },
  { id: 29, placeName: "Woodmere", zip: "NY 11598" },
//   { id: 30, placeName: "Williston Park", zip: "NY 11596" },
//   { id: 31, placeName: "Westbury", zip: "NY 11590" },
];

export {
  allServices,
  allServicesQA,
  targetWords,
  serviceWeProvide,
  months,
  places,
};
